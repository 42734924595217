import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, InputAdornment, Button, CircularProgress } from '@material-ui/core';

import { toast } from 'react-toastify';
import { Scope } from '@unform/core';
import { isEqual } from 'lodash';
import TitleCard from '../../../title/title-card';
import TextField from '../../../form/text-field';
import Checkbox from '../../../form/checkbox';
import PriceField from '../../../form/price-field';
import Icons from '../../../settings/components/icons';
import { StyledPaper, useStyles } from '../../../styles';

import { ReactComponent as Balcony } from '../../../../assets/balcony.svg';
import { ReactComponent as Delivery } from '../../../../assets/delivery.svg';
import { useBranch } from '../../../../contexts/branch';
import { useSettings } from '../../../../contexts/settings';
import { useAuth } from '../../../../contexts/auth';

export default function Logistic({ formRef, address, correios }) {
  const { verifyPerms } = useAuth();
  const { addCorreios, updateOneBranchAddress } = useBranch();
  const { fetchSettings } = useSettings();

  const [isLoading, setLoading] = useState(false);
  const [realState, setRealState] = useState(correios);
  const [hasExpedition, setExpedition] = useState(address?.has_order_expedition);
  const [hasPickup, setPickup] = useState(address?.has_order_pickup);
  const [correiosState, setCorreios] = useState(correios.enabled);
  const styles = useStyles();
  const { id } = useParams();

  const handleModify = useCallback((param, data) => {
    const newParam = {};
    newParam[param] = data;

    if (param === 'overlay_price') {
      newParam[param] = (Number(data) * 1000);
    }

    setRealState({ ...realState, ...newParam });
  }, [realState]);

  const modify = useMemo(() => isEqual(correios, realState), [realState, correios]);

  const updatePickup = useCallback(async () => {
    try {
      const data = {
        has_order_pickup: !hasPickup,
      };
      await updateOneBranchAddress(id, data);
      setPickup(!hasPickup);
      toast.success('Os dados da farmácia foram atualizados');
    } catch (err) {
      toast.error('Ocorreu um erro au atualizar os dados da farmácia');
    }
  }, [id, updateOneBranchAddress, hasPickup]);

  const updateExpedition = useCallback(async () => {
    try {
      const data = {
        has_order_expedition: !hasExpedition,
      };
      await updateOneBranchAddress(id, data);
      setExpedition(!hasExpedition);
      toast.success('Os dados da farmácia foram atualizados');
    } catch (err) {
      toast.error('Ocorreu um erro au atualizar os dados da farmácia');
    }
  }, [id, updateOneBranchAddress, hasExpedition]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();
      await addCorreios({ ...data.correios, enabled: correiosState, branch_id: id });
      await fetchSettings({});
      setLoading(false);
      return toast.success('Os dados da farmácia foram atualizados');
    } catch (err) {
      setLoading(false);
      return toast.error('Ocorreu um erro au atualizar os dados da farmácia');
    }
  }, [addCorreios, correiosState, formRef, fetchSettings, id]);

  return (
    <Scope path="correios">
      <TitleCard>Geral</TitleCard>

      <StyledPaper>
        <h5 style={{ fontWeight: 'bold' }}>Operação</h5>

        <Grid container spacing={4} style={{ marginBottom: 16, marginTop: 16 }} className={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) && 'disabled-events'}>
          <Grid item onClick={updatePickup}>
            <div style={{
              border: '2px solid', borderRadius: 10, height: 150, width: 260, cursor: 'pointer', borderColor: hasPickup ? '#22DFA2' : '#E1E1E1',
            }}
            >
              <Grid container>
                <Grid sm={6}>
                  <Icons color={hasPickup ? '#22DFA2' : '#E1E1E1'} ml="20px" mt="40px">
                    <Balcony />
                  </Icons>
                </Grid>

                <Grid sm={6}>
                  <div style={{ fontSize: 12, marginRight: 20, marginTop: 27 }}>
                    Clientes podem fazer a retirada do produto adquirido no balcão desta loja.
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item>
            <div style={{
              border: '2px solid', borderRadius: 10, height: 150, width: 260, cursor: 'pointer', borderColor: hasExpedition ? '#22DFA2' : '#E1E1E1',
            }}
            >
              <Grid container onClick={updateExpedition}>
                <Grid sm={6}>
                  <Icons color={hasExpedition ? '#22DFA2' : '#E1E1E1'} ml="24px" mt="50px">
                    <Delivery />
                  </Icons>
                </Grid>

                <Grid sm={6}>
                  <div style={{ fontSize: 12, marginRight: 20, marginTop: 27 }}>
                    Esta loja entrega produtos via motoboys, transportadoras e/ou Correios.
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <h5 style={{ fontWeight: 'bold' }}>Correios</h5>

        <Grid container spacing={4} style={{ marginBottom: -8, marginTop: 0 }}>
          <Grid container item spacing={1}>
            {/* <Grid item sm={3} xs={12}>
              <Checkbox
                name="availability"
                label="Quero colocar meu número de contrato"
              />
            </Grid> */}

            <Grid item sm={3} xs={12}>
              <Checkbox
                name="enabled"
                checked={!correiosState}
                onClick={() => setCorreios(!correiosState)}
                onChange={() => handleModify('enabled', !correiosState)}
                label="Não quero operar com os Correios"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <PriceField
                name="overlay_price"
                onChange={(e) => handleModify('overlay_price', e.target.value)}
                label="Sobretaxar ou Subsidiar Serviços dos Correios"
                separator="."
                InputProps={{
                  startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              name="contract_code"
              onChange={(e) => handleModify('contract_code', e.target.value)}
              label="Número do contrato"
              placeholder="Nome do contrato"
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <TextField
              name="password"
              onChange={(e) => handleModify('password', e.target.value)}
              label="Senha"
              placeholder="Senha"
            />
          </Grid>

          <Grid container item sm={12} xs={12} justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={styles.buttonPrimary}
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'write' }) || modify}
                onClick={onSubmit}
                type="button"
                style={{ width: 210 }}
              >
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </StyledPaper>
    </Scope>
  );
}
