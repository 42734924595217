/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import api from '../api';

import errHandler from '../actions/_errhandler';

interface ColaboratorsItem {
  id: string;
  is_enabled: boolean;
  name: string;
  branch: {
    id: string;
  }
}

interface RegisterAgent {
  username: string;
  phone_number: string;
  phone_ddd: string;
  phone_ddi: string;
  branch_id: string;
  is_enabled: boolean;
  email: string;
  name: string;
  gender: string;
  store_id: string;
  document: string;
  password: string;
  department: number;
  group_id: number;
  is_admin: boolean;
}

interface SearchCredentials {
  name?: string;
  is_aneblad?: boolean;
  group_id?: string;
}

interface ErpUpdateData {
  agent_erp_name: string;
  agent_erp_id: number;
}

interface ColaboratorContextData {
  isLoading: boolean;
  colaborators: ColaboratorsItem[];
  preColaborators: ColaboratorsItem[];
  fetchColaborators(params: object): Promise<void>;
  fetchPreRegistrations(): Promise<void>;
  getOneColaborator(id: string): Promise<ColaboratorsItem>
  addColaborator(colaborator: RegisterAgent): Promise<void>;
  addNewAgent(colaborator: RegisterAgent): Promise<void>;
  updateAgent(id: string | number, colaborator: RegisterAgent): Promise<void>;
  updateColaborator(id: string): Promise<void>;
  deleteColaborator(id: number): Promise<void>;
  getPreAgent(document: string): Promise<void>;
  updatePreAgent(document: string | number, colaborator: RegisterAgent): Promise<void>;
  deletePreAgent(document: string): Promise<void>;
  updateErpData(agent_id: string, data: ErpUpdateData): Promise<void>;
  getErpData(agent_id: string): Promise<ErpUpdateData>;
}

const ColaboratorContext = createContext<ColaboratorContextData>({} as ColaboratorContextData);

export const ColaboratorsProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [colaborators, setColaboratos] = useState<ColaboratorsItem[]>(() => ({} as ColaboratorsItem[]));
  const [preColaborators, setPreColaborators] = useState<ColaboratorsItem[]>(() => ({} as ColaboratorsItem[]));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchColaborators = useCallback(async (params: any) => {
    try {
      setLoading(true);
      const response = await api.get('/v2/agent/search/name', {
        params: { ...params },
      });
      setColaboratos(response.data.agents || []);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  const fetchPreRegistrations = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/v2/agent/list-preregistration');
      setPreColaborators(response.data.items || []);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  useEffect(() => {
    async function getContributors() {
      await fetchColaborators({});
      await fetchPreRegistrations();
    }
    getContributors();
  }, [fetchColaborators, fetchPreRegistrations]);

  const getPreAgent = useCallback(async (document: string) => {
    try {
      const response = await api.get(`/v2/agent/pre-registration/${document}`);
      return response.data.item;
    } catch (err) {
      errHandler(err);
    }
  }, []);

  const updatePreAgent = useCallback(async (document: string, preAgent: any) => {
    try {
      await api.put(`/v2/agent/pre-registration/${document}`, { ...preAgent });
      await fetchPreRegistrations();
    } catch (err) {
      errHandler(err);
    }
  }, [fetchPreRegistrations]);

  const deletePreAgent = useCallback(async (document: string) => {
    try {
      await api.delete(`/v2/agent/pre-registration/${document}`);
      await fetchPreRegistrations();
    } catch (err) {
      errHandler(err);
    }
  }, [fetchPreRegistrations]);

  const addColaborator = useCallback(async (colaborator: any) => {
    try {
      await api.post('/v2/agent/pre-registration', colaborator);
      await fetchPreRegistrations();
    } catch (err) {
      errHandler(err);
    }
  }, [fetchPreRegistrations]);

  const addNewAgent = useCallback(async (colaborator: any) => {
    try {
      await api.post('/v2/agent-old', colaborator);
    } catch (err) {
      errHandler(err);
    }
  }, []);

  const updateAgent = useCallback(async (id: number, colaborator: any) => {
    try {
      await api.put(`/v2/agent/${id}`, { ...colaborator });
      await fetchColaborators({});
    } catch (err) {
      errHandler(err);
    }
  }, [fetchColaborators]);

  const getOneColaborator = useCallback(async (id: string) => {
    try {
      const response = await api.get(`/v2/agent/${id}`);

      return response.data;
    } catch (err) {
      errHandler(err);
    }
  }, []);

  const updateColaborator = useCallback(async (agent_id: string) => {
    try {
      await api.put(`/v2/agent/${agent_id}`);
    } catch (err) {
      errHandler(err);
    }
  }, []);

  const updateErpData = useCallback(async (agent_id: string, erp_data: any) => {
    try {
      await api.put(`/v2/agent/erp-data/${agent_id}`, erp_data);
    } catch (err) {
      errHandler(err);
    }
  }, []);

  const getErpData = useCallback(async (agent_id: string) => {
    try {
      const response = await api.get(`/v2/agent/erp-data?agent_erp_id=${agent_id}`);
      return response.data;
    } catch (err) {
      return null;
    }
  }, []);

  const deleteColaborator = useCallback(async (agent_id: number) => {
    try {
      await api.delete(`/v2/agent/${agent_id}`);
      await fetchColaborators({});
    } catch (err) {
      errHandler(err);
    }
  }, [fetchColaborators]);

  return (
    <ColaboratorContext.Provider
      value={{
        fetchColaborators,
        fetchPreRegistrations,
        addColaborator,
        addNewAgent,
        updateAgent,
        deleteColaborator,
        getOneColaborator,
        updateColaborator,
        deletePreAgent,
        getPreAgent,
        updateErpData,
        getErpData,
        updatePreAgent,
        isLoading,
        colaborators,
        preColaborators,
      }}
    >
      {children}
    </ColaboratorContext.Provider>
  );
};

export function useColaborators(): ColaboratorContextData {
  const context = useContext(ColaboratorContext);

  if (!context) {
    throw new Error('useColaborators must be used within an ColaboratorProvider');
  }
  return context;
}
