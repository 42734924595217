import React, { useCallback, useState } from 'react';

// Libs
import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

// Context
// import { useColaborators } from '../../../contexts/contributors';

// Styles
import { toast } from 'react-toastify';
import { useStyles } from '../../styles';
import { StyledTooltip } from '../../tooltip';

// Assets
// import Placeholder from '../../../assets/placeholder.svg';
import avatar from '../../../assets/avatar.png';

import DeleteModal from '../../deleteModal';
import { useColaborators } from '../../../contexts/contributors';
import { useAuth } from '../../../contexts/auth';

function ContributorsData({ colaborator }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();
  const { deleteColaborator } = useColaborators();
  const [modal, setModal] = useState(false);

  const handleDeleteColaborator = useCallback(async () => {
    try {
      await deleteColaborator(colaborator.id);

      toast.success('Colaborador deletado com sucesso!');
      setModal(false);
    } catch (err) {
      setModal(false);
      toast.error('Não foi possível deletar este colaborador');
    }
  }, [colaborator, deleteColaborator]);

  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          {colaborator.picture
            ? (
              <img src={colaborator?.picture} alt={colaborator.name || 'image'} style={{ borderRadius: '50%' }} className={styles.tableImg} />
            )
            : (
              <img src={avatar} className={styles.tableImg} style={{ borderRadius: '50%' }} alt="placeholder" />
            )}

          {colaborator.name}
        </Grid>
      </TableCell>

      <TableCell>{colaborator?.department?.name || 'Não definido'}</TableCell>

      <TableCell>{colaborator?.group?.name || 'Não definido'}</TableCell>

      <TableCell>{colaborator?.branch?.title || 'Não definido'}</TableCell>

      <TableCell>{colaborator.is_enabled ? 'Ativo' : 'Inativo'}</TableCell>

      <TableCell>
        <Grid container justify="flex-end">
          <Grid item>
            <StyledTooltip title="Editar Colaborador" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Orga. > Colab.', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/add-contributor/${colaborator.id}`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title="Remover Colaborador" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Orga. > Colab.', access: 'delete' })}
                className={styles.actionButton}
                component={Link}
                onClick={() => setModal(!modal)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      <DeleteModal type="colaborador" prefix="o" title={colaborator.name} openModal={modal} setModal={setModal} deleteFunction={handleDeleteColaborator} />

    </>
  );
}

export default ContributorsData;
