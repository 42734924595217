import React from 'react';

import { IconButton, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import { remove } from 'lodash';
import { toast } from 'react-toastify';

import SubtitleCard from '../title/subtitle-card';
import TitleCard from '../title/title-card';
import Select from '../form/select';

import actions from '../../actions';
import useListLoader from '../../hooks/useListLoader';

import {
  StyledPaper, Container, AddButton, useStyles, ButtonGhost, TextButtonGhost,
} from '../styles';

export default function Suggestions({
  similarProducts,
  setSimilarProducts,
  buyWith,
  setBuyWith,
  productId,
  refetch,
  product: selectedProduct,
}) {
  const styles = useStyles();

  const isAvailableInEcommerce = !!selectedProduct?.ecommerce?.is_available;
  const isAvailableInCallcenter = !!selectedProduct?.callcenter?.is_available;

  const { items: products, isLoading } = useListLoader('product',
    {
      'items-per-page': 1000,
      'sort-by': 'title',
      ascending: 1,
      'available-ecommerce': isAvailableInEcommerce,
      'available-prescription': isAvailableInCallcenter,
    });

  const handleBuyWith = async (product, indexProduct) => {
    const item = buyWith[indexProduct];

    item.id = product.id;
    item.title = product.title;

    const productsIds = buyWith?.map(productItem => productItem?.id);

    const update = await actions.products.updateBuyWith(productsIds, productId);

    setBuyWith(buyWith);

    return update;
  };

  const handleSimilar = async (product, indexProduct) => {
    const item = similarProducts[indexProduct];

    item.id = product.id;
    item.title = product.title;

    const productsIds = similarProducts?.map(similarProduct => similarProduct?.id);

    const update = await actions.products.updateSimilar(productsIds, productId);

    setSimilarProducts(similarProducts);

    return update;
  };

  const handleRemoveSimilar = async (data) => {
    const list = similarProducts;

    remove(list, (x) => x.id === data.id);

    setSimilarProducts(list);

    const productsIds = list?.map(similarProduct => similarProduct?.id);

    const update = await actions.products.updateSimilar(productsIds, productId);

    refetch();

    toast.success('Produto removido com sucesso!');

    return update;
  };

  const handleRemoveBuyWith = async (data) => {
    const list = buyWith;

    remove(list, (x) => x.id === data.id);

    setBuyWith(list);

    const productsIds = list?.map(buyWithProduct => buyWithProduct?.id);

    const update = await actions.products.updateBuyWith(productsIds, productId);

    refetch();

    toast.success('Produto removido com sucesso!');

    return update;
  };

  const renderProducts = ({ item, index }) => <MenuItem key={item.id} onClick={() => handleSimilar(item, index)} value={item.id}>{item.title}</MenuItem>;
  const renderBuyWith = ({ item, index }) => <MenuItem key={item.id} onClick={() => handleBuyWith(item, index)} value={item.id}>{item.title}</MenuItem>;

  return (
    <>
      <TitleCard>Sugestões da compra</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard>Produtos Semelhantes</SubtitleCard>
        </Grid>

        {similarProducts?.map((item, index) => {
          if (isLoading) {
            return <Skeleton key={item.id} height="58px" width="100%" />;
          }

          return (
            <Grid item={item.id} container direction="row" className={styles.gridCategoriesInputContainer}>
              <Grid item xs={11} className={styles.gridCategoriesInputSelect}>
                <Select
                  name="produto"
                  label="Escolha um Produto"
                  options={products}
                  initialValue={item?.id}
                  index={index}
                >
                  {renderProducts}
                </Select>
              </Grid>

              <Grid item xs={1} className={styles.gridCategoriesInputCloseIcon2}>
                <CloseIcon color="disabled" onClick={() => handleRemoveSimilar(item)} />
              </Grid>
            </Grid>
          );
        })}

        <Grid container direction="row" className={styles.gridCategoriesInputContainer}>
          <Grid
            item
            className={styles.gridCategoriesIconAdd}
          >
            <IconButton
              type="button"
              onClick={() => setSimilarProducts([...similarProducts, {}])}
              className={styles.iconButton}
            >
              <AddCircleOutlineIcon fontSize="small" color="primary" />
            </IconButton>

            <AddButton
              onClick={() => setSimilarProducts([...similarProducts, {}])}
              type="button"
            >
              Adicionar Produto
            </AddButton>
          </Grid>
        </Grid>

        <Container>
          <Grid container direction="row" className={styles.gridPaperTop}>
            <SubtitleCard>Compre junto</SubtitleCard>
          </Grid>

          {buyWith?.map((item, index) => {
            if (isLoading) {
              return <Skeleton key={item.id} height="58px" width="100%" />;
            }

            return (
              <Grid container direction="row" className={styles.gridCategoriesInputContainer}>
                <Grid item xs={11} className={styles.gridCategoriesInputSelect}>
                  <Select
                    name="produto"
                    label="Escolha um Produto"
                    options={products}
                    initialValue={item?.id}
                    index={index}
                  >
                    {renderBuyWith}
                  </Select>
                </Grid>

                <Grid item xs={1} className={styles.gridCategoriesInputCloseIcon2}>
                  <CloseIcon color="disabled" onClick={() => handleRemoveBuyWith(item)} />
                </Grid>
              </Grid>
            );
          })}

          <Grid container direction="row" className={styles.gridCategoriesInputContainer}>
            <ButtonGhost onClick={() => setBuyWith([...buyWith, {}])} disabled={buyWith.length >= 2}>
              <AddCircleOutlineIcon fontSize="small" color="primary" />
              <TextButtonGhost>Adicionar Produto</TextButtonGhost>
            </ButtonGhost>
          </Grid>
        </Container>

      </StyledPaper>
    </>
  );
}
