import { pickBy } from 'lodash';
import { useState } from 'react';

const defaultValues = {};

export default function useCustomImage({ initialValues = {}, implicitValues = {} }) {
  const [imageValues, setImageValues] = useState({});
  function getAllValues() {
    return {
      // defaults
      ...defaultValues,
      ...initialValues,

      // Always override with implicit (invisible) params
      ...implicitValues,
    };
  }

  function getVisibleValues(values) {
    return Object.entries(values).reduce((memo, [key, val]) => {
      // don't show keys that are implicit
      if (implicitValues[key] === undefined) {
        return {
          ...memo,
          [key]: val || '',
        };
      }

      return memo;
    }, {});
  }


  function updateQuery(newValues) {
    const allParams = pickBy({
      ...getAllValues(),
      ...newValues,
    }, (v) => v !== undefined);

    const visible = getVisibleValues(allParams);

    setImageValues(visible);
  }

  return {
    params: getAllValues(),
    update: updateQuery,
    imageValues,
  };
}
