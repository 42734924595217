import React, { useState, useCallback } from 'react';
import { Grid, CircularProgress, Collapse, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Scope } from '@unform/core';
import { toast } from 'react-toastify';
// import MdEditor from 'react-markdown-editor-lite';
// import MarkdownIt from 'markdown-it';
import ReactMarkdown from 'react-markdown';
import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import TitleCard from '../../title/title-card';
import TextFieldMulti from '../../form/text-fieldmulti';

import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import { useAuth } from '../../../contexts/auth';

export default function Others({ formRef, content, social_description }) {
  const { verifyPerms } = useAuth();

  const [slogan, setSlogan] = useState(content || '');
  const [description, setDescription] = useState(social_description || '');

  const [isCollapsed, setCollapse] = useState(false);
  const { updatePolicy, updateSocialMedia } = useSettings();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();
      const sectionData = {
        name: 'Slogan',
        content: data.slogan.content,
      };
      await updatePolicy(sectionData, 'slogan');
      await updateSocialMedia({ description: data.slogan.description });
      setLoading(false);

      return toast.success('Dados atualizados com sucesso!');
    } catch (err) {
      setLoading(false);
      return toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updatePolicy, formRef, setLoading, updateSocialMedia]);

  function lengthLabel(data) {
    if (data.length > 50) return;

    setSlogan(data);

    return data.slogan ? data.slogan.length : 0;
  }

  function lengthDescrip(data) {
    if (data.length > 160) return;

    setDescription(data);

    return data.descrip ? data.descrip.length : 0;
  }

  // const handleCollpase = useCallback(() => {
  //   setCollapse(!isCollapsed);
  // }, [isCollapsed]);

  return (
    <Scope path="slogan">
      <TitleCard color="#A3A3A3" bold>Textos de Apoio </TitleCard>

      <StyledPaper>

        <GridContainer width="100%" mr="24px" container>

          <GridContainer container item margin="12px" spacing={3}>
            <GridContainer container item lg={12} spacing={3}>
              <GridContainer item lg={3}>
                <TextFieldMulti
                  name="content"
                  label="Slogan"
                  style={{ marginBottom: '15px' }}
                  multiline
                  value={slogan}
                  rows={4}
                  rowsMax={4}
                  onChange={(e) => lengthLabel(e.target.value)}
                  helperText={`${slogan ? slogan.length : 0}/50 Caracteres`}
                  InputProps={{
                    maxLength: 50,
                    endAdornment: (
                      <InputAdornment style={{ marginTop: '-35px', cursor: 'pointer' }} position="end">
                        {isCollapsed ? (
                          <VisibilityOff color="disabled" onClick={() => setCollapse(!isCollapsed)} fontSize="default" />

                        ) : (
                          <Visibility color="primary" onClick={() => setCollapse(!isCollapsed)} fontSize="default" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                <Collapse in={isCollapsed}>
                  <TitleCard color="#A3A3A3" bold>Visualização do Slogan</TitleCard>
                  <StyledPaper style={{ fontSize: '12px', wordWrap: 'break-word' }}>
                    <ReactMarkdown type="text" source={slogan} />
                  </StyledPaper>
                </Collapse>
              </GridContainer>

              <Grid item lg={3} justify="center">
                <Text color="#A3A3A3"> DICA: Para a correta visualização do Slogan, limite-se a duas linhas de texto e respeite o comprimento do formulário.</Text>
              </Grid>

              <GridContainer item lg={6}>
                <TextFieldMulti
                  name="description"
                  label="Descrição do Site"
                  style={{ marginBottom: '15px' }}
                  multiline
                  value={description}
                  rows={4}
                  rowsMax={4}
                  onChange={(e) => lengthDescrip(e.target.value)}
                  helperText={`${description ? description.length : 0}/160 Caracteres`}
                  InputProps={{
                    maxLength: 160,
                  }}
                />
              </GridContainer>
            </GridContainer>
            <GridContainer container item lg={12} mt="0" justify="flex-end">
              <ButtonPrimary disabled={verifyPerms({ role: 'Conf. Website', access: 'write' })} onClick={onSubmit} variant="contained" color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>
        </GridContainer>
      </StyledPaper>
    </Scope>
  );
}
