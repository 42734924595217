import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { TextArea } from './styles';

export default function TabPanel(props) {
  const { name, children, value, index, ...other } = props;

  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <TextArea
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      rows="9"
      ref={inputRef}
      defaultValue={defaultValue}
      {...other}
    />
  );
}
