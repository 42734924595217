import React, { useCallback, useRef } from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';

import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Container } from '../styles';
import Modal from '../modal';
import SubtitleCard from '../title/subtitle-card';
import Select from '../form/select';

export default function FilterStatus({ isVisible, setVisible, handleParams }) {
  const formRef = useRef();
  const options = [
    { id: 0, type: 'Todos Pedidos' },
    { id: 10, type: 'Pedido Criado' },
    { id: 20, type: 'Pedido Aprovado' },
    { id: 55, type: 'Receita Médica Retida' },
    { id: 30, type: 'Pedido Em Manipulação' },
    { id: 50, type: 'Pedido Finalizado' },
  ];

  const renderItem = ({ item, handleValue }) => (
    <MenuItem
      key={item.id}
      value={item.id}
      onClick={handleValue}
    >
      {item.type}
    </MenuItem>
  );

  const onSubmit = useCallback(async () => {
    try {
      const { status_id } = formRef.current.getData();

      const schema = Yup.object().shape({
        status_id: Yup.string().required('Nenhum status foi selecionado'),
      });
      await schema.validate({ status_id }, { abortEarly: false });

      await handleParams('status', status_id);

      setVisible(false);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        toast.error(err.errors[0]);
        return formRef.current.setErrors(validationErrors);
      }
      return toast.error('Ocorreu um erro. Tente novamente');
    }
  }, [handleParams, setVisible]);

  return (
    <Modal
      isVisible={isVisible}
      toggle={() => setVisible(!isVisible)}
      buttonPrimary="filtrar"
      buttonSecondary="cancelar"
      title="Filtrar"
      modalStyle={2}
      onSubmit={onSubmit}
    >
      <Form ref={formRef}>
        <Container style={{ margin: '16px 0 0', paddingBottom: 24 }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <SubtitleCard>Selecione um status</SubtitleCard>
            </Grid>

            <Grid item xs={12}>
              <Select name="status_id" label="Status" options={options}>{renderItem}</Select>
            </Grid>

          </Grid>
        </Container>
      </Form>
    </Modal>
  );
}
