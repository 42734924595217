import * as Yup from 'yup';

export const BlogSchema = [
  {
    name: 'title',
    label: 'Titulo',
    isFilter: false,
  },
  {
    name: 'status',
    label: 'Status',
    isFilter: false,
  },
  {
    name: 'publication_date',
    label: 'Publicação',
    isFilter: false,
  },
  {
    name: 'updated',
    label: 'Atualizado',
    isFilter: false,
  },
  {
    name: 'writer',
    label: 'Autor',
    isFilter: false,
  },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];

export const NewPostSchema = Yup.object().shape({
  author: Yup
    .string()
    .required('O campo autor é obrigatório')
    .min(0)
    .max(60, 'O nome do autor deve ter até 60 caracteres'),
  title: Yup
    .string()
    .required('O campo título é obrigatório')
    .min(0)
    .max(50, 'O título deve ter até 50 caracteres'),
  abstract: Yup
    .string()
    .min(0)
    .max(300, 'O resumo deve ter até 300 caracteres'),
  body: Yup
    .string()
    .min(0)
    .max(65535, 'O resumo deve ter até 65535 caracteres'),
});
