import React from 'react';
import ReactDOM from 'react-dom';

import TagManager from 'react-gtm-module';

// import actions from './actions/index';
import Router from './routes';
import store from './store';
import config from './config';

const tagManagerArgs = {
  gtmId: config.gtmID,
};

TagManager.initialize(tagManagerArgs);

function render() {
  ReactDOM.render(<Router />, document.getElementById('root'));
}

async function start() {
  store.set({
    category_byId: {},
    category_queries: {},
    product_queries: {},
    product_byId: {},
  });
  // const env = process.env.REACT_APP_API_URL || {};

  // const location = window.location.host;

  // await actions.token.getCredentials(env.REACT_APP_DOMAIN || 'localhost:3000');
  // await actions.token.getSettings();
  // await actions.category.getCategory();

  render();
}

start();
