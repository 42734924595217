import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Container } from '../../styles';

export default function DeleteCategory({ category }) {
  return (
    <Container style={{ margin: '30px 0 0 0' }}>
      <Grid container justify="center" direction="column" alignItems="center">
        <Grid item>
          <span>Tem certeza que deseja remover a categoria<span style={{ fontWeight: 'bold' }}>{` ${category.name}`}</span>?</span>
        </Grid>

        <Grid item>
          <span>Esta ação não poderá ser desfeita.</span>
        </Grid>
      </Grid>
    </Container>
  );
}
