import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  grid: {
    margin: '32px 0',
  },

  item: {
    fontSize: '12px',
    lineHeight: '14px',
    width: 'auto',
  },
}));

export const ItemContainer = styled.button`
  background: none;
  border: 2px solid #22E0A1;
  border-radius: 4px;
  color: #22E0A1;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 32px;
  line-height: 26px;
  margin: 0 12px;
  width: 32px;

  ${props => props.active && css`
    background: #22E0A1;
    color: #FFF
  `}

  ${props => props.noBorder && css`
    border: none;
  `}
`;
