import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';

import { Button, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import Subtitle from '../../text/subtitle';
import Text from '../../text/text';
import TitleCard from '../../title/title-card';

import Step from '../stepper';
import Status from './order-status';

import { useOrder } from '../../../contexts/order';
import Spinner from '../../spinner';

import Customer from './customer';
import Resume from './resume';
import Payment from './payment';
import Expedition from './expedition';
import ProductOrder from './product-order';

export default function Details({ setDetails, orderId, setOrderAlias }) {
  const formRef = useRef();
  const history = useHistory();

  const [order, setOrder] = useState();

  const { fetchOneOrder } = useOrder();

  useEffect(() => {
    const getOrder = async () => {
      try {
        const data = await fetchOneOrder(orderId);
        setOrderAlias(data.order.alias);
        setOrder(data);
      } catch (err) {
        toast.error(`Não foi possível obter detalhes deste do pedido ${orderId}`);
        history.push('/orders');
      }
    };

    getOrder();
  }, [fetchOneOrder, orderId, history, setOrderAlias]);

  const updateOrder = useCallback(async () => {
    try {
      const data = await fetchOneOrder(orderId);
      setOrderAlias(data.order.alias);
      setOrder(data);
    } catch (err) {
      toast.error(`Não foi possível obter detalhes deste do pedido ${orderId}`);
      history.push('/orders');
    }
  }, [fetchOneOrder, history, orderId, setOrderAlias]);

  const handleToBack = useCallback(() => {
    setDetails(false);
    history.goBack();
  }, [setDetails, history]);

  const initialData = { delivery: order?.shipping_address };

  const agroupProductsByInquiryId = order?.order.inquiry_display_ids ? order?.products.reduce((acc, product) => {
    const id = product.inquiry_display_id || product.inquiry_id || '';

    if (!acc[id]) {
      acc[id] = [];
    }

    acc[id].push(product);

    return acc;
  }, {}) : null;

  return order?.order?.id ? (
    <Form ref={formRef} initialData={initialData}>
      <Grid container justify="center" md={12}>
        <Grid container md={11} item spacing={2}>
          <Grid item style={{ color: '#A3A3A3' }}>
            <ArrowBack onClick={handleToBack} style={{ cursor: 'pointer' }} />
          </Grid>

          <Grid item>
            <Subtitle color="#A3A3A3">Pedido {order.order.label} {order.order.inquiry_display_ids ? `a partir da Solicitação ${order.order.inquiry_display_ids.join(', ')}` : ''}</Subtitle>
          </Grid>

          <div style={{ border: '0.1px solid #E1E1E1', width: '100%', marginTop: 4, marginBottom: 50 }} />

          <Grid container justify="center" alignItems="center" item md={12}>
            <Step step={order.order.status} />
          </Grid>
        </Grid>

        <Grid item md={10} style={{ marginBottom: 12 }}>
          <Grid container justify="center" spacing={3}>
            <Grid item md={4}>
              <TitleCard>
                Status do Pedido
              </TitleCard>

              <Status updateOrder={updateOrder} pickup={order.shipping_address.pickup_on_pharmacy} order={order} />
            </Grid>

            <Grid item md={8}>
              <TitleCard>
                <Grid container item alignItems="center">
                  <Grid item xs={9}>
                    <Grid container>
                      <Grid item>
                        Resumo do Pedido
                      </Grid>

                      <Grid item>
                        <Text color="#A3A3A3">&nbsp;・ realizado em {format(order.order.created_at, 'dd/MM/yyy')}</Text>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container xs={3} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => {
                        const win = window.open(`/orders/${order.order.id}/print`, '_blank');
                        win.focus();
                      }}
                      style={{
                        marginRight: 16,
                      }}
                    >
                      Imprimir
                    </Button>
                  </Grid>

                </Grid>
              </TitleCard>

              <Resume order={order} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={10} style={{ marginTop: 12 }}>
          <Grid container justify="center" spacing={3}>

            <Grid item md={(order.payment || order.alternative_payment) ? 4 : 6}>
              <TitleCard>
                Dados do Comprador
              </TitleCard>

              <Customer order={order} />
            </Grid>

            {(order.payment || order.alternative_payment) && (
              <Grid item md={4}>
                <TitleCard>
                  Pagamento
                </TitleCard>

                <Payment order={order} updateOrder={updateOrder} />
              </Grid>
            )}

            <Grid item md={(order.payment || order.alternative_payment) ? 4 : 6}>
              <TitleCard>
                Expedição do Pedido
              </TitleCard>

              <Expedition order={order} />
            </Grid>
          </Grid>
        </Grid>

        {
          agroupProductsByInquiryId ? Object.keys(agroupProductsByInquiryId).map((inquiryId) => (
            <Fragment key={inquiryId}>
              <Grid item md={10} style={{ marginBottom: 36, marginTop: 40 }}>
                <Subtitle color="#A3A3A3" ml="16px">{inquiryId ? 'Itens da solicitação' : 'Itens do pedido'}</Subtitle>

                <div style={{ border: '0.1px solid #E1E1E1', width: '100%', marginTop: 4 }} />
              </Grid>

              <Grid container justify="center" spacing={3}>
                {agroupProductsByInquiryId[inquiryId].map((product) => (
                  <Grid item md={10}>
                    <TitleCard>
                      <Grid container item alignItems="center" spacing={1}>
                        <Grid item>
                          {product.title}
                        </Grid>

                        <Grid item>
                          <Text color="#A3A3A3">e demais componentes</Text>
                        </Grid>
                      </Grid>
                    </TitleCard>

                    <ProductOrder product={product} />
                  </Grid>

                ))}
              </Grid>
            </Fragment>
          )) : (
            <>
              <Grid item md={10} style={{ marginBottom: 36, marginTop: 40 }}>
                <Subtitle color="#A3A3A3" ml="16px">Itens do pedido</Subtitle>

                <div style={{ border: '0.1px solid #E1E1E1', width: '100%', marginTop: 4 }} />
              </Grid>

              <Grid container justify="center" spacing={3}>
                {order?.products.map((product) => (
                  <Grid item md={10}>
                    <TitleCard>
                      <Grid container item alignItems="center" spacing={1}>
                        <Grid item>
                          {product.title}
                        </Grid>

                        <Grid item>
                          <Text color="#A3A3A3">e demais componentes</Text>
                        </Grid>
                      </Grid>
                    </TitleCard>

                    <ProductOrder product={product} />
                  </Grid>
                ))}
              </Grid>
            </>
          )
        }

      </Grid>
    </Form>
  )
    : (
      <Spinner />
    );
}
