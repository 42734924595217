import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useField } from '@unform/core';

import TextField from '@material-ui/core/TextField';
import { setCurrency } from '../../utils/currency';

import { useStyles } from './styles';

function CurrencyField({
  name, include, theme, onChange, initialValue, value, separator, offerDiscount, discountPrice, onLoadFieldValue, ...rest
}) {
  const styles = useStyles();
  const refs = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [state, setState] = useState({
    value: initialValue,
  });
  const firstLoad = useRef(true);

  function handleChange(e) {
    const valuesAsCurrency = setCurrency(e.target.value, separator);

    if (valuesAsCurrency === '0.00') {
      setState({ value: undefined });
    } else {
      setState({ value: valuesAsCurrency });
    }

    if (onChange) {
      e.persist();

      onChange(e, valuesAsCurrency);
    }
  }

  const getValue = useCallback(() => value ?? state.value, [state.value, value]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: refs.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (firstLoad.current && onLoadFieldValue) {
      firstLoad.current = false;
      onLoadFieldValue(defaultValue);
    } else if (onLoadFieldValue) {
      onLoadFieldValue(getValue());
    }
  }, [defaultValue, onLoadFieldValue, getValue]);

  return (
    <TextField
      className={styles.textField}
      type="number"
      value={discountPrice ? (!offerDiscount ? 0 : getValue()) : getValue()}
      onChange={(e) => handleChange(e)}
      error={error}
      helperText={error}
      defaultValue={defaultValue === '0.00' ? undefined : defaultValue}
      inputRef={refs}
      variant="outlined"
      {...rest}
    />
  );
}

CurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  include: PropTypes.string,
  theme: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  separator: PropTypes.oneOf(['.', ',']).isRequired,
};

export default CurrencyField;
