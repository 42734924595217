import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Scope } from '@unform/core';
import CheckBox from '../form/checkbox';
import PriceField from '../form/price-field';
import TextField from '../form/text-field';
import { StyledPaper, useStyles } from '../styles';
import SubtitleCard from '../title/subtitle-card';
import TitleCard from '../title/title-card';

export default function Pricing({
  setMinimum, minimum, priceWithDiscount, setPriceWithDiscount, offerDiscount, setOfferDiscount, setPrice, setDiscountPrice, setBulkConfigPrice, isPriceLowerThanDiscountPrice, isDiscountPriceLowerThanBulkConfigPrice,
}) {
  const styles = useStyles();

  useEffect(() => {
    if (!offerDiscount) {
      setPriceWithDiscount('');
      setOfferDiscount('');
    }
  }, [offerDiscount, setPriceWithDiscount, setOfferDiscount]);

  return (
    <>
      <TitleCard>Precificação</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <Grid item xs={12}>
            <PriceField
              name="price"
              label="Preço Sugerido"
              onChange={(event) => setPrice(event.currentTarget.value)}
              separator="."
              InputProps={{
                startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} className={styles.gridPricingTextField} style={{ marginBottom: isPriceLowerThanDiscountPrice ? '1rem' : '' }}>
            <PriceField
              name="discount_price"
              label="Preço de Venda"
              onChange={(event) => setDiscountPrice(event.currentTarget.value)}
              error={isPriceLowerThanDiscountPrice}
              helperText={isPriceLowerThanDiscountPrice ? 'O preço de venda deve ser menor do que o preço sugerido' : undefined}
              separator="."
              InputProps={{
                startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} className={styles.gridPricingSubtitle}>
            <SubtitleCard>Desconto por Quantidade</SubtitleCard>
          </Grid>

          <Scope path="bulk_config">
            <Grid item xs={12} className={styles.gridPricingCheckBox}>
              <div className={styles.checkBox}>
                <CheckBox
                  name="is_active"
                  label="Oferecer desconto na compra em quantidade"
                  defaultChecked={offerDiscount}
                  value={offerDiscount}
                  onChange={(event) => setOfferDiscount(event.target.checked)}
                />
              </div>
            </Grid>

            <Grid item xs={12} className={styles.gridPricingTextField2}>
              <TextField
                name="min_qty"
                label="Pedido Mínimo para Obter Desconto"
                offerDiscount={offerDiscount}
                InputProps={{
                  endAdornment: minimum > 0 && <InputAdornment className={styles.adornmentEnd}><span>Unidades</span></InputAdornment>,
                }}
                onLoadFieldValue={(value) => (offerDiscount ? setMinimum(Number(value)) : setMinimum(0))}
                isNumber
                discountPrice
                disabled={!offerDiscount}
              />
              {offerDiscount && (minimum === 0 || minimum === undefined || minimum === null) && <Alert severity="error" style={{ marginTop: '1rem' }}>Deve conter pelo menos 1 unidade</Alert>}
            </Grid>

            <Grid item xs={12} className={styles.gridPricingTextField3} style={{ marginBottom: isDiscountPriceLowerThanBulkConfigPrice ? '3rem' : '' }}>
              <PriceField
                name="price"
                label="Preço por item com Desconto por Quantidade"
                discountPrice
                offerDiscount={offerDiscount}
                onChange={(event) => setBulkConfigPrice(event.currentTarget.value)}
                separator="."
                error={isDiscountPriceLowerThanBulkConfigPrice}
                helperText={isDiscountPriceLowerThanBulkConfigPrice ? 'O preço por item com desconto por quantidade deve ser menor do que o preço de venda' : undefined}
                InputProps={{
                  startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
                }}
                onLoadFieldValue={(value) => (offerDiscount ? setPriceWithDiscount(value) : setPriceWithDiscount(0))}
                disabled={!offerDiscount}
              />
            </Grid>
          </Scope>
        </Grid>
      </StyledPaper>
    </>
  );
}
