import React, { useState } from 'react';

import TabPanel from './tab-panel';
import TitleCard from '../../title/title-card';

import { TextTab, TabsContainer } from '../styles';
import { StyledPaper } from '../../styles';

export default function HelperTexts() {
  const [value, setValue] = useState(0);

  return (
    <>
      <TitleCard>Textos de Apoio</TitleCard>

      <StyledPaper>
        <TabsContainer
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <TextTab name="description" label="Descrição" />
          <TextTab name="dosage" label="Posologia" />
          <TextTab name="recommendations" label="Advertências" />
          <TextTab name="benefits" label="Benefícios" />
        </TabsContainer>

        <TabPanel value={value} index={0} name="description" />
        <TabPanel value={value} index={1} name="dosage" />
        <TabPanel value={value} index={2} name="recommendations" />
        <TabPanel value={value} index={3} name="benefits" />
      </StyledPaper>
    </>
  );
}
