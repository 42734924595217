import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: #F9F9F9;
  }

  body, input, button {
    font-family:'Poppins', sans-serif;
  }

  button {
    cursor: pointer;
  }

  #root {
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
  }

  .disabled-events {
    color: #A3A3A3;
    pointer-events: none;
    cursor: none;
  }

  .MuiDialog-paperScrollPaper {
    max-height: none !important;
  }

  .noPadding {
    padding: 0;
  }

  .rotateIcon {
    transform: rotate(180deg);
  }

  .Toastify__toast-container {
    font-size: 14px;
    font-weight: 500;
    width: 550px;
  }

  .Toastify__toast-container div {
    font-family: 'Poppins';
    padding-left: 12px;
  }

  .Toastify__toast--success {
    background: #22E0A1;
  }

  .Toastify__toast--warning {
    background: #F2C94C;
  }

  .Toastify__toast--error {
    background: #EB5757;
  }

  .Toastify__toast--info {
    background: #034AFD;
  }
  .tox-statusbar {
    display: none !important;
  }

  .tox .tox-tinymce {
    border: none !important;
  }

  .tox-tinymce {
    font-family: 'Poppins' !important;
    border: none !important;
  }

  .tox-edit-area body {
    font-family: 'Poppins' !important;
  }

  .tox .tox-button {
    color: white !important;
    background-color: #22E0A1 !important;
    border-color: #22E0A1 !important;
  }

  .tox .tox-button:hover {
    background-color: #20b281 !important;
    border-color: #20b281 !important;
  }

  .tox .tox-field {
    border-color: #22E0A1 !important;
  }

  .tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textfield, .tox .tox-toolbar-textfield {
    border-color: #E1E1E1 !important;
  }

  .tox .tox-menubar+.tox-toolbar, .tox .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary {
    border: none !important;
  }


  /* Scrollbar */

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #c2c2c2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #A3A3A3;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #828282;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  @media print {
    @page {
      size: A4 portrait;
      margin: 0;
    }

    body {
      height: fit-content;
    }

    #root {
      min-height: fit-content;
      height: fit-content;
    }

    .no-print {
      display: none;
    }
  }
`;
