import { Grid } from '@material-ui/core';
import React from 'react';

import styled, { css } from 'styled-components';

const Styled = styled(Grid)`
  
  ${props => props.type === 'grid' && css`
    margin-left: -8px;
    margin-right: -8px;
    margin-top: 0;
  `}

  ${props => props.type === 'section' && css`
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 14px;
    margin-top: -6px;
    width: 100%;
  `}

  ${props => props.type === 'radiogroup' && css`
    margin-bottom: 6px;
    margin-top: 0;
  `}

  ${props => props.type === 'normal' && css`
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  `}

  
  ${props => props.type === 'theme' && css`
    color: ${props.theme.main_color} !important;  
  `}

  ${props => props.type === 'textfield' && css`
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 0;

    @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  `}

  ${props => props.mt && css`
    margin-top: ${props.mt} !important;
  `}
  
  ${props => props.mr && css`
    margin-right: ${props.mr} !important;
  `}

  ${props => props.mb && css`
    margin-bottom: ${props.mb} !important;
  `}

  ${props => props.ml && css`
    margin-left: ${props.ml} !important;
  `}

  ${props => props.margin && css`
    margin: ${props.margin} !important;
  `}

  ${props => props.color && css`
    color: ${props.color} !important;
  `}

  ${props => props.border && css`
    border: ${props.border} !important;
  `}

  ${props => props.borderRight && css`
    border-right: ${props.borderRight} !important;
  `}
  
  ${props => props.bdradius && css`
  border-radius: ${props.bdradius} !important;
  `}

  ${props => props.padding && css`
    padding: ${props.padding} !important;
  `}
  
  ${props => props.pddt && css`
    padding-top: ${props.pddt} !important;
  `}
  
  ${props => props.pdr && css`
    padding-right: ${props.pdr} !important;
  `}
  
  ${props => props.pdt && css`
    padding-top: ${props.pdr} !important;
  `}

  ${props => props.pdl && css`
    padding-left: ${props.pdl} !important;
  `}

  ${props => props.width && css`
    width: ${props.width} !important;
  `}
  
  ${props => props.height && css`
    height: ${props.height} !important;
  `}

  ${props => props.mxWidth && css`
    max-width: ${props.mxWidth} !important;
  `}

  ${props => props.hiden && css`
    display: ${props.hiden} !important;
  `}
  
  ${props => props.flexGrow && css`
    flex-grow: ${props.flexGrow} !important;
  `}
  
  ${props => props.flexDirection && css`
    display:flex !important;
    flex-direction: ${props.flexDirection} !important;
  `}

  ${props => props.mxHght && css`
    max-height: ${props.mxHght} !important;
  `}

  ${props => props.mnHght && css`
    min-height: ${props.mnHght} !important;
  `}

  ${props => props.justfy && css`
    display:flex !important;
    justify-content: ${props.justfy} !important;
  `}
  
  ${props => props.mlMobile && css`
    @media only screen and (min-device-width: 320px) and (max-width: 736px) {
      margin-left: ${props.mlMobile};
    }
  `}
  
  @media only screen and (min-device-width:1024px) and (max-device-width:1336px ) {
    ${props => props.tbmb && css`
      margin-bottom: ${props.tbmb} !important;
    `}
    
    ${props => props.tbWidth && css`
      width: ${props.tbWidth} !important;
    `}

    ${props => props.tbml && css`
      margin-left: ${props.tbml} !important;
    `}
    
    ${props => props.tbpdl && css`
      padding-left: ${props.tbpdl} !important;
    `}

    ${props => props.tbmr && css`
      margin-right: ${props.tbmr} !important;
    `}

    ${props => props.tbmb && css`
      margin-bottom: ${props.tbmb} !important;
    `}

    ${props => props.tbmt && css`
      margin-top: ${props.tbmt} !important;
    `}

    ${props => props.tbHiden && css`
      display:${props.tbHiden} !important;
    `}

    ${props => props.tbjustfy && css`
      display:flex !important;
      justify-content: ${props.tbjustfy} !important;
    `}
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    ${props => props.mmb && css`
      margin-bottom: ${props.mmb} !important;
    `}

    ${props => props.mwidth && css`
      width: ${props.mwidth} !important;
    `}

    ${props => props.mheight && css`
      height: ${props.mheight} !important;
    `}

    ${props => props.mml && css`
      margin-left: ${props.mml} !important;
    `}
    
    ${props => props.mpdl && css`
      padding-left: ${props.mpdl} !important;
    `}

    ${props => props.mmr && css`
      margin-right: ${props.mmr} !important;
    `}

    ${props => props.mmt && css`
      margin-top: ${props.mmt} !important;
    `}

    ${props => props.mHiden && css`
      display:${props.mHiden} !important;
    `}

    ${props => props.mjustfy && css`
      display:flex !important;
      justify-content: ${props.mjustfy} !important;
    `}
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 736px) {
    ${props => props.smb && css`
      margin-bottom: ${props.smb} !important;
    `}

    
    ${props => props.sBorder && css`
      display: inline-block;
      height: 50%;
      left: 0;
      top: 25%;
      position: relative;
      border-right: 1px solid #A3A3A3;
    `}

    ${props => props.sjustfy && css`
      display:flex !important;
      justify-content: ${props.sjustfy} !important;
    `}

    ${props => props.swidth && css`
      width: ${props.swidth} !important;
    `}

    ${props => props.sheight && css`
      height: ${props.sheight} !important;
    `}

    ${props => props.hidenMobile && css`
      display: ${props.hidenMobile} !important;
    `}

    ${props => props.sml && css`
      margin-left: ${props.sml} !important;
    `}
    
    ${props => props.spdl && css`
      padding-left: ${props.spdl} !important;
    `}

    ${props => props.spd && css`
      padding: ${props.spd} !important;
    `}

    ${props => props.smr && css`
      margin-right: ${props.smr} !important;
    `}

    ${props => props.smb && css`
      margin-bottom: ${props.smb} !important;
    `}

    ${props => props.smt && css`
      margin-top: ${props.smt} !important;
    `}

    ${props => props.sHiden && css`
      display:${props.sHiden} !important;
    `}

    ${props => props.sjustfy && css`
      display:flex !important;
      justify-content: ${props.sjustfy} !important;
    `}
  }
`;

export default function Container({ children, ...props }) {
  return (
    <Styled {...props}>{children}</Styled>
  );
}
