import React, { useEffect, useState } from 'react';

import { Scope } from '@unform/core';

import DatePicker from '../../form/date-picker';
import CheckBox from '../../form/checkbox';
import TitleCard from '../../title/title-card';

import { StyledPaper, useStyles } from '../../styles';

export default function Visibility({ category }) {
  const styles = useStyles();
  const [callcenter, setCallcenter] = useState(false);
  const [ecommerce, setEcommerce] = useState(false);

  useEffect(() => {
    if (category) {
      setCallcenter(category?.callcenter?.status);
      setEcommerce(category?.ecommerce?.status);
    }
  }, [category]);

  return (
    <>
      <TitleCard>Visibilidade da categoria por canal</TitleCard>

      <StyledPaper style={{ paddingTop: '20px' }}>
        <Scope path="ecommerce">
          <div className={styles.checkBox}>
            <CheckBox
              name="status"
              label="E-commerce, com visibilidade a partir de:"
              checked={ecommerce}
              onChange={() => setEcommerce(!ecommerce)}
            />
          </div>

          <DatePicker name={category ? 'from' : 'loading'} date={category.ecommerce.from} label="Data" />
        </Scope>

        <div className={styles.containerCheckboxBottom}>
          <Scope path="callcenter">
            <div className={styles.checkBox}>
              <CheckBox
                name="status"
                label="Prescrição, com visibilidade a partir de:"
                checked={callcenter}
                onChange={() => setCallcenter(!callcenter)}
              />
            </div>

            <DatePicker name={category ? 'from' : 'loading'} date={category.callcenter.from} label="Data" />
          </Scope>
        </div>
      </StyledPaper>
    </>
  );
}
