import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import api from '../../../api';

import Modal from '../../modal';
import TextField from '../../form/text-field';
import DatePicker from '../../form/date-picker';
import CheckBox from '../../form/checkbox';
import TimePicker from '../../form/time-picker';

export function ExpeditionModal({
  onSubmitShipment,
  isOpen,
  order,
  onClose,
}) {
  const [isDeliverySpecified, setIsDeliverySpecified] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(order?.order?.delivery_date || 0);

  const expeditionDate = order.order.expedition_date || order.order.expedition_date_estimate;
  const isPickupOnPharmacy = !!order.shipping_address.pickup_on_pharmacy;
  const shippingEstimate = order?.order?.shipping_estimate?.shipping_options?.[0];

  const [formData, setFormData] = React.useState({
    expedition_date: expeditionDate ? new Date(expeditionDate) : new Date(),
    delivery_time: expeditionDate ? new Date(expeditionDate).setHours(0, 0, 0, 0) : new Date().setHours(0, 0, 0, 0),
    delivery_date_time_specified: false,
    tracking_code: order.order?.tracking_code || undefined,
    delivery_observation: order.order?.delivery_obs || '',
  });

  function handleSubmitShipment(data) {
    const deliveryTime = new Date(data.delivery_time);
    const adjustedExpeditionDate = new Date(data.expedition_date).setHours(deliveryTime.getHours(), deliveryTime.getMinutes(), deliveryTime.getSeconds(), 0);

    onSubmitShipment({
      ...data,
      expedition_date: new Date(adjustedExpeditionDate),
    });
  }

  useEffect(() => {
    if (!isPickupOnPharmacy) {
      api.get(`/v3/orders/${order.order.id}/delivery-date-estimate`, {
        params: {
          expedition_date: formData.expedition_date.getTime(),
        },
      }).then((response) => {
        setDeliveryDate(response.data.delivery_date);
      });
    }
  }, [formData.expedition_date, isPickupOnPharmacy, order.order.id]);

  return (
    <Modal
      title="Estimativa de expedição"
      toggle={onClose}
      isVisible={isOpen}
      buttonPrimary="Salvar e notificar o cliente"
      buttonSecondary="Cancelar"
      onSubmit={() => handleSubmitShipment(formData)}
    >
      <div
        style={{
          padding: '20px 10px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={2}>
              {isPickupOnPharmacy ? (
                <img src="/pickup.svg" alt="Pickup on pharmacy icon" />
              ) : (
                <img src="/truck.svg" alt="Delivery icon" />
              )}

            </Grid>
            <Grid item xs={10}>
              <Typography>
                {isPickupOnPharmacy ? 'Retirada' : 'Serviço'}:
                {' '}
                <Typography
                  component="span" style={{
                    fontWeight: '500',
                    fontSize: '14px',
                    marginLeft: '5px',
                    padding: '5px 10px',
                    borderRadius: '32px',
                    backgroundColor: '#EAEFED',
                    color: '#465953',
                  }}
                >
                  {isPickupOnPharmacy ? `${order?.pickup_address?.street}, ${order?.pickup_address?.number}` : `${shippingEstimate?.name} (${shippingEstimate?.shipping_time_max} dia${shippingEstimate?.shipping_time_max > 1 ? 's' : ''})`}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {isPickupOnPharmacy ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker
                    name="expedition_date"
                    label="Data de expedição"
                    value={formData.expedition_date.getTime()}
                    minDate={Date.now()}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        expedition_date: value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBox
                    name="is-delivery-specified"
                    label="Quero especificar a hora"
                    checked={isDeliverySpecified}
                    onChange={() => {
                      setIsDeliverySpecified(prevState => !prevState);
                      if (!isDeliverySpecified) {
                        setFormData({
                          ...formData,
                          delivery_date_time_specified: false,
                        });
                      }
                    }}
                    labelStyles={{
                      marginBottom: '-14px',
                    }}
                  />
                </Grid>
                {isDeliverySpecified && (
                <Grid item xs={12}>
                  <TimePicker
                    name="delivery_time"
                    label="Horário previsto"
                    value={formData.delivery_time}
                    onChange={(value) => {
                      const dateSpecified = Boolean(value.getHours() === 0 && value.getMinutes() === 0);

                      setFormData({
                        ...formData,
                        delivery_date_time_specified: !dateSpecified,
                        delivery_time: value,
                      });
                    }}
                  />
                </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item container xs={12}>
                <Grid item xs={2}>
                  <img src="/target.svg" alt="Target illustration" />
                </Grid>
                <Grid item container xs={10}>
                  <Grid item xs={12}>
                    <DatePicker
                      name="expedition_date"
                      label="Data de expedição"
                      value={formData.expedition_date.getTime()}
                      minDate={Date.now()}
                      onChange={(value) => {
                        setFormData({
                          ...formData,
                          expedition_date: value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      name="delivery_date"
                      label="Data de entrega"
                      value={deliveryDate}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="tracking_code"
                  label="Código de rastreio"
                  value={formData.tracking_code}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tracking_code: e.target.value.toUpperCase(),
                    });
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              name="delivery_observation"
              label="Observação"
              value={formData.delivery_observation}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  delivery_observation: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
