import React, { useState, useRef, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useField } from '@unform/core';

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useStyles } from './styles';

function TimeDatePicker({ name, date, ...rest }) {
  const styles = useStyles();
  const datePickerRef = useRef();
  const { fieldName, registerField } = useField(name);

  const [newDate, setNewDate] = useState(!date && new Date());

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      {/* <DateTimePicker
        variant="inline"
        value={newDate}
        onChange={setNewDate}
        className={styles.textField}
      /> */}

      <KeyboardDateTimePicker
        autoOk
        variant="inline"
        ampm={false}
        value={newDate || date}
        onChange={setNewDate}
        disablePast
        format="dd/MM/yyyy HH:mm"
        className={styles.textField}
        inputProps={{ ref: datePickerRef }}
        inputVariant="outlined"
        minDateMessage="Não é possível escolher uma data passada"
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

export default TimeDatePicker;
