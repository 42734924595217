import React from 'react';

// Libs
import { Grid, MenuItem } from '@material-ui/core';

// Components
import TitleCard from '../../../title/title-card';
import Select from '../../../form/select';
import TextField from '../../../form/text-field';

// Styles
import { StyledPaper } from '../../../styles';

function BasicData({ formRef }) {
  return (
    <>
      <TitleCard>Dados básicos</TitleCard>
      <StyledPaper>
        <Grid container direction="column" spacing={2}>
          <Grid item className="select">
            <Select
              name="status"
              label="Status"
              options={[{ value: 'Draft', label: 'Rascunho' }, { value: 'Published', label: 'Publicado' }, { value: 'Hidden', label: 'Ocultado' }]}
              initialValue={formRef?.current?.getFieldValue('status')}
              InputLabelProps={{ shrink: true }}
            >
              {({ item }) => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>}
            </Select>
          </Grid>

          <Grid item>
            <TextField
              name="author"
              label="Autor"
              placeholder="Nome Completo"
              InputLabelProps={{ shrink: true }}
              error={formRef.current ? formRef.current.getFieldError('author') : false}
              style={{ marginBottom: 10 }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}

export default BasicData;
