import React, { useCallback, useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';

import { Scope } from '@unform/core';
import Checkbox from '../../form/checkbox';

function RolesAndPermissionsData({ roleAndPermission }) {
  const [write, setWrite] = useState(roleAndPermission?.write);
  const [read, setRead] = useState(roleAndPermission?.read);
  const [deleteState, setDelete] = useState(roleAndPermission?.delete);

  const handleWrite = useCallback((e) => {
    setWrite(e);
    if (e === true) {
      setRead(e);
    }
  }, []);

  const handleDelete = useCallback((e) => {
    setDelete(e);

    if (e === true) {
      setRead(true);
      setWrite(true);
    }
  }, []);
  return (
    <>
      <Scope path={`grouprules[${roleAndPermission.index}]`}>
        <Scope path="permissions">
          <TableCell>{roleAndPermission?.label}</TableCell>
          <TableCell style={{ width: '92px' }}>
            <Grid item>
              <Checkbox style={{ marginLeft: '20px' }} name="read" checked={read} onClick={(e) => setRead(e.target.checked)} defaultChecked={roleAndPermission?.read} />
            </Grid>
          </TableCell>

          <TableCell style={{ width: '94px' }}>
            <Grid item>
              <Checkbox style={{ marginLeft: '20px' }} name="write" checked={write} onClick={(e) => handleWrite(e.target.checked)} defaultChecked={roleAndPermission?.write} />
            </Grid>
          </TableCell>

          <TableCell style={{ width: '98px' }}>
            <Grid item>
              <Checkbox style={{ marginLeft: '20px' }} name="delete" checked={deleteState} onClick={(e) => handleDelete(e.target.checked)} defaultChecked={roleAndPermission?.delete} />
            </Grid>
          </TableCell>
        </Scope>

      </Scope>

    </>
  );
}

export default RolesAndPermissionsData;
