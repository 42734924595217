import React from 'react';

// Libs
// import { Grid, Button, MenuItem } from '@material-ui/core';
// import { Form } from '@unform/web';
// import { useHistory, useLocation } from 'react-router-dom';

// Icons
// import SortIcon from '@material-ui/icons/Sort';
// import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

// Components
// import ContentWrapper from '../../content-wrapper';
// import TabNavigator from '../../tabs-navigation';
// import SearchField from '../../form/search-field';
// import Spinner from '../../spinner';
// import TableMaterial from '../../table';
// import Select from '../../form/select';

// Styles
// import { useStyles } from '../../styles';
import { SecondHeaderContainer } from '../styles';

function Overview() {
  /** CONSTS */
  // const styles = useStyles();
  // const advancedFilterFormRef = useRef();
  // const history = useHistory();
  // const { search } = useLocation();
  // const formRef = useRef();

  /** STATES */
  // const [parseSearch] = useState('');
  // const [showFilter, setShowFilter] = useState(false);

  /** CALLBACKS */
  // const handleParams = useCallback((param, data) => {
  //   const getAllParams = new URLSearchParams(search);
  //   const allParams = Object.fromEntries(getAllParams);

  //   const params = {};
  //   params[param] = data;

  //   let newParams = {};

  //   if (allParams.ascending === '1' && allParams[param] === data) {
  //     newParams = { ...allParams, ...params, ascending: 1 };
  //   } else {
  //     newParams = { ...allParams, ...params, ascending: 0 };
  //   }

  //   if (param === 'q') {
  //     newParams.p = 1;
  //     const isEqual = allParams?.q === data;
  //     if (!isEqual) {
  //       formRef.current.reset();
  //     }
  //   }

  //   // return history.push({
  //   //   pathname: '/products',
  //   //   search: `?${new URLSearchParams(newParams)}`,
  //   // });
  // }, [search]);

  return (
    <>
      <SecondHeaderContainer>
        Overview
      </SecondHeaderContainer>
    </>
  );
}

export default Overview;
