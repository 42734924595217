import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { useStyles } from './styles';

import DatePicker from '../../form/date-picker';
import Description from '../../text/description';
import Paper from '../../paper';
import TextField from '../../form/text-field';
import formatCep from '../../../utils/formatCep';

import { ReactComponent as Delivery } from './assets/ic-pedido-enviado.svg';
import { ReactComponent as Withdrawal } from './assets/ic-pedido-retirada.svg';
import { useOrder } from '../../../contexts/order';
import { useAuth } from '../../../contexts/auth';

export default function Expedition({ order }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();
  const formRef = useRef();
  const { updateOrderStatus } = useOrder();
  const [expeditionDate, setExpeditionDate] = useState(order.order.expedition_date || order.order.expedition_date_estimate || '');

  const updateTrackingCode = useCallback(async ({ expedition_date }) => {
    try {
      const data = formRef.current.getData();
      await updateOrderStatus({
        id: order.order.id,
        status: order.order.status,
        tracking_code: data.tracking_code,
        expedition_date: expedition_date?.getTime() || order?.order?.expedition_date,
      });
      toast.success(`Dados de expedição do pedido ${order.order.label} foram atualizados.`);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar código de rastreio');
    }
  }, [updateOrderStatus, order]);

  const handleDeliveryDate = useCallback((date) => {
    setExpeditionDate(date);
    updateTrackingCode({ expedition_date: date });
  }, [updateTrackingCode]);
  function RenderRecipient(recipient) {
    if (recipient !== null) return <Description>{recipient}</Description>;
    return <Description>{order.customer.name}</Description>;
  }

  useEffect(() => {
    if (order.order.expedition_date) {
      setExpeditionDate(order.order.expedition_date);
    }
  }, [order]);

  return (
    <Paper height="auto">
      <div className={styles.flexGrow}>

        <Grid container direction="column">
          <Grid container item justify="center" alignContent="center">
            {order.shipping_address.pickup_on_pharmacy ? (
              <Withdrawal style={{ marginBottom: -20, marginTop: -42, height: 140 }} />
            ) : (
              <Delivery style={{ marginBottom: -20, marginTop: -42, height: 140 }} />
            )}
          </Grid>

          <Grid item style={{ marginBottom: 4 }}>
            <Description bold>Endereço de {order.shipping_address.pickup_on_pharmacy ? 'Retirada' : 'Entrega'}: </Description>
          </Grid>

          <Grid item>
            <Description>
              Tipo: {order.shipping_address.type === 'business' ? 'Comercial' : 'Residencial'}
            </Description>
          </Grid>

          <Grid item>
            <Description>
              {RenderRecipient(order.shipping_address.recipient)}
            </Description>
          </Grid>

          <Grid item>
            <Description>
              {`${order.shipping_address.street}, ${order.shipping_address.number}`}
            </Description>
          </Grid>

          {(order?.shipping_address?.obs || order?.shipping_address?.district) && (
            <Grid item>
              <Description>
                {order?.shipping_address?.obs && `${order?.shipping_address?.obs}, `}
                {order?.shipping_address?.district && `${order?.shipping_address?.district}`}
              </Description>
            </Grid>
          )}

          {order?.shipping_address?.complement && (
            <Grid item>
              <Description>
                {order?.shipping_address?.complement}
              </Description>
            </Grid>
          )}

          <Grid item>
            <Description>
              {`${order.shipping_address.city} - ${order.shipping_address.uf}`}
            </Description>
          </Grid>

          <Grid item>
            <Description>CEP: {formatCep(order.shipping_address.zip)}</Description>
          </Grid>

          {!order.shipping_address.pickup_on_pharmacy && order.order?.shipping_estimate?.shipping_options?.length && (
            <Grid item>
              <Description>Tipo de Entrega: {order.order?.shipping_estimate.shipping_options[0].name}</Description>
            </Grid>
          )}

        </Grid>

        <Grid container justify="center" style={{ alignItems: 'flex-end', flexGrow: 1, width: '100%' }}>
          <Form ref={formRef} initialData={{ tracking_code: order.order?.tracking_code, delivery_date: order.order?.delivery_date }}>
            <Grid item md={12} style={{ marginBottom: 12 }}>
              <DatePicker
                disabled={verifyPerms({ role: 'Pedidos', access: 'write' }) || ![50, 30].includes(order.order?.status)}
                name="expedition_date"
                label="Data prevista de expedição"
                value={expeditionDate}
                minDate={Date.now()}
                onChange={(e) => handleDeliveryDate(e)}
                helperText=""
              />
            </Grid>

            {order.shipping_address.pickup_on_pharmacy ? null : (
              <Grid item md={12} style={{ marginBottom: 12 }}>
                <TextField
                  fullWidth
                  defaultValue={order.order?.tracking_code}
                  disabled={verifyPerms({ role: 'Pedidos', access: 'write' })}
                  name="tracking_code"
                  onBlur={updateTrackingCode}
                  label="Código de Rastreio"
                  placeholder="Código de Rastreio"
                />
              </Grid>
            )}
          </Form>
        </Grid>
      </div>
    </Paper>
  );
}
