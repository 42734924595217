import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #E1E1E1',
    borderRadius: '5px',
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },

  /* small fix to the white pixel on the right */
  table: {
    width: 'calc(100% + 1px)',

    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

export const TableCellHeader = styled(TableCell)`
  border-left: 1px solid #E1E1E1;

  div {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 37px;
    justify-content: space-between;

    & button {      
      height: 20px !important;
      width: 20px !important;
    }
  }

  &.MuiTableCell-head {
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.03em;
  }

  &.MuiTableCell-root {
    padding: 0 16px;
  }

  :first-child {
    border-left: none;
  }
`;

export const RowContainer = styled(TableRow)`
  .MuiTableCell-root {
    border: none;
    border-left: 1px solid #EDF8F6;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0.03em;
    padding: 8px 16px;
  }

  .MuiTableCell-root:first-child {
    border-left: none;
  }

  &:nth-of-type(odd){
    background-color: #F9F9F9;
  }
`;
