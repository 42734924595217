export const tabs = [
  {
    name: 'tab1',
    label: 'Dados Básicos',
  },
  {
    name: 'tab2',
    label: 'Integrações',
  },
  {
    name: 'tab3',
    label: 'Políticas',
  },
];
