import React, { useState } from 'react';

// Libs
import { format } from 'date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import MUITableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Components
import TableMaterial from '../../table';
import Pagination from '../../pagination';

// Styled
import { TableHeader, BodyTableRow, TableContainer, StyledTableCell } from '../styles';

const Head = ({ head }) => (
  <TableHeader className="tableHeader">
    <TableRow>
      {head.map(h => (
        <TableCell align="left" key={h.name}>{h.label}</TableCell>
      ))}
      <TableCell />
    </TableRow>
  </TableHeader>
);

function Row(props) {
  const { row, collapseHeader, collapseHeader2 } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <BodyTableRow>
        <TableCell component="th" scope="row">
          <b>{format(row.date, "dd 'de' MMMM, EEEE", { locale: ptBRLocale })}</b>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.tid_withdraw}
        </TableCell>
        <TableCell component="th" scope="row">
          <p style={{ color: '#22DFA2' }}>{row.income}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <p style={{ color: '#EB5757' }}>{row.outcome}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.receiver}
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
          </IconButton>
        </TableCell>
      </BodyTableRow>
      <TableRow>
        <StyledTableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <TableMaterial
                headers={collapseHeader}
                noPagination
              />
            </Box>
            <Box>
              <TableMaterial
                headers={collapseHeader2}
                noPagination
              />
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function CollapsibleTable({
  tableHeader, collapseHeader, collapseHeader2, tableContent, noPagination, pagination, handleParams,
}) {
  const postsPerPage = useState(30);

  return (
    <>
      <TableContainer>
        <MUITableContainer>
          <Table>
            <Head head={tableHeader} />
            <TableBody>
              {tableContent.map(row => (
                <Row
                  key={row.name}
                  row={row}
                  collapseHeader={collapseHeader}
                  collapseHeader2={collapseHeader2}
                />
              ))}
            </TableBody>
          </Table>
        </MUITableContainer>
      </TableContainer>
      {!noPagination && (
        <Pagination handleParams={handleParams} pagination={pagination} postsPerPage={postsPerPage || []} />
      )}
    </>
  );
}

export default CollapsibleTable;
