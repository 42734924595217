import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";

import { Button, Grid } from "@material-ui/core";
// import { SortIcon } from '@material-ui/icons';

import Breadcrumb from "../layout/app-header/app-bar/breadcrumb";
import ContentWrapper from "../content-wrapper";
// import Filters from './components/filters';
// import SearchField from '../form/search-field';
import TableMaterial from "../table";

import { Container, PageTitle, useStyles } from "../styles";

import Data from "./data";
import schema from "./schema";
import Spinner from "../spinner";
import { useCoupons } from "../../contexts/coupons";
import { useAuth } from "../../contexts/auth";

export default function PromoCodeList() {
  const formRef = useRef();
  const history = useHistory();
  const styles = useStyles();

  const { verifyPerms } = useAuth();
  const { coupons, isLoading } = useCoupons();

  // const { search } = useLocation();

  // const [showFilter, setShowFilter] = useState(false);
  // const [parseSearch, setParseSearch] = useState('');

  const renderItem = (data) => <Data data={data} />;

  // useEffect(() => {
  //   const getAllParams = new URLSearchParams(search);
  //   const allParams = Object.fromEntries(getAllParams);

  //   const getProduct = async () => {
  //     await fetchCoupons(allParams?.name);
  //   };

  //   setParseSearch(allParams?.name);
  //   formRef.current.setFieldValue('search', allParams?.name);

  //   getProduct();
  // }, [search, fetchCoupons]);

  // const handleParams = useCallback((param, data) => {
  //   const getAllParams = new URLSearchParams(search);
  //   const allParams = Object.fromEntries(getAllParams);

  //   const params = {};
  //   params[param] = data;

  //   let newParams = {};

  //   if (allParams.ascending === '1' && allParams[param] === data) {
  //     newParams = { ...allParams, ...params, ascending: 1 };
  //   } else {
  //     newParams = { ...allParams, ...params, ascending: 0 };
  //   }

  //   if (param === 'name') {
  //     newParams.p = 1;
  //     const isEqual = allParams?.name === data;
  //     if (!isEqual) {
  //       formRef.current.reset();
  //     }
  //   }

  //   return history.push({
  //     pathname: '/promocode',
  //     search: `?${new URLSearchParams(newParams)}`,
  //   });
  // }, [search, history]);
 
  return !isLoading ? (
    <>
      <Breadcrumb title="Lista de Cupons" text="Marketing" current="Cupons" />

      <ContentWrapper align="top">
        <Form ref={formRef}>
          <Grid container alignItems="center" className={styles.gridRow}>
            <Grid item sm={3} className={styles.gridTitle}>
              <PageTitle>Cupons</PageTitle>
            </Grid>

            <Grid container item sm={9} justify="flex-end">
              {/* <div className={styles.divSearchField}>
                <SearchField label="buscar código" name="search" parseSearch={parseSearch} placeholder="buscar código" search={search} />
              </div> */}

              {/* <Button className={styles.buttonFilter} color="primary" onClick={() => setShowFilter(!showFilter)} variant="outlined">
                  <SortIcon />
                </Button> */}

              <Button
                disabled={verifyPerms({ role: "Marketing", access: "write" })}
                className={styles.filterButton}
                color="primary"
                onClick={() => history.push("/promocode/create")}
                type="button"
                variant="contained"
              >
                criar cupom
              </Button>
            </Grid>
          </Grid>
        </Form>

        {/* showFilter && (<Filters />) */}

        <Container
          className={styles.containerTableBottom}
          style={{
            margin: "24px 0 180px !important",
          }}
        >
          <TableMaterial
            headers={schema}
            listQuery={coupons?.coupons}
            noPagination
            renderItem={renderItem}
          />
        </Container>
      </ContentWrapper>
    </>
  ) : (
    <Spinner />
  );
}
