import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { IconButton, TextField } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';

export default function TextFieldRound({
  name,
  include,
  handleParams,
  search,
  searchValue,
  setSearchValue,
  textStyle,
  theme,
  width,
  type,
  parseSearch,
  clear,
  ...rest
}) {
  const styles = useStyles();
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const searchDiv = () => {
    if (!textStyle || textStyle === 1) {
      return styles.searchDiv;
    }

    return styles.searchDiv2;
  };

  const searchField = () => {
    if (!textStyle || textStyle === 1) {
      return styles.searchField;
    }

    return styles.searchField2;
  };

  const searchIcon = () => {
    if (!textStyle || textStyle === 1) {
      return styles.searchIcon;
    }

    return styles.searchIcon2;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div className={searchDiv()}>
      <TextField
        variant="outlined"
        className={searchField()}
        inputRef={inputRef}
        defaultValue={defaultValue}
        width={width}
        error={error}
        {...rest}
      />

      {parseSearch !== ''
        ? (
          <IconButton className={searchIcon()} type="button" onClick={clear}>
            <CloseIcon />
          </IconButton>
        )
        : (
          <IconButton className={searchIcon()} type="submit">
            <SearchIcon />
          </IconButton>
        )}
    </div>
  );
}
