import React from 'react';
// import { Scope } from '@unform/core';

import { Grid } from '@material-ui/core';

import DatePicker from '../../form/date-picker';
// import CheckBox from '../../form/checkbox';
import TitleCard from '../../title/title-card';

import { StyledPaper } from '../../styles';

export default function Visibility({ isEditForbid }) {
  // const styles = useStyles();
  // const [callcenter, setCallcenter] = useState(false);
  // const [ecommerce, setEcommerce] = useState(false);

  /* useEffect(() => {
    if (category) {
      setCallcenter(category?.callcenter?.status);
      setEcommerce(category?.ecommerce?.status);
    }
  }, [category]); */

  return (
    <>
      <TitleCard>Disponibilidade por canal</TitleCard>

      <StyledPaper style={{ paddingTop: '10px' }}>
        {/* <Scope path="ecommerce"> */}
        {/* <div className={styles.checkBox}>
            <CheckBox
              name="status"
              label="E-commerce, ativo entre:"
              checked={ecommerce}
              onChange={() => setEcommerce(!ecommerce)}
            />
          </div> */}

        <Grid container spacing={2} style={{ marginTop: 0 }}>
          <Grid sm={6}>
            <div style={{ width: 'calc(100% - 8px)' }}>
              <DatePicker disabled={isEditForbid} name="available_from" label="Data Início" />
            </div>
          </Grid>

          <Grid container sm={6} justify="flex-end">
            <div style={{ width: 'calc(100% - 8px)' }}>
              <DatePicker disabled={isEditForbid} name="available_to" label="Data Término" />
            </div>
          </Grid>
        </Grid>
        {/* </Scope> */}

        {/* <div className={styles.containerCheckboxBottom}>
          <Scope path="callcenter">
            <div className={styles.checkBox}>
              <CheckBox
                name="status"
                label="Call center, ativo entre:"
                checked={callcenter}
                onChange={() => setCallcenter(!callcenter)}
              />
            </div>

            <Grid container spacing={2} style={{ marginTop: 12 }}>
              <Grid sm={6}>
                <div style={{ width: 'calc(100% - 8px)' }}>
                  <DatePicker name="available_from" label="Data Início" />
                </div>
              </Grid>

              <Grid container sm={6} justify="flex-end">
                <div style={{ width: 'calc(100% - 8px)' }}>
                  <DatePicker name="available_from" label="Data Término" />
                </div>
              </Grid>
            </Grid>
          </Scope>
        </div> */}
      </StyledPaper>
    </>
  );
}
