import React, { useCallback } from 'react';

import { Grid, MenuItem, InputAdornment } from '@material-ui/core';

import { Scope } from '@unform/core';
import TextField from '../../../form/text-field';

import { useStyles } from '../../../styles';

import ufs from '../../../../utils/uf.json';

import CheckBox from './checkbox';
import Select from '../../../form/select';
import PriceField from '../../../form/price-field';

export default function City({ weekDays, setWeekDays }) {
  const styles = useStyles();

  const modalityOptions = [{ id: 'ground', label: 'Terrestre' }, { id: 'air', label: 'Aéreo' }, { id: 'sea', label: 'Marítimo' }];

  const shippingRefrigeratedOptions = [{ id: true, label: 'Sim' }, { id: false, label: 'Não' }];

  const handleWeekDays = useCallback((day) => {
    const findDay = weekDays.find((alredyDay) => alredyDay === day);
    if (findDay) {
      setWeekDays(
        weekDays.filter((alredyDay) => alredyDay !== day),
      );
    } else {
      setWeekDays([...weekDays, day]);
    }
  }, [weekDays, setWeekDays]);

  const renderModality = ({ item, handleValue }) => <MenuItem value={item.id} key={item.id} onClick={handleValue}>{item.label}</MenuItem>;
  const renderUF = ({ item, handleValue }) => <MenuItem value={item.sigla} onClick={handleValue} style={{ zIndex: 1 }}>{item.sigla}</MenuItem>;
  const renderShippingRefrigerated = ({ item, handleValue }) => <MenuItem value={item.id} key={item.id} onClick={handleValue}>{item.label}</MenuItem>;

  return (
    <Scope path="city">
      <Grid container spacing={2}>
        <Grid container item spacing={2} style={{ marginTop: '20px' }}>
          <Grid item sm={4} xs={12}>
            <Select options={ufs} name="state" placeholder="Estado" label="Estado">
              {renderUF}
            </Select>
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              name="city"
              label="Cidade"
              placeholder="Cidade"
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <TextField
              name="shipping_time"
              type="number"
              label="Prazo do Envio"
              placeholder="Prazo do Envio"
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item sm={4} xs={12}>
            <TextField
              name="service_name"
              label="Nome do Serviço"
              placeholder="Nome do Serviço"
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <Select options={modalityOptions} name="service_type" placeholder="Modalidade" label="Modalidade">
              {renderModality}
            </Select>
          </Grid>

          <Grid item sm={4} xs={12}>
            <PriceField
              name="service_cost"
              label="Valor"
              separator="."
              InputProps={{
                startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
              }}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <Select options={shippingRefrigeratedOptions} name="ships_low_temperature" placeholder="Envio de refrigerados" label="Envio de refrigerados">
              {renderShippingRefrigerated}
            </Select>
          </Grid>

          <Grid item style={{ marginTop: '20px' }}>
            <h5 style={{ fontWeight: 'bold', marginBottom: '7px' }}>Dias da Semana</h5>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <CheckBox defaultValue={weekDays} label="Seg" id="monday" onClick={() => handleWeekDays('monday')} />
              </Grid>

              <Grid item>
                <CheckBox defaultValue={weekDays} label="Ter" id="tuesday" onClick={() => handleWeekDays('tuesday')} />
              </Grid>

              <Grid item>
                <CheckBox defaultValue={weekDays} label="Qua" id="wednesday" onClick={() => handleWeekDays('wednesday')} />
              </Grid>

              <Grid item>
                <CheckBox defaultValue={weekDays} label="Qui" id="thursday" onClick={() => handleWeekDays('thursday')} />
              </Grid>

              <Grid item>
                <CheckBox defaultValue={weekDays} label="Sex" id="friday" onClick={() => handleWeekDays('friday')} />
              </Grid>

              <Grid item>
                <CheckBox defaultValue={weekDays} label="Sáb" id="saturday" onClick={() => handleWeekDays('saturday')} />
              </Grid>

              <Grid item>
                <CheckBox defaultValue={weekDays} label="Dom" id="sunday" onClick={() => handleWeekDays('sunday')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Scope>

  );
}
