import React from 'react';

import './index.css'; // TO DO: Can update to styled-components

const ContentWrapper = ({ align, children }) => {
  const className = align === 'top' ? 'content-outer top' : 'content-outer';

  return (
    <div className={className}>
      <div className="content-inner">
        {children}
      </div>
    </div>
  );
};

export default ContentWrapper;
