import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';

export const HueContainer = styled.div`
  display: block;
  margin-top: 15px;
  border-radius: 10px;
  width: 418px;
`;

export const StyledPaper = styled.div`
  padding: ${props => !props.noPadding && '24px'};
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 24px;
  background: #fff;
`;

export const Huebox = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 5px;
  
  ${props => props.colorId && css`
      background-color: ${props.colorId};
  `}
`;

export const ButtonPrimary = styled(Button)`
  color: #FFFFFF;
  font-Size: 14px;
  font-Style: normal;
  font-Weight: 600;
  line-Height: 20px;
  padding-Left: theme.spacing(3);
  padding-Right: theme.spacing(3);
  width: 204px;
`;
