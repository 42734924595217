import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CircularProgress, Grid, Button } from '@material-ui/core';
import { Form } from '@unform/web';

// import SortIcon from '@material-ui/icons/Sort';

import { toast } from 'react-toastify';
import Spinner from '../spinner';
import ContentWrapper from '../content-wrapper';
// import Filters from './components/filters';
import ProductData from './data';
import ProductSchema from './schema';
import SearchField from '../form/search-field';
import TableMaterial from '../table';
import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';

import { Container, PageTitle, useStyles } from '../styles';

import { useProduct } from '../../contexts/product';
import { useAuth } from '../../contexts/auth';

export default function ProductList() {
  const styles = useStyles();
  const formRef = useRef();
  const history = useHistory();
  const { verifyPerms } = useAuth();

  const [parseSearch, setParseSearch] = useState('');
  const { products, fetchProducts, addProduct, isLoading } = useProduct();

  const { search } = useLocation();

  const renderItem = (product) => <ProductData product={product} refetch={fetchProducts} />;

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const getProduct = async () => {
      await fetchProducts(allParams);
    };

    getProduct();
  }, [search, fetchProducts]);

  useEffect(() => {
    function getValue() {
      const splitSearch = search.split('&');
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      const keywords = splitSearch.map(item => {
        if (item.indexOf('q=') !== -1 && formRef.current) {
          setParseSearch(item.replace('q=', '').replace('?', ''));

          return formRef.current.setFieldValue('search', allParams?.q);
        }

        return null;
      });

      return keywords;
    }

    getValue();
  }, [search]);

  const handleProduct = async () => {
    try {
      const response = await addProduct({
        price: '0.00',
        discount_price: '0.00',
        max_qty: 3,
        title: 'Novo produto',
        ecommerce: {
          availability: false,
        },
        callcenter: {
          availability: false,
        },
      });
      return history.push(`/products/${response.id}?new`);
    } catch (err) {
      return toast.error('Ocorreu um erro ao tentar criar um novo produto.');
    }
  };

  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...params, ascending: 1 };
    } else {
      newParams = { ...allParams, ...params, ascending: 0 };
    }

    if (param === 'q') {
      newParams.p = 1;
      const isEqual = allParams?.q === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    return history.push({
      pathname: '/products',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  return (
    <>
      <Breadcrumb title="Lista de Produtos" text="Catálogo" current="Produtos" />

      <ContentWrapper align="top">
        <Grid container direction="row" alignItems="center" className={styles.gridRow}>
          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>Lista de Produtos</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <Form ref={formRef} onSubmit={(formData) => handleParams('q', formData.search)}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchField}>
                  <SearchField
                    name="search"
                    handleParams={handleParams}
                    search={search}
                    label="buscar produto"
                    placeholder="buscar produto"
                    parseSearch={parseSearch}
                    clear={() => handleParams('q', '')}
                  />
                </div>

                {/*
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.buttonFilter}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <SortIcon />
                </Button>
                */}

                <Button
                  disabled={verifyPerms({ role: 'Catálogo', access: 'write' })}
                  className={styles.filterButton}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => handleProduct()}
                >
                  {isLoading ? (
                    <CircularProgress size={15} style={{ color: '#fff' }} />
                  ) : (
                    'criar novo produto'
                  )}
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>

        {/* showFilter && (
          <Filters />
        ) */}

        {isLoading ? <Spinner /> : (
          <Container
            className={styles.containerTableBottom}
          >
            <TableMaterial
              headers={ProductSchema}
              pagination={products.pagination}
              renderItem={renderItem}
              listQuery={products.items}
              handleParams={handleParams}
            />
          </Container>
        )}
      </ContentWrapper>
    </>
  );
}
