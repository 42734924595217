import React, { useCallback, useState } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';

import { toast } from 'react-toastify';
import TitleCard from '../../title/title-card';

import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import TextField from '../../form/text-field';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import { useAuth } from '../../../contexts/auth';

export default function CommercialCondition({ formRef }) {
  const { verifyPerms } = useAuth();
  const { updateSocialMedia } = useSettings();

  const [isLoading, setLoading] = useState(false);

  const testValidScript = (value) => {
    const regex = /(<|%3C)script[\s\S]*?(>|%3E)[\s\S]*?(<|%3C)(\/|%2F)script[\s\S]*?(>|%3E)/gi;

    return regex.test(value);
  };

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { marketing } = formRef.current.getData();

      if ((!testValidScript(marketing.id_mailchimp) && marketing.id_mailchimp.length > 0)) {
        toast.error('O script de integração com o Mail Chimp é inválido');
        setLoading(false);
        return;
      }

      if ((!testValidScript(marketing.id_resultados_digitais) && marketing.id_resultados_digitais.length > 0)) {
        toast.error('O script de integração com o Resultados Digitais é inválido');
        setLoading(false);
        return;
      }

      const parsedMedias = {};

      const parseData = {
        id_resultados_digitais: '',
        id_mailchimp: '',
      };

      Object.keys(marketing).forEach((item) => {
        parsedMedias[item] = !marketing[item] ? '' : `${parseData[item]}${marketing[item]}`;
      });

      await updateSocialMedia(parsedMedias);

      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updateSocialMedia, formRef]);

  return (
    <>
      <TitleCard color="#A3A3A3" bold>Marketing</TitleCard>

      <StyledPaper>

        <GridContainer>

          <GridContainer container item width="auto" margin="12px" spacing={3}>
            <GridContainer container item lg={12} spacing={3}>

              <Grid item lg={12} md={12} xs={12}>
                <Text bold>Outras ferramentas de Marketing</Text>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  name="id_mailchimp"
                  placeholder="Script Mail Chimp"
                  label="Script Mail Chimp"
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextField
                  name="id_resultados_digitais"
                  placeholder="Resultados Digitais"
                  label="Script Resultados Digitais (não usar*)"
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Text>* O script da Resultados Digitais deve ser substituído pela integração via APP disponibilizada abaixo. Suporte ao script será descontinuado a partir de 21 de novembro.</Text>
              </Grid>

            </GridContainer>
            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary disabled={verifyPerms({ role: 'Conf. Website', access: 'write' })} variant="contained" onClick={onSubmit} color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>

        </GridContainer>
      </StyledPaper>
    </>
  );
}
