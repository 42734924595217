import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { toast } from 'react-toastify';
import Modal from '../../../modal';

import { Container } from '../styles';
import { useSettings } from '../../../../contexts/settings';

export default function DeleteProduct({
  openModal,
  location,
  id,
  setModal,
}) {
  const { removeLocation } = useSettings();
  const { id: branch_id } = useParams();
  const handleDeleteLocation = useCallback(async () => {
    await removeLocation({ id, branch_id });

    toast.success(`A localização ${location} foi removida com sucesso!`);
  }, [id, branch_id, location, removeLocation]);
  return (
    <Modal
      isVisible={openModal}
      title="Remover Localização"
      toggle={() => setModal(!openModal)}
      size="xs"
      buttonPrimary="manter"
      buttonSecondary="remover permanentemente"
      modalActions={2}
      onSubmit={handleDeleteLocation}
      isDelete
    >
      <Container style={{ margin: '30px 0 0 0' }}>
        <Grid container justify="center" direction="column" alignItems="center">
          <Grid item>
            <span>Tem certeza que deseja remover a localização<span style={{ fontWeight: 'bold' }}>{` ${location}`}</span>?</span>
          </Grid>

          <Grid item>
            <span>Esta ação não poderá ser desfeita.</span>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}
