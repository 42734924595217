import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Grid } from '@material-ui/core';
import { Form } from '@unform/web';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import SearchField from '../../form/search-field';
import RadioButton from '../../form/form-radio-button';

import {
  CardLogin, Row, RowBottom, Subtitle, Title, useStyles,
} from '../styles';

import actions from '../../../actions';

export default function LoginOrganization({ redirect }) {
  const styles = useStyles();
  const history = useHistory();
  const buttonContinuar = useRef();
  const [org, setOrg] = useState();

  const handleSubmit = async (formData) => {
    const response = await actions.auth.login(formData);

    if (!redirect) return history.push('/login');

    history.push(redirect);

    return response;
  };

  return (
    <div>
      <CardLogin cardOrganization>
        <Title>
          Escolher organização
        </Title>

        <Subtitle mt="16px" padding="0 38px">
          Selecione a organização que gostaria de acessar a interface administrativa
        </Subtitle>

        <Form onSubmit={handleSubmit}>
          <Grid container direction="row" style={{ marginLeft: '-13px' }}>
            <Grid item xs={12} sm={12} style={{ marginBottom: 22 }}>
              <SearchField
                name="search"
                label="Filtrar por nome da organização"
                style={{ marginTop: '30px' }}
              />
            </Grid>

            <Grid item xs={12} style={{ height: '114px', marginLeft: '30px', overflowY: 'auto' }}>
              <FormControl required>
                <RadioGroup value={org}>
                  <RadioButton
                    style={{ marginBottom: '-6px' }}
                    value="a"
                    control={<Radio color="primary" />}
                    label="Farmácia da Villa"
                    onClick={() => setOrg('a')}
                  />

                  <RadioButton
                    style={{ marginBottom: '-6px' }}
                    value="b"
                    control={<Radio color="primary" />}
                    label="Manipulação Farmácia São Paulo"
                    onClick={() => setOrg('b')}
                  />

                  <RadioButton
                    style={{ marginBottom: '-6px' }}
                    value="c"
                    control={<Radio color="primary" />}
                    label="Farmácia das Américas"
                    onClick={() => setOrg('c')}
                  />

                  <RadioButton
                    style={{ marginBottom: '-6px' }}
                    value="d"
                    control={<Radio color="primary" />}
                    label="Pedido Pago"
                    onClick={() => setOrg('d')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Row style={{ marginTop: '38px' }}>
            <Button color="primary" variant="contained" type="submit" ref={buttonContinuar} className={styles.button}>
              Continuar
            </Button>
          </Row>
        </Form>
      </CardLogin>

      <RowBottom>
        <Button
          onClick={() => { history.push('/'); }}
          color="primary"
          type="button"
          className={styles.buttonText}
        >
          <KeyboardBackspaceIcon className={styles.iconKeyboard} />
          voltar para página inicial
        </Button>
      </RowBottom>
    </div>
  );
}
