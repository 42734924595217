import React from 'react';
import { find, remove, unionBy } from 'lodash';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { MenuItem } from '@material-ui/core';

import { AddButton, IconContainer, useStyles } from '../../styles';
import Select from '../../form/select';

export default function CategoriesInput({
  categories,
  section,
  index,
  categoriesList,
  setCategoriesList,

}) {
  const styles = useStyles();

  function handleSubCategory() {
    const newSection = section;
    newSection.subs = [...section?.subs, {}];

    const mergeCategories = unionBy(categoriesList, newSection, 'id');

    setCategoriesList(mergeCategories);
  }

  const renderList = () => {
    const findItem = find(categories?.items, (o) => o.id === section?.id);

    return findItem?.subcategories;
  };

  const removeCategory = () => {
    setCategoriesList(() => categoriesList.filter((category) => category && category.id !== section.id));
  };

  const removeSubCategory = (item) => {
    const { parent_id } = item;

    const filterCategory = categoriesList.find((category) => category.id === parent_id);
    remove(filterCategory.subs, (sub) => sub.id === item.id);

    const mergeCategories = unionBy(categoriesList, filterCategory, 'id');
    setCategoriesList(mergeCategories);
  };

  const handleCategory = category => {
    const item = categoriesList[index];

    item.name = category.name;
    item.id = category.id;
    item.subs = [];

    setCategoriesList(categoriesList);
  };

  const handleSub = (subcategory, indexSub) => {
    const item = categoriesList[index];
    const sub = item.subs[indexSub];

    sub.id = subcategory.id;
    sub.name = subcategory.name;
    sub.parent_id = subcategory.parent_id;

    setCategoriesList(categoriesList);
  };

  const renderCategory = ({ item }) => (
    <MenuItem
      key={item.id}
      value={item.id}
      onClick={() => handleCategory(item)}
    >
      {item.name}
    </MenuItem>
  );

  const renderSubcategories = ({ item, indexSub }) => (
    <MenuItem
      onClick={() => handleSub(item, indexSub)}
      key={item.id}
      value={item.id}
    >
      {item.name}
    </MenuItem>
  );

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={index === 0 ? 12 : 11}>
          <Select
            name={`categoriesList[0]${section?.id}.id`}
            label="Escolha uma Categoria"
            options={categories.items}
            initialValue={section.id}
          >
            {renderCategory}
          </Select>
        </Grid>

        {index > 0
          && (
            <Grid item xs={1} className={styles.gridCategoriesInputCloseIcon} onClick={() => removeCategory()}>
              <CloseIcon color="disabled" />
            </Grid>
          )}
      </Grid>

      {section?.subs?.map((item, i) => (
        <Grid container direction="row" key={item?.id} className={styles.gridCategoriesInputContainer}>
          <Grid item xs={1} className={styles.gridCategoriesInputIcon}>
            <IconContainer item>
              <SubdirectoryArrowRightIcon color="disabled" />
            </IconContainer>
          </Grid>

          <Grid item xs={10} className={styles.gridCategoriesInputSelect}>
            <Select
              name={`categoriesList[0]${section?.listId}.sub[${i}]${item?.list}.id`}
              label="Escolha uma subcategoria"
              options={renderList()}
              initialValue={item?.id}
              indexSub={i}
            >
              {renderSubcategories}
            </Select>
          </Grid>

          <Grid item xs={1} className={styles.gridCategoriesInputCloseIcon2} onClick={() => removeSubCategory(item)}>
            <CloseIcon color="disabled" />
          </Grid>
        </Grid>
      ))}

      <Grid container direction="row" className={styles.gridCategoriesInputContainer}>
        <Grid item style={{ marginTop: '2px' }}>
          <IconContainer item>
            <SubdirectoryArrowRightIcon color="disabled" />
          </IconContainer>
        </Grid>

        <Grid
          item
          className={styles.gridCategoriesIconAdd}
          onClick={handleSubCategory}
        >
          <IconButton type="button" className={styles.iconButton}>
            <AddCircleOutlineIcon fontSize="small" color="primary" />
          </IconButton>

          <AddButton type="button">Adicionar Subcategoria</AddButton>
        </Grid>
      </Grid>
    </>
  );
}
