import React, { useMemo } from 'react';

import { Grid } from '@material-ui/core';

import Description from '../../text/description';
import Paper from '../../paper';
import Title from '../../text/title';

export default function Resume({ order }) {
  const productsLength = useMemo(() => {
    const totalUpdated = order?.products?.reduce((accumulator, product) => {
      const productsSubtotal = product.qty;

      return accumulator + productsSubtotal;
    }, 0);

    return totalUpdated;
  }, [order]);

  const totalProductsPrice = useMemo(() => {
    const totalProducts = order?.products?.reduce((accumulator, product) => {
      const productsSubtotal = Number(product.price_net);

      return accumulator + productsSubtotal;
    }, 0);

    return totalProducts;
  }, [order]);

  /* const discountOrder = useMemo(() => {
    if (order.order.price_gross === order.order.price_net) {
      return Number(0).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return (
      Number(order.order.price_gross) - Number(order.order.price_net)
    ).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [order]); */

  return (
    <Paper height="300px">
      <div style={{ height: 168 }}>
        <Grid container>
          <Grid
            item
            container
            justify="space-between"
            style={{ marginBottom: 20 }}
          >
            <Grid item>
              <Description>{productsLength} produtos</Description>
            </Grid>

            <Grid item>
              <Description bold>
                {Number(totalProductsPrice).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Description>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justify="space-between"
            style={{ marginBottom: 20 }}
          >
            <Grid item>
              <Description>Frete</Description>
            </Grid>

            <Grid item>
              <Description bold>
                {Number(order?.order?.freight_cost).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Description>
            </Grid>
          </Grid>

          {order?.order?.free_shipping && (
            <Grid
              item
              container
              justify="space-between"
              style={{ marginBottom: 20 }}
            >
              <Grid item>
                <Description>Frete Grátis</Description>
              </Grid>

              <Grid item>
                <Description bold>
                  R$-
                  {Number(order?.order?.freight_cost).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Description>
              </Grid>
            </Grid>
          )}

          {order?.coupon && (
            <Grid
              item
              container
              justify="space-between"
              style={{ marginBottom: 20 }}
            >
              <Grid item>
                <Description>Cupom {order.coupon.name}</Description>
              </Grid>

              <Grid item>
                <Description bold>
                  R$-
                  {Number(order.coupon.discount).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Description>
              </Grid>
            </Grid>
          )}

          {/*           <Grid item container justify="space-between" style={{ marginBottom: 20 }}>
            <Grid item>
              <Description>Desconto condição de pagamento</Description>
            </Grid>

            <Grid item>
              <Description bold>
                R$-{discountOrder}
              </Description>
            </Grid>
          </Grid> */}
        </Grid>
      </div>

      <div
        style={{
          border: '0.1px solid #E1E1E1',
          width: '100%',
          marginBottom: 24,
        }}
      />

      <Grid container justify="space-between">
        <Grid item style={{ paddingTop: 5 }}>
          <Description bold>total</Description>
        </Grid>

        <Grid item>
          {/* <Description>
            {Number(order?.order?.price_gross)
              === Number(order?.order?.price_net)
              && `de ${Number(order?.order?.price_gross).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })} por`}
          </Description> */}

          {order?.order?.price_net && (
            <Title>
              &nbsp;{' '}
              {Number(order?.order?.price_net).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Title>
          )}
        </Grid>
      </Grid>

      <div
        style={{ border: '0.1px solid #E1E1E1', width: '100%', marginTop: 24 }}
      />
    </Paper>
  );
}
