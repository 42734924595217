export const CategorySchema = [
  {
    name: 'title',
    label: 'Nome da Categoria',
    isFilter: false,
  },
  {
    name: 'visibility',
    label: 'Visibilidade',
    isFilter: false,
  },
  {
    name: 'subCategories',
    label: 'Subcategorias',
    isFilter: false,
  },
  {
    name: 'products',
    label: 'Produtos',
    isFilter: false,
  },
  {
    name: 'created_at',
    label: 'Criação',
    isFilter: false,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];

export const VisibilitySchema = [
  {
    id: 1,
    label: 'E-Commerce',
  },
  {
    id: 2,
    label: 'Prescrição',
  },
];
