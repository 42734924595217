import styled from 'styled-components';

export const Container = styled.div`
  max-width: 80mm;
  padding: 1rem 0;

  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    * {
      font-size: 12px;
    }

    div p {
      padding: 1px 8px;
    }
  }

  table td {
    padding: 0px 5px;
  }
`;
