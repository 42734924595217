import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import Layout from '../components/layout';
import actions from '../actions';
import store from '../store';

export default function PublicRoute({ component, layout, ...rest }) {
  const { user } = store.get();

  useEffect(() => {
    function checkUser() {
      if (user) {
        actions.user.getCheckLogin();
      }
    }

    checkUser();
  }, [user]);

  return (
    <Route
      {...rest}
      render={props => (
        <Layout Component={component} LayoutFrame={layout} user={user} {...props} />
      )}
    />
  );
}
