import api from '../api';
import store from '../store';
import errHandler from './_errhandler';

export const getCategories = async () => {
  const response = await api.get('/v2/store/categories')
    .then(res => {
      const categories = res.data;

      store.set({ categories: categories.items });

      return categories;
    })
    .catch(errHandler);

  return response;
};

export const getCategory = (search) => api
  .get(`/v2/store/category?recursive=true&fetch_products=true&fetch_keywords=true&name=${search}`)
  .then(res => {
    const categories = res.data;

    store.set({ categories });

    return res.data;
  })
  .catch(errHandler);

export const getCategoryByID = (category_id) => api
  .get(`/v2/store/category?ids=${category_id}&recursive=true&fetch_products=true&fetch_keywords=true`)
  .then(resp => resp.data)
  .catch(errHandler);

export const getProductByCategoryID = (category_id) => api
  .get('/v2/product', { params: { category_id } })
  .then(resp => resp.data)
  .catch(errHandler);

export const postCategory = (data) => api
  .post('/v2/store/category', data)
  .then(resp => resp.data)
  .catch(errHandler);

export const getFeaturedProduct = (product_id) => api
  .get(`/v2/product/${product_id}`)
  .then(resp => resp.data)
  .catch(errHandler);

export const deleteCategory = (category_id) => api
  .delete(`/v2/store/category/${category_id}`)
  .then(resp => resp)
  .catch(errHandler);

export const putCategory = (category_id, data) => api
  .put(`/v2/store/category/${category_id}`, data)
  .then(resp => resp.data)
  .catch(errHandler);

export const putReorderSubcategories = (category_id, data) => api
  .put(`/v2/store/category/${category_id}/children`, data)
  .then(resp => resp)
  .catch(errHandler);

export const getSubs = async (id) => {
  const resp = await api.get(`/v2/store/category/${id}/subcategories`)
    .then(res => res.data)
    .catch(errHandler);

  return resp;
};
