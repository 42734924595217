import React from 'react';

import { Drawer, makeStyles } from '@material-ui/core';

import ListItems from './list-items';
import { Toolbar } from './styles';
import avatar from '../../../../assets/avatar.png';

import { useAuth } from '../../../../contexts/auth';
import Subtitle from '../../../text/subtitle';

export default function DrawerMenu({ isOpen, handleToggle, container }) {
  const useStyles = makeStyles(theme => ({
    drawer: {
      width: '50px',
      [theme.breakpoints.up('sm')]: {
        width: 240,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: 240,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      scrollbarWidth: 'none',
      [theme.breakpoints.down('xs')]: {
        width: 50,
      },

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }));

  const styles = useStyles();
  const { user } = useAuth();

  return (
    <nav className={styles.drawer}>
      <Drawer
        variant="permanent"
        anchor="left"
        open={isOpen}
        onClose={handleToggle}
        classes={{ paper: styles.drawerPaper }}
        container={container}
      >
        <Toolbar>
          <img src={user?.picture || avatar} alt="avatar" />

          <div>
            <h3>{user?.name}</h3>
            <p>{user?.group?.name}</p>
          </div>
        </Toolbar>

        <ListItems />

        <div style={{
          marginTop: 'auto',
          marginBottom: '2rem',
        }}
        >
          <Subtitle
            as="p"
            size="0.75rem"
            align="center"
            color="#AFAFAF"
          >v2.3.2
          </Subtitle>
        </div>
      </Drawer>
    </nav>
  );
}
