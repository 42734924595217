import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import { TextareaAutosize } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  searchIconSelect: {
    color: '#00E095',
    marginTop: '4px',
    marginRight: -55,
    height: '18px',
  },

  circularProgressIcon: {
    color: '#00E095',
    marginTop: '0',
    marginRight: '-52px',
  },

  searchDiv: {
    display: 'grid',
    maxWidth: '360px',
    width: '360px',
  },

  searchDiv2: {
    display: 'block',
    maxWidth: '100%',
    width: '100%',
  },

  iconButtonSearch: {
    marginTop: '-20px',
    border: '1px solid #000',
    width: 10,
  },

  searchIcon: {
    background: 'none !important',
    color: '#00E095',
    height: '36px',
    marginBottom: '-6px',
    marginLeft: '316px',
    marginTop: '-29px',
    width: '36px',
  },

  searchIcon2: {
    background: 'none !important',
    color: '#00E095',
    display: 'flex',
    justifySelf: 'flex-end',
    height: '36px',
    marginRight: '10px',
    marginTop: '-20px',
    width: '36px',
  },

  searchField: {
    height: '40px',
    width: '100%',

    '& fieldset': {
      border: '1.5px solid #E1E1E1',
      borderRadius: '40px',
      height: '40px',
      top: '6px',

      '& legend': {
        fontSize: '8.9px',
      },
    },

    '& .MuiFormLabel-root': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
    },

    '& .MuiInputBase-input': {
      color: '#424242',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.03em',
    },

    '& .MuiFormHelperText-contained': {
      alignSelf: 'flex-end',
      fontSize: '10px',
      margin: '-8px',
      marginRight: '5px',
      marginTop: '10px',
    },

    /* this fixes the fieldset label padding */
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
    },

    /* this fixes the 'text-field' padding */
    '& .MuiOutlinedInput-input': {
      padding: '21.5px 14px',
    },

    '& ::placeholder': {
      color: '#A3A3A3',
      opacity: '1',
    },
  },

  searchField2: {
    width: '100%',
    height: '40px',

    '& fieldset': {
      border: '1.5px solid #E1E1E1',
      borderRadius: '10px',
      height: '40px',
      top: '6px',

      '& legend': {
        fontSize: '8.9px',
      },
    },

    '& .MuiFormLabel-root': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
    },

    '& .MuiInputBase-input': {
      color: '#424242',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.03em',
    },

    '& .MuiFormHelperText-contained': {
      alignSelf: 'flex-end',
      fontSize: '10px',
      margin: '-8px',
      marginRight: '5px',
      marginTop: '10px',
    },

    /* this fixes the fieldset label padding */
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
    },

    /* this fixes the 'text-field' padding */
    '& .MuiOutlinedInput-input': {
      padding: '21.5px 14px',
    },

    '& ::placeholder': {
      color: '#A3A3A3',
      opacity: '1',
    },
  },

  searchField3: {
    width: '100%',
    height: '40px',

    '& fieldset': {
      border: '1.5px solid #E1E1E1',
      borderRadius: '10px',
      height: '40px',
      top: '6px',

      '& legend': {
        fontSize: '8.9px',
      },
    },

    '& .MuiFormLabel-root': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
    },

    '& .MuiInputBase-input': {
      color: '#424242',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.03em',
      padding: '12.5px 4px !important',
    },

    '& .MuiFormHelperText-contained': {
      alignSelf: 'flex-end',
      fontSize: '10px',
      margin: '-8px',
      marginRight: '5px',
      marginTop: '10px',
    },

    /* this fixes the fieldset label padding */
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
    },

    /* this fixes the 'text-field' padding */
    '& .MuiOutlinedInput-input': {
      padding: '21.5px 14px',
    },

    '& ::placeholder': {
      color: '#A3A3A3',
      opacity: '1',
    },
  },

  textField: {
    width: '100%',
    height: '40px',

    '& fieldset': {
      border: '1.5px solid #E1E1E1',
      borderRadius: '10px',
      height: '40px',
      top: '6px',

      '& legend': {
        fontSize: '8.9px',
      },
    },

    '& input': {
      height: '14px',
    },

    '& .MuiFormLabel-root': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
    },

    '& .MuiInputBase-input': {
      color: '#424242',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.03em',
    },

    '& .MuiFormHelperText-contained': {
      alignSelf: 'flex-end',
      fontSize: '10px',
      margin: '-8px',
      marginRight: '5px',
      marginTop: '10px',
    },

    '& .Mui-disabled': {
      color: '#A3A3A3',

      '& svg': {
        color: '#A3A3A3',
      },
    },

    '& .MuiIconButton-root': {
      padding: '8px',
      marginRight: '-12px',
      marginTop: '16px',
    },

    '& .MuiSvgIcon-root': {
      color: '#22E0A1',
      height: '20px',
      width: 'auto',
    },

    '& .MuiSelect-icon': {
      color: '#22E0A1',
    },

    /* remove gray background when 'select' is focused */
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },

    /* this fixes the 'select' padding */
    '& .MuiSelect-selectMenu': {
      padding: '18.5px 14px !important',
    },

    /* this fixes the fieldset label padding */
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
    },

    /* this fixes the 'text-field' padding */
    '& .MuiOutlinedInput-input': {
      padding: '21.5px 14px 6px',
    },

    '& ::placeholder': {
      color: '#A3A3A3',
      opacity: '1',
    },
  },

  textFieldMulti: {
    width: '100%',

    '& fieldset': {
      border: '1px solid #E1E1E1',
      borderRadius: '10px',
      top: '6px',

      '& legend': {
        fontSize: '8.9px',
      },
    },

    '& textarea': {
      padding: '0',
    },

    '& .MuiFormLabel-root': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
    },

    '& .MuiInputBase-input': {
      color: '#424242',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '13px',
      letterSpacing: '0.03em',
    },

    '& .MuiFormHelperText-contained': {
      alignSelf: 'flex-end',
      fontSize: '10px',
      margin: '-8px',
      marginRight: '5px',
      marginTop: '10px',
    },

    '& .MuiOutlinedInput-multiline': {
      padding: '21.5px 14px 9px',
    },

    /* this fixes the fieldset label padding */
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
    },

    '& ::placeholder': {
      color: '#A3A3A3',
      opacity: '1',
    },
  },

  textFieldMulti2: {
    width: '100%',

    '& fieldset': {
      border: '1px solid #E1E1E1',
      borderRadius: '5px',
      top: '6px',

      '& legend': {
        fontSize: '8.9px',
      },
    },

    '& textarea': {
      padding: '0',
    },

    '& .MuiFormLabel-root': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
    },

    '& .MuiInputBase-input': {
      color: '#424242',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.03em',
    },

    '& .MuiFormHelperText-contained': {
      alignSelf: 'flex-end',
      fontSize: '10px',
      margin: '-8px',
      marginRight: '5px',
      marginTop: '10px',
    },

    '& .MuiOutlinedInput-multiline': {
      padding: '30px 24px 20px',
    },

    /* this fixes the fieldset label padding */
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
    },

    '& ::placeholder': {
      color: '#A3A3A3',
      opacity: '1',
    },
  },
}));

export const DropzoneContainer = styled(Grid)`
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  color: #999;
  margin-top: 50px;
  cursor: pointer;
  background: #e5f8fa;

  div {
    border: 1px dashed #666;
    padding: 30px;
    border-radius: 10px;
  }

  p {
    font-size: 14px;
    line-height: 19px;
    margin-top: 15px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #25baa2;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  padding: 10px;
  width: 400px;
  border: none;
  margin-bottom: 10px;

  &:disabled {
    background-color: #a3a3a3;
  }
`;

export const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  color: #A3A3A3;
  font-size: 14px;
  padding: 5px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  ${props => props.size && css`
      height: ${props.size.height} !important;
      width: ${props.size.width} !important;
  `}

  .edit {
    margin: 48px 0 0 !important;
  }

  .editRemove {
    margin: 11px 0 2px !important;

    span {
      margin: 4px 0 0 6px;
    }
  }

  .editRemoveSpan span {
    margin-left: 4px;
  }

  .iconButton {
    border-radius: 0;
    color: #FFFFFF;
    margin-top: calc(50% - 55px);
  }

  .iconButton:hover {
    background: none;
  }

  .mt {
    margin-top: -8px;
  }

  .noDash {
    border: none !important;
    outline: none !important;

    div {
      border: none !important;
      outline: none !important;
    }
  }

  .noEvent {
    pointer-events: none;
  }

  .onFileDrop {
    background-color: rgb(66,66,66,.8);
    height: 136px;
    margin: -8px 0 0 -8px;
    opacity: 1;
    text-align: center;
    width: 136px;
  }

  .onImageNew {
    background-color: rgb(66,66,66);
    border-radius: 5px;
    height: 136px;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 136px;
  }

  .onImagePreview {
    background-color: rgb(66,66,66,.8);
    border-radius: 5px;
    height: 136px;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 136px;

    ${props => props.size && css`
      height: ${props.size.height} !important;
      width: ${props.size.width} !important;
    `}
  }

  span {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }

  :hover img {
    background-color: #424242;
  }

  :hover div {
    outline: 2px dashed #FFFFFF;
    outline-offset: -10px;
    opacity: 1;
  }
`;

export const ImageNew = styled.div`
  backface-visibility: hidden;
  background-color: rgba(0, 185, 204, 0.1);
  border: 0;
  border-radius: 5px;
  display: inline-block;
  height: 136px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  width: 136px;

  ${props => props.size && css`
    height: ${props.size.height} !important;
    width: ${props.size.width} !important;

    .onImageNew {
      height: ${props.size.height} !important;
      width: calc(${props.size.width} + 16px) !important;

      .iconButton {
        margin-top: calc((${props.size.height} / 2) - 55px) !important;
      }
    }

    .onFileDrop {
      height: ${props.size.height} !important;
      width: calc(${props.size.width} + 16px) !important;

      .iconButton {
        margin-top: calc((${props.size.height} / 2) - 55px) !important;
      }
    }
  `}
`;

export const ImageNewContainer = styled.div`
  height: 140px;
  margin: -10.5px;
  width: 140px;
  outline: 1px dashed #22E0A1;
  outline-offset: -10px;

  svg {
    margin-left: calc(50% - 17.5px);
    margin-top: calc(50% - 17.5px);
  }

  ${props => props.size && css`
    height: ${props.size.height} !important;
    margin: -8px;
    width: ${props.size.width} !important;

    svg {
      margin-left: calc((${props.size.width} / 2) - 17.5px) !important;
      margin-top: calc((${props.size.height} / 2) - 17.5px) !important;
    }
  `}

  ${props => props.banner && css`
    width: calc(${props.size.width} + 15px) !important;
  `}
`;

export const ImagePreview = styled.img`
  backface-visibility: hidden;
  border-radius: 5px;
  height: 136px;
  width: 136px;

  ${props => props.size && css`
    height: ${props.size.height} !important;
    width: ${props.size.width} !important;
  `}
`;
