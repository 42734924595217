import * as user from './user';
import * as products from './product';
import * as category from './category';
import * as token from './token';
import * as auth from './auth';
import * as general from './general';
import * as media from './media';

export default {
  user,
  products,
  category,
  token,
  auth,
  general,
  media,
};
