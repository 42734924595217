import * as Yup from 'yup';

export const categorySchema = Yup.object().shape({
  callcenter: Yup.object().shape({
    status: Yup.boolean().required(),
    from: Yup.number().default(() => new Date().getTime()),
  }),
  description: Yup.string(),
  ecommerce: Yup.object().shape({
    status: Yup.boolean().required(),
    from: Yup.number().default(() => new Date().getTime()),
  }),
  featured_product: Yup.string(),
  logo: Yup.string(),
  name: Yup.string().required(),
  position: Yup.string(),
  subcategories: Yup.array().of(
    Yup.object().shape({
      description: Yup.string(),
      featured_product: Yup.string(),
      logo: Yup.string(),
      name: Yup.string(),
      position: Yup.string(),
      visible: Yup.boolean(),
    }),
  ),
  keywords: Yup.string(),
  visible: Yup.boolean().default(true),
});

export const updateSchema = Yup.object().shape({
  callcenter: Yup.object().shape({
    status: Yup.boolean().required(),
    from: Yup.number().default(() => new Date().getTime()),
  }),
  description: Yup.string(),
  ecommerce: Yup.object().shape({
    status: Yup.boolean().required(),
    from: Yup.number().default(() => new Date().getTime()),
  }),
  featured_product: Yup.string(),
  logo: Yup.string(),
  name: Yup.string().required(),
  position: Yup.string(),
  keywords: Yup.string(),
  visible: Yup.boolean().default(true),
});

export const subcategorySchema = Yup.object().shape({
  description: Yup.string(),
  featured_product: Yup.string(),
  name: Yup.string().required(),
  position: Yup.string(),
  visible: Yup.boolean(),
}).nullable();

export const productSchema = Yup.object().shape({
  title: Yup.string().required(),
  variants: Yup.string(),
});

export const tabs = [
  {
    name: 'aba1',
    label: 'Aba 1',
  },
  {
    name: 'aba2',
    label: 'Aba 2',
  },
  {
    name: 'aba3',
    label: 'Aba 3',
  },
];

export const subcategoriesSchema = [
  {
    name: 'position',
    label: 'Ordenar',
    isFilter: false,
  },
  {
    name: 'name',
    label: 'Subcategoria',
    isFilter: false,
  },
  {
    name: 'products',
    label: 'Produtos',
    isFilter: false,
  },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];

export const productsSchema = [
  {
    name: 'productName',
    label: 'Produto',
    isFilter: false,
  },
  {
    name: 'variant',
    label: 'Variante Escolhida',
    isFilter: false,
  },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];
