import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import StarBorderIcon from '@material-ui/icons/StarBorder';
// import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import NewAddressForm from './new-address-form';

import { Container, ItemContainer, StarContainer } from './styles';

function AddressList({ address }) {
  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <Container isAddress>
        <Grid container direction="row" alignContent="center" alignItems="center" justify="space-between" md={12}>
          <Grid item md={2}>
            <StarContainer><StarBorderIcon color="disabled" /></StarContainer>
          </Grid>
          <Grid item md={8}>
            <ItemContainer>
              <p><strong>{address?.recipient || ''}</strong></p>
              <p>
                {address.street}, {address.number} - {address?.complement || address?.obs}<br />
                {address.city} - {address.uf} - CEP: {address.zip}
              </p>
            </ItemContainer>
          </Grid>
          <Grid item md={2}>
            <ItemContainer>
              <EditIcon color="disabled" style={{ cursor: 'pointer' }} onClick={() => setVisible(!isVisible)} />
            </ItemContainer>
          </Grid>
          {/*
        <Grid item>
        <ItemContainer>
          <ClearIcon color="disabled" />
        </ItemContainer>
        </Grid> */}
        </Grid>
      </Container>

      {isVisible && (
        <NewAddressForm address={address} toggle={() => setVisible(!isVisible)} />
      )}
    </>
  );
}

export default AddressList;
