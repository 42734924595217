import React, { useMemo } from 'react';

import { IconButton, TableHead, TableRow } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { useLocation } from 'react-router-dom';
import { TableCellHeader } from './styles';

export default function TableHeader({ headers, handleParams }) {
  const { search } = useLocation();

  const isAscending = useMemo(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    if (allParams?.ascending === '0') return allParams['sort-by'];

    return false;
  }, [search]);

  return (
    <TableHead>
      <TableRow>
        {headers && headers.map(header => (
          <TableCellHeader key={header.name}>
            <div>
              <span>
                {header.label}
              </span>

              {header.isFilter && (
                <IconButton>
                  {isAscending !== header.name ? <ArrowDropDown onClick={() => handleParams('sort-by', header.name)} fontSize="small" /> : (
                    <ArrowDropUp onClick={() => handleParams('sort-by', header.name)} fontSize="small" />
                  ) }

                </IconButton>
              )}
            </div>
          </TableCellHeader>
        ))}
      </TableRow>
    </TableHead>
  );
}
