/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

// Libs
import { Scope } from '@unform/core';
import Grid from '@material-ui/core/Grid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Components
import AddModal from './add-modal';
import ImageUpload from './image-upload';

// Hooks
import useCustomImage from '../../../hooks/useCustomImage';

// Styles
import { AddImageContainer } from '../styles';

function AddImage({
  title,
  imageList,
  formRef,
  refetch,
  defaultImage,
}) {
  /** CONSTS */
  const { imageValues, update } = useCustomImage({ initialValues: {}, implicitValues: {} });

  /** STATES */
  const [editGallery, setEditGallery] = useState();
  const [listIndex, setListIndex] = useState();
  const [id, setId] = useState();
  const [image, setImage] = useState(defaultImage || '');
  const [isVisible, setVisible] = useState(false);
  const [removeImg, setRemoveImg] = useState(false);

  /** EFFECTS */
  useEffect(() => {
    async function changeImage() {
      if (image) {
        setVisible(true);
      }
    }

    changeImage();
  }, [image, formRef]);

  /** FUNCTIONS */
  const listId = (data) => {
    if (data) {
      return data;
    }

    return imageList?.length + 1;
  };

  // const handleUpdate = async (index) => {
  //   const newImageList = [...imageList];
  //   const customImage = newImageList[index];
  //   const isMaskSVG = customImage?.image_mask?.indexOf('.svg');
  //   const isTextSVG = customImage?.image_text?.indexOf('.svg');

  //   update({
  //     base: customImage?.image_base,
  //     mask: customImage?.image_mask,
  //     mask_type: isMaskSVG > 0 ? 'link_MaskSvg' : 'image/png',
  //     text: customImage?.image_text,
  //     text_type: isTextSVG > 0 ? 'link_TextSvg' : 'image/png',
  //     margin_left: customImage?.logo_area_left,
  //     margin_top: customImage?.logo_area_top,
  //     height: customImage?.logo_max_height,
  //     width: customImage?.logo_max_width,
  //     inverse_logo: customImage?.inverse_logo,
  //     text_blending: customImage?.text_blending === 'alpha' ? 'normal' : customImage.text_blending,
  //   });

  //   setImage();
  //   setEditGallery(customImage);
  //   setListIndex(index);
  //   setId(listId(newImageList[index].id));
  //   setVisible(true);
  // };

  const openModal = () => {
    setImage();
    setEditGallery();
    setListIndex();
    setId(listId());
    setVisible(true);
  };

  const cancel = () => {
    setVisible(!isVisible);

    formRef.current.setFieldValue('img.image_base', undefined);

    update({});
  };

  const remove = () => {
    formRef.current.setFieldValue('img.image_base', undefined);
    setRemoveImg(!removeImg);
    update({});
  };

  const close = () => {
    setVisible(!isVisible);
  };

  const handleImage = async (value) => {
    const newValues = {};
    // const { img } = formRef.current.getData();

    setImage(value);

    return update({ ...imageValues, ...newValues });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    refetch();
  };

  function getIdType(data) {
    if (data.id) {
      return data.id.toString();
    }

    return '';
  }

  return (
    <AddImageContainer className="imgContainer">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppableProvided) => (
            <Grid
              container
              alignItems="center"
              direction="row"
              spacing={1}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {imageList && imageList.map((item, index) => (
                <Draggable
                  key={getIdType(item)}
                  draggableId={getIdType(item)}
                  index={index}
                >
                  {(draggableProvided) => (
                    <Grid
                      item
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <Scope path="image">
                        <ImageUpload
                          name="image_base"
                          previewImg={item.thumbnail}
                          setImage={previewURL => handleImage(previewURL)}
                        // onClick={() => handleUpdate(index)}
                        />
                      </Scope>
                    </Grid>
                  )}
                </Draggable>
              ))}

              {droppableProvided.placeholder}

              {/* <Grid item>
                <Scope path="img">
                  <ImageUpload
                    name="image_base"
                    acceptImg=".svg, .jpg, .jpeg, .png"
                    setImage={previewURL => handleImage(previewURL)}
                    onClick={() => openModal()}
                  />
                </Scope>
              </Grid> */}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <Grid item>
        <Scope path="img">
          <ImageUpload
            name="image_base"
            acceptImg=".svg, .jpg, .jpeg, .png"
            setImage={previewURL => handleImage(previewURL)}
            onClick={() => openModal()}
            remove={removeImg}
          />
        </Scope>
      </Grid>

      <AddModal
        image={image}
        isVisible={isVisible}
        listIndex={listIndex}
        editGallery={editGallery}
        setEditGallery={setEditGallery}
        imageList={imageList}
        title={title}
        cancel={cancel}
        remove={remove}
        close={close}
        setRemoveImg={setRemoveImg}
        id={id}
        formRef={formRef}
        refetch={refetch}
        setImage={setImage}
        handleImage={handleImage}
        item={imageValues}
      />
    </AddImageContainer>
  );
}

export default AddImage;
