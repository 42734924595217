import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const CustomTab = withStyles({
  root: {
    flex: '1',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.03em',
    lineHeight: '22px',
    maxWidth: 'none',
    minHeight: '56px',
    textAlign: 'center',
    textTransform: 'none',
  },
})(Tab);

export default function TabNavigator({ tabs, activeTab, onChange }) {
  return (
    <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={onChange}>
      {tabs && tabs.map(tab => (
        <CustomTab
          key={tab.name} label={tab.label} active={activeTab === tab.length ? true : undefined}
        />
      ))}
    </Tabs>
  );
}
