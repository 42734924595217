import React from 'react';

import FavoriteIcon from '@material-ui/icons/Favorite';

import { Span, useStyles } from '../styles';

export default function FooterLogin() {
  const styles = useStyles();

  return (
    <div className={styles.footerLogin}>
      <span className={styles.spanCopyright}>
        &copy; 2020 Pedido Pago
      </span>

      <div className={styles.verticalLine} />

      <Span>
        Termos Gerais e Condições de Uso
      </Span>

      <div className={styles.verticalLine} />

      <Span>
        Política de Privacidade
      </Span>

      <div className={styles.divRight}>
        <Span>
          Feito com
        </Span>

        <FavoriteIcon className={styles.iconHeart} />

        <Span>
          em SP
        </Span>
      </div>
    </div>
  );
}
