import React from 'react';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import { IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import Placeholder from '../../../assets/placeholder.svg';

import Select from '../../form/select';

import { StyledTooltip } from '../../tooltip';
import { useStyles } from '../../styles';
import { useAuth } from '../../../contexts/auth';

export default function ProductsListData({ product, deleteProduct }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();

  const renderVariants = ({ item, handleValue }) => <MenuItem key={item?.variant_hash} onClick={handleValue}>{item?.options[0]}</MenuItem>;

  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          {product?.image
            ? (
              <img src={product.image.image_base} alt={product?.title || 'image'} className={styles.tableImg} />
            )
            : (
              <img src={Placeholder} className={styles.tableImg} alt="placeholder" />
            )}

          {product?.title}
        </Grid>
      </TableCell>

      <TableCell>
        {product?.has_variant
          ? (
            <Select options={product?.variant} name="variant_hash" label="Variant">
              {renderVariants}
            </Select>
          )
          : 'Sem Variantes'}
      </TableCell>

      <TableCell style={{ width: '80px' }}>
        <Grid container direction="row" justify="center">
          <Grid item>
            <StyledTooltip title="Remover" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Catálogo', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => deleteProduct(product.id)}
                style={{ marginLeft: '-2px' }}
              >
                <CloseIcon color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
}
