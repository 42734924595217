import React from 'react';

import { Scope } from '@unform/core';
import TitleCard from '../../title/title-card';
import EditorPolicy from './editor';

export default function Privacy({ content }) {
  return (
    <Scope path="privacy">
      <TitleCard color="#A3A3A3" bold> Política de Privacidade</TitleCard>

      <EditorPolicy content={content} policy_name="privacy" policy_title="Política de Privacidade" />

    </Scope>

  );
}
