import React, { useCallback, useState } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';

import { toast } from 'react-toastify';
import { Scope } from '@unform/core';
import TextField from '../../form/text-field';
import TitleCard from '../../title/title-card';

import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';

export default function FormContact({ formRef }) {
  const { updateContact } = useSettings();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { contact } = formRef.current.getData();

      await updateContact(contact);
      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [formRef, updateContact]);
  return (
    <>
      <TitleCard color="#A3A3A3" bold>Formulário para Contato</TitleCard>

      <StyledPaper>
        <Scope path="contact">
          <GridContainer>
            <GridContainer container item width="auto" margin="12px" spacing={3}>
              <GridContainer container item lg={12} spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    name="leadership_email"
                    label="Email de ”Fale com a Gerência"
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    name="prescriber_email"
                    label="Email de ”Fale com Suporte ao Prescritor”"
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    name="financial_email"
                    label="Email de ”Fale com o Setor Financeiro”"
                  />
                </Grid>
              </GridContainer>

              <GridContainer container item lg={12} spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    name="prescription_email"
                    label="Email de ”Envie sua Receita”"
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    name="pharmacist_email"
                    label="Email de ”Solicito Atenção Farmacêutica”"
                  />
                </Grid>
              </GridContainer>

              <GridContainer container item lg={12} spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    name="recruitment_email"
                    label="Email de ”Trabalhe Conosco”"
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    name="purchase_email"
                    label="Email de ”Fale com o Setor de Compras”"
                  />
                </Grid>
              </GridContainer>

              <GridContainer container item lg={12} mt="24px" justify="flex-end">
                <ButtonPrimary variant="contained" onClick={onSubmit} color="primary">
                  {isLoading ? (
                    <CircularProgress size={15} style={{ color: '#fff' }} />
                  ) : (
                    'salvar alterações'
                  )}
                </ButtonPrimary>
              </GridContainer>
            </GridContainer>
          </GridContainer>
        </Scope>

      </StyledPaper>
    </>
  );
}
