import React, { useState } from 'react';
import { Scope } from '@unform/core';
import { find, map } from 'lodash';
import * as Yup from 'yup';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';

import Modal from '../../modal';
import TableMaterial from '../../table';
import TitleCard from '../../title/title-card';

import { AddButton, StyledPaper, useStyles } from '../../styles';

import NewSubcategory from './new-subcategory';
import SubcategoriesData from './subcategories-data';

import { subcategorySchema } from '../schemas';
import actions from '../../../actions';
import { reorderList } from '../../../utils/reorder';
import { toBase64 } from '../../../utils/getBase64';
import { useAuth } from '../../../contexts/auth';

export default function SubCategories({
  tableHeaders,
  formRef,
  subcategories,
  id,
  refetch,
}) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();

  const [modal, setModal] = useState(false);
  const [editSubs, setEdit] = useState();

  const [subProducts, setSubProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [draggableList, setDraggable] = useState([]);
  const [initialDataForm, setInitialData] = useState();

  function renderOptions(edit) {
    if (id && subcategories?.length > options.length) {
      const mapValues = subcategories ? subcategories.map((item, index) => index + 1) : [];

      setOptions([...mapValues, mapValues.length + 1]);

      return true;
    }

    if (edit) {
      return edit;
    }

    if ((subcategories && subcategories.length === options.length) || subcategories === undefined) {
      setOptions([...options, options.length + 1]);

      return true;
    }
  }

  const toggleModal = () => {
    if (modal) { setEdit(); setSubProducts([]); }

    renderOptions(editSubs);

    setModal(!modal);
  };

  const editSubcategory = (subId, index) => {
    const subcategory = find(subcategories, ['id', subId]);

    setEdit({ ...subcategory, index });
    setInitialData(subcategory);
    setSubProducts(subcategory.products);
    toggleModal();
  };

  const deleteSubcategory = async (indexSub, idSub) => {
    const resp = await actions.category.deleteCategory(idSub);

    await refetch();
    return resp;
  };

  async function setVisibility(subId, visibility) {
    const changeVisible = { visible: visibility };

    const resp = await actions.category.putCategory(subId, changeVisible);

    await refetch();
    return resp;
  }

  const handleEditSub = async (newSub, sub) => {
    const productsArr = map(subProducts, 'id');
    const logo = newSub.logo.type ? await toBase64(newSub.logo) : null;

    const editCategory = {
      ...newSub,
      logo: undefined,
      new_logo_content_type: newSub.logo.type,
      new_logo_image: logo,
      replace_products: productsArr,
      index: newSub.index - 1,
    };

    try {
      await subcategorySchema.validate(editCategory, { abortEarly: false });

      const updateCategory = await actions.category.putCategory(sub.id, editCategory);
      const subs = await actions.category.getSubs(id);

      const editReorder = {
        list: subs,
        start: sub.index - 1,
        end: newSub.position - 1,
        editData: editSubs,
        id,
      };

      await reorderList(editReorder);

      await refetch();

      toggleModal();

      return updateCategory;
    } catch (error) {
      const validatorError = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          validatorError[err.path] = err.message;
        });

        toast.error('Erro ao atualizar a subcategoria');

        formRef.current.setErrors(validatorError);
      }
    }
  };

  const handleSubmit = async () => {
    const data = formRef.current.getData();
    const subcategoriesForm = data.subcategories;
    const logo = subcategoriesForm.logo.type ? await toBase64(subcategoriesForm.logo) : null;

    const productsArr = map(subProducts, 'id');

    const newSub = {
      ...subcategoriesForm,
      logo,
      visible: true,
      products: productsArr,
      index: subcategoriesForm.position,
    };

    if (initialDataForm) return handleEditSub(subcategoriesForm, editSubs);

    try {
      // await subcategorySchema.validate(newSub, { abortEarly: false });

      const addSub = await actions.category.postCategory({ ...newSub, parent_id: parseFloat(id) });
      const subs = await actions.category.getSubs(id);

      setModal(false);

      await reorderList({
        list: subs,
        start: 0,
        end: subcategoriesForm.position - 1,
        editData: {},
        id,
      });

      await refetch();
      return addSub;
    } catch (error) {
      const validatorError = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          validatorError[err.path] = err.message;
        });

        toast.error('Erro ao adicionar a subcategoria');

        formRef.current.setErrors(validatorError);
      }
    }
  };

  const renderSubCategories = (subcateg, index) => (
    <SubcategoriesData
      index={index}
      subcategories={subcateg}
      deleteSubcategory={deleteSubcategory}
      setVisibility={setVisibility}
      editSubcategory={editSubcategory}
    />
  );

  return (
    <>
      <TitleCard>Subcategorias</TitleCard>

      <StyledPaper>
        <Grid container>
          <Grid item xs>
            <TableMaterial
              headers={tableHeaders}
              isDraggable
              listQuery={subcategories}
              maxHeight="380px"
              noPagination
              renderItem={renderSubCategories}
              draggableList={draggableList}
              setDraggable={setDraggable}
              editData={id && subcategories}
              id={id}
              refetch={refetch}
            />
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            className={verifyPerms({ role: 'Catálogo', access: 'write' }) ? 'disabled-events' : styles.gridPaperBottom}
          >
            <Grid item onClick={toggleModal} style={{ cursor: 'pointer' }}>
              <IconButton type="button" className={styles.iconButton}>
                <AddCircleOutlineIcon fontSize="small" color={verifyPerms({ role: 'Catálogo', access: 'write' }) ? 'disabled' : 'primary'} />
              </IconButton>

              <AddButton disabled={verifyPerms({ role: 'Catálogo', access: 'write' })} type="button">Adicionar Subcategoria</AddButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>

      <Scope path="subcategories">
        <Modal
          isVisible={modal}
          title="Adicionar Subcategoria"
          toggle={toggleModal}
          buttonPrimary="adicionar"
          buttonSecondary="cancelar"
          onClick={handleSubmit}
        >
          <NewSubcategory
            subProducts={subProducts}
            setSubProducts={setSubProducts}
            subcategories={subcategories}
            editData={editSubs}
            setEdit={setEdit}
            formRef={formRef}
            options={options}
            setOptions={setOptions}
            setInitialData={setInitialData}
          />
        </Modal>
      </Scope>
    </>
  );
}
