const ProductSchema = [
  {
    name: 'title',
    label: 'Nome do Produto',
    isFilter: true,
  },
  //  TODO: waiting resolution
  // {
  //   name: 'fabrication',
  //   label: 'Fabricação',
  //   isFilter: false,
  // },
  {
    name: 'visibility',
    label: 'Visibilidade',
    isFilter: false,
  },
  {
    name: 'category',
    label: 'Categoria',
    isFilter: false,
  },
  {
    name: 'created_at',
    label: 'Criação',
    isFilter: true,
  },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];

export default ProductSchema;
