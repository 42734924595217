import React, { useCallback, useMemo } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Scope } from '@unform/core';
import { toast } from 'react-toastify';
import Select from '../../form/select';
import PriceField from '../../form/price-field';
import TitleCard from '../../title/title-card';

import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import { useAuth } from '../../../contexts/auth';

export default function CommercialCondition({ formRef }) {
  const { verifyPerms } = useAuth();
  const { updatePaymentConfig } = useSettings();
  const [isLoading, setLoading] = React.useState(false);

  const { settings } = useSettings();

  const installments = useMemo(() => {
    const installmentArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const newInstallments = installmentArray.map((installment) => ({ value: installment, label: `${installment}x sem juros` }));
    return newInstallments;
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();

      await updatePaymentConfig({ ...data.payment_config, free_shipping_city: Number(data.payment_config.free_shipping_city), free_shipping_country: Number(data.payment_config.free_shipping_country) });
      toast.success('As Condições Comerciais foram atualizadas com sucesso!');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return toast.error('Não foi possível atualizar os dados da farmácia.');
    }
  }, [formRef, updatePaymentConfig]);

  return (
    <Scope path="payment_config">
      <TitleCard color="#A3A3A3" bold> Condições Comerciais </TitleCard>

      <StyledPaper>

        <GridContainer width="100%" mr="24px" container>

          <GridContainer container item margin="12px" spacing={4}>
            <GridContainer container item lg={12} spacing={3}>
              <Grid item lg={12} justify="center">
                <Text bold> Cartão de Crédito Online </Text>
              </Grid>
              <Grid item lg={4}>
                <Select
                  name="discount_credit_card"
                  label="Desconto no Cartão de Crédito à Vista"
                  placeholder="Desconto no Cartão de Crédito à Vista"
                  options={[{ label: '0%', value: '0' }, { label: '3%', value: '0.03' }, { label: '5%', value: '0.05' }, { label: '8%', value: '0.08' }, { label: '10%', value: '0.1' }, { label: '12%', value: '0.12' }]}
                >
                  {({ item }) => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>}
                </Select>
              </Grid>
              <Grid item lg={4}>
                <Select
                  name="installment_received"
                  label="Opção de Parcelamento"
                  placeholder="Opção de Parcelamento"
                  options={installments}
                >
                  {({ item }) => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>}
                </Select>
              </Grid>
              <Grid item lg={4}>
                <PriceField
                  name="credit_card_min_installment"
                  label="Parcela Mínima"
                  placeholder="0,00"
                  separator="."
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">R$</Text></InputAdornment>,
                  }}
                />
              </Grid>
            </GridContainer>

            <GridContainer container item lg={12} spacing={3}>
              <Grid item lg={12} justify="center">
                <Text bold> Boleto Bancário com Compensação Automática </Text>
              </Grid>
              <Grid item lg={4}>
                <Select
                  name="discount_billet"
                  label="Desconto no Boleto Bancário"
                  placeholder="Desconto no Boleto Bancário"
                  options={[{ label: '0%', value: '0' }, { label: '3%', value: '0.03' }, { label: '5%', value: '0.05' }, { label: '8%', value: '0.08' }, { label: '10%', value: '0.1' }, { label: '12%', value: '0.12' }]}
                >
                  {({ item }) => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>}
                </Select>
              </Grid>
              {/* <Grid item lg={4}>
                <Select
                  name="due_date"
                  label="Prazo de Vencimento"
                  placeholder="Prazo de Vencimento"
                  options={['5', '10', '15', '20', '25', '30']}
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="0">Dias</Text></InputAdornment>,
                  }}
                >
                  {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
                </Select>
              </Grid> */}
            </GridContainer>

            <GridContainer container item lg={12} spacing={3}>
              <Grid item lg={12} justify="center">
                <Text bold> Custo do Frete e Prazo de entrega </Text>
              </Grid>
              <Grid item lg={4}>
                <Select
                  name="free_shipping_country"
                  label="Frete Grátis para Todo o Brasil "
                  options={settings.shipping_country}
                >
                  {({ item }) => (
                    Number(item.free_shipping) >= 0
                      ? <MenuItem value={Number(item.free_shipping)} key={item.free_shipping}>A partir de R${item.free_shipping || 0}</MenuItem>
                      : <MenuItem value={-1} key={item.free_shipping}>Sem frete grátis</MenuItem>
                  )}
                </Select>
              </Grid>
              <Grid item lg={4}>
                <Select
                  name="free_shipping_city"
                  label="Frete Grátis para Minha Cidade "
                  options={settings.shipping_country}
                >
                  {({ item }) => (
                    Number(item.free_shipping) >= 0
                      ? <MenuItem value={Number(item.free_shipping)} key={item.free_shipping}>A partir de R${item.free_shipping || 0}</MenuItem>
                      : <MenuItem value={-1} key={item.free_shipping}>Sem frete grátis</MenuItem>
                  )}
                </Select>
              </Grid>
              {/* <Grid item lg={4}>
                <Select
                  name="free_shipping"
                  label="Frete Grátis para Minha Cidade "
                  options={['2', '3', '5', '10']}
                >
                  {({ item }) => <MenuItem value={item} key={item}>{item} dias úteis</MenuItem>}
                </Select>
              </Grid> */}
            </GridContainer>
            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary disabled={verifyPerms({ role: 'Conf. Website', access: 'write' })} onClick={onSubmit} variant="contained" color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>

        </GridContainer>
      </StyledPaper>
    </Scope>

  );
}
