import * as Yup from 'yup';

const volume_variant_schema = Yup.object().shape({
  value: Yup.string().required('Volume da variante é requerido.'),
  unit: Yup.string().required('Un. de volume é requerido.'),
  price_relative_perc: Yup.string().required('O Preço vs Padrão é requerido.'),
});

const options_value = Yup.object().shape({
  value: Yup.string().required('Variante é requerido.'),
});

const variants_schema = Yup.object().shape({
  name: Yup.string().required('Nome da variante é requerido.'),
  options: Yup.array().required('Variante é requerido.').of(options_value),
});

export const schema = Yup.object().shape({
  // available_from: Yup.date().default(() => new Date()),
  // available_to: Yup.date().default(() => new Date()),
  // benefits: Yup.string(),
  // callcenter: Yup.object().shape({
  //   availability: Yup.boolean(),
  //   from: Yup.number().default(() => new Date().getTime()),
  // }),
  // categories: Yup.array().of(
  //   Yup.number(),
  // ),
  // compounded: Yup.boolean().required(),
  // description: Yup.string().required('Descrição obrigatória'),
  // discount_price: Yup.string(),
  // dosage: Yup.string(),
  // ecommerce: Yup.object().shape({
  //   availability: Yup.boolean(),
  //   from: Yup.number().default(() => new Date().getTime()),
  // }),
  volume_variants: Yup.array()
    .of(volume_variant_schema)
    .ensure(),
  variants: Yup.array()
    .of(variants_schema)
    .ensure(),
  title: Yup.string().required('Nome do produto é obrigatório'),
  price: Yup.number().notRequired(),
  discount_price: Yup.number().required('O preço de venda e obrigatório'),
  form: Yup.string().required('Forma farmacêutica obrigatória'),
  max_qty: Yup.number().required('Quantidade máxima é obrigatório').nullable().min(1, 'A quantidade máxima do produto por pedido deve ser pelo menos a 1'),
  // freeze_out_of_stock: Yup.boolean(),
  // has_variant: Yup.boolean().required(),
  // ingredients: Yup.array().of(
  //   Yup.object().shape({
  //     concentration: Yup.string(),
  //     is_active: Yup.boolean(),
  //     measure: Yup.string(),
  //     name: Yup.string(),
  //     qsp: Yup.boolean(),
  //     stock_consumption: Yup.boolean(),
  //     visible: Yup.boolean(),
  //   }),
  // ),
  // is_promotion: Yup.boolean(),
  // is_refrigerated: Yup.boolean(),
  // keywords_concat: Yup.string(),
  // media: Yup.array().of(
  //   Yup.object().shape({
  //     base: Yup.object().shape({
  //       content_type: Yup.string(),
  //       image: Yup.string(),
  //     }),
  //     description: Yup.string(),
  //     is_customized_image: Yup.boolean(),
  //     logo: Yup.object().shape({
  //       area_left: Yup.number(),
  //       area_top: Yup.number(),
  //       max_height: Yup.number(),
  //       max_width: Yup.number(),
  //     }),
  //     mask: Yup.object().shape({
  //       content_type: Yup.string(),
  //       image: Yup.string(),
  //     }),
  //     position: Yup.number(),
  //     text: Yup.object().shape({
  //       content_type: Yup.string(),
  //       image: Yup.string(),
  //     }),
  //     title: Yup.string(),
  //     type: Yup.number(),
  //   }),
  // ),
  // promotion: Yup.object().shape({
  //   from: Yup.number(),
  //   is_active: Yup.boolean(),
  //   price: Yup.string(),
  //   to: Yup.number(),
  // }),
  // recommendations: Yup.string(),
  // target_consumer: Yup.string(),
  // recommended_age: Yup.object().shape({
  //   max: Yup.number().moreThan(
  //     Yup.ref('min'), 'Idade máxima menor do que a Idade mínima',
  //   ),
  //   min: Yup.string(),
  // }),
  // subtitle: Yup.string(),
  // summary: Yup.string().required('Resumo obrigatório'),
  // title: Yup.string().required(),
  // track_qty: Yup.boolean(),
  // usage: Yup.number(),
  // variant_request: Yup.array().of(
  //   Yup.object().shape({
  //     name: Yup.string(),
  //     options: Yup.array().of(
  //       Yup.object().shape({
  //         value: Yup.string(),
  //       }),
  //     ),
  //   }),
  // ),
  // variant_values: Yup.array().of(
  //   Yup.object().shape({
  //     callcenter_availability: Yup.boolean(),
  //     callcenter_datetime: Yup.date(),
  //     ecommerce_availability: Yup.boolean(),
  //     ecommerce_datetime: Yup.boolean(),
  //     form: Yup.number(),
  //     image: Yup.string(),
  //     is_refrigerated: Yup.boolean(),
  //     is_visible: Yup.boolean(),
  //     on_hold: Yup.boolean(),
  //     price: Yup.number(),
  //     shipping_term: Yup.number(),
  //     sku: Yup.string(),
  //     standard: Yup.boolean(),
  //     url: Yup.string(),
  //     usage: Yup.number(),
  //     weight: Yup.number(),
  //   }),
  // ),
  // weight: Yup.string(),
});

export const mediaSchema = Yup.object().shape({
  base: Yup.object().shape({
    content_type: Yup.string(),
    image: Yup.string(),
  }),
  description: Yup.string(),
  is_customized_image: Yup.boolean(),
  logo: Yup.object().shape({
    area_left: Yup.number(),
    area_top: Yup.number(),
    max_height: Yup.number(),
    max_width: Yup.number(),
  }),
  mask: Yup.object().shape({
    content_type: Yup.string(),
    image: Yup.string(),
  }),
  position: Yup.number(),
  text: Yup.object().shape({
    content_type: Yup.string(),
    image: Yup.string(),
  }),
  title: Yup.string(),
  type: Yup.number(),
});

export const compoundSchema = [
  {
    id: 'true',
    label: 'Manipulado',
  },
  {
    id: 'false',
    label: 'Industrializado',
  },
];

export const variantSchema = [
  {
    id: 'true',
    label: 'Único (sem variantes)',
  },
  {
    id: 'false',
    label: 'Configurável (com variantes)',
  },
];

export const logisticSchema = [
  {
    id: 'false',
    label: 'Temperatura Ambiente',
  },
  {
    id: 'true',
    label: 'Manter Refrigerado',
  },
];
