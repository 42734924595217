import { withStyles } from '@material-ui/styles';

import Tooltip from '@material-ui/core/Tooltip';

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#424242',
    boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
    borderRadius: '10px',
    fontSize: '14px',
    padding: '8px 20px',
    textAlign: 'center',
  },
}))(Tooltip);
