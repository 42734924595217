import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { Form } from '@unform/web';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import TextField from '../../form/text-field';

import {
  CardLogin, Row, RowBottom, Subtitle, Title, useStyles,
} from '../styles';

export default function LoginRequest() {
  const styles = useStyles();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    console.log('params');
    console.log(params.cpf);
  }, [params.cpf]);

  const handleSubmit = (formData) => {
    console.log('form data');
    console.log(formData);
  };

  return (
    <div>
      <CardLogin cardRequest>
        <Title style={{ padding: '0 28px' }}>
          Solicitar Autorização de Acesso no Sistema
        </Title>

        <Subtitle>
          Preencha os dados abaixo para que o gestor possa analisar sua solicitação
        </Subtitle>

        <Form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={3} style={{ marginTop: 30 }}>

            <Grid item xs={12} sm={12}>
              <TextField
                name="nome"
                label="Nome Completo"
                placeholder="Henrique Dias Ramos"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                name="local"
                label="Local de Trabalho"
                placeholder="Rua Sergipe, 120"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                name="funcao"
                label="Função"
                placeholder="Vendas"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                name="cpf"
                label="CPF"
                placeholder="000.000.000-00"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                name="email"
                label="E-mail para Receber Link de Acesso"
                placeholder="nome@email.com.br"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Row style={{ marginTop: '40px' }}>
            <Button color="primary" variant="contained" type="submit" className={styles.button}>
              Continuar
            </Button>
          </Row>

          <Row>
            <Button
              onClick={() => { history.goBack(); }}
              color="primary"
              variant="outlined"
              type="button"
              className={styles.buttonOutline}
            >
              Voltar
            </Button>
          </Row>
        </Form>
      </CardLogin>

      <RowBottom>
        <Button
          onClick={() => { history.push('/'); }}
          color="primary"
          type="button"
          className={styles.buttonText}
        >
          <KeyboardBackspaceIcon className={styles.iconKeyboard} />
          voltar para página principal
        </Button>
      </RowBottom>
    </div>
  );
}
