import React from 'react';
import { ToastContainer } from 'react-toastify';
import {
  useHistory,
} from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

import GlobalStyles from '../../styles/global';

import AppHeader from './app-header';
import FooterLogin from './footer/footer-login';
import HeaderLogin from './header/header-login';
import Spinner from '../spinner';

import { AppContainer } from '../styles';

import store from '../../store';
import AppProvider from '../../contexts/index';
import { WhatsappButton } from '../whatsapp/whatsapp';

export default function Layout(props) {
  const { settings } = store.get();

  const history = useHistory();

  const routePath = history.location.pathname.split('/');

  function renderMain() {
    const { Component, LayoutFrame, ...restProps } = props;

    restProps.settings = settings;

    const brand = {
      linkColor: '#000',
      color: '#22E0A1',
    };

    const theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 770,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      palette: {
        primary: { main: brand.color },
        secondary: { main: '#005A7D' },
        error: { main: red[500] },

      },
      typography: {
        fontFamily: 'Poppins, san-serif',
        useNextVariants: true,
      },
      shadows: [
        'none', 'none', 'none', 'none', 'none',
        'none', 'none', 'none', 'none', 'none',
        'none', 'none', 'none', 'none', 'none',
        'none', 'none', 'none', 'none', 'none',
        'none', 'none', 'none', 'none', 'none',
      ],
      shape: { borderRadius: 0 },
      overrides: {
        MuiAppBar: {
          positionFixed: { height: '64px' },
        },
        MuiToolbar: {
          root: { height: '64px' },
        },
        MuiButton: {
          root: { textTransform: 'none', borderRadius: 100 },
          containedPrimary: { color: '#fff', fontWeight: 'bold' },
          label: {
            whiteSpace: 'nowrap',
            overflow: 'ellipsis',
          },
        },
        'Mui-disabled': {
          textTransform: 'none',
          padding: '14px 16px 12px',
          lineHeight: 1,
        },
        MuiChip: {
          root: {
            backgroundColor: grey[100],
            color: grey[600],
          },
          avatar: {
            backgroundColor: grey[100],
            paddingLeft: '3px',
            color: grey[600],
          },
          label: {
            paddingTop: '3px',
            paddingLeft: '4px',
          },
        },
        MuiExpansionPanel: {
          backgroundColor: 'rgba(0, 185, 204, 0.1);',
        },
      },
    });

    const backgroundColor = () => {
      if (LayoutFrame === 'default') {
        return '#F9F9F9';
      }

      return '#FFFFFF';
    };

    return (
      <AppProvider>
        <MuiThemeProvider theme={theme}>
          <GlobalStyles />

          <AppContainer style={{ background: backgroundColor() }}>
            {LayoutFrame === 'default'
              ? (
                <>
                  <ThemeProvider theme={settings?.brand || {}}>
                    <AppHeader {...restProps} />

                    <div className="app-content">
                      <Component {...restProps} />
                    </div>
                  </ThemeProvider>
                </>
              )
              : LayoutFrame === 'login' ? (
                <>
                  <HeaderLogin />

                  <div className="app-content">
                    <Component {...restProps} />

                    <FooterLogin />
                  </div>
                </>
              ) : (
                <Component {...restProps} />
              )}

            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </AppContainer>

          <WhatsappButton bottomAnchor={(
            routePath.includes('products')
            || routePath.includes('add-contributor')
            || routePath.includes('edit-roles-and-permissions')
            || routePath.includes('blog-post')
            || routePath.includes('promocode')
            || routePath.includes('categories'))
            && routePath.length > 2 ? '90px' : '20px'}
          />

        </MuiThemeProvider>
      </AppProvider>
    );
  }

  return settings || !props.login ? renderMain() : <Spinner />;
}
