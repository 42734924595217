import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

function StepMannager(props) {
  const { steps, defaultSection } = props;
  const params = useParams();
  const mounted = useRef();

  const [step, setStep] = useState(params.step || defaultSection);

  function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const previousStep = usePrevious(step);

  const updateStep = () => {
    const newStep = params.step;

    if (previousStep !== newStep) {
      setStep(newStep || defaultSection);
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      updateStep();
    }
  });

  const getChildProps = () => {
    const { router } = props;

    return { router };
  };

  function renderStep() {
    const Step = steps[step];

    if (Step) {
      return (
        <Step {...getChildProps()} {...props} />
      );
    }
    return null;
  }
  return renderStep();
}

export default StepMannager;
