import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { ItemContainer, useStyles } from './styles';

export default function Pagination({ handleParams, pagination }) {
  const { search } = useLocation();
  const styles = useStyles();
  const [activityPage, setActivityPage] = useState();

  const pages = useMemo(() => {
    const newPages = pagination?.last_page ? pagination : { near_pages: [1], last_page: 1 };
    return newPages;
  }, [pagination]);

  useEffect(() => {
    const getParams = new URLSearchParams(search);
    const pageParams = getParams.get('p');
    setActivityPage(Number(pageParams) || 1);
  }, [search]);

  const handlePage = (param, page) => {
    setActivityPage(page);
    handleParams(param, page);
  };

  return (
    <Grid container justify="center" alignItems="center" className={styles.grid}>
      <ItemContainer className={styles.item} style={{ width: '60px' }} noBorder type="button" onClick={() => handlePage('p', 1)}>
        primeira
      </ItemContainer>

      {pages?.near_pages.map((number) => {
        const isActive = (number === activityPage);

        return (
          <ItemContainer key={number} active={isActive} onClick={() => handlePage('p', number)} className={isActive ? 'is-active' : ''}>
            {number}
          </ItemContainer>
        );
      })}

      <ItemContainer className={styles.item} noBorder type="button" onClick={() => handlePage('p', pages?.last_page)}>
        última
      </ItemContainer>
    </Grid>
  );
}
