/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

import { CategoryItem } from '../@types/category';

interface CategoryState {
  items: CategoryItem;
  total_count: number;
}

interface CategoryContextData {
  isLoading: boolean;
  categories: CategoryState;
  fetchCategories(params: object): Promise<object>;
  fetchCategoriesWithParams(params: object): Promise<CategoryState[]>;
  getOneCategory(id: number): Promise<{ item: CategoryItem }>;
  addCategory(data: CategoryItem): Promise<void>;
  deleteCategory(id: number): Promise<void>;
  updateCategory(id: number, data: CategoryItem): Promise<void>;
}

const CategoryContext = createContext<CategoryContextData>({} as CategoryContextData);

export const CategoryProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [categories, setCategories] = useState<CategoryState>(() => ({} as CategoryState));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchCategories = useCallback(async (params: any) => {
    setLoading(true);
    const response = await api.get('/v2/store/category', {
      params: {
        ...params,
        recursive: true,
        fetch_products: true,
        fetch_keywords: true,
      },
    });

    setCategories(response.data);
    setLoading(false);
    return params;
  }, []);

  const fetchCategoriesWithParams = useCallback(async (params: any) => {
    const response = await api.get('/v2/store/category', {
      params: {
        ...params,
      },
    });

    return response.data;
  }, []);

  const getOneCategory = useCallback(async (id: number) => {
    const queryParams = {
      recursive: true,
      fetch_products: true,
      fetch_keywords: true,
    };
    const response = await api.get(`/v2/store/category/${id}`, { params: queryParams });

    return { item: response.data };
  }, []);

  useEffect(() => {
    async function getCategoriesData() {
      await fetchCategories({});
    }

    getCategoriesData();
  }, [fetchCategories]);

  const addCategory = useCallback(async (data: any) => {
    const response = await api.post('/v2/store/category', data);
    return response.data;
  }, []);

  const deleteCategory = useCallback(async (id: number) => {
    await api.delete(`/v2/store/category/${id}`);
    await fetchCategories({});
  }, [fetchCategories]);

  const updateCategory = useCallback(async (id: number, data: any) => {
    await api.put(`/v2/store/category/${id}`, data);
    await fetchCategories({});
  }, [fetchCategories]);

  return (
    <CategoryContext.Provider
      value={{
        fetchCategories, fetchCategoriesWithParams, getOneCategory, addCategory, deleteCategory, updateCategory, isLoading, categories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export function useCategory(): CategoryContextData {
  const context = useContext(CategoryContext);

  if (!context) {
    throw new Error('useCategory must be used within an CategoryProvider');
  }
  return context;
}
