import React, { useCallback, useRef, useState } from 'react';

import { Grid, Button, MenuItem, CircularProgress } from '@material-ui/core';
import { Form } from '@unform/web';

import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import cep from 'cep-promise';
import TextField from '../../../form/text-field';
import SubtitleCard from '../../../title/subtitle-card';

import { Container } from '../../../styles';
import { useCustomer } from '../../../../contexts/customer';
import Select from '../../../form/select';
import { ButtonPrimary } from '../../../settings/styles';

function NewAddressForm({ toggle, address }) {
  const formRef = useRef();
  const { id } = useParams();
  const history = useHistory();

  const { updateCustomerAddress } = useCustomer();
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const [initialData, setInitialData] = useState(address);

  const addressSchema = Yup.object().shape({
    address: Yup.object().shape({
      zip: Yup.string().required('CEP obrigatório').min(8, 'CEP inválido'),
      street: Yup.string().required('Rua obrigátorio'),
      number: Yup.number().required('Número obrigatório').typeError('Número obrigatório'),
      complement: Yup.string(),
      city: Yup.string().required('Cidade obrigatório'),
      uf: Yup.string().required('Estado obrigatório'),
      obs: Yup.string(),
      reference: Yup.string(),
      type: Yup.string(),
    }),
  });

  const handleData = useCallback((data) => {
    setInitialData({ ...initialData, ...data });
  }, [initialData]);

  async function getCep(zip) {
    const data = formRef.current.getData();

    try {
      if (zip.length !== 8) return null;
      const response = await cep(zip);
      handleData({ ...data, ...response, zip: response.cep, district: response.neighborhood, uf: response.state });

      setVisible(false);
      setVisible(true);
      return response;
    } catch (err) {
      return toast.error('CEP não encontrado');
    }
  }

  const handleUpdateAddress = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();
      await addressSchema.validate({ address: data }, { abortEarly: false });

      await updateCustomerAddress(data, id, address.id);
      setLoading(false);
      toast.success('Endereço atualizado com sucesso!');
      history.push('/customers');
    } catch (err) {
      const validationErrors = {};
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        toast.error(err.errors[0]);
        return formRef.current.setErrors(validationErrors);
      }
      return toast.error('Não foi possível atualizar este endereço.');
    }
  }, [updateCustomerAddress, id, address, addressSchema, history]);

  const renderType = ({ item, handleValue }) => <MenuItem value={item.value} key={item.name} onClick={handleValue}>{item.name}</MenuItem>;
  return (
    <Container>
      <SubtitleCard>Cadastrar novo endereço</SubtitleCard>

      {isVisible && (
      <Form ref={formRef} initialData={initialData}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField name="recipient" label="Nome do Destinatário" placeholder="Nome do Destinatário" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Select
              name="type"
              width="384px"
              label="Tipo de Endereço"
              options={[{ name: 'Residencial', value: 'residential' }, { name: 'Comercial', value: 'business' }]}
            >
              {renderType}
            </Select>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="zip"
              onChange={(e) => getCep(e.target.value.replaceAll('-', ''))}
              label="CEP"
              placeholder="CEP"
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField name="street" label="Rua" placeholder="Rua" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField name="number" label="Número" placeholder="Número" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField name="complement" label="Complemento" placeholder="Complemento" />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField name="district" label="Bairro" placeholder="Bairro" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField name="city" label="Cidade" placeholder="Cidade" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField name="uf" label="Estado" placeholder="Estado" />
          </Grid>
        </Grid>

        <Container>
          <Grid container>
            <Grid item xs={12}>
              <ButtonPrimary style={{ float: 'right' }} variant="contained" color="primary" onClick={handleUpdateAddress}>
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
              <Button style={{ float: 'right', marginRight: 10 }} variant="outlined" color="primary" onClick={toggle}>cancelar</Button>
            </Grid>
          </Grid>
        </Container>
      </Form>
      )}

    </Container>
  );
}

export default NewAddressForm;
