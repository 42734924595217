export default function formatRg(rg) {
  if (rg?.length === 9) {
    const rgAtualizado = rg?.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/,
      (regex, argumento1, argumento2, argumento3, argumento4) => `${argumento1}.${argumento2}.${argumento3}-${argumento4}`);
    return rgAtualizado;
  }
  const rgAtualizado = rg?.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/,
    (regex, argumento1, argumento2, argumento3, argumento4) => `${argumento1}.${argumento2}.${argumento3}-${argumento4}`);
  return rgAtualizado;
}
