import React, { useCallback, useState } from 'react';

import {
  Button, FormControl, Grid, RadioGroup, Radio, InputAdornment,
} from '@material-ui/core';

import RadioButton from '../../form/form-radio-button';
import SubtitleCard from '../../title/subtitle-card';
import TitleCard from '../../title/title-card';
import TextField from '../../form/text-field';

import useStyles from './styles';
import { StyledPaper } from '../../styles';
import PriceField from '../../form/price-field';

export default function Info({
  formRef, type, setType, usage, setUsage, id, initialData,
}) {
  const styles = useStyles();
  const [name, setName] = useState();

  const handleRandom = useCallback(() => {
    const randomString = Math.random().toString(36).substring(7).toUpperCase();
    formRef.current.setFieldValue('name', randomString);
  }, [formRef]);

  function lengthLabel(data) {
    if (data) {
      return data ? data.length : 0;
    }

    return 0;
  }

  const isEditForbid = initialData?.forbid_edit;

  return (
    <>
      <TitleCard>Dados Básicos</TitleCard>

      <StyledPaper>
        <Grid container spacing={2}>
          {!initialData.is_system_managed && (
            <>
              <Grid item sm={7} className={styles.gridCategoryTextField}>
                <TextField
                  name="name"
                  disabled={id !== 'create'}
                  label="Código Do Cupom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Código do Cupom"
                  inputProps={{ maxLength: 20 }}
                  onChange={(e) => setName(e.target.value)}
                  helperText={`${lengthLabel(name)}/20 Caracteres`}
                  value={name}
                />
              </Grid>

              <Grid item sm={5}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  disabled={id !== 'create'}
                  onClick={handleRandom}
                  style={{ borderWidth: 2, height: 36, margin: '10px 0 0', width: '100%' }}
                >
                  gerar código aleatório
                </Button>
              </Grid>

              <Grid item sm={6} style={{ marginTop: 12 }}>
                <SubtitleCard>Tipo do Desconto</SubtitleCard>

                <FormControl disabled={isEditForbid} required className={styles.formControl}>
                  <RadioGroup row name="type" value={type}>
                    <Grid container>
                      <RadioButton
                        value="percentage"
                        control={<Radio color="primary" />}
                        label="Percentagem"
                        className={styles.radioFormulationMargin}
                        onClick={() => setType('percentage')}
                      />

                      <RadioButton
                        value="price"
                        control={<Radio color="primary" />}
                        label="Valor fixo"
                        className={styles.radioFormulationMargin}
                        onClick={() => setType('price')}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sm={6} style={{ marginTop: 12 }}>
                <SubtitleCard>Aplicar Sobre</SubtitleCard>

                <FormControl disabled={id !== 'create'} required className={styles.formControl} style={{ pointerEvents: id !== 'create' && 'none' }}>
                  <RadioGroup row name="usage" value={usage}>
                    <Grid container>
                      <RadioButton
                        value="discount_cart"
                        control={<Radio color="primary" />}
                        label="Itens do carrinho"
                        className={styles.radioFormulationMargin}
                        onClick={() => setUsage('discount_cart')}
                      />
                      <RadioButton
                        value="discount_freight"
                        control={<Radio color="primary" />}
                        label="Frete"
                        className={styles.radioFormulationMargin}
                        onClick={() => setUsage('discount_freight')}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item sm={12} style={{ margin: '-6px 0 6px' }}>
            <SubtitleCard>Desconto no Cupom</SubtitleCard>
            {type === 'percentage' && (
              <TextField
                name={usage}
                label="Desconto no Cupom"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment className={styles.adornmentEnd}><span>{type === 'percentage' ? '%' : 'R$'}</span></InputAdornment>,
                }}
                disabled={isEditForbid}
              />
            )}

            {type === 'price' && (
            <PriceField
              name={usage}
              label="Desconto no Cupom"
              separator="."
              InputProps={{
                startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
              }}
              disabled={isEditForbid}
            />
            )}

          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
