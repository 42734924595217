import React from 'react';
import _ from 'lodash';

import actions from '../actions';
import usePrevious from './usePrevious';
import store from '../store';

export default function useListLoader(endpoint, queryParams, { maxAge = 60000 } = {}) {
  const prevQueryParams = usePrevious(queryParams);

  const [isLoading, setLoading] = React.useState(false);

  const cachedResult = queryParams && actions.general.fetchCached(endpoint, queryParams);
  const cachedItems = cachedResult ? cachedResult.data : [];
  const results = store.get(`${endpoint}_results`);

  async function runFetch() {
    if (!queryParams) return;

    setLoading(true);
    try {
      await actions.general.fetch(endpoint, queryParams);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (_.isEqual(queryParams, prevQueryParams)) return;

    async function update() {
      if (!cachedResult) {
        runFetch();
      } else {
        const age = Date.now() - (cachedResult.fetched || 0);
        if (age >= maxAge) runFetch();
      }
    }

    update();
  });

  return {
    items: cachedItems,
    isLoading,
    refetch: runFetch,
    results,
  };
}
