import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  price: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#424242',
  },

  productOrderTitle: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#A3A3A3',
  },

  ProductResume: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#424242',
  },

  outlinedButton: {
    border: '2px solid #22E0A1',
    borderRadius: '16px',
    height: '32px',
    width: '96px',
  },

  tableButton: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'none !important',
    },
  },

  flexGrow: {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
  },

}));
