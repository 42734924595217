import api from '../api';
import errHandler from './_errhandler';

export const addMediaToProduct = async (id, media) => {
  const addMedia = await api.post(`/v2/store/product/${id}/media`, media)
    .then(res => res.data)
    .catch(errHandler);

  return addMedia;
};

export const removeMedia = async (media) => {
  const remove = await api.delete(`/v2/store/product/${media.productId}/media/${media.id}`)
    .then(res => res.data)
    .catch(errHandler);

  return remove;
};

export const updateMedia = async (media) => {
  const update = await api.put(`/v2/store/product/${media.productId}/media/${media.id}`, media)
    .then(res => res.data)
    .catch(errHandler);

  return update;
};
