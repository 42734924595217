import React, { useCallback, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Grid, CircularProgress } from '@material-ui/core';

import { toast } from 'react-toastify';
import TitleCard from '../../title/title-card';

import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import RedirectList from '../components/redirects-list';
import { AddButton, useStyles } from '../../styles';

export default function Redirects({ formRef, redirects, setRedirects }) {
  const styles = useStyles();

  const { updateRedirects } = useSettings();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { redirects: formRedirects } = formRef.current.getData();

      await updateRedirects(formRedirects.replace_redirects);

      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updateRedirects, formRef]);

  const addRedirects = () => {
    setRedirects([...redirects, {
      path: '',
      value: '',
    }]);
  };

  return (
    <>

      <TitleCard color="#A3A3A3" bold>Redirecionamento de Páginas</TitleCard>

      <StyledPaper>

        <GridContainer container item width="auto" margin="12px" spacing={4}>
          <Grid item xs={12} sm={12}>
            <RedirectList redirects={redirects} setRedirect={setRedirects} />
          </Grid>

          <Grid container direction="row" alignItems="center" className={styles.gridPaperBottom}>
            <Grid
              item
              style={{ cursor: 'pointer' }}
              onClick={addRedirects}
            >
              <IconButton type="button" className={styles.iconButton}>
                <AddCircleOutlineIcon fontSize="small" color="primary" />
              </IconButton>

              <AddButton type="button">Adicionar novo redirecionamento</AddButton>
            </Grid>
          </Grid>

          <GridContainer container item lg={12} mt="24px" justify="flex-end">
            <ButtonPrimary onClick={onSubmit} variant="contained" color="primary">
              {isLoading ? (
                <CircularProgress size={15} style={{ color: '#fff' }} />
              ) : (
                'salvar alterações'
              )}
            </ButtonPrimary>
          </GridContainer>
        </GridContainer>

      </StyledPaper>

    </>
  );
}
