import React, { useRef, useCallback, useState, useEffect } from 'react';

// Libs
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';

// Icons
import SortIcon from '@material-ui/icons/Sort';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

// Components
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
import SearchField from '../../form/search-field';
import Spinner from '../../spinner';
import TableMaterial from '../../table';
import Select from '../../form/select';
import TabPanel from './tab-panel';

// Schemas
import { ColaboratorsSchema, PreColaboratorsSchema } from './schema';

// Context
// import { useColaborators } from '../../../contexts/contributors';

// Data
import ColaboratorsData from './data';
import PreColaboratorsData from './data-pre-colaborators';
import TabNavigator from '../../tabs-navigation';

// Styles
import { PageTitle, useStyles } from '../../styles';
import { Divider, FormContainer, CleanForm, Container } from '../styles';
import { useColaborators } from '../../../contexts/contributors';
import { useBranch } from '../../../contexts/branch';
import { useGroups } from '../../../contexts/group';
import { useAuth } from '../../../contexts/auth';

function Contributors() {
  /** CONSTS */
  const styles = useStyles();
  const formRef = useRef();
  const advancedFilterFormRef = useRef();
  const history = useHistory();
  const { verifyPerms } = useAuth();
  const { search } = useLocation();
  const { departments } = useGroups();
  const { branchs } = useBranch();
  const { colaborators, preColaborators, fetchColaborators, isLoading } = useColaborators();

  const renderItem = (colaborator) => <ColaboratorsData colaborator={colaborator} refetch={fetchColaborators} />;
  const renderPreColaborators = (colaborator) => <PreColaboratorsData colaborator={colaborator} refetch={fetchColaborators} />;

  const [department, setDepartment] = useState('');
  // const [functionGroup, setFunctionGroup] = useState('');
  // const [functionGroupList, setFunctionGroupList] = useState([]);

  const [branch_id, setBranch] = useState('');
  const [is_enabled, setIsEnabled] = useState('');

  const [showFilter, setShowFilter] = useState(false);
  const [parseSearch, setParseSearch] = useState('');
  const [activeTab, setTab] = useState(0);

  const [statusOptions] = useState([
    { label: 'Ativo', value: true }, { label: 'Inativo', value: false },
  ]);

  // useEffect(() => {
  //   if (departments?.length && groups?.length) {
  //     // setDepartment(departments[0].id);

  //     // const findGroup = groups.find((item) => item.department.id === departments[0].id);
  //     // setFunctionGroup(findGroup.id);
  //     setFunctionGroupList(groups.filter((item) => item.department.id === departments[0].id));
  //   }
  // }, [departments, groups]);

  const handleDepartment = useCallback((e) => {
    // const filteredFunctions = groups.filter((item) => item.department.id === e);

    // setFunctionGroupList(filteredFunctions);
    // setFunctionGroup(filteredFunctions?.length ? filteredFunctions[0].id : '');
    setDepartment(e);
  }, []);

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const getColaborator = async () => {
      await fetchColaborators(allParams);
    };

    getColaborator();
  }, [search, fetchColaborators]);

  useEffect(() => {
    function getValue() {
      const splitSearch = search.split('&');
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      if (Object.keys(allParams).length) {
        setShowFilter(true);
      }

      setTab(Number(allParams?.tab) || 0);
      setDepartment(allParams?.department_id);
      // setFunctionGroup(allParams?.group_id);
      setBranch(allParams?.branch_id);
      setIsEnabled(allParams?.is_enabled);

      const keywords = splitSearch.map(item => {
        if (item.indexOf('name=') !== -1 && formRef.current) {
          setParseSearch(item.replace('name=', '').replace('?', ''));

          return formRef.current.setFieldValue('search', allParams?.name);
        }

        return null;
      });

      return keywords;
    }

    getValue();
  }, [search]);

  const handleParams = useCallback((param, data) => {
    if (data === undefined || data === 0) return;
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    let newParams = {};

    // if (param === 'department_id') {
    //   const filteredFunctions = groups.filter((item) => item.department.id === data);
    //   newParams = { ...allParams, ...params, group_id: filteredFunctions?.length ? filteredFunctions[0].id : '' };
    // } else {
    newParams = { ...allParams, ...params, tab: 0 };
    // }

    if (param === 'name') {
      newParams.p = 1;
      const isEqual = allParams?.name === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    history.push({
      pathname: '/contributors',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  const tabs = [
    {
      name: 'tab1',
      label: 'Colaboradores',
    },
    {
      name: 'tab2',
      label: 'Convites em aberto',
    },
  ];

  const handleClearParams = useCallback(() => {
    history.push('/contributors');
  }, [history]);

  return colaborators ? (
    <>
      <Breadcrumb
        title="Lista de Colaboradores"
        text="Organização"
        current="Colaboradores"
      />

      <ContentWrapper align="top">
        <Grid container direction="row" alignItems="center" className={styles.gridRow}>
          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>Lista de Colaboradores</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <Form ref={formRef} onSubmit={(formData) => handleParams('name', formData.search)}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchField}>
                  <SearchField
                    name="search"
                    handleParams={handleParams}
                    search={search}
                    label="buscar colaborador"
                    placeholder="buscar colaborador"
                    parseSearch={parseSearch}
                    clear={() => handleParams('name', '')}
                  />
                </div>

                <Button
                  variant={showFilter ? 'contained' : 'outlined'}
                  color="primary"
                  className={styles.buttonFilter}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <SortIcon />
                </Button>

                <Button
                  className={styles.filterButton}
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={verifyPerms({ access: 'write', role: 'Orga. > Colab.' })}
                  onClick={() => history.push('/add-contributor/create')}
                >
                  convidar colaborador
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>

        <Container style={{ display: showFilter ? 'flex' : 'none' }}>
          <Divider />
          <FormContainer>
            <Form ref={advancedFilterFormRef} onSubmit={(formData) => handleParams('q', formData.search)} className="form">
              <Grid item xs={12} className="title">
                Filtros avançados
              </Grid>
              <Grid container direction="row" alignItems="center" justify="flex-start">

                {/* <Grid item className="select">
                  <Select
                    name="group_id"
                    label="Cargo"
                    options={functionGroupList}
                    value={functionGroup}
                    onChange={(e) => setFunctionGroup(e.target.value)}
                    onClick={(e) => handleParams('group_id', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.id} key={item.name}>{item.name}</MenuItem>}
                  </Select>
                </Grid> */}
                <Grid item className="select">
                  <Select
                    name="department"
                    label="Departamento"
                    options={departments?.length ? departments : []}
                    value={department}
                    onClick={(e) => handleParams('department_id', e.target.value)}
                    onChange={(e) => { handleDepartment(e.target.value); }}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.id} key={item.name}>{item.name}</MenuItem>}
                  </Select>
                </Grid>

                <Grid item className="select">
                  <Select
                    name="branch_id"
                    label="Unidade"
                    options={branchs.items}
                    value={branch_id}
                    onChange={(e) => setBranch(e.target.value)}
                    onClick={(e) => handleParams('branch_id', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.id} key={item.id}>{item.fantasy_name}</MenuItem>}
                  </Select>
                </Grid>
                <Grid item className="select">
                  <Select
                    name="status"
                    label="Status"
                    options={statusOptions}
                    value={is_enabled}
                    onChange={(e) => setIsEnabled(e.target.value)}
                    onClick={(e) => handleParams('is_enabled', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>}
                  </Select>
                </Grid>
              </Grid>
            </Form>
            <CleanForm onClick={handleClearParams}>
              <HighlightOffOutlinedIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }} />
              <p>Limpar todos os filtros</p>
            </CleanForm>
          </FormContainer>
        </Container>
      </ContentWrapper>
      {isLoading ? <Spinner /> : (
        <Container className={styles.containerTableBottom}>
          <Grid item xs={12}>
            <TabNavigator tabs={tabs} activeTab={activeTab} onChange={(event, newValue) => setTab(newValue)} />
          </Grid>
          <TabPanel value={activeTab} index={0}>
            <TableMaterial
              headers={ColaboratorsSchema}
              noPagination
              renderItem={renderItem}
              listQuery={colaborators}
            />
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <TableMaterial
              headers={PreColaboratorsSchema}
              noPagination
              renderItem={renderPreColaborators}
              listQuery={preColaborators}
            />
          </TabPanel>

        </Container>
      )}
    </>
  ) : (
    <Spinner />
  );
}

export default Contributors;
