import React from 'react';
import { WhatsappArea } from './style';
import whatsapp from './whatsapp.png';

export function WhatsappButton({
  bottomAnchor,
}) {
  return (
    <a
      className="no-print"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=5511910504942&text=Ol%C3%A1,%20solicito%20suporte%20na%20plataforma%20Pedido%20Pago.%20Minha%20d%C3%BAvida%20%C3%A9:"
      rel="noopener noreferrer"
    >
      <WhatsappArea bottomAnchor={bottomAnchor}>
        <img src={whatsapp} alt="whatsapp" />
      </WhatsappArea>
    </a>
  );
}
