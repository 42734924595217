import React, { useCallback, useState } from 'react';

// Libs
import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Context
// import { useColaborators } from '../../../contexts/contributors';

// Styles
import { toast } from 'react-toastify';
import { useStyles } from '../../styles';
import { StyledTooltip } from '../../tooltip';
import { useGroups } from '../../../contexts/group';
import DeleteModal from '../../deleteModal';
import { useAuth } from '../../../contexts/auth';

function RolesAndPermissionsData({ roleAndPermission }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();
  const [modal, setModal] = useState(false);
  const { deleteGroup } = useGroups();

  const handleDeleteGroup = useCallback(async () => {
    try {
      await deleteGroup(roleAndPermission.id);
      toast.success(`O cargo ${roleAndPermission.name} foi deletada.`);
      setModal(false);
    } catch (err) {
      toast.error('Não foi possível remover este cargo.');
    }
  }, [deleteGroup, roleAndPermission]);

  return (
    <>
      <TableCell>{roleAndPermission.name}</TableCell>
      <TableCell>{roleAndPermission?.department?.name}</TableCell>

      <TableCell>{roleAndPermission.colaboradores}</TableCell>

      <TableCell>
        <Grid container justify="flex-end">
          <Grid item>
            <StyledTooltip title="Copiar cargo" placement="top" className={styles.tableButton}>
              <IconButton
                className={styles.actionButton}
                component={Link}
              >
                <FileCopyIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title="Gerenciar cargo" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Orga. > Funç.', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/edit-roles-and-permissions/${roleAndPermission.id}`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title="Remover cargo" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Orga. > Funç.', access: 'delete' })}
                className={styles.actionButton}
                component={Link}
                onClick={() => setModal(!modal)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      <DeleteModal type="Cargo" prefix="a" title={roleAndPermission.name} openModal={modal} setModal={setModal} deleteFunction={handleDeleteGroup} />
    </>
  );
}

export default RolesAndPermissionsData;
