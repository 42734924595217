export const schema = [
  {
    name: 'alias',
    label: 'Pedido',
  },
  {
    name: 'costumer_name',
    label: 'Nome do Cliente',
    isFilter: false,
  },
  {
    name: 'display_id',
    label: 'Solicitação',
    isFilter: false,
  },
  {
    name: 'prescription_retained',
    label: 'Retenção',
    isFilter: false,
  },
  {
    name: 'created_at',
    label: 'Realizado',
    isFilter: false,
  },
  {
    name: 'amount',
    label: 'Valor',
    isFilter: false,
  },
  {
    name: 'payment',
    label: 'Pagamento',
    isFilter: false,
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'tracking',
    label: 'Rastreio',
    isVisible: false,
  },
  {
    name: 'action',
    label: 'Ações',
  },
];
