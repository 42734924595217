import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

export const Container = styled.div`
  margin: 24px 0;
  overflow: hidden;
`;

export const CheckboxContainer = styled(Button)`
&& {
    width: 68px;
   height: 40px;
   border-radius: 10px;
   background: #22DFA2;
   border: 2px solid transparent;

  &:hover {
    background-color: rgba(34, 223, 162, 0.5);
  }

   display: flex;
   align-items: center;
   justify-content: center;

  ${props => !props.checked && css`
    border: 2px solid #E1E1E1;
    background: #FFF;

    &:hover {
      background-color: rgba(28, 28, 28, 0.2);
    }
  `}
}

`;
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  overflow: hidden;
   white-space: nowrap;width: 1px;
   height: 1px;
   margin: -1px;
   padding: 0;
`;

export const Text = styled.label`
  color: #FFFF;
  cursor: pointer;
 ${props => !props.checked && css`
    color: #424242;
  `}
   font-size: 12px;
   font-weight: 510;
`;

const useStyles = makeStyles(() => ({
  productImg: {
    marginLeft: '16px',
    marginTop: '5px',
    heigth: '60px',
    width: '60px',
  },

  gridCategoryFields: {
    height: '146px',
    maxHeight: '146px',
    marginLeft: '24px',
    marginTop: '-10px',
    width: 'calc(100% - 160px)',
  },

  griCategoryLogo: {
    height: '136px',
  },

  gridCategoryMulti: {
    marginTop: '-2px',
  },

  gridCategoryTextField: {
    marginBottom: '24px',
  },

  gridFeaturedPaper: {
    marginBottom: '24px',
  },

  gridFeaturedSelect: {
    marginBottom: '6px',
    marginTop: '-10px',
  },

  gridFeaturedSubtitle: {
    marginTop: '28px',
  },

  gridModal: {
    marginTop: '26px',
  },

  gridProducts: {
    '& .MuiTableContainer-root': {
      height: '186px',
    },
  },

  gridProductsModalFields: {
    marginTop: '-8px',
    width: '100%',
  },

  gridProductsModal: {
    marginBottom: '22px',
  },

  gridProductsModalAdd: {
    display: 'flex',
    flexGrow: '1',
    marginTop: '16px',
    justifyContent: 'flex-end',
  },

  gridSubcategoryFields: {
    height: '128px',
    maxHeight: '146px',
    marginLeft: '24px',
    marginTop: '8px',
    width: 'calc(100% - 160px)',
  },

  gridSubcategoryList: {
    marginTop: '18px',
  },

  gridTextField: {
    marginBottom: '26px',
  },

  imagePreview: props => ({
    backgroundImage: props.backgroundImage,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '5px',
    height: '104px',
    marginTop: '8px',
    width: '100%',
  }),

  orderAdornment: {
    marginRight: '-10px',
  },

  productName: {
    color: '#ffffff',
    fontSize: '36px',
    fontWeight: 'bold',
  },
}));

export default useStyles;
