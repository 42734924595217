import React, { useEffect, useRef, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';

// import SortIcon from '@material-ui/icons/Sort';

import { Scope } from '@unform/core';
import ContentWrapper from '../content-wrapper';
// import Filters from './components/filters';
import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
// import BasicData from './basic/basic-data';
import FormContact from './basic/form-contact';
// import ColorSetings from './basic/color-settings';
import CommercialCondition from './basic/commercial-conditions';
// import SiteDiffirentials from './basic/site-differentials';
import TabNavigator from '../tabs-navigation';
import TabPanel from './components/tab-panel';
import Others from './basic/others';
import SocialMedias from './Integrations/social-medias';
import Redirects from './Integrations/redirects';
import Marketing from './Integrations/marketing';
import RDStation from './Integrations/rd-station';
import ExchangeReturn from './policies/exchange-return';
import Privacy from './policies/privacy';
import TermsConditions from './policies/terms-conditions';
import WhoWeAre from './policies/who-we-are';

import { Container, PageTitle, useStyles } from '../styles';

import { tabs } from './schema';
import { useSettings } from '../../contexts/settings';
import GoogleTags from './Integrations/google-tags';
import FacebookTags from './Integrations/facebook-tags';
import Chat from './Integrations/chat';
import Spinner from '../spinner';

export default function CategoryList() {
  const {
    settings, getSections, fetchPaymentConfig, getCredentials, getContact, getRedirects, getRdStation,
  } = useSettings();
  const formRef = useRef(null);
  const styles = useStyles();
  const [initialData, setInitialData] = useState(null);
  const [activeTab, setTab] = useState(0);
  const [redirects, setRedirects] = useState([{
    path: '',
    value: '',
  }]);

  useEffect(() => {
    async function getData() {
      const response = await getSections();
      const payment = await fetchPaymentConfig();
      const credentials = await getCredentials();
      const all_redirects = await getRedirects();
      const contact = await getContact();
      const rdStation = await getRdStation();

      if (all_redirects?.length) {
        setRedirects(all_redirects);
      }
      const parsedMedias = {};
      const parsedAnalytics = {};

      const parseLink = {
        facebook_page: 'https://www.facebook.com/',
        instagram_account: 'https://www.instagram.com/',
        twitter_profile: 'https://www.twitter.com/',
        youtube_channel: 'https://www.youtube.com/',
        linkedin_profile: 'https://www.linkedin.com/company/',
        tiktok_account: 'https://www.tiktok.com/',
      };

      const parseAnalytics = {
        google_universal_analytics: 'UA-',
        google_analytics_v4: 'G-',
        google_tag_manager: 'GTM-',
        facebook_pixel_page_view: 'FB-',
        facebook_pixel_cart: 'FB-',
        facebook_pixel_order_completed: 'FB-',
      };

      Object.keys(settings?.social || {}).forEach((item) => {
        if (item === 'facebook_domain_verification') return parsedAnalytics[item] = settings.social[item];
        if (!parseAnalytics[item] && (item !== 'id_resultados_digitais' && item !== 'id_mailchimp')) return;
        parsedAnalytics[item] = settings?.social[item].replace(parseAnalytics[item], '');
      });

      Object.keys(settings?.social || {}).forEach((item) => {
        if (!parseLink[item]) return;
        parsedMedias[item] = settings.social[item].replace(parseLink[item], '');
      });

      const data = {
        colors: settings?.brand,
        payment_config: payment,
        social_medias: parsedMedias,
        marketing: { ...parsedAnalytics, ...rdStation },
        rdStation,
        contact,
        google: {
          google_tags: parsedAnalytics,
          google_keys: credentials.google,
        },
        facebook: {
          facebook_tags: parsedAnalytics,
          facebook_keys: credentials.facebook,
          instagram_keys: credentials.instagram,
        },
        slogan: response.items?.find((item) => item.id === 'slogan'),
        sections: {
          who_we_are: response.items?.find((item) => item.id === 'whoWeAre'),
          privacy: response.items?.find((item) => item.id === 'privacy'),
          terms_condition: response.items?.find((item) => item.id === 'termsCondition'),
          exchange_return: response.items?.find((item) => item.id === 'exchange'),
        },
      };
      setInitialData(data);
    }

    if (settings) {
      getData();
    }
  }, [settings, getSections, fetchPaymentConfig, getCredentials, getContact, getRdStation, getRedirects]);

  return (
    <>
      <Breadcrumb title="Edição de Website" text="Configurações" current={`${settings?.store?.domain}.pedidopago.com.br`} />
      {initialData ? (
        <Form
          ref={formRef} initialData={initialData} style={{
            marginBottom: '80px',
          }}
        >

          <ContentWrapper align="top">
            <Grid container direction="row" alignItems="center" className={styles.gridRow}>
              <Grid item lg={4}>
                <PageTitle>{`${settings.store.domain}.pedidopago.com.br`}</PageTitle>
              </Grid>
              <Grid item lg={8} justify="center">
                <TabNavigator tabs={tabs} activeTab={activeTab} onChange={(event, newValue) => setTab(newValue)} />
              </Grid>
            </Grid>

            <TabPanel value={activeTab} index={0}>
              {/* <Container className={styles.containerTableBottom}>
                <BasicData formRef={formRef} />
              </Container>

              <Container className={styles.containerTableBottom}>
                <ColorSetings formRef={formRef} colors={settings?.brand} />
              </Container> */}

              <Container className={styles.containerTableBottom}>
                <CommercialCondition formRef={formRef} />
              </Container>

              {/* <Container className={styles.containerTableBottom}>
                <SiteDiffirentials />
              </Container> */}

              <Container className={styles.containerTableBottom}>
                <Others content={initialData?.slogan?.content || ''} social_description={settings?.social?.description} formRef={formRef} />
              </Container>

              <Container className={styles.containerTableBottom}>
                <FormContact formRef={formRef} />
              </Container>
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              <Scope path="social_medias">
                <Container className={styles.containerTableBottom}>
                  <SocialMedias formRef={formRef} />
                </Container>
              </Scope>

              <Scope path="redirects">
                <Container className={styles.containerTableBottom}>
                  <Redirects formRef={formRef} redirects={redirects} setRedirects={setRedirects} />

                </Container>
              </Scope>

              <Scope path="google">
                <Container className={styles.containerTableBottom}>
                  <GoogleTags formRef={formRef} />
                </Container>
              </Scope>

              <Scope path="facebook">
                <Container className={styles.containerTableBottom}>
                  <FacebookTags formRef={formRef} />
                </Container>
              </Scope>

              <Scope path="chat">
                <Container className={styles.containerTableBottom}>
                  <Chat formRef={formRef} />
                </Container>
              </Scope>

              <Scope path="marketing">
                <Container className={styles.containerTableBottom}>
                  <Marketing formRef={formRef} />
                </Container>
              </Scope>

              <Scope path="rdStation">
                <Container className={styles.containerTableBottom}>
                  <RDStation formRef={formRef} data={initialData?.rdStation} />
                </Container>
              </Scope>
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <Scope path="sections">
                <Container className={styles.containerTableBottom}>
                  <ExchangeReturn formRef={formRef} content={initialData?.sections?.exchange_return?.content || ''} />
                </Container>

                <Container className={styles.containerTableBottom}>
                  <Privacy formRef={formRef} content={initialData?.sections?.privacy?.content || ''} />
                </Container>

                <Container className={styles.containerTableBottom}>
                  <TermsConditions formRef={formRef} content={initialData?.sections?.terms_condition?.content || ''} />
                </Container>

                <Container className={styles.containerTableBottom}>
                  <WhoWeAre formRef={formRef} content={initialData?.sections?.who_we_are?.content || ''} />
                </Container>
              </Scope>
            </TabPanel>
          </ContentWrapper>
        </Form>
      ) : (
        <Spinner />
      )}
    </>
  );
}
