import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Breadcrumb from '../../../layout/app-header/app-bar/breadcrumb';
import TableMaterial from '../../../table';
import { Container, useStyles } from '../../../styles';

import { schema } from '../../../orders/schema';
import DataOrdersRow from '../../../orders/data';

import { useOrder } from '../../../../contexts/order';
import TabPanel from '../tab-panel';
import Spinner from '../../../spinner';

export default function OrdersPanel({ value, customer }) {
  const styles = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { searchOrders, isLoading } = useOrder();
  const [orders, setOrders] = useState([]);

  const renderItem = (order) => <DataOrdersRow order={order} handleDetails={() => history.push(`/orders/${order.id}`)} />;

  useEffect(() => {
    async function getCustomerOrder() {
      const getAlredyParams = new URLSearchParams(search);
      const alredyParams = Object.fromEntries(getAlredyParams);
      const response = await searchOrders({ ...alredyParams, customer_name: customer.customer.name });
      setOrders(response);
    }
    getCustomerOrder();
  }, [customer, search, searchOrders]);

  const replacedOrder = useMemo(() => {
    if (orders?.items?.length) {
      return orders.items.filter((order) => order.customer_id === customer.customer.id);
    }
  }, [customer, orders]);

  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const paramsObj = {};
    paramsObj[param] = data;

    let newParams = {};

    newParams = { ...allParams, ...paramsObj };

    return history.push({
      pathname: `/customers/${customer.customer.id}`,
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history, customer]);

  return (
    <TabPanel value={value} index={1}>
      <Breadcrumb title="Detalhes do Cliente" text="Clientes" current={customer?.customer?.name} />
      <Container className={styles.containerTableBottom} style={{ marginTop: 10 }}>
        {
          isLoading ? <Spinner /> : (
            <TableMaterial
              headers={schema}
              listQuery={replacedOrder}
              renderItem={renderItem}
              handleParams={handleParams}
              pagination={orders.pagination}
            />
          )
          }
      </Container>
    </TabPanel>
  );
}
