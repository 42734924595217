import React, { useEffect, useState } from 'react';

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Scope } from '@unform/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import CheckBox from '../../form/checkbox';
import SubtitleCard from '../../title/subtitle-card';
import SvgRender from '../../svg-render';
import TextField from '../../form/text-field';
import { StyledTooltip } from '../../tooltip';
import RadioButton from '../../form/form-radio-button';

import useStyles from './material-style';
import ImageField from '../../form/image-upload';

export default function CustomImageForm({ editGallery, handleImage, image, item, setBlendModeApi }) {
  const styles = useStyles();

  const [title, setTitle] = useState(editGallery?.title || '');
  const [description, setDescription] = useState(editGallery?.description || '');
  const [height, setHeight] = useState(editGallery?.logo_max_height || '100');
  const [margin_left, setMarginLeft] = useState(editGallery?.logo_area_left || '0');
  const [margin_top, setMarginTop] = useState(editGallery?.logo_area_top || '0');
  const [width, setWidth] = useState(editGallery?.logo_max_width || '80');
  const [isInverse, setInverse] = useState(editGallery?.inverse_logo);
  const [blendMode, setBlendMode] = useState(editGallery?.text_blending || 'multiply');

  useEffect(() => {
    if (editGallery?.text_blending === 'alpha') {
      setBlendMode('normal');
    }
  }, [editGallery]);

  const handleMath = (changeItem, operation, setState, value) => {
    let newValue = 0;

    if (operation === 'sum') {
      newValue = Number(value) + 1;
    } else {
      newValue = Number(value) - 1;
    }

    setState(newValue.toString());
    handleImage(changeItem, newValue.toString());
  };

  const handleLogo = () => {
    handleImage('inverse_logo', !isInverse);

    setInverse(!isInverse);
  };

  const handleBlend = (mode) => {
    setBlendMode(mode);

    if (mode === 'normal') {
      setBlendModeApi('alpha');
    } else setBlendModeApi(mode);

    handleImage('text_blending', mode);
  };

  return (
    <Grid container direction="row">
      <Scope path="medias">
        <Grid xs={8} style={{ height: 480, overflowX: 'hidden', overflowY: 'scroll', paddingRight: 24 }}>
          <Grid container direction="row" spacing={2}>
            <Grid item sm={12}>
              <TextField name="title" label="Título da Imagem" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Grid>

            <Grid item sm={12}>
              <TextField name="description" label="Subítulo da Imagem" value={description} onChange={(e) => setDescription(e.target.value)} />
            </Grid>
          </Grid>

          <h3 className={styles.title} style={{ margin: '24px 0 16px' }}>Composição da imagem do produto</h3>

          <Grid container direction="row" spacing={2}>
            <Grid item sm={4}>
              <SubtitleCard tip="Ilustração da embalagem com sua tampa na cor branca.">Imagem base</SubtitleCard>
              <p className={styles.text} style={{ marginBottom: '12px' }}>
                Formatos aceitos: <b>.jpg</b> ou <b>.png</b>
                <br />Dimensão: 512 px por 512 px
                <br />Tamanho sugerido: até 100 Kb
              </p>

              <ImageField
                name="image_base"
                acceptImg=".png, .jpg"
                edit
                size={{ height: '200px', width: '200px' }}
                previewImg={editGallery?.image_base || image}
                setImage={previewURL => handleImage('base', previewURL)}
              />
            </Grid>

            <Grid item sm={4}>
              <SubtitleCard tip="Máscara sobre a tampa. Para acentuar os efeitos de iluminação, altere a opacidade da máscara.">
                Máscara da Tampa
              </SubtitleCard>

              <p className={styles.text} style={{ marginBottom: '12px' }}>
                Formatos aceitos: <b>.svg</b> ou <b>.png</b>
                <br />Dimensão: 512 px por 512 px
                <br />Tamanho sugerido: até 15 Kb
              </p>

              <ImageField
                name="image_mask"
                acceptImg=".svg, .png"
                edit
                size={{ height: '200px', width: '200px' }}
                previewImg={editGallery?.image_mask}
                setImage={() => handleImage('mask', '')}
              />
            </Grid>

            <Grid item sm={4}>
              <SubtitleCard tip="Ilustração da embalagem com sua tampa na cor branca.">Rótulagem</SubtitleCard>
              <p className={styles.text} style={{ marginBottom: '12px' }}>
                Formatos aceitos: <b>.svg</b> ou <b>.png</b>
                <br />Dimensão: 512 px por 512 px
                <br />Tamanho sugerido: até 15 Kb
              </p>

              <ImageField
                name="image_text"
                acceptImg=".svg, .png"
                edit
                size={{ height: '200px', width: '200px' }}
                previewImg={editGallery?.image_text}
                setImage={() => handleImage('text', '')}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: '16px' }}>
            <SubtitleCard>Posicionamento do logo sobre a imagem</SubtitleCard>
          </div>

          <Grid container direction="row" spacing={2} style={{ marginBottom: '-10px' }}>
            <Grid item sm={6}>
              <Grid container spacing={2}>
                <Grid item sm={8}>
                  <TextField
                    name="width"
                    label="Largura máxima do logo"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    onBlur={() => handleImage('width', width)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={styles.adornment}>
                          <StyledTooltip title="max-width" placement="right">
                            <div>
                              <span className={styles.spanTooltip}>
                                px
                              </span>
                              <HelpOutlineIcon fontSize="small" className={styles.helpIcon} />
                            </div>
                          </StyledTooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <IconButton className={styles.iconButton} onClick={() => handleMath('width', 'sub', setWidth, width)}>
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>

                  <IconButton className={styles.iconButton} onClick={() => handleMath('width', 'sum', setWidth, width)}>
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6}>
              <Grid container spacing={2}>
                <Grid item sm={8}>
                  <TextField
                    name="height"
                    label="Altura máxima do logo"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    onBlur={() => handleImage('height', height)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={styles.adornment}>
                          <StyledTooltip title="max-height" placement="right">
                            <div>
                              <span className={styles.spanTooltip}>
                                px
                              </span>
                              <HelpOutlineIcon fontSize="small" className={styles.helpIcon} />
                            </div>
                          </StyledTooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <IconButton className={styles.iconButton} onClick={() => handleMath('height', 'sub', setHeight, height)}>
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>

                  <IconButton className={styles.iconButton} onClick={() => handleMath('height', 'sum', setHeight, height)}>
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2} style={{ marginBottom: '8px' }}>
            <Grid item sm={6}>
              <Grid container spacing={2}>
                <Grid item sm={8}>
                  <TextField
                    name="margin_top"
                    label="Margem do topo"
                    value={margin_top}
                    onChange={(e) => setMarginTop(e.target.value)}
                    onBlur={() => handleImage('margin_top', margin_top)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={styles.adornment}>
                          <StyledTooltip title="margin-top" placement="right">
                            <div>
                              <span className={styles.spanTooltip}>
                                px
                              </span>
                              <HelpOutlineIcon fontSize="small" className={styles.helpIcon} />
                            </div>
                          </StyledTooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <IconButton className={styles.iconButton} onClick={() => handleMath('margin_top', 'sub', setMarginTop, margin_top)}>
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>

                  <IconButton className={styles.iconButton} onClick={() => handleMath('margin_top', 'sum', setMarginTop, margin_top)}>
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6}>
              <Grid container spacing={2}>
                <Grid item sm={8}>
                  <TextField
                    name="margin_left"
                    label="Margem da esquerda"
                    value={margin_left}
                    onChange={(e) => setMarginLeft(e.target.value)}
                    onBlur={() => handleImage('margin_left', margin_left)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className={styles.adornment}>
                          <StyledTooltip title="margin-left" placement="right">
                            <div>
                              <span className={styles.spanTooltip}>
                                px
                              </span>
                              <HelpOutlineIcon fontSize="small" className={styles.helpIcon} />
                            </div>
                          </StyledTooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <IconButton className={styles.iconButton} onClick={() => handleMath('margin_left', 'sub', setMarginLeft, margin_left)}>
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>

                  <IconButton className={styles.iconButton} onClick={() => handleMath('margin_left', 'sum', setMarginLeft, margin_left)}>
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <CheckBox
                defaultChecked={isInverse}
                name="inverse_logo"
                label="Aplica versão negativa do logo (ideal para embalagens pretas ou ambar)."
                onClick={handleLogo}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <SubtitleCard>Blend Mode da Rótulagem</SubtitleCard>

              <FormControl required>
                <RadioGroup value={blendMode}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <RadioButton
                        value="multiply"
                        control={<Radio color="primary" />}
                        label="Multiply"
                        onClick={() => handleBlend('multiply')}
                      />
                    </Grid>

                    <Grid item>
                      <RadioButton
                        value="screen"
                        control={<Radio color="primary" />}
                        label="Screen"
                        onClick={() => handleBlend('screen')}
                      />
                    </Grid>

                    <Grid item>
                      <RadioButton
                        value="normal"
                        control={<Radio color="primary" />}
                        label="Alpha"
                        onClick={() => handleBlend('normal')}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <h3 className={styles.titlePreview}>Prévia da imagem composta:</h3>
          <Grid item style={{ width: 350, maxHeight: 350, height: 350 }}>
            <SvgRender item={item} />
          </Grid>
        </Grid>
      </Scope>
    </Grid>
  );
}
