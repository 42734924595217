import React, { useCallback } from 'react';

import { FormControl, RadioGroup, Radio, InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import TitleCard from '../../title/title-card';
import SubtitleCard from '../../title/subtitle-card';
import ComponentsList from './components-list';
import VariantList from './variant-list';
import TextField from '../../form/text-field';

import { AddButton, StyledPaper, useStyles } from '../../styles';
import RadioButton from '../../form/form-radio-button';
import Select from '../../form/select';
import PriceField from '../../form/price-field';

import actions from '../../../actions';

export default function Formulation({
  setUsage,
  usage,
  formsOptions,
  setFormsOptions,
  ingredients,
  setIngredients,
  volumeVariants,
  setVolumeVariants,
  setVolumeUnit,
  volumeUnit,
}) {
  const styles = useStyles();

  const addInput = () => {
    setIngredients([...ingredients, {
      name: '',
      concentration: '',
      measure: 'gr',
      visible: true,
      is_active: false,
    }]);
  };

  const addVariants = () => {
    setVolumeVariants([...volumeVariants, {
      name: '',
      concentration: '',
      measure: 'gr',
      visible: true,
      is_active: false,
    }]);
  };

  const handleUsage = useCallback(async (id) => {
    setUsage(id);
    const res = await actions.products.getForms(id);
    setFormsOptions(res.forms);
  }, [setFormsOptions, setUsage]);

  const renderItem = ({ item, handleValue }) => <MenuItem key={item.id} value={item.id} onClick={handleValue}>{item.name}</MenuItem>;
  const unity = [
    'g',
    'Kg',
    'mL',
    'L',
    'un',
    'filmes',
    'sachês',
    'envelopes',
    'pastilhas',
    'doses',
    'cápsulas',
    'comprimidos',
    'biscoitos',
    'chocolates',
    'gomas',
    'glóbulos',
  ];

  const handleVolumeUnit = (e) => {
    if (e.target.value === 0) return;
    setVolumeUnit(e.target.value.trim());
  };
  return (
    <>
      <TitleCard>Formulação</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard>Aplicação</SubtitleCard>
        </Grid>

        {/* <--- USAGE TYPES ---> */}
        <Grid item xs={12} sm={12}>
          <FormControl required className={styles.formControl}>
            <RadioGroup row value={usage} name="usage">
              <Grid container direction="row">
                <RadioButton
                  value={1}
                  control={<Radio color="primary" />}
                  label="Uso interno"
                  className={styles.radioFormulationMargin}
                  onClick={() => handleUsage(1)}
                />

                <RadioButton
                  value={2}
                  control={<Radio color="primary" />}
                  label="Uso externo"
                  className={styles.radioFormulationMargin}
                  onClick={() => handleUsage(2)}
                />

                <RadioButton
                  value={3}
                  control={<Radio color="primary" />}
                  label="Outros"
                  onClick={() => handleUsage(3)}
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* <--- USAGE TYPES ---> */}

        {/* <--- VARIANTS VOLUME --->  */}
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item container style={{ marginBottom: '15px' }}>
            <Grid item xs={4} sm={4}>
              <Select
                options={formsOptions}
                name="form"
                label="Forma Farmacêutica"
              >
                {renderItem}
              </Select>
            </Grid>
          </Grid>

          <Grid
            container
            item
            justify="flex-start"
            alignItems="center"
            direction="row"
            className={styles.gridPaperTop}
          >
            <SubtitleCard>Volume ou Quantidade</SubtitleCard>
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextField
              placeholder="30"
              name="volume"
              label="Volume ou Quantidade Padrão"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Select
              options={unity}
              name="volume_unit"
              label="Un. de Volume"
              onClick={handleVolumeUnit}
            >
              {({ item }) => <MenuItem key={item} value={item}>{item}</MenuItem>}
            </Select>
          </Grid>

          <Grid item xs={3} sm={3}>
            <PriceField
              name="volume_price"
              label="Preço Vs Padrão"
              separator="."
              value={100}
              disabled
              InputProps={{
                endAdornment: <InputAdornment className={styles.adornmentEnd}><span>%</span></InputAdornment>,
              }}
            />
          </Grid>

          {(volumeVariants?.length && volumeUnit) ? (
            <Grid item xs={12} sm={12}>
              <VariantList volumeUnit={volumeUnit} variants={volumeVariants} setVariants={setVolumeVariants} formsOptions={formsOptions} />
            </Grid>
          ) : (
            <Grid />
          )}

          <Grid container direction="row" alignItems="center" className={styles.gridPaperBottom}>
            <Grid
              item
              style={{ cursor: 'pointer' }}
            >
              <IconButton type="button" className={styles.iconButton}>
                <AddCircleOutlineIcon fontSize="small" color="primary" />
              </IconButton>

              <AddButton disabled={!volumeUnit} onClick={addVariants} type="button">Adicionar Variante de Volume</AddButton>
            </Grid>
          </Grid>
        </Grid>
        {/* <--- VARIANTS VOLUME --->  */}

        {/* <--- FORM(INGREDIENTS) --->  */}
        <Grid item xs={12} sm={12} className={styles.gridRowInputList}>
          <ComponentsList ingredients={ingredients} setIngredients={setIngredients} addInput={addInput} />
        </Grid>
        {/* <--- FORM(INGREDIENTS) --->  */}

        <Grid container direction="row" alignItems="center" className={styles.gridPaperBottom}>
          <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={addInput}
          >
            <IconButton type="button" className={styles.iconButton}>
              <AddCircleOutlineIcon fontSize="small" color="primary" />
            </IconButton>

            <AddButton type="button">Adicionar Matéria-Prima</AddButton>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
