import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Editor } from '@tinymce/tinymce-react';

import { StyledPaper } from '../styles';

export default function EditorPolicy({ initialValue, limit, getContent }) {
  const sizeLimit = limit ?? 65000;

  /** STATES */
  const [content, setContent] = useState(initialValue);
  const [length, setLength] = useState(0);
  const editorRef = useRef(null);

  /** FUNCTIONS */
  const handleInit = (evt, editor) => {
    setLength(editor.getContent({ format: 'text' }).length);
    editorRef.current = editor;
  };

  const handleUpdate = useCallback(async (value, editor) => {
    const updateLength = editor.getContent({ format: 'text' });
    if (updateLength.length <= sizeLimit) {
      setContent(value);
      setLength(updateLength.length);
      editorRef.current.getContent();
    }
  }, [editorRef, sizeLimit]);

  const handleBeforeAddUndo = (evt, editor) => {
    const beforeUndoLength = editor.getContent({ format: 'text' }).length;

    if (beforeUndoLength > sizeLimit) {
      evt.preventDefault();
    }
  };

  /** EFFECTS */
  useEffect(() => {
    getContent(content);
  }, [getContent, content]);

  return (
    <StyledPaper>
      <Editor
        apiKey="dxot24ri4a0i7k3sinutegl9z9x52yz2xkm7kzwkl9zarrl6"
        initialValue={initialValue}
        value={(content !== initialValue && content !== '') ? content : initialValue}
        onInit={handleInit}
        onEditorChange={handleUpdate}
        onBeforeAddUndo={handleBeforeAddUndo}
        init={{
          height: 420,
          directionality: 'pt_BR',
          content_style: "@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700&display=swap'); body { font-family: 'Poppins', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Poppins', sans-serif; }",
          selector: 'textarea#full-featured-non-premium',
          plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
          menubar: 'file edit view insert format tools table help',
          toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify',
          toolbar_sticky: true,
          autosave_ask_before_unload: true,
        }}
      />
      <p style={{ fontSize: 12, color: '#A3A3A3', marginTop: 8 }}>Faltam: {sizeLimit - length} caracteres</p>
    </StyledPaper>
  );
}
