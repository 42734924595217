import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

import { Pagination } from '../@types/pagination';
import errHandler from '../actions/_errhandler';

interface CouponItem {
  name: string;
  discount_cart: number;
  minimum_cart: number;
  once_store: boolean;
  once_customer: boolean;
  available_to: Date | number;
  available_from: Date | number;
  store_id: string;
}

interface CouponsState {
  items: CouponItem[];
  pagination: Pagination;
}

interface CouponsContextData {
  isLoading: boolean;
  coupons: CouponsState;
  fetchCoupons(name?: string): Promise<void>;
  createCoupon(coupon: CouponItem): Promise<void>;
  updateCoupon(id: string, coupon: CouponItem): Promise<void>;
  deleteCoupon(id: string): Promise<void>;
}

const CouponsContext = createContext<CouponsContextData>({} as CouponsContextData);

export const CouponsProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [coupons, setCoupons] = useState<CouponsState>(() => ({} as CouponsState));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchCoupons = useCallback(async (name: any) => {
    try {
      setLoading(true);
      const response = await api.get('/v2/coupons/list', { params: { name } });
      setCoupons(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  useEffect(() => {
    async function getCoupons() {
      await fetchCoupons({});
    }

    getCoupons();
  }, [fetchCoupons]);

  const createCoupon = useCallback(async (coupon: any) => {
    try {
      setLoading(true);
      await api.post('/v2/coupon/new', { ...coupon });
      await fetchCoupons({});
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, [fetchCoupons]);

  const updateCoupon = useCallback(async (id: any, coupon: any) => {
    try {
      setLoading(true);
      await api.put(`/v2/coupon/${id}`, { ...coupon });
      await fetchCoupons({});
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, [fetchCoupons]);

  const deleteCoupon = useCallback(async (id: any) => {
    try {
      setLoading(true);
      await api.delete(`/v2/coupon/${id}`);
      await fetchCoupons({});
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, [fetchCoupons]);

  return (
    <CouponsContext.Provider
      value={{
        fetchCoupons, createCoupon, deleteCoupon, updateCoupon, coupons, isLoading,
      }}
    >
      {children}
    </CouponsContext.Provider>
  );
};

export function useCoupons(): CouponsContextData {
  const context = useContext(CouponsContext);

  if (!context) {
    throw new Error('useCoupons must be used within an CouponsProvider');
  }
  return context;
}
