import React from 'react';

import Grid from '@material-ui/core/Grid';

function TabPanel({ children, value, index, ...other }) {
  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {children}
    </Grid>
  );
}

export default TabPanel;
