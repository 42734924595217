const CustomerSchema = [
  {
    name: 'name',
    label: 'Nome',
    // isFilter: true,
  },
  {
    name: 'email',
    label: 'E-mail',
    // isFilter: true,
  },
  {
    name: 'phone',
    label: 'Telefone',
    // isFilter: true,
  },
  {
    name: 'cpf',
    label: 'CPF',
    // isFilter: true,
  },
  {
    name: 'ultimo',
    label: 'Último Pedido',
    // isFilter: true,
  },
  // {
  //   name: 'cidade',
  //   label: 'Cidade',
  //   isFilter: true,
  // },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];

export default CustomerSchema;
