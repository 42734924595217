import api from '../api';
import errHandler from './_errhandler';

// export const getCheckLogin = () => api.get('/v2/agent/check-login')
//   .then(res => res.data)
//   .catch(errHandler);

export const getRegistrationStatus = (document) => api.get(`/v2/agent/registration-status/${document}`)
  .then(res => res.data)
  .catch(errHandler);

/*
export const login = (username, password) => api
  .post('/v1/authorize', {
    domain_id: 2,
    expires: 0,
    password,
    type: 'agent',
    username,
  })
  .then(res => {
    const jwt = res.data.auth_header;
    localStorage.setItem('jwt_agent', jwt);
  })
  .catch(errHandler);
*/
