import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import { remove } from 'lodash';
import React, { useEffect, useState } from 'react';
import actions from '../../../actions';
import Select from '../../form/select';
import TextField from '../../form/text-field';
import { ActionButton, useStyles } from '../../styles';
import SubtitleCard from '../../title/subtitle-card';
import { StyledTooltip } from '../../tooltip';

export default function ComponentsList({ ingredients, setIngredients }) {
  const styles = useStyles();
  const [measures, setMeasures] = useState([]);

  useEffect(() => {
    async function getMeasures() {
      const measureList = await actions.products.getMeasureList();

      setMeasures(measureList.units);
    }

    getMeasures();
  }, []);

  const setVisible = name => (value, i) => {
    const list = [...ingredients];
    list[i][name] = value;
    setIngredients(list);
  };

  const removeInput = item => {
    const list = [...ingredients];

    remove(list, (data) => data === item);

    setIngredients(list);
  };

  const renderItem = ({ item, handleValue }) => <MenuItem key={item} value={item} onClick={handleValue}>{item}</MenuItem>;

  return (
    <>
      <SubtitleCard>Matérias-Primas</SubtitleCard>

      {ingredients.map((x, i) => (
        <Grid item xs={12} sm={12} key={x.name}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={4} sm={4}>
              <TextField
                name={`replace_ingredients[${i}].name`}
                placeholder="Matéria-Prima"
                label="Matéria-Prima"
                defaultValue={x?.name}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <TextField
                name={`replace_ingredients[${i}].concentration`}
                placeholder="1.5"
                label="Concentração"
                type="tel"
                defaultValue={x?.concentration}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <Select
                name={`replace_ingredients[${i}].measure`}
                label="Un. de Medida"
                options={measures}
                initialValue={x.measure}
              >
                {renderItem}
              </Select>
            </Grid>

            <Grid item xs={i >= 1 ? 2 : 1} className={styles.gridButtonContainer}>
              <Grid container direction="row" justify="center" alignContent="center" spacing={i >= 1 ? 1 : 4}>
                <Grid item xs={i >= 1 ? 4 : 6} className={styles.gridButton}>
                  <StyledTooltip
                    placement="top"
                    title={x.is_active
                      ? 'O componente foi definido como Princípio-Ativo. Clique para remover'
                      : 'Marcar como Princípio-Ativo'}
                  >
                    <ActionButton className={styles.actionButton} onClick={() => setVisible('is_active')(!x.is_active, i)}>
                      {x.is_active
                        ? <OfflineBoltIcon className={styles.icon} color="primary" fontSize="default" />
                        : <OfflineBoltOutlinedIcon className={styles.icon} color="disabled" fontSize="default" />}
                    </ActionButton>
                  </StyledTooltip>
                </Grid>

                <Grid item xs={i >= 1 ? 4 : 6} className={styles.gridButton}>
                  <StyledTooltip
                    placement="top"
                    title={x.visible
                      ? 'Marcar como invisível para o cliente'
                      : 'Marcar como visível para o cliente'}
                  >
                    <ActionButton className={styles.actionButton} onClick={() => setVisible('visible')(!x.visible, i)}>
                      {x.visible
                        ? <VisibilityOffIcon className={styles.icon} color="disabled" fontSize="default" />
                        : <VisibilityOffIcon className={styles.icon} color="primary" fontSize="default" />}
                    </ActionButton>
                  </StyledTooltip>
                </Grid>

                {i > 0
                  && (
                    <Grid item xs={4} className={styles.gridButton}>
                      <StyledTooltip title="Remover" placement="top">
                        <ActionButton className={styles.actionButton} onClick={() => removeInput(x)}>
                          <CloseIcon className={styles.icon} color="disabled" fontSize="default" />
                        </ActionButton>
                      </StyledTooltip>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
