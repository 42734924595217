import styled, { css } from 'styled-components';

import TableHead from '@material-ui/core/TableHead';
import MUITableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export const SecondHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0px 0px 0px;
  /* width: calc(100% - 24px); */

  form {
    width: 100%;
  }
`;

export const FilterFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* width: calc(100% - 24px); */
  justify-content: space-between;
  align-items: center;

  > button {
    margin-top: 10px;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #E1E1E1;
  /* width: calc(100% - 24px); */
  padding: 0px 24px 0px 0px;
  margin: 24px 0px 24px 0px;
`;

export const CleanForm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;

  width: 100%;
  min-width: 180px;

  background-color: transparent;
  border: 0px;

  margin: 63px 20px 0px 0px;

  p {
    text-decoration: underline;
    margin: 0px 0px 0px 4px;
  }
`;

export const PageSubtitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #A3A3A3;
  margin-left: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0px 24px 0px;

  >h3 {
    margin: 0px;
  }
`;

const transactionStatusColor = {
  approved: css`
    background-color: #22DFA2;
  `,
  waiting: css`
    background-color: #F2C94C;
  `,
  refused: css`
    background-color: #EB5757;
  `,
  reversal: css`
    background-color: #FF5F00;
  `,
  chargeBack: css`
    background-color: #9D0B0B;
  `,
  pendent: css`
    background-color: #A3A3A3;
  `,
};

const actionStatusColor = {
  balance: css`
    b {
      color: #22DFA2;
      font-size: 16px;
    }
    background-color: rgba(34, 224, 161, 0.1);
    .MuiButton-contained {
      background-color: #22DFA2;
    }
  `,
  waitingBalance: css`
    b {
      color: #F2C94C;
      font-size: 16px;
    }
    background-color: rgba(242, 201, 76, 0.1);
    .MuiButton-contained {
      background-color: #F2C94C;
    }
  `,
  history: css`
    color: #044BFD;
    background-color: rgba(4, 75, 253, 0.1);
    .MuiButton-contained {
      background-color: #044BFD;
    }
  `,
};

export const Container = styled.div`
  ${props => (props.type === 'informative'
    ? css`
    background-color: #FFF;
    width: 100%;
    margin: 0px 16px 0px 0px;
    height: 62px;
    padding: 15px;
    font-size: 12px;
    color: #424242;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
  `
    : css`
    width: 100%;
    height: 89px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    padding: 24px 16px 25px 16px;
    border: 0px;
    margin: 0px 24px 0px 0px;

    p {
      font-weight: 400;
      font-size: 12px;
      color: #4F4F4F;
    }

    .MuiButton-contained {
      color: #FFF;
      font-weight: 600;
      font-size: 14px;
      padding: 8px 24px;
    }

    ${actionStatusColor[props.actionType]}

    > svg {
      align-self: center;
    }

    .actionContent{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `)}
`;

export const Circle = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin: 0px 8px 0px 0px;
  ${props => transactionStatusColor[props.status]}
`;

export const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: calc(100% - 24px); */
  margin: 0px 0px 37px 0px;

  div:last-child {
    margin: 0px;
  }
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: calc(100% - 16px); */
  margin: 0px 0px 24px 0px;
`;

export const PageTitleBold = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #333;
`;

export const TableHeader = styled(TableHead)`
  .MuiTableCell-head{
    font-weight: 600;
    color: #A3A3A3;
    font-size: 14px;
  }
`;

export const TableContainer = styled.div`
  .MuiTableContainer-root {
    overflow-x: unset !important;
  }
`;

export const BodyTableRow = styled(MUITableRow)`
  .MuiTableCell-root {
    border-left: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0.03em;
    padding: 8px 16px;
  }

  .MuiTableCell-root:first-child {
    border-left: 2px solid #E1E1E1;
    border-top: 2px solid #E1E1E1;
  }

  .MuiTableCell-root:last-child {
    border-right: 2px solid #E1E1E1;
  }
`;

export const StyledTableCell = styled(TableCell)`
  padding: 16px 0px 0px 0px;

  .MuiCollapse-wrapper{
    margin: 0px;
    width: 100%;
  }

  .MuiBox-root{
    :first-child {
      margin-top: 9px;
    }
    margin: 0px 0px 25px 0px;
  }

  :last-child{
    border-bottom: 0px;
  }
`;
