import React, { useCallback, useEffect, useRef, useState } from 'react';

// Libs
import { Grid, MenuItem, IconButton } from '@material-ui/core';
import { Form } from '@unform/web';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Components
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
import Select from '../../form/select';
import TextField from '../../form/text-field';
import TitleCard from '../../title/title-card';
import MaskField from '../../form/mask-field';
import AddImage from '../components/add-image';
import SaveBar from '../../save-bar';

// Styles
import { PageTitle, StyledPaper } from '../../styles';

// Utils
import { useColaborators } from '../../../contexts/contributors';
import { useBranch } from '../../../contexts/branch';
import { useSettings } from '../../../contexts/settings';
import { useGroups } from '../../../contexts/group';
import Spinner from '../../spinner';
import { useAuth } from '../../../contexts/auth';

function AddContributor() {
  const {
    addColaborator, getOneColaborator, updateAgent, getPreAgent, updatePreAgent, updateErpData,
  } = useColaborators();
  const { fetchLogin, verifyPerms } = useAuth();
  /** CONSTS */
  const formRef = useRef();
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();
  const { branchs } = useBranch();
  const { departments, groups } = useGroups();
  const { settings } = useSettings();
  const { user } = useAuth();

  const [isLoading, setLoading] = useState(false);

  const [department, setDepartment] = useState('');
  const [functionGroup, setFunctionGroup] = useState('');
  const [agentErpCode, setAgentErpCode] = useState('');
  const [agentErpName, setAgentErpName] = useState('');
  const [functionGroupList, setFunctionGroupList] = useState([]);
  const [initialData, setInitialData] = useState('initial');

  useEffect(() => {
    async function getColaborator() {
      try {
        const getAllParams = new URLSearchParams(search);
        const allParams = Object.fromEntries(getAllParams);

        let response = {};

        if (allParams?.is_pre === 'true') {
          response = await getPreAgent(id);
        } else {
          response = await getOneColaborator(id);
        }

        if (groups?.length && response?.group_id) {
          setFunctionGroupList(groups.filter((item) => item.department.id === response?.department));
          setDepartment(response?.department);
        } else if (departments?.length && groups?.length) {
          const findGroup = groups.find((item) => item.department.id === departments[0].id);

          setFunctionGroupList(groups.filter((item) => item.department.id === departments[0].id));

          setDepartment(departments[0].id);
          setFunctionGroup(findGroup.id);
        }

        if (response?.group_id) {
          setDepartment(response?.department);
          setFunctionGroup(response?.group_id);
        }

        if (response?.agent_erp_id) {
          setAgentErpCode(response.agent_erp_id);
          setAgentErpName(response.agent_erp_name);
        }

        setInitialData({ ...response, is_enabled: !!response?.is_enabled, img: { image_base: response?.picture } });
      } catch (err) {
        toast.error('Não foi possível obter informações deste colaborador.');
        history.push('/contribuitors');
      }
    }

    if (id === 'create') {
      if (departments?.length && groups?.length) {
        const findGroup = groups.find((item) => item.department.id === departments[0].id);

        setFunctionGroupList(groups.filter((item) => item.department.id === departments[0].id));

        if (id === 'create') {
          setDepartment(departments[0].id);
          setFunctionGroup(findGroup.id);
        }
      }
      setInitialData('');
    } else {
      getColaborator();
    }
  }, [id, getOneColaborator, departments, groups, getPreAgent, history, search]);

  // useEffect(() => {
  //   if (departments?.length && groups?.length) {
  //     const findGroup = groups.find((item) => item.department.id === departments[0].id);

  //     setFunctionGroupList(groups.filter((item) => item.department.id === departments[0].id));

  //     if (id === 'create') {
  //     setDepartment(departments[0].id);
  //     setFunctionGroup(findGroup.id);
  //     }
  //   }
  // }, [departments, groups, id]);

  const hanldeDepartment = useCallback((e) => {
    const filteredFunctions = groups.filter((item) => item.department.id === e);

    setFunctionGroupList(filteredFunctions);
    setFunctionGroup(filteredFunctions?.length ? filteredFunctions[0].id : '');
    setDepartment(e);
  }, [groups]);

  const handleSubmit = useCallback(async () => {
    try {
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      formRef.current.setErrors({});
      setLoading(true);

      const data = formRef.current.getData();

      const schemaAgent = Yup.object().shape({
        document: Yup.string().required('CPF obrigatório'),
        department: Yup.number().required('Departamento obrigatório'),
        group_id: Yup.number().required('Cargo obrigatório'),
        branch_id: Yup.string().required('Unidade obrigatório'),
        agent_erp_id: agentErpName.length > 0 ? Yup.number().required('Código ERP obrigatório') : Yup.string(),
        agent_erp_name: agentErpCode.length > 0 ? Yup.string().required('Nome ERP obrigatório') : Yup.string(),
      });

      const passowordSchema = Yup.object().shape({
        password: Yup.string().min(6, 'No mínimo 6 dígitos'),
        confirm_password: Yup.string().oneOf(
          [Yup.ref('password')],
          'Confirmação incorreta',
        ),
      });

      if (data.passowrd) {
        await passowordSchema.validate(data, {
          abortEarly: false,
        });
      }

      await schemaAgent.validate({ ...data, department, group_id: functionGroup }, {
        abortEarly: false,
      });

      const user_document = data.document.replaceAll('-', '').replaceAll('.', '');

      // if (user_document.charAt(0) === '0') {
      //   user_document = user_document.substring(1);
      // }

      if (id === 'create') {
        await addColaborator({
          name: data.name,
          gender: data.gender,
          phone_number: data.phone_number,
          phone_ddd: data.phone_ddd,
          email: data.email,
          branch_id: data.branch_id,
          phone_ddi: data.phone_ddi,
          store_id: settings.store.id,
          document: user_document,
          username: data.email,
          is_enabled: data.is_enabled,
          department,
          group_id: functionGroup,
          agent_erp_id: data.agent_erp_id,
          agent_erp_name: data.agent_erp_name,
        });
      } else if (allParams?.is_pre === 'true') {
        await updatePreAgent(id, {
          username: data.email,
          phone_number: data.phone_number,
          phone_ddd: data.phone_ddd,
          phone_ddi: data.phone_ddi,
          branch_id: data.branch_id,
          is_enabled: data.is_enabled,
          email: data.email,
          name: data.name,
          gender: data.gender,
          store_id: settings.store.id,
          document: user_document,
          department,
          group_id: functionGroup,
          agent_erp_id: data.agent_erp_id,
          agent_erp_name: data.agent_erp_name,
        });
      } else {
        await updateAgent(id, {
          username: data.email,
          phone_number: data.phone_number,
          phone_ddd: data.phone_ddd,
          phone_ddi: data.phone_ddi,
          branch_id: data.branch_id,
          is_enabled: data.is_enabled,
          email: data.email,
          name: data.name,
          gender: data.gender,
          store_id: settings.store.id,
          document: user_document,
          department,
          group_id: functionGroup,
        });

        if (agentErpCode || agentErpName) {
          await updateErpData(id, {
            agent_erp_id: data.agent_erp_id,
            agent_erp_name: data.agent_erp_name,
          });
        }

        await fetchLogin();
      }

      setLoading(false);
      toast.success(`Colaborador ${id === 'create' ? 'criado' : 'atualizado'} com sucesso!`);
      history.push(id === 'create' ? '/contributors?tab=1' : '/contributors');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        setLoading(false);
        toast.error(err.errors[0]);
        return formRef.current.setErrors(validationErrors);
      }

      setLoading(false);
      toast.error('Não foi possível adicionar esta pessoa a sua lista de colaboradores.');
    }
  }, [agentErpName, agentErpCode, updateErpData, addColaborator, settings, department, functionGroup, history, id, updateAgent, fetchLogin, search, updatePreAgent]);

  return initialData !== 'initial' ? (
    <>
      <Breadcrumb
        title={id === 'create' ? 'Convidar colaborador' : 'Editar colaborador'}
        text="Organização"
        breadcrumb="Colaboradores"
        link="/contributors"
        current={id === 'create' ? 'Convidar colaborador' : 'Editar colaborador'}
      />

      <ContentWrapper align="top">
        <Grid container justify="flex-start" direction="row" style={{ marginBottom: '32px' }}>
          <IconButton
            size="small"
            style={{ color: '#A3A3A3' }}
            onClick={() => history.push('/contributors')}
          >
            <ArrowBackIcon />
          </IconButton>
          <PageTitle>{id === 'create' ? 'Convidar colaborador' : 'Editar colaborador'}</PageTitle>
        </Grid>
      </ContentWrapper>
      <TitleCard>Dados básicos</TitleCard>
      <StyledPaper>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={{ branch_id: branchs.items[0].id, is_enabled: true, ...initialData }}>
          <Grid container direction="row" justify="space-between" alignItems="center" xs={12} spacing={3}>
            {user.id === id && (
            <Grid item xs={12} sm={4} style={{ display: 'flex', placeContent: 'center', width: '100%' }}>
              <AddImage
                title="picture"
                formRef={formRef}
              />
            </Grid>
            ) }

            <Grid item xs={user.id === id ? 8 : 12}>
              <Grid container direction="row" spacing={3}>
                <Grid item xs>
                  <TextField
                    name="name"
                    label="Nome Completo"
                    placeholder="Nome Completo"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs>
                  <Select
                    name="gender"
                    label="Sexo"
                    options={[
                      { label: 'Feminino', gender: 'f' },
                      { label: 'Masculino', gender: 'm' },
                      { label: 'Não binário', gender: 'n' },
                    ]}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.gender} key={item.gender}>{item.label}</MenuItem>}
                  </Select>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={3}>
                <Grid item xs={6}>
                  <MaskField
                    name="document"
                    mask="999.999.999-99"
                    label="CPF"
                    placeholder="000.000.000-00"
                    maskChar=""
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="phone_ddd"
                    label="DDD"
                    placeholder="11"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <MaskField
                    name="phone_number"
                    mask="9 9999-9999"
                    label="Celular"
                    placeholder="9 9999-9999"
                    maskChar=""
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={3}>
                <Grid item xs>
                  <TextField
                    name="email"
                    label="E-Mail"
                    placeholder="E-Mail"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={3}>
                <Grid item xs>
                  <Select
                    name="department"
                    label="Departamento"
                    options={departments?.length ? departments : []}
                    value={department}
                    onChange={(e) => { hanldeDepartment(e.target.value); }}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.id} key={item.name}>{item.name}</MenuItem>}
                  </Select>
                </Grid>
                <Grid item xs>
                  <Select
                    name="group_id"
                    label="Cargo"
                    options={functionGroupList}
                    value={functionGroup}
                    onChange={(e) => setFunctionGroup(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.id} key={item.name}>{item.name}</MenuItem>}
                  </Select>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={3}>
                <Grid item xs>
                  <Select
                    name="branch_id"
                    label="Unidade"
                    options={branchs.items}
                    InputLabelProps={{ shrink: true }}
                  >
                    {({ item }) => <MenuItem value={item.id} key={item.id}>{item.fantasy_name}</MenuItem>}
                  </Select>
                </Grid>

                <Grid item xs>
                  <Select
                    name="is_enabled"
                    label="Status"
                    options={[{ label: 'Ativo', value: true }, { label: 'Inativo', value: false }]}
                    InputLabelProps={{ shrink: true }}
                    initialValue={initialData.is_enabled}
                  >
                    {({ item }) => <MenuItem value={item.value} key={item.label}>{item.label}</MenuItem>}
                  </Select>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={3}>
                <Grid item xs>
                  <TextField
                    name="agent_erp_id"
                    value={agentErpCode}
                    label="Código no ERP"
                    placeholder="Código cadastrado no ERP"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setAgentErpCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    name="agent_erp_name"
                    label="Nome no ERP"
                    value={agentErpName}
                    placeholder="Nome cadastrado no ERP"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setAgentErpName(e.target.value)}
                  />
                </Grid>
              </Grid>

              {/* {id === 'create' && (
              <Grid container direction="row" spacing={3}>
                <Grid item xs>
                  <TextField
                    name="password"
                    label="Senha"
                    type="password"
                    placeholder="Senha"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    name="confirm_password"
                    label="Repetir Senha"
                    type="password"
                    placeholder="Repetir Senha"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              )} */}

            </Grid>
          </Grid>

          <SaveBar
            buttonPrimary="salvar alterações"
            buttonSecondary="sair sem salvar"
            type="submit"
            history={id === 'create' ? '/contributors?tab=1' : '/contributors'}
            disabled={verifyPerms({ access: 'write', role: 'Orga. > Colab.' })}
            isLoading={isLoading}
          />
        </Form>
      </StyledPaper>
    </>
  ) : (
    <Spinner />
  );
}

export default AddContributor;
