/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';

import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';

import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ContentWrapper from '../content-wrapper';
import { PageTitle, useStyles } from '../styles';

import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import SaveBar from '../save-bar';
import api from '../../api';

import Info from './components/info';
import Rules from './components/rules';
import Visibility from './components/visibility';
import Spinner from '../spinner';
import { formatDate } from '../../utils/time';
import { useCoupons } from '../../contexts/coupons';
import { useAuth } from '../../contexts/auth';

export default function NewCategory() {
  const styles = useStyles();
  const formRef = useRef();
  const history = useHistory();

  const { verifyPerms } = useAuth();
  const { createCoupon, updateCoupon } = useCoupons();

  const [initialData, setInitialData] = useState('create');
  const [type, setType] = useState('percentage');
  const [usage, setUsage] = useState('discount_cart');
  const [isIlimited, setIlimited] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();

  const price = useCallback((data) => {
    if (data?.discount_freight) {
      if (!data?.is_absolute_freight) {
        return Number(data?.discount_freight) * 100;
      }
      return data?.discount_freight;
    }
    if (!data?.is_absolute_cart) {
      return Number(data?.discount_cart) * 100;
    }
    return data?.discount_cart;
  }, []);

  useEffect(() => {
    async function getCoupon() {
      try {
        const response = await api.get(`/v2/coupon/${id}`);

        const discount = {};

        const newPrice = price(response.data.coupon);

        if (response.data.coupon?.discount_freight) {
          discount.discount_freight = newPrice;
        } else {
          discount.discount_cart = newPrice;
        }

        setType((response.data.coupon?.is_absolute_cart === true || response.data.coupon?.is_absolute_freight === true) ? 'price' : 'percentage');
        setUsage(response.data.coupon?.discount_cart ? 'discount_cart' : 'discount_freight');
        setIlimited(!response.data.coupon?.maximum_available);
        setInitialData({
          ...response.data.coupon,
          minimum_cart: Number(response.data.coupon.minimum_cart),
          amount_used: response?.data?.coupon.amount_used || 0,
          ...discount,
        });
      } catch (err) {
        toast.error('Não foi possível obter dados deste cupom.');
        history.push('/promocode');
      }
    }

    if (id !== 'create') {
      getCoupon();
    } else {
      const today = new Date();
      today.setFullYear(today.getFullYear() + 1);
      setInitialData({ available_to: today, minimum_cart: 0 });
    }
  }, [id, history, price]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRef.current.getData();

      const available_from = data.available_from ? formatDate(data.available_from) : null;
      const available_to = data.available_to ? formatDate(data.available_to) : null;

      const isTheSameDate = available_from && available_to && available_from === available_to;

      const availableToDate = isTheSameDate ? new Date(available_from).setDate(new Date(available_from).getDate() + 1) : available_to;

      if (initialData.is_system_managed) {
        const schema = Yup.object().shape({
          [usage]: Yup.string().required('Selecione um valor para o cupom'),
          name: Yup.string().required('Crie um código para o cupom'),
          maximum_available: Yup.string().when('undefined_availability', {
            is: false,
            then: Yup.string().required('Selecione uma quantia de cupons disponíveis'),
          }),
        });

        await schema.validate({ ...data }, {
          abortEarly: false,
        });
      }

      const absolute = {};
      let discount_type = false;

      if (type === 'price') {
        discount_type = true;
      } else {
        discount_type = false;
      }

      if (data?.discount_freight) {
        if (discount_type === false) {
          absolute.discount_freight = Number(data.discount_freight) / 100;
        }
        absolute.is_absolute_freight = discount_type;
      } else {
        if (discount_type === false) {
          absolute.discount_cart = Number(data.discount_cart) / 100;
        }
        absolute.is_absolute_cart = discount_type;
      }

      if (id === 'create') {
        await createCoupon({ ...data, available_to: availableToDate, available_from, ...absolute, maximum_available: Number(data.maximum_available) || null });
        toast.success('Cupom criado com sucesso!');
      } else {
        await updateCoupon(id, { ...data, available_to: availableToDate, available_from, ...absolute, maximum_available: Number(data.maximum_available) });
        toast.success('Cupom atualizado com sucesso!');
      }

      history.push('/promocode');
      setLoading(false);
    } catch (err) {
      const validationErrors = {};
      setLoading(false);

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        toast.error(err.errors[0]);
        return formRef.current.setErrors({
          zip: validationErrors,
        });
      }
      console.info(err);
      toast.error(`Ocorreu um erro na ${id === 'create' ? 'criação' : 'edição'} do cupom.`);
    }
  }, [id, createCoupon, updateCoupon, type, history, usage]);

  return (initialData !== 'create') ? (
    <>
      <Breadcrumb
        title={id === 'create' ? 'Novo Cupom' : initialData.name}
        text="Marketing"
        breadcrumb="Cupons"
        link="/promocode"
        current={id === 'create' ? 'Criar Cupom' : 'Editar Cupom'}
      />

      <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
        <ContentWrapper align="top">
          <Grid container direction="row" className={styles.gridRowForm} alignItems="baseline">
            <Grid item xs={12} className={styles.gridTitle}>
              <PageTitle>{id === 'create' ? 'Novo Cupom' : initialData.name}</PageTitle>
            </Grid>

            <Grid item xs={8} sm={8} className={styles.gridRowFormPadding}>
              <Info
                formRef={formRef}
                type={type}
                setType={setType}
                usage={usage}
                setUsage={setUsage}
                initialData={initialData}
                id={id}
              />
              {!initialData.is_system_managed && (
                <Rules formRef={formRef} isIlimited={isIlimited} setIlimited={setIlimited} usage={usage} isEditForbid={initialData?.forbid_edit} />
              )}
            </Grid>

            {!initialData.is_system_managed && (
              <Grid item xs={4} sm={4}>
                <Visibility isEditForbid={initialData?.forbid_edit} formRef={formRef} />
              </Grid>
            )}
          </Grid>
        </ContentWrapper>

        <SaveBar
          disabled={verifyPerms({ role: 'Marketing', access: 'write' }) || initialData?.forbid_edit}
          buttonPrimary="salvar alterações"
          buttonSecondary="sair sem salvar"
          isLoading={isLoading}
          type="submit"
          history="/promocode"
        />
      </Form>
    </>
  ) : (
    <Spinner />
  );
}
