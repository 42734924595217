import React, { useEffect, useRef, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useField } from '@unform/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useStyles } from './styles';

function FormDatePicker({ name, date, ...rest }) {
  const styles = useStyles();
  const datePickerRef = useRef();
  const { fieldName, registerField } = useField(name);

  const [newDate, setNewDate] = useState(!date && new Date());

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        className={styles.textField}
        format="dd/MM/yyyy"
        value={newDate || date}
        onChange={setNewDate}
        inputProps={{ ref: datePickerRef }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormDatePicker;
