import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

export const TabsContainer = styled(Tabs)`
  border: 1px solid #E1E1E1;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  min-height: 40px !important;
`;

export const TextTab = styled(Tab)`
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: bold !important;
  letter-spacing: 0.03em !important;
  line-height: 22px !important;
  min-height: 40px !important;
  min-width: 126px !important;
  text-transform: none !important;
  width: 126px !important;
`;

export const TooltipContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const HelperText = styled(Typography)`
  color: #A3A3A3;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: -10px;
`;

export const IconGrid = styled(Grid)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;

export const AddImageContainer = styled.div`
  /* width: 90%; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  height: 100%;
  margin: 10px 0px 0px 0px;
  display: flex;
  place-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin: 32px 0px 60px 0px;

  position: relative;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;

  .title {
    padding: 8px 0px 0px 0px;
    font-size: 12px;
    font-weight: 600;
  }

  .select {
    padding: 20px 24px 0px 0px;
    width: 173px;
  }

  .form {
    width: 70%
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #E1E1E1;
  width: 100%;

  position: absolute;
`;

export const CleanForm = styled.button`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: center;

  width: 230px;

  background-color: transparent;
  border: 0px;

  margin: 30px 0px 0px 0px;

  p {
    text-decoration: underline;
    margin: 0px 0px 0px 4px;
  }
`;

export const StyledPaper = styled.div`
  .tox .tox-sidebar-wrap{
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-top: 10px;
  }
`;

export const DeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  text-align: center;

  > img {
    align-self: center;
    margin: 16px 0px 32px 0px;
    max-width: 100%;
    /* width: 80%; */
  }

  > h5 {
    > b {
      margin: 0px 5px 0px 5px;
      color: #22E0A1;
    }
  }

  p {
    margin-top: 10px;
    font-size: 12px;
    color: #EB5757;
  }
`;

export const AdvancedFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
