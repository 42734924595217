import React, { useState, useMemo } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { MonetizationOnOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import master from './assets/mastercard.png';
import visa from './assets/visa.png';
import amex from './assets/amex.png';
import elo from './assets/elo.png';
import diners from './assets/diners.png';
import { ReactComponent as SmallBillet } from './assets/ic-aguardando-boleto.svg';
import { ReactComponent as PixIcon } from './assets/pix.svg';
import { ReactComponent as CreditCardIcon } from './assets/credit-card.svg';
import TextField from '../../form/text-field';
import Description from '../../text/description';
import Paper from '../../paper';
import { StyledTooltip } from '../../tooltip';
import { useStyles } from './styles';
import { useOrder } from '../../../contexts/order';
import { useBranch } from '../../../contexts/branch';

export default function Payment({ order, updateOrder }) {
  const styles = useStyles();
  const [copy, setCopy] = useState('Copiar código de barras');
  const [invoice, setInvoice] = useState(order?.order?.invoice_number || '');
  const { updateOrderInvoice } = useOrder();
  const { branchs } = useBranch();

  const currentMethod = order?.payment?.[0] || order?.alternative_payment;
  const payment = {
    payment_method: currentMethod?.payment_method || currentMethod?.method || order?.order?.payment_method,
    pix_expiration_date: currentMethod?.pix_expiration_date || null,
    amount: currentMethod?.amount,
    status: currentMethod?.status || null,
  };

  const formatedDate = useMemo(() => {
    if (payment.payment_method !== 'pix') return null;
    const date = new Date(payment.pix_expiration_date);

    const day = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'long',
    });

    const hour = `${date.getHours()}:${date.getMinutes()}`;

    return {
      day,
      hour,
    };
  }, [payment.payment_method, payment.pix_expiration_date]);

  const copyURL = (code) => {
    const copiedMessage = 'Link copiado para a área de transferência';

    navigator.clipboard.writeText(code);

    setCopy(copiedMessage);
  };

  const updateInvoice = async () => {
    if (invoice.length) {
      try {
        await updateOrderInvoice({
          id: order?.order.id,
          number: invoice,
        });
        await updateOrder();
        toast.success('Número de nota fiscal atualizado');
      } catch (e) {
        toast.error('Não foi possível atualizar o número de nota fiscal');
      }
    }
  };

  const cardBrand = {
    mastercard: master,
    visa,
    amex,
    diners,
    elo,
  };

  const paymentStatus = {
    paid: {
      title: 'Aprovado',
      color: '#00BFA5',
    },
    waiting_payment: {
      title: 'Aguardando pagamento',
      color: '#ffc107',
    },
    refunded: {
      title: 'Pagamento estornado',
      color: '#ffc107',
    },
    refused: {
      title: 'Recusado pela operadora',
      color: '#ff0000',
    },
    processing: {
      title: 'Processando pagamento',
      color: '#007bff',
    },
  };

  const sellingBranch = branchs?.items?.find((branch) => branch.id === order?.order?.selling_branch_id);

  return (
    <Paper height="auto">
      <div className={styles.flexGrow}>
        <Grid container direction="column">
          <Grid
            container
            item
            justify="center"
            alignContent="center"
            style={{ height: '78px' }}
          >
            {payment.payment_method === 'boleto' ? (
              <SmallBillet
                style={{ marginBottom: -20, marginTop: -42, height: 140 }}
              />
            ) : payment.payment_method === 'pix' ? (
              <PixIcon fill="#22dfa1" />
            ) : payment.payment_method === 'credit_card' ? (
              Object.keys(cardBrand).includes(order?.payment?.[0]?.card_brand) ? (
                <img
                  src={cardBrand[order.payment?.[0].card_brand]}
                  style={{ width: 100 }}
                  alt="Credit card brand"
                />
              ) : (
                <CreditCardIcon />
              )
            ) : (
              <MonetizationOnOutlined
                style={{
                  fontSize: '4rem',
                  color: '#22dfa1',
                  marginBottom: 20,
                }}
              />
            )}
          </Grid>

          {payment.status && (
            <>
              <Grid item style={{ marginBottom: 4 }}>
                <Description bold>Status de Pagamento:</Description>
              </Grid>

              <Grid item style={{ marginBottom: 20 }}>
                <Typography
                  style={{
                    fontSize: '0.875rem',
                    color: paymentStatus[payment.status].color,
                  }}
                >
                  {paymentStatus[payment.status].title}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item style={{ marginBottom: 4 }}>
            <Description bold>Forma de Pagamento:</Description>
          </Grid>

          <Grid item style={{ marginBottom: 20 }}>
            {payment.payment_method === 'boleto' ? (
              <>
                <div style={{ marginBottom: 4 }}>
                  <Description>Boleto Bancário</Description>
                </div>
                {order?.payment?.[0]?.status !== 'paid' ? (
                  <>
                    <Description>
                      vencimento em{' '}
                      {format(
                        Date.parse(order?.payment?.[0]?.billet_expiration_date),
                        'dd/MM/yyyy',
                      )}
                    </Description>
                  </>
                ) : (
                  <>
                    <Description>
                      compensado em{' '}
                      {format(order?.order?.paid_at, 'dd/MM/yyyy')}
                    </Description>{' '}
                    <br />
                    <Description>TID {order?.payment?.[0]?.tid}</Description>
                  </>
                )}
              </>
            ) : payment.payment_method === 'pix' ? (
              <>
                <div>
                  <Description>Pix</Description>
                </div>
                <div>
                  <Description>TID: {order?.payment?.[0]?.tid}</Description>
                </div>
              </>
            ) : payment.payment_method === 'credit_card' ? (
              <>
                <div style={{ marginBottom: 4 }}>
                  <Description>
                    Cartão de Crédito final{' '}
                    {order?.payment?.[0]?.card_last_digits}
                  </Description>
                </div>

                {order?.payment?.[0]?.card_holder_name && (
                  <div style={{ marginBottom: 4 }}>
                    <Description>
                      Titular: {order.payment?.[0].card_holder_name}
                    </Description>
                  </div>
                )}

                {order?.payment?.[0]?.tid && (
                  <div style={{ marginBottom: 4 }}>
                    <Description>TID: {order?.payment?.[0]?.tid}</Description>
                  </div>
                )}
              </>
            ) : (
              <>
                <div style={{ marginBottom: 4 }}>
                  <Description>
                    Método: {order?.alternative_payment?.method}
                  </Description>
                </div>
                <div style={{ marginBottom: 4 }}>
                  <Description>
                    Tipo: {order?.alternative_payment?.type}
                  </Description>
                </div>
                <div style={{ marginBottom: 4 }}>
                  <Description>
                    Obs: {order?.alternative_payment?.obs}
                  </Description>
                </div>
              </>
            )}

            {order?.order?.paid_at && (
              <div>
                <Description>
                  Aprovado em: {format(order?.order?.paid_at, 'dd/MM/yyyy')}
                </Description>
              </div>
            )}

            {sellingBranch && (
              <div>
                <Description>
                  Razão social: {sellingBranch.legal_name}
                </Description>
              </div>
            )}
          </Grid>

          {order?.alternative_payment && (
            <>
              <Grid item style={{ marginBottom: 4 }}>
                <Description bold>Valor do pedido:</Description>
              </Grid>
              <Grid item style={{ marginBottom: 20 }}>
                <div style={{ marginBottom: 4 }}>
                  <Description>
                    {Number(order?.alternative_payment?.amount).toLocaleString(
                      'pt-br',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      },
                    )}
                  </Description>
                </div>
              </Grid>
            </>
          )}

          <Grid item style={{ marginBottom: 20 }}>
            <Description bold>Nº Nota Fiscal:</Description>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  name="invoice"
                  value={invoice}
                  label="Nota fiscal"
                  placeholder="Número da nota fiscal"
                  disabled={
                    order?.order?.status !== 30 || order?.order?.invoice_number
                  }
                  onChange={(e) => setInvoice(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={
                    order?.order?.status !== 30 || order?.order?.invoice_number
                  }
                  onClick={() => updateInvoice()}
                  fullWidth
                  style={{
                    marginBottom: -12,
                  }}
                >
                  Fixar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {!(
            payment.payment_method === 'boleto'
            && order?.payment?.[0]?.status === 'paid'
          ) && (
            <>
              <Grid item style={{ marginBottom: 4 }}>
                <Description bold>
                  {payment.payment_method === 'boleto'
                    && 'Código de Barras Numérico'}
                  {payment.payment_method === 'credit_card'
                    && 'Número de parcelas'}
                  {payment.payment_method === 'pix' && 'Valor do pedido'}
                </Description>

                {payment.payment_method === 'pix' && (
                  <>
                    <div>
                      <span>
                        {Number(order?.payment?.[0]?.amount_split).toLocaleString(
                          'pt-BR',
                          { style: 'currency', currency: 'BRL' },
                        )}
                      </span>
                    </div>
                    {!order?.order?.paid_at && (
                      <div>
                        expira em {formatedDate?.hour}, {formatedDate?.day}
                      </div>
                    )}
                  </>
                )}
              </Grid>

              <Grid item style={{ marginBottom: 24 }}>
                <Description>
                  {payment.payment_method === 'boleto'
                    && order?.payment?.[0]?.billet_barcode}
                  {payment.payment_method === 'credit_card'
                    && `
                  ${order?.payment?.[0]?.installments}x de
                  ${Number(order?.payment?.[0]?.amount_split).toLocaleString(
                      'pt-BR',
                      { style: 'currency', currency: 'BRL' },
                    )}`}
                </Description>
              </Grid>
            </>
          )}

          {payment.payment_method === 'credit_card' && (
            <>
              <Grid item style={{ marginBottom: 4 }}>
                <Description bold>Total:</Description>
              </Grid>
              <Grid item>
                <Description>
                  {Number(payment?.amount).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Description>
              </Grid>
            </>
          )}
        </Grid>

        {payment.payment_method === 'boleto'
          && order?.payment?.[0]?.status !== 'paid' && (
            <Grid
              container
              justify="space-around"
              spacing={2}
              style={{ alignItems: 'flex-start' }}
            >
              <Grid item md={6}>
                <StyledTooltip
                  title={copy}
                  placement="top"
                  className={styles.tableButton}
                >
                  <Button
                    className={styles.outlinedButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => copyURL(order?.payment?.[0]?.billet_barcode)}
                  >
                    copiar
                  </Button>
                </StyledTooltip>
              </Grid>

              <Grid item md={6}>
                <StyledTooltip
                  title="Imprimir Boleto"
                  placement="top"
                  className={styles.tableButton}
                >
                  <Button
                    className={styles.outlinedButton}
                    variant="outlined"
                    onClick={() => window.open(order?.payment?.[0]?.billet_url, '_blank')}
                    color="primary"
                  >
                    imprimir
                  </Button>
                </StyledTooltip>
              </Grid>
            </Grid>
        )}
      </div>
    </Paper>
  );
}
