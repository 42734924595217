import React from 'react';

import Grid from '@material-ui/core/Grid';

import Modal from '../modal';

import { Container } from '../styles';

export default function DeleteModal({
  openModal,
  setModal,
  deleteFunction,
  type,
  prefix,
  title,
}) {
  return (
    <Modal
      isVisible={openModal}
      title={`Remover ${type}`}
      toggle={() => setModal(!openModal)}
      size="xs"
      buttonPrimary={`manter ${type && type}`}
      buttonSecondary="remover permanentemente"
      modalActions={2}
      onSubmit={deleteFunction}
      isDelete
    >
      <Container style={{ margin: '30px 0 0 0' }}>
        <Grid container justify="center" direction="column" alignItems="center">
          <Grid item>
            <span>Tem certeza que deseja remover {prefix && prefix} {type && type}<span style={{ fontWeight: 'bold' }}>{` ${title}`}</span>?</span>
          </Grid>

          <Grid item>
            <span>Esta ação não poderá ser desfeita.</span>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}
