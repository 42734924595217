import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  divRight: {
    flex: '1',
    marginRight: '88px',
    textAlign: 'right',
  },

  footerLogin: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid rgba(47, 49, 56, 0.15)',
    bottom: '0',
    display: 'flex',
    height: '72px',
    left: '0',
    position: 'fixed',
    width: '100%',
  },

  headerLogin: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid rgba(47, 49, 56, 0.15)',
    display: 'flex',
    height: '84px',
    justifyContent: 'center',
    left: '0',
    width: '100%',
    position: 'fixed',
    top: '0',
  },

  iconHeart: {
    color: '#22E0A1',
    height: '16px',
    marginBottom: '-3px',
    marginLeft: '-16px',
    marginRight: '-16px',
  },

  spanCopyright: {
    color: '#424242',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.03em',
    lineHeight: '24px',
    margin: '0 16px 0 88px',
  },

  verticalLine: {
    borderLeft: '1px solid #A3A3A3',
    height: '32px',
  },
}));

export const Span = styled.span`
  color: #A3A3A3;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.03em;
  line-height: 24px;
  margin: 0 16px;
`;
