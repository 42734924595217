import React, { useCallback, useState } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { toast } from 'react-toastify';
import { Scope } from '@unform/core';
import TextField from '../../form/text-field';
import TitleCard from '../../title/title-card';

import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';

export default function FacebookTags({ formRef }) {
  const { updateSocialMedia, updateCredentials } = useSettings();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { facebook } = formRef.current.getData();

      const parsedMedias = {};

      const parseData = {
        facebook_pixel_page_view: 'FB-',
        facebook_domain_verification: '',
      };

      Object.keys(facebook.facebook_tags).forEach((item) => {
        if (!facebook.facebook_tags[item]) return parsedMedias[item] = '';
        parsedMedias[item] = `${parseData[item]}${facebook.facebook_tags[item]}`;
      });

      await updateSocialMedia(parsedMedias);
      await updateCredentials({ facebook: { ...facebook.facebook_keys }, instagram: { ...facebook.instagram_keys } });
      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updateSocialMedia, updateCredentials, formRef]);
  return (
    <>
      <TitleCard color="#A3A3A3" bold>Facebook & Instagram - Login e Acompanhamento</TitleCard>

      <StyledPaper>

        <GridContainer>

          <GridContainer container item width="auto" margin="12px" spacing={3}>
            <GridContainer container item lg={12} spacing={3}>

              <Grid item lg={6}>
                <Grid item container spacing={3} lg={12}>
                  <Scope path="facebook_tags">
                    <Grid item lg={12}>
                      <TextField
                        name="facebook_pixel_page_view"
                        label="Pixel page View (Visualização de Página)"
                        InputProps={{
                          startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">FB-</Text></InputAdornment>,
                        }}
                      />
                    </Grid>

                  </Scope>
                </Grid>

              </Grid>
              <Grid item lg={6}>
                <Scope path="facebook_keys">
                  <TextField
                    name="app_id"
                    label="ID do aplicativo no Facebook"
                  />
                </Scope>
              </Grid>

              <Grid item lg={12}>
                <Scope path="facebook_tags">
                  <TextField
                    name="facebook_domain_verification"
                    label="Verificação do Domínio do Facebook"
                  />
                </Scope>
              </Grid>
            </GridContainer>

            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary variant="contained" onClick={onSubmit} color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>

        </GridContainer>
      </StyledPaper>
    </>
  );
}
