import React, { useCallback, useState } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { toast } from 'react-toastify';
import { Scope } from '@unform/core';
import TextField from '../../form/text-field';
import TitleCard from '../../title/title-card';

import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';

export default function GoogleTags({ formRef }) {
  const { updateSocialMedia, updateCredentials } = useSettings();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { google } = formRef.current.getData();

      const parsedMedias = {};

      const parseData = {
        google_universal_analytics: 'UA-',
        google_analytics_v4: 'G-',
        google_tag_manager: 'GTM-',
      };

      Object.keys(google.google_tags).forEach((item) => {
        if (!google.google_tags[item]) return parsedMedias[item] = '';
        parsedMedias[item] = `${parseData[item]}${google.google_tags[item]}`;
      });

      await updateSocialMedia(parsedMedias);
      await updateCredentials({ google: { ...google.google_keys } });
      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updateSocialMedia, updateCredentials, formRef]);
  return (
    <>
      <TitleCard color="#A3A3A3" bold>Google - Login e Acompanhamento</TitleCard>

      <StyledPaper>

        <GridContainer>

          <GridContainer container item width="auto" margin="12px" spacing={3}>
            <Scope path="google_tags">
              <GridContainer container item lg={12} spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    name="google_universal_analytics"
                    label="Universal Analytics"
                    InputProps={{
                      startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">UA-</Text></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    name="google_analytics_v4"
                    label="Measurement ID"
                    InputProps={{
                      startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">G-</Text></InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    name="google_tag_manager"
                    label="Tag Manager (GTM)"
                    InputProps={{
                      startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">GTM-</Text></InputAdornment>,
                    }}
                  />
                </Grid>
              </GridContainer>
            </Scope>

            <Scope path="google_keys">
              <GridContainer container item lg={12} spacing={3}>
                <Grid item lg={6}>
                  <TextField
                    name="project_id"
                    label="ID do Projeto no Google"
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    name="secret"
                    label="Chave Secreta do Google"

                  />
                </Grid>
              </GridContainer>
            </Scope>

            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary variant="contained" onClick={onSubmit} color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>

        </GridContainer>
      </StyledPaper>
    </>
  );
}
