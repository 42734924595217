export const tabs = [
  {
    name: 'tab1',
    label: 'Logística',
  },
];

export const city = [
  {
    name: 'fu',
    label: 'UF',
    isFilter: false,
  },
  {
    name: 'city',
    label: 'Cidade',
    isFilter: false,
  },
  {
    name: 'days',
    label: 'Dias da Semana',
    isFilter: false,
  },
  {
    name: 'freight',
    label: 'Preço',
    isFilter: false,
  },
  {
    name: 'service',
    label: 'Nome do Serviço',
    isFilter: false,
  },
  {
    name: 'shipping_term',
    label: 'Prazo de Envio',
    isFilter: false,
  },
  {
    name: 'modality',
    label: 'Modalidade',
    isFilter: false,
  },
  {
    name: 'ships_low_temperature',
    label: 'Envio de refrigerados',
    isFilter: false,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];

export const neighborhood = [
  {
    name: 'fu',
    label: 'UF',
    isFilter: false,
  },
  {
    name: 'city',
    label: 'Cidade',
    isFilter: false,
  },
  {
    name: 'neighborhood',
    label: 'Bairro',
    isFilter: false,
  },
  {
    name: 'days',
    label: 'Dias da Semana',
    isFilter: false,
  },
  {
    name: 'freight',
    label: 'Preço',
    isFilter: false,
  },
  {
    name: 'service',
    label: 'Nome do Serviço',
    isFilter: false,
  },
  {
    name: 'shipping_term',
    label: 'Prazo de Envio',
    isFilter: false,
  },
  {
    name: 'modality',
    label: 'Modalidade',
    isFilter: false,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];

export const zip = [
  {
    name: 'zip1',
    label: 'CEP Inicial',
    isFilter: false,
  },
  {
    name: 'zip2',
    label: 'CEP Final',
    isFilter: false,
  },
  {
    name: 'freight',
    label: 'Preço',
    isFilter: false,
  },
  {
    name: 'Service',
    label: 'Nome do Serviço',
    isFilter: false,
  },
  {
    name: 'days',
    label: 'Dias da Semana',
    isFilter: false,
  },
  {
    name: 'shipping_term',
    label: 'Prazo de Envio',
    isFilter: false,
  },
  {
    name: 'modality',
    label: 'Modalidade',
    isFilter: false,
  },
  {
    name: 'ships_low_temperature',
    label: 'Envio de refrigerados',
    isFilter: false,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];
