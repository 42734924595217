import React, { useState } from 'react';

// Libs
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { IconButton, TableCell, Grid } from '@material-ui/core';

// Icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Components
import { StyledTooltip } from '../../tooltip';
import Modal from '../../modal';

// Assets
import Placeholder from '../../../assets/placeholder.svg';

// Styles
import { useStyles } from '../../styles';
import { DeleteModalContainer } from '../styles';

// Context
import { useBlogPosts } from '../../../contexts/blog';
import { useAuth } from '../../../contexts/auth';

function BlogPostItem({ post }) {
  const styles = useStyles();

  const { verifyPerms } = useAuth();
  const { deletePost } = useBlogPosts();

  const postStatus = {
    Draft: 'Rascunho',
    Published: 'Publicado',
    Hidden: 'Ocultado',
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  async function handleDelete(id) {
    const apiData = {
      id,
      displayMsg: true,
    };

    await deletePost(apiData);
  }

  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          {post.image?.image
            ? (
              <img src={post?.image.image} alt={post.image.asset_id || 'image'} className={styles.tableImg} />
            )
            : (
              <img src={Placeholder} className={styles.tableImg} alt="placeholder" />
            )}
          {post.title}
        </Grid>
      </TableCell>

      <TableCell>{postStatus[post.status]}</TableCell>

      <TableCell>{format((post.created_at), 'dd/MM/yyyy')}</TableCell>

      <TableCell>{format((post.updated_at), 'dd/MM/yyyy')}</TableCell>

      <TableCell>{post.author}</TableCell>

      <TableCell style={{ width: '160px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar postagem" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Marketing', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/blog-post/${post.id}`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Excluir postagem" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Marketing', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      <Modal
        isVisible={openDeleteModal}
        // isVisible
        title="Excluir Postagem"
        toggle={() => setOpenDeleteModal(!openDeleteModal)}
        buttonPrimary="excluir"
        buttonSecondary="cancelar"
        onClick={() => handleDelete(post.id)}
      >
        <DeleteModalContainer>
          {post?.image ? (
            <img src={post?.image.image} alt={post?.image.asset_id || 'image'} className={styles.tableImg} />
          ) : (
            <img src={Placeholder} alt="placeholder" />
          )}
          <h5>
            Você tem certeza que deseja excluir a postagem
            <b>{post.title}</b>
            ?
          </h5>

          <p>Essa ação é irreversível.</p>
        </DeleteModalContainer>
      </Modal>
    </>
  );
}

export default BlogPostItem;
