import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {Decimal} from 'decimal.js';

import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useStyles } from '../styles';
import { StyledTooltip } from '../tooltip';
import DeleteCoupon from './components/delete';
import { useCoupons } from '../../contexts/coupons';
import { useAuth } from '../../contexts/auth';

export default function Data({ data }) {
  const styles = useStyles();

  const { verifyPerms } = useAuth();
  const { deleteCoupon } = useCoupons();
  const [modal, setModal] = useState(false);

  const handleDeleteCoupon = useCallback(async () => {
    try {
      await deleteCoupon(data.id);

      toast.success('Cupom deletado.');
      setModal(!modal);
    } catch (err) {
      setModal(!modal);
      toast.error('Não foi possível deletar este cupom.');
    }
  }, [data, modal, deleteCoupon]);

  const price = useMemo(() => {
    if (data?.discount_freight) {
      if (!data?.is_absolute_freight) {
        return Decimal(data?.discount_freight).mul(100).toString();
      }
      return data?.discount_freight;
    }
    if (!data?.is_absolute_cart) {
      return Decimal(data?.discount_cart).mul(100).toString();
    }
    return data?.discount_cart;
  }, [data]);

  return (
    <>
      <TableCell>{data.name}</TableCell>
      <TableCell>{(data?.is_absolute_cart || data?.is_absolute_freight)
      && 'R$'} {price} {(!data?.is_absolute_cart && !data?.is_absolute_freight) && '%'}
      </TableCell>
      <TableCell>{data?.discount_freight ? 'No Frete' : 'Em Itens do Carrinho'}</TableCell>
      {/* <TableCell>{data?.eligibility}</TableCell> */}
      <TableCell>
        {data?.available_from && data?.available_to
         && `${format(data?.available_from, "'Do dia' dd 'de' MMMM 'de' yyyy", { locale: pt })} ${format(data.available_to, "'até' dd 'de' MMMM 'de' yyyy", { locale: pt })}`}
      </TableCell>

      <TableCell style={{ width: '96px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar Cupom" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Marketing', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/promocode/${data.id}`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-4px', marginRight: '-8px' }}>
            <StyledTooltip title="Remover Cupom" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Marketing', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => setModal(true)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      <DeleteCoupon openModal={modal} setModal={setModal} coupon={data} deleteCoupon={handleDeleteCoupon} />
    </>
  );
}
