import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import {
  FormControl, FormGroup, FormControlLabel, Switch,
} from '@material-ui/core';
import SubtitleCard from '../title/subtitle-card';

export default function FormSwitch({
  name, include, theme, label, defaultChecked, ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = false, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      getValue: (refs) => refs.checked,
      clearValue: (refs) => { refs.checked = false; },
      setValue: (refs) => { refs.checked = true; },
    });
  }, [fieldName, registerField]);

  return (

    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="top"
          control={(
            <Switch
              color="primary"
              inputRef={inputRef}
              defaultChecked={Boolean(defaultValue) || Boolean(defaultChecked)}
              {...rest}
            />
      )}
          label={(
            <SubtitleCard style={{ color: '#424242', fontWeight: 700 }}>
              {label}
            </SubtitleCard>
        )}
        />
      </FormGroup>
    </FormControl>

  );
}
