import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';

import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { formatDate } from '../../utils/time';
import ContentWrapper from '../content-wrapper';
import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import SaveBar from '../save-bar';
import { PageTitle, useStyles } from '../styles';
import BasicInfo from './basic-info';
import Catalog from './catalog';
import CustomerProfile from './customer-profile';
import Formulation from './formulation';
import Gallery from './gallery';
import HelperTexts from './helper-texts';
import Suggestions from './suggestions';
import Keywords from './keywords';
import Logistic from './logistic';
// import Variant from './new-variants';
import Pricing from './pricing';
// import VariantList from './variant-list';
import Visibility from './visibility';
import Highlights from './highlights';

import { schema } from './schemas';
import { useProduct } from '../../contexts/product';
import { useCategory } from '../../contexts/category';
import actions from '../../actions';
import Spinner from '../spinner';
import SecondayVariants from './secondary-variants';
import LightningPromotion from './lightning_promotion';
import { useAuth } from '../../contexts/auth';
import { checkPriceIsLower } from '../../utils/currency';

export default function Product() {
  const styles = useStyles();
  const formRef = useRef(null);
  const params = useParams();

  const { verifyPerms } = useAuth();
  const { getOneProduct, updateProduct } = useProduct();
  const { fetchCategoriesWithParams } = useCategory();

  const { id } = params;
  const { search } = useLocation();

  const [minimum, setMinimum] = useState();
  const [priceWithDiscount, setPriceWithDiscount] = useState();
  const [offerDiscount, setOfferDiscount] = useState();

  const [product, setProduct] = React.useState(null);
  const [gender, handleGenderChange] = useState('a');
  // const [hasVariant, setVariant] = useState(false);
  // const [isCompounded, setCompounded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [isRefrigerated, setRefrigerated] = useState(false);
  const [resume, setResume] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [title, setTitle] = useState('');
  const [usage, setUsage] = useState(1);
  const [categoriesList, setCategoriesList] = useState([]);
  const [buyWith, setBuyWith] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [formsOptions, setFormsOptions] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [banner, setBannerImage] = useState('');
  const [volumeUnit, setVolumeUnit] = useState();
  const [price, setPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [bulkConfigPrice, setBulkConfigPrice] = useState();

  const [categories, setCategories] = useState([]);

  const [ingredients, setIngredients] = useState([{
    name: '',
    concentration: '',
    measure: '',
    visible: true,
    is_active: false,
  }]);

  const [volumeVariants, setVolumeVariants] = useState([]);

  const [secondaryVariants, setSecondaryVariants] = useState([{
    name: '',
    options: '',
    visible: true,
  }]);

  const refetch = useCallback(async () => {
    try {
      setLoadingForm(true);
      const { item } = await getOneProduct(id);

      setProductImages(item.medias?.length && item.medias.filter((image) => image?.type === 1));

      const foundBanner = item?.medias?.find((image) => image?.type === 2);
      setBannerImage(foundBanner);

      setProduct(item);
      setVolumeUnit(item.volume_unit?.trim());
      handleGenderChange(item.target_consumer || 'a');
      setUsage(item.usage || 1);
      setSimilarProducts(item.related_products || [{}]);
      setBuyWith(item.buywith_products || [{}]);
      setTitle(item.title);
      setSubtitle(item.subtitle);
      setResume(item.summary);

      const res = await actions.products.getForms(item.usage || 1);
      const response = await fetchCategoriesWithParams({
        recursive: true,
        fetch_keywords: true,
      });

      setCategories(response);
      setFormsOptions(res.forms);

      if (item?.volume_variants?.items?.length) {
        setVolumeVariants(item.volume_variants?.items);
      }

      if (item?.variants?.items?.length) {
        const replace_secondary_variants = item.variants.items.map((second) => {
          const second_variants = second;
          const mappedOptions = second?.options?.length ? second.options.map((option) => option.value) : [];
          second_variants.options = mappedOptions;

          return second_variants;
        });
        setSecondaryVariants(replace_secondary_variants);
      }
      if (item?.ingredients) {
        setIngredients(item.ingredients);
      }

      if (item.categories) {
        const { categories: categoriesItem } = item;

        const categoriesArr = categoriesItem.map(category => !category.parent_id && category);

        const subArr = categoriesItem.map(category => category.parent_id && category);

        const categoriesStruct = categoriesArr.map(category => {
          const subs = [];
          subArr.map(sub => {
            if (sub?.parent_id === category?.id) return subs.push(sub);

            return null;
          });

          if (!category.id) return null;

          return { ...category, subs };
        });

        setCategoriesList(categoriesStruct);
      }
      setLoadingForm(false);
    } catch (err) {
      setLoadingForm(false);
      toast.error('Ocorreu um erro ao buscar as categorias. Tente novamente mais tarde');
    }
  }, [getOneProduct, id, fetchCategoriesWithParams]);

  useEffect(() => {
    async function getCategory() {
      try {
        await refetch();
      } catch (err) {
        toast.error('Ocorreu um erro ao atualizar a categoria. Tente novamente mais tarde');
      }
    }
    getCategory();
  }, [refetch]);

  const history = useHistory();

  function newTimestamp(date) {
    const brutalTime = date.replace(/\//g, ' ');
    const time = brutalTime.split(' ');
    return Date.parse(`${time[2]}-${time[1]}-${time[0]} ${time[3]}`);
  }

  function handleScrollError(name, message) {
    const element = formRef.current.getFieldRef(name);

    if (element) {
      formRef.current.setFieldError(name, message);

      element.focus();
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  const isPriceLowerThanDiscountPrice = checkPriceIsLower(price, discountPrice);
  const isDiscountPriceLowerThanBulkConfigPrice = offerDiscount ? checkPriceIsLower(discountPrice, bulkConfigPrice) : undefined;

  async function onSubmit(formData) {
    setLoading(true);

    // secondary variants
    const data = formRef.current.getData();

    const replace_secondary_variants = data.replace_secondary_variants.map((item) => {
      const replacedItem = item;
      const options = [];

      const replacedKeys = item?.options?.length ? item.options : [];
      replacedKeys.map((key) => options.push({ value: key }));
      replacedItem.options = options;

      return replacedItem;
    });

    const variants = (replace_secondary_variants?.length
      && (replace_secondary_variants[0]?.name || replace_secondary_variants[0]?.options?.length))
      ? { items: replace_secondary_variants }
      : { items: [] };

    const volume_variants = (formData.replace_volume_variants?.length && formData.replace_volume_variants[0]?.value) ? {
      items: formData.replace_volume_variants,
    } : { items: [] };

    const promotion = formData.promotion?.price ? {
      price: formData.promotion.price,
      from: newTimestamp(formData.promotion.from),
      to: newTimestamp(formData.promotion.to),
    } : null;

    const formExists = formsOptions.find((formOption) => formOption.id === formData.form);
    if (!formExists) {
      setLoading(false);

      const form = formRef?.current?.getFieldRef('form');

      if (form) {
        form.node.focus();
        form.node.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        formRef.current.setFieldError('form', 'Você precisa selecionar uma nova Forma Farmacêutica');
      }

      return toast.warn('Você precisa selecionar uma nova Forma Farmacêutica');
    }

    // const replace_categories = concat(ids, subIds);

    const replace_categories = categoriesList.map((category) => {
      if (category) {
        const concatenedIds = [];
        concatenedIds.push(category.id);
        if (category.subs) {
          category.subs.forEach((sub) => concatenedIds.push(sub.id));
        }
        return concatenedIds;
      }

      return [];
    }).flat();

    const ingredientsArr = formData.replace_ingredients.map((item, i) => ({
      ...item,
      concentration: parseFloat(item.concentration.split(',').join('.')).toString(),
      is_active: ingredients[i].is_active,
      visible: ingredients[i].visible,
    }));

    const { ecommerce, callcenter, recommended_age } = formData;
    const minAge = parseFloat(recommended_age.min);
    const maxAge = parseFloat(recommended_age.max);

    // const ingred = [];
    // ingredients.map((item) => item && ingred.push({ ...item, concentration: parseFloat(item.concentration.split(',').join('.')) }));
    const age = {
      min: minAge,
      max: maxAge,
    };

    try {
      formRef.current.setErrors({});

      const newProduct = {
        ...formData,
        id,
        bulk_config: {
          is_active: offerDiscount,
          min_qty: minimum,
          price: priceWithDiscount ? String(priceWithDiscount) : null,
        },
        // categoriesList: formattedCategories,
        // has_variant: hasVariant,
        // compounded: isCompounded,
        usage,
        // freeze_out_of_stock: TODO,
        is_refrigerated: isRefrigerated,
        ecommerce: { ...ecommerce, from: formatDate(ecommerce.from) },
        callcenter: { ...callcenter, from: formatDate(callcenter.from) },
        target_consumer: gender,
        recommended_age: age,
        replace_ingredients: ingredientsArr,
        replace_categories,
        max_qty: formData.max_qty && Number(formData.max_qty),
        assembly_days: formData.assembly_days && parseFloat(formData.assembly_days),
        variants,
        volume_variants,
        promotion,
        weight: formData?.weight !== '' ? formData.weight : null,
      };

      if (newProduct.bulk_config?.min_qty === '') {
        delete newProduct.bulk_config.min_qty;
      }
      if (newProduct.bulk_config?.price === '') {
        delete newProduct.bulk_config.price;
      }

      if (newProduct.discount_price === '') {
        delete newProduct.discount_price;
      }

      if (newProduct.price === '') {
        newProduct.price = '0.00';
      }

      if (usage > 0) {
        newProduct.usage = usage;
      }

      await schema.validate({ ...newProduct, volume_variants: volume_variants?.items, variants: variants?.items }, {
        abortEarly: false,
      });

      // const asyncImages = productImages.map(async (image) => {
      //   if (image?.base) {
      //     await actions.media.addMediaToProduct(id, image);
      //   }
      // });
      // await Promise.all(asyncImages);

      await updateProduct(newProduct);
      history.push('/products');

      return toast.success(`Produto ${newProduct.title} foi atualizado com sucesso`);
    } catch (err) {
      const validationErrors = {};

      setLoading(false);

      if (err instanceof Yup.ValidationError) {
        console.log(err);
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        const error = Object.keys(validationErrors)[0];
        const errorMessage = validationErrors[error];

        handleScrollError(error, errorMessage);

        toast.error(errorMessage);

        return formRef.current.setErrors(validationErrors);
      }

      if (err.response) {
        if (err.response.data.message.includes('slug already in use for store')) {
          handleScrollError('slug', 'O slug escolhido já está sendo utilizado em outro lugar da página');
          return toast.error('O slug escolhido já está sendo utilizado em outro lugar da página');
        }

        if (err.response.data.message.includes('update product: rpc error: code = InvalidArgument desc = invalid slug')) {
          handleScrollError('slug', 'O link do produto é obrigatório.');
          return toast.error('O link do produto é obrigatório.');
        }

        if (err.response.data.message.includes('Unmarshal type error: expected=int32, got=string, field=assembly_days,')) {
          handleScrollError('assembly_days', 'Prazo de expedição é obrigatório.');
          return toast.error('Prazo de expedição é obrigatório.');
        }
      }

      return toast.error(`Não foi possível atualizar o produto ${product.title}, por favor revise o formulário`);
    }
  }

  const keywords_concat = product?.keywords?.join(', ');

  const initialData = { ...product, keywords_concat };

  useEffect(() => {
    setOfferDiscount(initialData?.bulk_config?.is_active);
    setPrice(initialData?.price);
    setDiscountPrice(initialData?.discount_price);
    setBulkConfigPrice(initialData?.bulk_config?.price);
    // eslint-disable-next-line
  }, [initialData?.bulk_config?.is_active, initialData?.price, initialData?.discount_price]);

  const isSubmitDisabled = verifyPerms({ role: 'Catálogo', access: 'write' }) || isPriceLowerThanDiscountPrice || isDiscountPriceLowerThanBulkConfigPrice;

  return (product && !loadingForm) ? (
    <>
      <Breadcrumb
        title={search ? 'Criar Produto' : 'Editar Produto'}
        text="Catálogo"
        breadcrumb="Produtos"
        link="/product"
        current={title}
      />

      <Form onSubmit={formData => onSubmit(formData)} ref={formRef} initialData={initialData}>
        <ContentWrapper align="top">
          <Grid container direction="row" className={styles.gridRowForm}>
            <Grid item xs={12} className={styles.gridTitle}>
              <PageTitle>{title || 'Novo produto'}</PageTitle>
            </Grid>

            <Grid item xs={8} sm={8} className={styles.gridRowFormPadding}>
              <BasicInfo
                title={title}
                setTitle={setTitle}
                subtitle={subtitle}
                setSubtitle={setSubtitle}
                product={product}
                resume={resume}
                setResume={setResume}
                // hasVariant={hasVariant}
                // setVariant={setVariant}
                // isCompounded={isCompounded}
                // setCompounded={setCompounded}
              />

              <Formulation
                setUsage={setUsage}
                usage={usage}
                volumeUnit={volumeUnit}
                setVolumeUnit={setVolumeUnit}
                formsOptions={formsOptions}
                setFormsOptions={setFormsOptions}
                volumeVariants={volumeVariants}
                setVolumeVariants={setVolumeVariants}
                ingredients={ingredients}
                setIngredients={setIngredients}
              />

              {/* {hasVariant === false
                ? (
                  <Formulation
                    setUsage={setUsage}
                    usage={usage}
                    volumeUnit={volumeUnit}
                    setVolumeUnit={setVolumeUnit}
                    formsOptions={formsOptions}
                    setFormsOptions={setFormsOptions}
                    volumeVariants={volumeVariants}
                    setVolumeVariants={setVolumeVariants}
                    ingredients={ingredients}
                    setIngredients={setIngredients}
                  />
                ) : (
                  <>
                    <Variant />
                    <VariantList />
                  </>
                )} */}

              <SecondayVariants
                formRef={formRef}
                variants={secondaryVariants}
                setVariants={setSecondaryVariants}
              />
              <Logistic setRefrigerated={setRefrigerated} isRefrigerated={isRefrigerated} />
              <HelperTexts />
              <Gallery
                productImages={productImages}
                setProductImages={setProductImages}
                banner={banner}
                setBannerImage={setBannerImage}
                title={title}
                imageList={product?.medias}
                refetch={refetch}
                formRef={formRef}
                id={id}
              />

              <Suggestions
                similarProducts={similarProducts}
                setSimilarProducts={setSimilarProducts}
                buyWith={buyWith}
                setBuyWith={setBuyWith}
                productId={id}
                refetch={refetch}
                product={product}
              />
            </Grid>

            <Grid item xs={4} sm={4} className={styles.gridRowFormPadding2}>
              <Visibility product={product} />
              <Pricing {...{
                setMinimum, minimum, priceWithDiscount, setPriceWithDiscount, offerDiscount, setOfferDiscount, setPrice, setDiscountPrice, product, formRef, price, discountPrice, setBulkConfigPrice, isPriceLowerThanDiscountPrice, isDiscountPriceLowerThanBulkConfigPrice,
              }}
              />
              <Catalog
                categories={categories}
                categoriesList={categoriesList}
                setCategoriesList={setCategoriesList}
                formRef={formRef}
              />
              <Highlights />
              <Keywords />
              <LightningPromotion product={product} />
              <CustomerProfile gender={gender} setGender={handleGenderChange} />
            </Grid>
          </Grid>
        </ContentWrapper>

        <SaveBar
          disabled={isSubmitDisabled}
          buttonPrimary="salvar alterações"
          buttonSecondary="sair sem salvar"
          type="submit"
          history="/product"
          isLoading={isLoading}
        />
      </Form>
    </>
  ) : (
    <Spinner />
  );
}
