import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

export const TabsContainer = styled(Tabs)`
  border: 1px solid #E1E1E1;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  min-height: 40px !important;
`;

export const TextTab = styled(Tab)`
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: bold !important;
  letter-spacing: 0.03em !important;
  line-height: 22px !important;
  min-height: 40px !important;
  min-width: 126px !important;
  text-transform: none !important;
  width: 126px !important;
`;

export const TooltipContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const HelperText = styled(Typography)`
  color: #A3A3A3;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: -10px;
`;

export const IconGrid = styled(Grid)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;
