import React, { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';

import EditIcon from '@material-ui/icons/Edit';

import { ActionButton, useStyles } from '../styles';
import { StyledTooltip } from '../tooltip';
import Text from '../text/text';

export default function CustomerData({ customer }) {
  const history = useHistory();
  const styles = useStyles();

  const phone = useMemo(() => {
    if (!customer?.phone || !customer?.ddd || !customer?.ddi) return null;
    const item = customer?.ddi?.replaceAll('+', '') + customer?.ddd + customer?.phone;
    if (item.length === 13) {
      const ddi = item.slice(0, 2);
      const ddd = item.slice(2, 4);
      const first_phone = item.slice(4, 9);
      const final_phone = item.slice(9, 13);

      return `+${ddi} (${ddd}) ${first_phone}-${final_phone}`;
    } if (item.length <= 12) {
      const ddi = item.slice(0, 2);
      const ddd = item.slice(2, 4);
      const first_phone = item.slice(4, 8);
      const final_phone = item.slice(8, 12);

      return `+${ddi} (${ddd}) ${first_phone}-${final_phone}`;
    }

    return null;
  }, [customer]);

  function formatCPF(value) {
    const replaceCpf = value.replace(/[^\d]/g, '');

    if (value.length >= 14) {
      return replaceCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return replaceCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return (
    <>
      <TableCell>{customer?.name ? customer?.name?.slice(0, 30) + (customer?.name?.length > 30 ? '...' : '') : (
        <Grid container justify="center">
          <Grid item>-</Grid>
        </Grid>
      )}
      </TableCell>
      <TableCell>{customer?.email ? customer?.email?.slice(0, 30) + (customer?.email?.length > 30 ? '...' : '') : (
        <Grid container justify="center">
          <Grid item>-</Grid>
        </Grid>
      )}
      </TableCell>
      <TableCell>{(customer?.phone && customer?.ddd && customer?.ddi) ? phone : (
        <Grid container justify="center">
          <Grid item>-</Grid>
        </Grid>
      )}
      </TableCell>
      <TableCell>{customer?.document ? formatCPF(customer.document) : (
        <Grid container justify="center">
          <Grid item>-</Grid>
        </Grid>
      )}
      </TableCell>
      <TableCell>
        <Grid container justify="center">
          <Grid item>
            {customer?.orders_info.last_seq ? (
              <StyledTooltip title="Vizualizar último pedido" placement="top" className={styles.tableButton}>
                <ActionButton
                  onClick={() => history.push(`/orders/${customer?.orders_info.last_id}`)}
                  style={{ width: '50px' }}
                  className={styles.actionButton}
                >
                  <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{customer?.orders_info.last_seq}</Text>
                </ActionButton>
              </StyledTooltip>
              ) : (
              '-'
            )}
          </Grid>
        </Grid>

      </TableCell>

      <TableCell>
        <Grid container direction="row" alignItems="center" justify="center">
          <StyledTooltip title="Editar Cliente" placement="top" className={styles.tableButton}>
            <ActionButton
              onClick={() => history.push(`/customers/${customer.id}`)}
              className={styles.actionButton}
            >
              <EditIcon className={styles.icon} color="disabled" fontSize="small" />
            </ActionButton>
          </StyledTooltip>
        </Grid>
      </TableCell>
    </>
  );
}
