import React, { useEffect } from 'react';
import { Container } from './styles';
import formatDocument from '../../../utils/formatDocument';
import formatCep from '../../../utils/formatCep';

export function PaymentPrint({ order, settings }) {
  const paymentMethod = {
    credit_card: 'CRÉDITO',
    pix: 'PIX',
    boleto: 'BOLETO',
  };

  const paymentStatus = {
    paid: 'PAGO',
    waiting_payment: 'AGUARDANDO PAGAMENTO',
    refused: 'RECUSADO',
  };

  useEffect(() => {
    window.print();
  }, []);

  return (
    <Container>
      <section>
        <strong>* * DADOS DO PEDIDO * *</strong>
        <table>
          <tbody>
            {order?.order?.inquiry_display_id && (
              <tr>
                <td>SOLICITAÇÃO:</td>
                <td>{order.order.inquiry_display_id}</td>
              </tr>
            )}
            <tr>
              <td>PEDIDO:</td>
              <td>{order?.order?.label}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <strong>* * DADOS DO CLIENTE * *</strong>
        <table>
          <tbody>
            <tr>
              <td>NOME:</td>
              <td>{order?.customer?.name}</td>
            </tr>
            <tr>
              <td>CPF:</td>
              <td>{formatDocument(order?.customer?.document.number)}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <strong>* * DADOS DO PAGAMENTO * *</strong>
        <table>
          <tbody>
            <tr>
              <td>VALOR:</td>
              <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(order?.order?.total_amount))}</td>
            </tr>
            <tr>
              <td>OPÇÃO:</td>
              <td>{order?.payment ? order.payment[0].payment_method === 'credit_card' ? `${paymentMethod[order.payment[0].payment_method]}, ${order.payment[0].installments}x` : paymentMethod[order.payment[0].payment_method] : order?.alternative_payment.method}</td>
            </tr>
            {order?.payment && (
              <tr>
                <td>STATUS:</td>
                <td>{paymentStatus[order.payment[0].status]}</td>
              </tr>
            )}
            {order?.payment && order.payment[0].card_brand && (
            <tr>
              <td>BANDEIRA:</td>
              <td>{order.payment[0].card_brand.toUpperCase()} {order.payment[0].card_last_digits}</td>
            </tr>
              )}
            {order?.payment && (
            <tr>
              <td>TID/NSU:</td>
              <td>{order.payment[0].tid}</td>
            </tr>
              )}
            {order?.payment && (
            <tr>
              <td>DATA/HORA:</td>
              <td>{new Date(order?.payment[0].created_at).toLocaleString()}</td>
            </tr>
              )}
          </tbody>
        </table>
      </section>
      <section>
        <strong>* * DADOS DO BENEFICIÁRIO * *</strong>
        <div>
          <p>{settings?.store?.name}</p>
          <p>CNPJ: {formatDocument(settings?.store?.cnpj)}</p>
          {settings?.store?.address?.[0] && (
            <>
              <p>{settings?.address[0]?.street}, {settings?.address[0]?.number}</p>
              <p>{settings?.address[0]?.district}</p>
              <p>{settings?.address[0]?.city}, {settings?.address[0]?.uf}</p>
              <p>{formatCep(settings?.address[0]?.zip)}</p>
            </>
          )}
        </div>
      </section>
    </Container>
  );
}
