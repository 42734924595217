import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
import TitleCard from '../../title/title-card';
import TextField from '../../form/text-field';
import Select from '../../form/select';
import TableMaterial from '../../table';

import { EditRolesAndPermissionsSchema } from './schema';

import RolesAndPermissionsData from './edit-roles-data';
import SaveBar from '../../save-bar';

import { PageTitle, StyledPaper } from '../../styles';
import { useGroups } from '../../../contexts/group';
import { useSettings } from '../../../contexts/settings';
import Spinner from '../../spinner';
import { useAuth } from '../../../contexts/auth';

function EditRolesAndPermissions() {
  const formRef = useRef();
  const history = useHistory();
  const { verifyPerms } = useAuth();
  const { addGroup, getOneGroup, updateGroup, departments } = useGroups();
  const { settings } = useSettings();
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState('initial');

  const rolesQuery = [
    {
      label: 'Pedidos', index: 0, role: 'Pedidos', write: true, read: true, delete: true,
    },
    {
      label: 'Clientes', index: 1, role: 'Clientes', write: true, read: true, delete: true,
    },
    {
      label: 'Orga. > Colab.', role: 'Orga. > Colab.', index: 2, write: true, read: true, delete: true,
    },
    {
      label: 'Orga. > Funç.', role: 'Orga. > Funç.', index: 3, write: true, read: true, delete: true,
    },
    {
      label: 'Catálogo', role: 'Catálogo', index: 4, write: true, read: true, delete: true,
    },
    {
      label: 'Financeiro', index: 5, write: true, role: 'Financeiro', read: true, delete: true,
    },
    {
      label: 'Marketing', index: 6, role: 'Marketing', write: true, read: true, delete: true,
    },
    {
      label: 'Conf. > Lojas', index: 7, role: 'Conf. > Lojas', write: true, read: true, delete: true,
    },
    {
      label: 'Conf. Website', index: 8, role: 'Conf. Website', write: true, read: true, delete: true,
    },
  ];
  const [table, setTable] = useState(rolesQuery);

  const role = {
    0: 'Pedidos',
    1: 'Clientes',
    2: 'Orga. > Colab.',
    3: 'Orga. > Funç.',
    4: 'Catálogo',
    5: 'Financeiro',
    6: 'Marketing',
    7: 'Conf. > Lojas',
    8: 'Conf. Website',
  };

  const renderItem = (roles) => (
    <RolesAndPermissionsData roleAndPermission={roles} />
  );

  useEffect(() => {
    async function getGroup() {
      const response = await getOneGroup(params.id);

      const grouprules = response.grouprules.map((item, index) => {
        const permissions = {};

        permissions.write = item.permissions.includes('write');
        permissions.read = item.permissions.includes('read');
        permissions.delete = item.permissions.includes('delete');
        return { role: item.role, index, permissions };
      });

      const newTable = rolesQuery.map((rule) => {
        const defaultRules = {
          write: false,
          read: false,
          delete: false,
        };
        const findRole = grouprules.find((group) => group.role === rule.role);
        const replaceFindRole = findRole?.permissions ? findRole.permissions : defaultRules;

        return { ...rule, ...replaceFindRole };
      });

      setTable(newTable);

      setInitialData({ name: response.name, department_id: response.department.id, is_default: !!response?.is_default });
    }

    if (params?.id !== 'create') {
      getGroup();
    } else {
      setInitialData({ department: departments?.length && departments[0].id });
    }
  }, [params, getOneGroup, departments, rolesQuery]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const data = formRef.current.getData();

      const grouprules = data.grouprules.map((item, index) => {
        const permissions = [];

        if (item.permissions.write) { permissions.push('write'); }
        if (item.permissions.read) { permissions.push('read'); }
        if (item.permissions.delete) { permissions.push('delete'); }

        return { role: role[index], permissions };
      });

      if (params.id === 'create') {
        await addGroup({ store_id: settings.store.id, name: data.name, department_id: Number(data.department_id), grouprules });
      } else {
        await updateGroup(params.id, { store_id: settings.store.id, name: data.name, department_id: Number(data.department_id), grouprules });
      }
      setLoading(false);
      toast.success(`O cargo ${data.name} foi ${params.id === 'create' ? 'criada' : 'atualizada'}!`);
      history.push('/roles-and-permissions');
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível criar/atualizar um novo cargo agora.');
    }
  }, [addGroup, params, history, role, settings, updateGroup]);

  const renderDepartment = ({ item, handleValue }) => <MenuItem value={item.id} key={item.name} onClick={handleValue}>{item.name}</MenuItem>;

  return (initialData !== 'initial' && departments?.length) ? (
    <>
      <Breadcrumb
        title="Gerenciar Cargos e Permissões"
        text="Organização"
        breadcrumb="Cargos e Permissões"
        link="/roles-and-permissions"
        current="Cargos e Permissões"
      />

      <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData} style={{ marginBottom: '260px' }}>
        <ContentWrapper align="top">
          <Grid container justify="flex-start" direction="row" style={{ marginBottom: '32px' }}>
            <IconButton
              size="small"
              style={{ color: '#A3A3A3' }}
              onClick={() => history.push('/roles-and-permissions')}
            >
              <ArrowBackIcon />
            </IconButton>
            <PageTitle>{initialData?.name || 'Cargos e Permissões'}</PageTitle>
          </Grid>
        </ContentWrapper>
        <TitleCard>Dados do cargo</TitleCard>
        <StyledPaper>
          <Grid container direction="row" spacing={3} xs={12}>
            <Grid item>
              <Select
                name="department_id"
                label="Departamento"
                disabled={initialData?.is_default}
                placeholder="nome do departamento"
                options={departments}
                InputLabelProps={{ shrink: true }}
                style={{ width: '375px', margin: '5px 0px 1px 0px' }}
              >
                {renderDepartment}
              </Select>
            </Grid>
            <Grid item>
              <TextField
                name="name"
                label="Cargo"
                placeholder="nome do cargo"
                InputLabelProps={{ shrink: true }}
                style={{ width: '375px', margin: '5px 0px 1px 0px' }}
              />
            </Grid>

          </Grid>

        </StyledPaper>
        <TitleCard>Lista de Permissões</TitleCard>
        <StyledPaper style={{ marginBottom: '20px' }}>
          {rolesQuery?.length && (
          <TableMaterial
            headers={EditRolesAndPermissionsSchema}
            renderItem={renderItem}
            listQuery={table}
            noPagination
          />
      )}

        </StyledPaper>
        <SaveBar
          disabled={verifyPerms({ role: 'Orga. > Funç.', access: 'write' })}
          buttonPrimary="salvar alterações"
          isLoading={isLoading}
          buttonSecondary="sair sem salvar"
          type="submit"
          history="/roles-and-permissions"
        />
      </Form>

    </>
  ) : (
    <Spinner />
  );
}

export default EditRolesAndPermissions;
