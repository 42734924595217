import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, IconButton, TableCell } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Modal from '../../../modal';
import Zip from './zip';

import { StyledTooltip } from '../../../tooltip';
import useStyles from '../styles';

import { useSettings } from '../../../../contexts/settings';

import DeleteLocation from './delete';
import { useAuth } from '../../../../contexts/auth';

export default function DataZip({ data }) {
  const styles = useStyles();
  const formRef = useRef();
  const { id: branch_id } = useParams();

  const { verifyPerms } = useAuth();
  const { updateLocation } = useSettings();

  const dataZip = { ...data, service_cost: data?.service_cost };

  const [modalZip, setModalZip] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [zipWeekDays, setZipWeekDays] = useState(data?.week_availibility);

  const updateZip = useCallback(async () => {
    setLoading(true);
    const dataZipForm = formRef.current.getData();

    try {
      const shipping_time = Number(dataZipForm.zip.shipping_time);
      await updateLocation({ id: data?.id, branch_id, body: { ...dataZipForm.zip, shipping_time, week_availibility: zipWeekDays } });

      setModalZip(!modalZip);

      setLoading(false);
    } catch (err) {
      toast.error('Ocorreu um erro');
      setLoading(false);
    }

    toast.success(`O CEP ${dataZipForm.zip.zip_origin} foi atualizado!`);
  }, [data, branch_id, updateLocation, zipWeekDays, modalZip]);

  const days = useMemo(() => {
    const formatDays = {
      monday: 'SEG ',
      tuesday: 'TER ',
      wednesday: 'QUA ',
      thursday: 'QUI ',
      friday: 'SEX ',
      saturday: 'SÁB ',
      sunday: 'DOM ',
    };

    const mapped = data.week_availibility.map((item) => formatDays[item]);

    return mapped;
  }, [data]);

  const modality = useMemo(() => {
    const formatModality = {
      ground: 'Terrestre',
      air: 'Aéreo',
      sea: 'Marítimo',
    };

    const formtedModality = formatModality[data.service_type];

    return formtedModality;
  }, [data]);

  return (
    <>
      <TableCell>{data?.zip_from}</TableCell>
      <TableCell>{data?.zip_to}</TableCell>
      <TableCell>{Number(data?.service_cost).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell>{data?.service_name}</TableCell>
      <TableCell>{days}</TableCell>
      <TableCell>{data?.shipping_time || '0'} Dias</TableCell>
      <TableCell>{modality}</TableCell>
      <TableCell>{data?.ships_low_temperature ? 'Sim' : 'Não'}</TableCell>

      <TableCell style={{ width: '120px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar CEP" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'write' })}
                className={styles.actionButton}
                onClick={() => setModalZip(!modalZip)}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Remover CEP" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => setDeleteModal(true)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>
      <Form ref={formRef} initialData={{ zip: dataZip }}>

        <Modal
          isVisible={modalZip}
          isLoading={isLoading}
          title="Atualizar CEP"
          toggle={() => setModalZip(!modalZip)}
          buttonPrimary="atualizar"
          buttonSecondary="cancelar"
          onClick={updateZip}
        >

          <Zip weekDays={zipWeekDays} setWeekDays={setZipWeekDays} />
        </Modal>
      </Form>

      <DeleteLocation openModal={deleteModal} setModal={setDeleteModal} id={data.id} location={data.zip_origin} />

    </>
  );
}
