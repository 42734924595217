import React, { useEffect, useState } from 'react';

import { Scope } from '@unform/core';

import CheckBox from '../form/checkbox';
import DatePicker from '../form/date-picker';
import TitleCard from '../title/title-card';

import { StyledPaper, useStyles } from '../styles';

export default function Visibility({ product }) {
  const [callcenter, setCallcenter] = useState(false);
  const [ecommerce, setEcommerce] = useState(false);
  const [soldOut, setSoldOut] = useState(false);

  useEffect(() => {
    if (product) {
      setCallcenter(product?.callcenter?.is_available);
      setEcommerce(product?.ecommerce?.is_available);
      setSoldOut(product?.sold_out);
    }
  }, [product]);

  const styles = useStyles();

  return (
    <>
      <TitleCard>Visibilidade por Canal</TitleCard>

      <StyledPaper style={{ paddingTop: '20px' }}>
        <Scope path="ecommerce">
          <div className={styles.checkBox}>
            <CheckBox
              name="availability"
              label="E-commerce, com visibilidade a partir de:"
              onClick={() => setEcommerce(!ecommerce)}
              checked={ecommerce}
            />
          </div>

          <DatePicker name="from" date={product.ecommerce.from} label="Data" />
        </Scope>

        <div className={styles.containerCheckboxBottom}>
          <Scope path="callcenter">
            <div className={styles.checkBox}>
              <CheckBox
                name="availability"
                label="Prescrição, com visibilidade a partir de:"
                onClick={() => setCallcenter(!callcenter)}
                checked={callcenter}
              />
            </div>

            <DatePicker name="from" date={product.callcenter.from} label="Data" />
          </Scope>

        </div>
        <div className={styles.containerCheckboxBottom}>
          <CheckBox
            name="sold_out"
            label="Esgotado no e-commerce"
            onClick={() => setSoldOut(!soldOut)}
            checked={soldOut}
          />
        </div>
      </StyledPaper>
    </>
  );
}
