import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-toastify';

import TitleCard from '../../title/title-card';
import TableMaterial from '../../table';
import Modal from '../../modal';

import { AddButton, StyledPaper, useStyles } from '../../styles';

import ProductsListData from './products-list-data';
import NewProducts from './new-products';

import actions from '../../../actions';
import { useAuth } from '../../../contexts/auth';

export default function ProductsList({ tableHeaders, products = [], categoryId, refetch, subProducts }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();

  const [modal, setModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productValue, setProductValue] = useState();

  const openModal = () => {
    setModal(true);
    setProductList([]);
    setProductValue();
  };

  const closeModal = () => {
    setModal(false);
  };

  async function deleteProduct(id) {
    const resp = await actions.category.putCategory(categoryId, { delete_products: [id] });

    await refetch();

    toast.success('Produto removido com sucesso.');

    return resp;
  }

  const renderProductsList = (item) => <ProductsListData product={item} deleteProduct={deleteProduct} />;

  async function handleSumit() {
    const productsIds = products.map(item => item.id);
    const arrProducts = [
      ...productsIds,
      productList.id,
    ];

    const resp = await actions.category.putCategory(categoryId, { replace_products: arrProducts });

    refetch();

    closeModal();

    return resp;
  }

  return (
    <>
      <TitleCard isHelper tooltip="Insira nesta lista produtos que não pertecem a nenhuma das subcategorias criadas." direction="bottom">
        Lista de produtos Sem Subcategoria
      </TitleCard>

      <StyledPaper>
        <Grid container>
          <Grid item xs>
            <TableMaterial
              headers={tableHeaders}
              listQuery={products}
              maxHeight="380px"
              noPagination
              renderItem={renderProductsList}
            />
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            className={verifyPerms({ role: 'Catálogo', access: 'write' }) ? 'disabled-events' : styles.gridPaperBottom}
          >
            <Grid item onClick={openModal} style={{ cursor: 'pointer' }}>
              <IconButton type="button" className={styles.iconButton}>
                <AddCircleOutlineIcon fontSize="small" color={verifyPerms({ role: 'Catálogo', access: 'write' }) ? 'disabled' : 'primary'} />
              </IconButton>

              <AddButton disabled={verifyPerms({ role: 'Catálogo', access: 'write' })} type="button">Adicionar Produto</AddButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>

      <Modal
        isVisible={modal}
        title="Adicionar produto a Subcategoria"
        size="xs"
        toggle={closeModal}
        buttonPrimary="adicionar"
        buttonSecondary="cancelar"
        onSubmit={handleSumit}
        isDisabled={!productList || !productValue}
      >
        <NewProducts
          subProducts={subProducts}
          roducts={productList}
          setProducts={setProductList}
          values={productValue}
          setValues={setProductValue}
        />
      </Modal>
    </>
  );
}
