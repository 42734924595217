import React, { useCallback, useRef, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Form } from '@unform/web';

import { useHistory, useLocation } from 'react-router-dom';
import ContentWrapper from '../content-wrapper';
import SearchField from '../form/search-field';
import Select from '../form/select';
import TableMaterial from '../table';

import CustomerSchema from './schema';
import CustomerData from './data';

import { Container, PageTitle, useStyles } from '../styles';

import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import { useCustomer } from '../../contexts/customer';
import Spinner from '../spinner';

export default function CustomerList() {
  const formRef = useRef(null);
  const history = useHistory();
  const { search } = useLocation();

  const [parseSearch, setParseSearch] = useState('');

  const [searchParam, setSearchParam] = useState('name');
  const renderItem = (customer) => <CustomerData customer={customer} />;
  const styles = useStyles();

  const { customers, isLoading, fetchCustomers } = useCustomer();

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const getCategory = async () => {
      await fetchCustomers(allParams);
    };

    getCategory();
  }, [fetchCustomers, search]);

  useEffect(() => {
    function getValue() {
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      formRef.current.setFieldValue('search', allParams?.email || allParams?.name || '');
      setParseSearch(allParams?.email || allParams?.name || '');
    }

    getValue();
  }, [search]);

  const handleParams = useCallback((param, data) => {
    const params = {};
    params[param] = data;

    let newParams = {};

    newParams = { ...params };

    if (param === 'name' || param === 'email') {
      newParams.p = 1;
    }

    return history.push({
      pathname: '/customers',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [history]);

  const options = [
    { id: 'name', type: 'Nome' },
    { id: 'email', type: 'E-mail' },
  ];

  const clearSearch = useCallback((id) => {
    formRef.current.reset();
    setParseSearch('');
    setSearchParam(id);
  }, []);

  const renderStatus = ({ item }) => (
    <MenuItem key={item.id} value={item.id} onClick={() => clearSearch(item.id)}>
      {item.type}
    </MenuItem>
  );
  return (
    <>
      <Breadcrumb title="Detalhes do Cliente" text="Clientes" />

      <ContentWrapper align="top">
        <Grid container direction="row" className={styles.gridRow}>
          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>Lista de Clientes</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <Form ref={formRef} initialData={{ status: 'name' }} onSubmit={(formData) => handleParams(searchParam, formData.search)}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchType}>
                  <Select
                    name="status"
                    label="Campo para busca"
                    options={options}
                    InputLabelProps={{ shrink: true }}
                  >
                    {renderStatus}
                  </Select>
                </div>

                <SearchField
                  name="search"
                  handleParams={handleParams}
                  search={search}
                  label="buscar cliente"
                  placeholder="buscar cliente"
                  parseSearch={parseSearch}
                  clear={() => handleParams('name', '')}
                />
              </div>
            </Form>
          </Grid>
        </Grid>

        {isLoading ? <Spinner /> : (
          <Container style={{ margin: '16px 0 100px !important' }}>
            <TableMaterial
              handleParams={handleParams}
              headers={CustomerSchema}
              renderItem={renderItem}
              listQuery={customers?.items}
              pagination={customers?.pagination}
            />
          </Container>
        )}

      </ContentWrapper>
    </>
  );
}
