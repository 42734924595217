import React from 'react';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { CircularProgress, DialogTitle } from '@material-ui/core';
import useModalStyles from './styles';

import { SubmitButton } from '../styles';

export default function Modal({
  buttonPrimary,
  buttonSecondary,
  buttonRemove,
  children,
  isVisible,
  modalActions,
  modalStyle,
  onSubmit,
  title,
  toggle,
  isDisabled,
  isDisabledDelete,
  isDelete,
  secondaryFunction,
  width,
  isLoading,
  ...rest
}) {
  const styles = useModalStyles();

  const styleModal = () => {
    if (!modalStyle || modalStyle === 1) {
      return styles.modal;
    }

    if (modalStyle === 3) {
      return styles.modal3;
    }

    return styles.modal2;
  };

  const styleActions = () => {
    if (!modalActions || modalActions === 1) {
      return styles.modalActions;
    }

    return styles.modalActions2;
  };

  return (
    <Dialog PaperProps={{ className: styleModal(), style: width }} open={isVisible} onClose={toggle} maxWidth="false">
      {title
        && (
          <DialogTitle className={styles.titleContainer}>
            <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                {title}
              </Grid>
              <Grid item>
                <IconButton onClick={toggle} size="small">
                  <CloseIcon color="disabled" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
        )}

      <DialogContent>
        {children}
      </DialogContent>

      {(buttonRemove || buttonSecondary || buttonPrimary) && (
      <DialogActions className={styleActions()}>
        {buttonRemove && (
        <SubmitButton onClick={secondaryFunction || onSubmit} style={{ color: '#D16262', textDecoration: 'underline' }} disabled={isDisabledDelete}>
          {buttonRemove}
        </SubmitButton>
        )}

        {buttonSecondary && (
        <SubmitButton onClick={isDelete ? onSubmit : toggle} variant="outlined" color="primary">
          {buttonSecondary}
        </SubmitButton>
        )}

        {buttonPrimary && (
        <SubmitButton onClick={isDelete ? toggle : onSubmit} variant="contained" color="primary" {...rest} disabled={isDisabled}>
          {isLoading ? <CircularProgress size={15} style={{ color: '#fff' }} /> : buttonPrimary}
        </SubmitButton>
        )}
      </DialogActions>
      )}

    </Dialog>
  );
}
