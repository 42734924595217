import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';
import { useStyles } from './styles';

export default function TextFieldMulti({
  name, include, textStyle, theme, preView, ...rest
}) {
  const styles = useStyles();
  const materialInputRef = useRef(null);
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const multi = () => {
    if (!textStyle || textStyle === 1) {
      return styles.textFieldMulti;
    }
    return styles.textFieldMulti2;
  };

  function getShrink(data) {
    if (data) {
      return {
        shrink: true,
      };
    }
    return null;
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <TextField
      variant="outlined"
      className={multi()}
      inputRef={inputRef}
      InputLabelProps={getShrink(defaultValue)}
      ref={materialInputRef}
      defaultValue={defaultValue}
      multiline
      error={error}
      {...rest}
    />
  );
}
