import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AutocompleteField from '../../form/autocomplete-field';

import Placeholder from '../../../assets/placeholder.svg';

import { Container } from '../../styles';
import useStyles from './styles';

import actions from '../../../actions';

export default function NewProducts({
  values,
  setValues,
  products,
  setProducts,
  subProducts,
  category,
}) {
  const styles = useStyles();

  const [options, setOptions] = useState([]);

  function getOptionsLabel(option) {
    return option?.title;
  }

  const getProducts = async () => {
    const arr = [];
    const response = await actions.products.getSearchProducts(values);

    if (category) return response.items;

    await response.items?.map(product => {
      const isSubProduct = subProducts.includes(product.id);

      if (isSubProduct) return null;

      arr.push(product);
      return null;
    });

    return arr;
  };

  function renderOptions(option) {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          {option?.image
            ? (
              <img
                className={styles.productImg}
                src={option.image && option.image.image_base}
                alt={option.media && option.media[0]?.title}
              />
            )
            : (
              <img src={Placeholder} className={styles.productImg} alt="placeholder" />
            )}
        </Grid>

        <Grid item xs>
          <span style={{ fontSize: '14px' }}>{option?.title}</span>

          <Typography variant="body2" color="textSecondary">
            {`a partir de R$ ${option?.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container>
      <Grid container direction="row" style={{ height: '102px' }}>
        <Grid item className={styles.gridProductsModalFields}>
          <AutocompleteField
            name="products"
            placeholder="Selecione o Produto"
            label="Selecione o Produto"
            renderLabel={getOptionsLabel}
            dataOption={getProducts()}
            textValue={values}
            onChange={e => setValues(e.target.value)}
            setOptions={setOptions}
            options={options}
            selected={products}
            setSelected={setProducts}
            renderOptions={renderOptions}
          />

          {/* <Grid item>
            <Grid item xs={12}>
              <Select name="variant" placeholder="Selecione a variante principal" label="Selecione a variante principal" />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Container>
  );
}
