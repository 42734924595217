import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { IconButton } from '@material-ui/core';
import { useStyles } from '../../styles';

import avatar from '../../../assets/avatar.png';
import formatDocument from '../../../utils/formatDocument';
import { useColaborators } from '../../../contexts/contributors';
import { useAuth } from '../../../contexts/auth';
import DeleteModal from '../../deleteModal';
import { StyledTooltip } from '../../tooltip';

function PreContributorsData({ colaborator }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();
  const { deletePreAgent } = useColaborators();
  const [modal, setModal] = useState(false);

  const handleDeletePreAgent = useCallback(async () => {
    try {
      await deletePreAgent(colaborator.document);

      toast.success('Colaborador deletado com sucesso!');
      setModal(false);
    } catch (err) {
      setModal(false);
      toast.error('Não foi possível deletar este colaborador');
    }
  }, [colaborator, deletePreAgent]);
  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          {colaborator.picture
            ? (
              <img src={colaborator?.picture} alt={colaborator.name || 'image'} style={{ borderRadius: '50%' }} className={styles.tableImg} />
            )
            : (
              <img src={avatar} className={styles.tableImg} style={{ borderRadius: '50%' }} alt="placeholder" />
            )}

          {colaborator?.name || 'Não definido'}
        </Grid>
      </TableCell>

      <TableCell>{formatDocument(colaborator?.document)}</TableCell>

      <TableCell>{`${format(colaborator.expires_at, "'Dia' dd 'de' MMMM 'de' yyyy", { locale: pt })}`}</TableCell>

      <TableCell>{colaborator.is_enabled ? 'Ativo' : 'Inativo'}</TableCell>

      <TableCell>
        <Grid container justify="flex-end">
          <Grid item>
            <StyledTooltip title="Editar Colaborador" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Orga. > Colab.', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/add-contributor/${colaborator.document}?is_pre=true`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title="Remover Colaborador" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Orga. > Colab.', access: 'delete' })}
                className={styles.actionButton}
                component={Link}
                onClick={() => setModal(!modal)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      <DeleteModal type="colaborador" prefix="o" title={colaborator?.name || colaborator?.document} openModal={modal} setModal={setModal} deleteFunction={handleDeletePreAgent} />

    </>
  );
}

export default PreContributorsData;
