import { map } from 'lodash';

import actions from '../actions';

export const reorderList = async ({ list, start, end, editData, id }) => {
  const result = Array.from(list);
  const [removed] = result.splice(start, 1);
  result.splice(end, 0, removed);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < result.length; i++) {
    result[i].index = i + 1;
  }

  if (id && editData) {
    const reorder = map(result, 'id');

    const data = {
      append: false,
      children: reorder,
    };

    await actions.category.putReorderSubcategories(id, data);

    return result;
  }

  return result;
};
