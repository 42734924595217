const getDigits = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = '0'.repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, '$1');

const addDecimal = (number, separator) => {
  const centsStartingPosition = number.length - 2;
  const currency = removeLeadingZeros(
    number.substring(0, centsStartingPosition),
  );
  const cents = number.substring(centsStartingPosition);
  return currency + separator + cents;
};

export const setCurrency = (value, separator) => {
  const digits = getDigits(value);
  const digitsWithPadding = padDigits(digits);
  return addDecimal(digitsWithPadding, separator);
};

export function checkPriceIsLower(price, priceToCompare) {
  const isPriceInvalid = !price || price === '0.00';
  const isPriceToCompareInvalid = !priceToCompare || priceToCompare === '0.00';

  if (isPriceInvalid || isPriceToCompareInvalid) return null;

  const priceAsNumber = Number(setCurrency(price, '.'));
  const priceToCompareAsNumber = Number(setCurrency(priceToCompare, '.'));

  return priceAsNumber < priceToCompareAsNumber;
}
