import React, { useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Scope } from '@unform/core';
import RadioButton from '../form/form-radio-button';
import SubtitleCard from '../title/subtitle-card';
import TextField from '../form/text-field';
import TitleCard from '../title/title-card';

import { StyledPaper, useStyles } from '../styles';

export default function CustomerProfile({ gender, setGender }) {
  const styles = useStyles();
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();

  return (
    <>
      <TitleCard>Perfil do Consumidor</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard>Restrições de Idade:</SubtitleCard>
        </Grid>

        <Grid container direction="row" spacing={3} className={styles.gridRowInput2}>
          <Scope path="recommended_age">
            <Grid item xs={6}>
              <TextField
                type="number"
                name="min"
                label="Idade Mínima"
                InputProps={{
                  endAdornment: minAge && <InputAdornment className={styles.adornmentEnd}><span>anos</span></InputAdornment>,
                }}
                value={minAge}
                onChange={(e) => setMinAge(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                name="max"
                label="Idade Máxima"
                InputProps={{
                  endAdornment: maxAge && <InputAdornment className={styles.adornmentEnd}><span>anos</span></InputAdornment>,
                }}
                value={maxAge}
                onChange={(e) => setMaxAge(e.target.value)}
              />
            </Grid>
          </Scope>
        </Grid>

        <Grid container className={styles.gridCustomerProfileContainer}>
          <SubtitleCard>Este produto é indicado para:</SubtitleCard>

          <Grid item xs={12}>
            <FormControl required className={styles.gridCustomerProfileFormControl}>
              <RadioGroup value={gender}>
                <RadioButton
                  className={styles.gridCustomerProfileRadioButton}
                  value="a"
                  control={<Radio color="primary" />}
                  label="Ambos os Sexos"
                  onClick={() => setGender('a')}
                />

                <RadioButton
                  className={styles.gridCustomerProfileRadioButton}
                  value="m"
                  control={<Radio color="primary" />}
                  label="Homens"
                  onClick={() => setGender('m')}
                />

                <RadioButton
                  className={styles.gridCustomerProfileRadioButton}
                  value="f"
                  control={<Radio color="primary" />}
                  label="Mulheres"
                  onClick={() => setGender('f')}
                />

                <RadioButton
                  className={styles.gridCustomerProfileRadioButton}
                  value="p"
                  control={<Radio color="primary" />}
                  label="Pets"
                  onClick={() => setGender('p')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
