import React from 'react';
import { Link } from 'react-router-dom';

import { Symbol, useStyles } from './styles';

export default function Breadcrumb({
  current, text, breadcrumb, link, title,
}) {
  const styles = useStyles();

  return (
    <div className={styles.breadcrumb}>
      <span className={styles.main}>{title}</span>
      <Symbol>・</Symbol>
      <span className={styles.text2}>{text}</span>

      {breadcrumb && (

        <>
          <Symbol symbol2>&gt;</Symbol>

          {link ? (
            <Link to={link} className={styles.text}>
              {breadcrumb}
            </Link>
          ) : <span className={styles.text2}>{breadcrumb}</span>}

        </>
      )}

      {current && (
        <>
          <Symbol symbol2>&gt;</Symbol>
          <span className={styles.text2}>{current}</span>
        </>
      )}
    </div>
  );
}
