/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';

// Libs
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useField } from '@unform/core';

// Styles
import { InputAdornment } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { MVAutocompleteContainer } from '../styles';

function MultipleValuesFreeSolo({
  id,
  name,
  label,
  defaultValue,
  formRef,
  ...rest
}) {
  const { fieldName, registerField } = useField(name);
  const [intoValues, setIntoValues] = useState(defaultValue?.length ? defaultValue : []);
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      ref.current.value = intoValues;
    }
    // formRef.current.setFieldValue(name, intoValues);
  }, [name, intoValues]);

  useEffect(() => {
    setIntoValues(defaultValue);
  }, [defaultValue]);

  const handleValue = (e) => {
    setIntoValues(e);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleKeyDown = event => {
    switch (event.key) {
      case ',': {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          setIntoValues([...intoValues, event.target.value]);
        }
        break;
      }
      default:
    }
  };

  return (
    <MVAutocompleteContainer className="MVAutocompleteContainer">
      <Autocomplete
        multiple
        id={id}
        ref={ref}
        options={[]}
        defaultValue={intoValues?.length > 0 ? intoValues : []}
        filterSelectedOptions
        size="small"
        value={intoValues?.length > 0 && intoValues}
        onChange={(e, newValue) => handleValue(newValue)}
        clearText="Limpar"
        closeText="Fechar"
        freeSolo
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
        renderInput={params => (
          <TextField
            {...rest}
            {...params}
            variant="outlined"
            label={label}
            onKeyDown={handleKeyDown}
            name={name}
            inputRef={ref}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </MVAutocompleteContainer>
  );
}

export default MultipleValuesFreeSolo;
