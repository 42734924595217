import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';

import { Link } from 'react-router-dom';

export const useStyles = makeStyles((theme) => ({
  adornmentStart: {
    marginRight: '-8px',
    marginTop: '16px',

    '& span': {
      color: '#A3A3A3',
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: '0.03em',
    },
  },

  adornmentEnd: {
    marginRight: '0',
    marginTop: '16px',

    '& span': {
      color: '#A3A3A3',
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: '0.03em',
    },
  },

  bottomLine: {
    borderBottom: '1.5px solid #E1E1E1',
    marginBottom: '-56px',
    width: '100%',
  },

  buttonFilter: {
    border: '1.5px solid',
    height: '36px',
    marginLeft: '16px',
    minWidth: '56px',
    width: '56px',
  },

  buttonPrimary: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '204px',
  },

  buttonSecondary: {
    color: '#A3A3A3',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
  },

  checkBox: {
    '& .MuiCheckbox-root': {
      marginRight: '8px',
      padding: '0',
    },

    '& .MuiFormControlLabel-root': {
      marginLeft: '-2px',
      marginRight: '0',
      marginTop: '0',
    },
  },

  containerTableBottom: {
    margin: '16px 0',
  },

  containerCheckboxBottom: {
    marginBottom: '8px',
    marginTop: '30px',
  },

  divFlexStart: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  divFlexEnd: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  divSearchField: {
    marginBottom: 0,
    marginRight: 0,
    marginTop: -10,
  },

  divFilterOrder: {
    width: 230,
    marginRight: 25,
    marginTop: -10,
  },

  divSearchType: {
    marginRight: '16px',
    width: '160px',
  },

  formControl: {
    paddingTop: '10px',
  },

  formControlGridColumn: {
    marginBottom: '-12px',
    marginTop: '8px',
  },

  filter: {
    margin: '16px 0 26px 0',
    borderTop: '1px solid #E1E1E1',
    borderBottom: '1px solid #E1E1E1',
    padding: '12px 0 32px 0',
  },

  filterButton: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    height: '36px',
    lineHeight: '20px',
    marginLeft: '16px',
    width: '226px',
  },

  gridButton: {
    display: 'flex',
    alignItems: 'center',
  },

  gridButtonContainer: {
    height: '100%',
    marginTop: '16px',
  },

  gridCategoriesInputCloseIcon: {
    paddingLeft: '12px',
    paddingTop: '18px',
    cursor: 'pointer',
  },

  gridCategoriesInputCloseIcon2: {
    paddingLeft: '12px',
    paddingTop: '24px',
    cursor: 'pointer',
  },

  gridCategoriesInputContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '0',
    marginTop: '0',
  },

  gridCategoriesInputContainer2: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '2px',
    marginTop: '4px',
  },

  gridCategoriesInputIcon: {
    marginTop: '12px',
  },

  gridCategoriesIconAdd: {
    cursor: 'pointer',
    marginLeft: '8px',
    marginTop: '6px',
  },

  gridCategoriesInputSelect: {
    paddingLeft: '8px',
  },

  gridColumn: {
    marginTop: '34px',
  },

  gridCustomerProfileContainer: {
    marginBottom: '-4px',
    marginTop: '30px',
  },

  gridCustomerProfileFormControl: {
    paddingTop: '8px',
  },

  gridCustomerProfileRadioButton: {
    marginBottom: '-8px',
  },

  gridImage: {
    marginBottom: '-7px',
    marginLeft: '0',
    marginTop: '16px',
  },

  gridPaperBottom: {
    marginBottom: '-14px',
    marginTop: '8px',
  },

  gridPaperTop: {
    marginTop: '-6px',
  },

  gridPricingCheckBox: {
    marginTop: '8px',
  },

  gridPricingTextField: {
    marginTop: '20px',
  },

  gridPricingTextField2: {
    marginTop: '10px',
  },

  gridPricingTextField3: {
    marginBottom: '6px',
    marginTop: '20px',
  },

  gridPricingSubtitle: {
    marginTop: '24px',
  },

  gridRow: {
    marginLeft: '-24px',
    marginTop: '-12px',
    minHeight: '56px',
    maxHeight: '56px',
    paddingRight: '24px',
    width: 'calc(100% + 48px)',
  },

  gridRowForm: {
    marginLeft: '-24px',
    marginTop: '-12px',
    paddingRight: '24px',
    position: 'absolute',
    width: 'calc(100vw - 245px)',
  },

  gridRowFormPadding: {
    padding: '16px 24px 80px',
  },

  gridRowFormPadding2: {
    padding: '16px 0px 80px',
  },

  gridRowInput: {
    marginTop: '8px',
  },

  gridRowInput2: {
    marginTop: '-12px',
  },

  gridRowInputList: {
    marginBottom: '16px',
    marginTop: '32px',
  },

  gridRowTextField: {
    marginTop: '-18px',
  },

  gridTitle: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '56px',
  },

  iconButton: {
    marginLeft: '-10px',
  },

  modalContainer: {
    marginBottom: '0',
    marginTop: '13px',
  },

  modalTextFieldRight: {
    height: '136px',
    maxHeight: '136px',
    marginBottom: '-10px',
    marginLeft: '24px',
    marginTop: '0',
    width: 'calc(100% - 160px)',
  },

  radioFormulationMargin: {
    marginRight: '48px',
  },

  save: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E1E1E1',
    bottom: 0,
    display: 'flex',
    left: 0,
    height: '80px',
    padding: '24px',
    position: 'fixed',
    width: '100%',
    zIndex: 999,
  },

  span: {
    alignItems: 'center',
    display: 'flex',
    color: '#424242',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
  },

  tableButton: {
    height: '32px',
    marginLeft: '4px',
    width: '32px',

    '&:hover': {
      backgroundColor: 'none !important',
    },
  },

  tableImg: {
    height: '30px',
    marginBottom: '-8px',
    marginRight: '8px',
    marginTop: '-8px',
    width: 'auto',
  },

  visilibitiesMargin: {
    marginBottom: '32px',
  },

  actionButton: {
    padding: '0 7px',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  removeFilters: {
    background: 'transparent',
    border: 'none',
    color: '#424242',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'underline',
  },
}));

export const Container = styled.div`
  margin: 24px 0 100px !important;
  overflow: hidden;
`;

export const StyledPaper = styled.div`
  padding: ${props => !props.noPadding && '24px'};
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 24px;
  background: #fff;
`;

export const SubmitButton = styled(Button)`
  padding: 5px 100px;
  border-radius: 4px;

  ${props => props.cancel && css`
    color: #1F577F;
    border: 1px solid #1F577F;
  `}
`;

export const AppContainer = styled.div`
  display: flex;

  .app-content {
    flex-grow: 1;
    padding: 24px;
    padding-bottom: 0;
  }
`;

export const IconContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LabelCheck = styled.p`
  color: #424242;
  font-size: 12px;
`;

export const PageTitle = styled.h3`
  color: #A3A3A3;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 27px;
  margin-left: 24px;
`;

export const ActionButton = styled(IconButton)`
  padding: 0 7px;

  .Mui-focusVisible, .MuiButtonBase-root:hover {
    background-color: none;
  }
`;

export const AddButton = styled.button`
  background: transparent;
  border: none;
  color: #424242;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  text-decoration: underline;

  &:disabled {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const LinkButton = styled(Link)`
  border: none;
  background: none;

  color: #22E0A1;
  text-decoration: underline;
  font-size: 12px;
`;

export const MVAutocompleteContainer = styled.div`
  margin: 14px 0 0 0;

  .MuiAutocomplete-root{
    border: 0px;
  }

  .MuiFormControl-fullWidth {
    /* max-width: 290px; */
    max-width: 100%;
  }

  .MuiInputBase-root {
    >fieldset{
      border: 1px solid #E1E1E1;
      border-radius: 10px;
    }

    > input {
      font-size: 12px;
    }
  }

  .MuiSvgIcon-root {
    color: #22E0A1;
    height: 20px;
    width: auto;
  }
`;

export const ButtonGhost = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;

  background: transparent;
  border: none;
  outline: none;

  margin-top: 18.5px;
  margin-left: 14px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TextButtonGhost = styled.p`
  font-size: 12px;
  line-height: 18px;

  text-decoration: underline;
  color: #424242;
`;
