import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .tampa {
    position: absolute;
    height: ${props => props.heightContainer};
    width: ${props => props.widthContainer};
    mix-blend-mode: multiply;
    fill: ${(props) => (props.isInverse ? props.theme.primary_color.light : props.theme.primary_color.dark)};

    path {
      fill: ${(props) => (props.isInverse ? props.theme.primary_color.light : props.theme.primary_color.dark)};
    }
  }

  .text {
    position: absolute;
    height: ${props => props.heightContainer};
    width: ${props => props.widthContainer};
    fill: ${(props) => (props.isInverse ? props.theme.primary_color.light : props.theme.primary_color.dark)};
    mix-blend-mode: ${props => props.textBlend};

    filter: ${(props) => (props.isInverse ? 'brightness(1)' : 'brightness(0.1)')};

    path {
      fill: ${(props) => (props.isInverse ? '#eee' : '#000')};
    }
  }

  .base {
    position: absolute;
    height: ${props => props.heightContainer};
    width: ${props => props.widthContainer};
  }

  .logo-container {
    display: flex;
    position: absolute;
    height: ${props => props.size.maxHeightLogo};
    max-width: ${props => props.size.maxWidthLogo};
    margin-left: ${props => props.size.logoLeft};
    margin-top: ${props => props.size.logoTop};
    border: 1px dotted #ddd;
    width: auto;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      mix-blend-mode: ${props => (props.isInverse ? 'screen' : 'multiply')}
    }
  }
`;
