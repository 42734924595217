import React, { useCallback, useState } from 'react';

import { Grid, CircularProgress, Button } from '@material-ui/core';

import { toast } from 'react-toastify';
import TitleCard from '../../title/title-card';

import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import TextField from '../../form/text-field';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import { useAuth } from '../../../contexts/auth';

export default function RDStation({ formRef, data }) {
  const { verifyPerms } = useAuth();
  const { updateRdStation, getRdStation } = useSettings();

  const [isLoading, setLoading] = useState(false);
  const [copy, setCopy] = useState(false);
  const [clientSecretChanged, setClientSecretChanged] = useState(false);
  const [clientIDChanged, setClientIDChanged] = useState(false);
  const [activedIntegration, setActivedIntegration] = useState(false);
  const [connectURL, setConnectURL] = useState('');

  const rdConnected = data?.connected;

  const handleCopyURL = useCallback(() => {
    const formData = formRef?.current?.getData();

    const { rdStation } = formData;

    navigator.clipboard.writeText(rdStation?.callback);

    setCopy(true);
  }, [formRef]);

  const handleSaveParams = useCallback(async () => {
    setClientIDChanged(false);
    setClientSecretChanged(false);
    setLoading(true);

    try {
      const { rdStation } = formRef.current.getData();

      await updateRdStation({
        client_id: rdStation.client_id,
        client_secret: rdStation.client_secret,
        callback: rdStation.callback,
      });
      const { connect_url, connected } = await getRdStation();

      setConnectURL(connect_url);
      setActivedIntegration(connected);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
    setLoading(false);
  }, [formRef, updateRdStation, getRdStation]);

  const onSubmit = useCallback(async () => {
    try {
      window.open(connectURL, '_blank');

      toast.success('Integração ativada com sucesso!');
    } catch (err) {
      toast.error('Ocorreu um erro ao ativar a integração com a RD Station. Tente novamente.');
    }
  }, [connectURL]);

  return (
    <>
      <TitleCard color="#A3A3A3" bold>RD Station</TitleCard>
      <StyledPaper>
        <GridContainer>
          <GridContainer container item width="auto" margin="12px" spacing={3}>
            <GridContainer container item lg={12} spacing={3}>
              <Grid item lg={12} md={12} xs={12} justify="center">
                <Text bold>Integração via APP</Text>
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                  <Grid item lg={9} md={12} xs={12}>
                    <TextField name="callback" placeholder="URL callback" label="URL callback" disabled />
                  </Grid>
                  <Grid item lg={3} md={12} xs={12} style={{ paddingBottom: 3 }}>
                    <Button
                      fullWidth
                      variant="outlined" color="primary"
                      onClick={() => handleCopyURL()}
                    >
                      {copy ? 'URL copiada' : 'copiar URL'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
                  <Grid item lg={4} md={12} xs={12}>
                    <TextField
                      name="client_id"
                      placeholder="Client Id"
                      label="Client Id"
                      onChange={() => setClientIDChanged(true)}
                    />
                  </Grid>
                  <Grid item lg={4} md={12} xs={12}>
                    <TextField
                      name="client_secret"
                      placeholder="Client secret"
                      label="Client secret"
                      onChange={() => setClientSecretChanged(true)}
                    />
                  </Grid>
                  <Grid item lg={4} md={12} xs={12} style={{ paddingBottom: 5 }}>
                    <Button
                      fullWidth
                      variant="outlined" color="primary"
                      onClick={() => handleSaveParams()}
                      disabled={
                        // verifyPerms({ role: 'Conf. Website', access: 'write' })
                        !clientIDChanged
                        && !clientSecretChanged
                      }
                    >
                      {isLoading ? (
                        <CircularProgress size={15} />
                      ) : (
                        'salvar alterações'
                      )}
                    </Button>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} xs={12} style={{ marginTop: 12 }}>
                  <Text>Assista ao <a href="http://bit.ly/video-tutorial-de-integracao-rd" rel="noopener noreferrer" target="_blank">tutorial de integração</a> com a Resultados Digitais e também baixe a <a href="http://bit.ly/documentacao-de-integracao-rd" rel="noopener noreferrer" target="_blank">documentação complementar</a>.</Text>
                </Grid>
              </Grid>

            </GridContainer>

            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary
                disabled={
                  verifyPerms({ role: 'Conf. Website', access: 'write' })
                  || !connectURL
                }
                variant="contained"
                onClick={onSubmit}
                color="primary"
              >
                {/* {activeIntegrationLink ? 'link copiado' : 'ativar integração'} */}
                {!activedIntegration || !rdConnected ? 'integração ativa' : 'ativar integração'}
              </ButtonPrimary>
            </GridContainer>

          </GridContainer>
        </GridContainer>
      </StyledPaper>
    </>
  );
}
