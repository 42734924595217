import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import Modal from '../../modal';
import TextFieldMulti from '../../form/text-fieldmulti';

export function CancelModal({
  onSubmit,
  onClose,
  isOpen,
  value,
  onChange,
}) {
  return (
    <Modal
      title="Revise a data"
      toggle={onClose}
      isVisible={isOpen}
      buttonPrimary="Cancelar pedido"
      buttonSecondary="Manter pedido"
      isDisabled={value.length < 10}
      onSubmit={onSubmit}
    >
      <div style={{
        padding: '20px 10px',
      }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">Deseja mesmo cancelar este pedido?</Typography>
            <Typography align="center" variant="h6">Esta ação é irreversível.</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextFieldMulti
              name="justification"
              label="Justificar cancelamento"
              multiline
              rows={4}
              value={value}
              onChange={(e) => {
                if (e.target.value.length <= 160) {
                  onChange(e.target.value);
                }
              }}
              helperText={value.length < 10 ? `Restam ${value.length}/10 caracteres para liberar` : `${value.length}/160 caracteres`}
              InputProps={{
                maxLength: 160,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}
