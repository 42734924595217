import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import {
  InputAdornment,
} from '@material-ui/core';

import TitleCard from '../../title/title-card';
import TextField from '../../form/text-field';
import TextFieldMulti from '../../form/text-fieldmulti';
import ImageField from '../../form/image-upload';

import useStyles from './styles';
import { StyledPaper } from '../../styles';
import Text from '../../text/text';
import { useAuth } from '../../../contexts/auth';
import { slugify } from '../../../utils/slugify';
import FormSwitch from '../../form/switch';

export default function CategoryBasicInfo({ setName, name, category }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();

  const [slug, setSlug] = useState(category.slug);
  const [fixLink, setFixLink] = useState(!!category?.is_fixed_slug);

  useEffect(() => {
    if (fixLink) return;
    setSlug(slugify(name || category.name));
  }, [name, category, fixLink]);

  function lengthLabel(data) {
    if (data) {
      return data.name ? data.name.length : 0;
    }
    return category && (category.name ? category.name.length : 0);
  }

  return (
    <>
      <TitleCard>Dados da Categoria</TitleCard>

      <StyledPaper>
        <Grid container direction="row">
          <Grid item className={verifyPerms({ role: 'Catálogo', access: 'write' }) && 'disabled-events'}>
            <ImageField
              name="logo"
              editRemove
              acceptImg={['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml']}
              previewImg={category?.logo}
            />
          </Grid>

          <Grid item className={styles.gridCategoryFields}>
            <Grid item xs={12} className={styles.gridCategoryTextField}>
              <TextField
                name="name"
                label="Nome da Categoria"
                placeholder="Nome da Categoria"
                inputProps={{ maxLength: 20 }}
                onChange={(e) => setName(e.target.value)}
                helperText={`${lengthLabel(name)}/20 Caracteres`}
                value={name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldMulti
                name="description"
                label="Descrição Curta"
                placeholder="Descrição Curta"
                rows={4}
              />
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justify="center"
              direction="row"
              spacing={3}
              xs={12}
            >

              <Grid item xs={9}>
                <TextField
                  name="slug"
                  label="Link da categoria"
                  placeholder="Link da categoria"
                  disabled={!!fixLink}
                  inputProps={{ maxLength: 50 }}
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  helperText={`${slug.length}/50 Caracteres`}
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="16px">meudominio/categoria/</Text></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ marginTop: 15 }}>
                <FormSwitch name="is_fixed_slug" label="Fixar link" onClick={() => setFixLink(!fixLink)} />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
