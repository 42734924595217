import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CheckBox from '@material-ui/core/Checkbox';
import { Form } from '@unform/web';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import TextField from '../../form/text-field';

import actions from '../../../actions';

import {
  CardLogin, Row, RowBottom, Span, Subtitle, Title, useStyles,
} from '../styles';

export default function LoginCPF() {
  const styles = useStyles();
  const history = useHistory();
  const buttonContinuar = useRef();

  const routeChange = async (data) => {
    if (buttonContinuar.current) {
      buttonContinuar.current.setAttribute('disabled', 'disabled');
    }

    if (data.cpf !== null && data.cpf !== '') {
      await actions.user.getRegistrationStatus(data.cpf).then((res) => {
        if (res.exists) {
          history.push(`/login/email/${data.cpf}`);
        } else {
          history.push(`/login/request/${data.cpf}`);
        }
      });
    }
  };

  const handleSubmit = (formData) => {
    routeChange(formData);
  };

  return (
    <div>
      <CardLogin cardCPF>
        <Title>
          Entrar no Laboratório Sistema
        </Title>

        <Subtitle className={styles.boldSubtitle}>
          farmacia.pedidopago.com.br
        </Subtitle>

        <Subtitle>
          Digite seu CPF para buscarmos na lista de colaboradores autorizados.
        </Subtitle>

        <Form onSubmit={handleSubmit}>
          <TextField
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: '30px' }}
          />

          <Span style={{ marginTop: 30 }}>
            <CheckBox color="primary" style={{ padding: '0' }} />
            <span className={styles.spanMargin} style={{ marginLeft: '10px' }}>Aceitar os</span>
            <span className={styles.decoration}>Termos Gerais</span>
            <span className={styles.spanMargin}>e</span>
            <span className={styles.decoration} style={{ marginRight: '5px' }}>Condições de Uso</span>
          </Span>

          <Row>
            <Button color="primary" variant="contained" type="submit" ref={buttonContinuar} className={styles.button}>
              Continuar
            </Button>
          </Row>
        </Form>

        <Row>
          <Button
            onClick={() => { history.push('/'); }}
            color="primary"
            variant="outlined"
            type="button"
            className={styles.buttonOutline}
          >
            Voltar
          </Button>
        </Row>
      </CardLogin>

      <RowBottom>
        <Button
          onClick={() => { history.push('/'); }}
          color="primary"
          type="button"
          className={styles.buttonText}
        >
          <KeyboardBackspaceIcon className={styles.iconKeyboard} />
          voltar para página principal
        </Button>
      </RowBottom>
    </div>
  );
}
