import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';

export const useStyles = makeStyles(() => ({
  breadcrumb: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderBottom: '1px solid #E1E1E1',
    display: 'flex',
    height: '36px',
    minHeight: '36px',
    paddingLeft: '24px',
    position: 'fixed',
    left: '240px',
    top: '64px',
    width: '100%',
    zIndex: '1299',
  },

  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '18px',
    width: '296px',

    '& svg': {
      color: '#FFFFFF',
      margin: '0 18px',
    },
  },

  logoContainer: {
    alignItems: 'center',
    display: 'flex',

    '& img': {
      maxWidth: '180px',
    },
  },

  main: {
    color: '#424242',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0.03em',
    lineHeight: '22px',
  },

  text: {
    color: '#424242 !important',
    cursor: 'pointer',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.03em',
    lineHeight: '24px',
    paddingTop: '1px',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  text2: {
    color: '#424242 !important',
    cursor: 'auto',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.03em',
    lineHeight: '24px',
    paddingTop: '1px',
  },
}));

export const AppBarContainer = styled(AppBar)`
  margin-left: 240px;
  z-index: 999;

  @media only screen and(max-width: 450px) {
    width: 100%;
  }
`;

export const ToolbarContainer = styled(Toolbar)`
  background: linear-gradient(90.17deg, #034AFD 0%, #22E0A1 100%);
  display: flex;

  p {
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.03em;
    margin-left: 6px;
    margin-top: 4px;
  }
`;

export const Symbol = styled.span`
  color: #424242;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.03em;
  line-height: 22px;
  margin: 0 4px;
  padding-top: 2px;

  ${props => props.header && css`
    color: #FFF;
    font-size: 22px;
    margin: 0;
    margin-left: 6px;
    padding-top: 3px;
  `}

  ${props => props.symbol2 && css`
    line-height: 24px;
    margin: 2px 4px 0;
    padding: 0;
  `}
`;
