import React, { useRef, useCallback, useState } from 'react';

// Libs
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';

// Icons
import SortIcon from '@material-ui/icons/Sort';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

// Components
import SearchField from '../../form/search-field';
import Spinner from '../../spinner';
import TableMaterial from '../../table';
import Select from '../../form/select';
import DatePicker from '../../form/date-picker';
import Card from '../components/Card';

// Schemas
import { transactionTableHeader } from './schema';

// Styles
import { useStyles, PageTitle } from '../../styles';
import {
  SecondHeaderContainer,
  FilterFormContainer,
  Divider,
  CleanForm,
  PageSubtitle,
  TitleContainer,
  CardContainer,
  TableHeaderContainer,
} from '../styles';

function Transactions() {
  /** CONSTS */
  const styles = useStyles();
  const advancedFilterFormRef = useRef();
  const formRef = useRef();
  const history = useHistory();
  const { search } = useLocation();

  const transactionStatus = [
    { value: 0, label: 'Aprovado' },
    { value: 1, label: 'Aguardando' },
    { value: 2, label: 'Recusado' },
    { value: 3, label: 'Estornado' },
    { value: 4, label: 'Chargeback' },
    { value: 5, label: 'Pendente' },
  ];

  /** STATES */
  const [parseSearch] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading] = useState(false);

  /** CALLBACKS */
  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...params, ascending: 1 };
    } else {
      newParams = { ...allParams, ...params, ascending: 0 };
    }

    if (param === 'q') {
      newParams.p = 1;
      const isEqual = allParams?.q === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    // return history.push({
    //   pathname: '/products',
    //   search: `?${new URLSearchParams(newParams)}`,
    // });
  }, [search]);

  return (
    <>
      <SecondHeaderContainer>
        <Form ref={formRef} onSubmit={(formData) => handleParams('q', formData.search)}>
          <FilterFormContainer>
            <Select
              name="receiver"
              label="Recebedor"
              options={['Farmacia Buenos Ayres', 'Farmacia A', 'Farmacia B']}
              value="Farmacia Buenos Ayres"
              InputLabelProps={{ shrink: true }}
              style={{ width: '100%', marginRight: 20 }}
            >
              {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
            </Select>
            <Select
              name="seachBy"
              label="Pesquisar por"
              options={['TID', 'A', 'B']}
              value="TID"
              InputLabelProps={{ shrink: true }}
              style={{ marginRight: 18 }}
            >
              {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
            </Select>
            <SearchField
              name="search"
              handleParams={handleParams}
              search={search}
              label=""
              placeholder="buscar por TID"
              parseSearch={parseSearch}
              clear={() => handleParams('q', '')}
            />
            <Button
              variant={showFilter ? 'contained' : 'outlined'}
              color="primary"
              className={styles.buttonFilter}
              onClick={() => setShowFilter(!showFilter)}
            >
              <SortIcon />
            </Button>
            <Button
              className={styles.filterButton}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => history.push('/add-contributor')}
              style={{ height: 36, paddingInline: 27, minWidth: 245 }}
            >
              Nova transação avulsa
            </Button>
          </FilterFormContainer>
        </Form>
      </SecondHeaderContainer>

      <Divider />

      {showFilter ? (
        <>
          <Form
            ref={advancedFilterFormRef}
          >
            <Grid item xs={12} className="title">
              Filtros avançados
            </Grid>
            <FilterFormContainer>
              <Select
                name="status"
                label="Status"
                options={transactionStatus}
                value={transactionStatus[0].value}
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%', marginRight: 20 }}
              >
                {({ item }) => <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>}
              </Select>
              <Select
                name="paymentMethod"
                label="Forma de Pagamento"
                options={['Cartão de Crédito', 'Boleto', 'PIX']}
                value="Cartão de Crédito"
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%', marginRight: 20 }}
              >
                {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
              </Select>
              <Select
                name="createdAt"
                label="Data de criação"
                options={['Entre:', 'Hoje', 'Ontem']}
                value="Entre:"
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%', marginRight: 20 }}
              >
                {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
              </Select>
              <DatePicker
                name="startDate"
                label="Data Início"
                style={{ width: '100%', marginRight: 20 }}
              />
              <DatePicker
                name="endDate"
                label="Data Término"
                style={{ width: '100%', marginRight: 20 }}
              />
              <CleanForm>
                <HighlightOffOutlinedIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }} />
                <p>Limpar todos os filtros</p>
              </CleanForm>
            </FilterFormContainer>
          </Form>

          <Divider />
        </>
      ) : ('')}

      <TitleContainer>
        <PageTitle>Consolidado</PageTitle>
        <PageSubtitle>(últimos 30 dias)</PageSubtitle>
      </TitleContainer>

      <CardContainer>
        <Card
          type="informative"
          status="approved"
          value="R$20.202.303,00"
        />

        <Card
          type="informative"
          status="waiting"
          value="R$20.202.303,00"
        />

        <Card
          type="informative"
          status="refused"
          value="R$20.202.303,00"
        />

        <Card
          type="informative"
          status="reversal"
          value="R$20.202.303,00"
        />

        <Card
          type="informative"
          status="chargeBack"
          value="R$20.202.303,00"
        />

        <Card
          type="informative"
          status="pendent"
          value="R$20.202.303,00"
        />
      </CardContainer>

      <TableHeaderContainer>
        <TitleContainer>
          <PageTitle>Lista de Transações</PageTitle>
          <PageSubtitle>(5.663 Resultados nos últimos 30 dias)</PageSubtitle>
        </TitleContainer>
        <Button
          variant="contained"
          endIcon={<VerticalAlignBottomIcon style={{ color: '#A3A3A3' }} />}
          style={{ height: 36, paddingInline: 27 }}
        >
          download da planilha
        </Button>
      </TableHeaderContainer>

      {isLoading ? <Spinner /> : (
        <TableMaterial
          headers={transactionTableHeader}
        />
      )}
    </>
  );
}

export default Transactions;
