import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '27px',
    color: '#a3a3a3',
  },

  titlePreview: {
    color: '#A3A3A3',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '27px',
    margin: '-32px 0 16px 24px',
  },

  text: {
    fontSize: 12,
    lineHeight: '20px',
    color: '#a3a3a3',
  },

  img: {
    width: '100%',
    height: 200,
    borderRadius: 5,
    marginTop: 8,
  },

  imgPreview: {
    width: '100%',
    height: 325,
    borderRadius: 5,
  },

  container: {
    borderRight: '1px solid #E1E1E1',
    paddingRight: 16,
    overflow: 'hidden',

    '& > h3': {
      margin: '26px 0',
    },
  },

  previewContainer: {
    padding: 24,

    '& > h3': {
      top: 0,
      marginBottom: 26,
    },
  },

  iconButton: {
    color: '#22E0A1',
    marginTop: '7px',
  },

  helpIcon: {
    color: '#A3A3A3',
    cursor: 'default',
    marginBottom: '-4px',
  },

  adornment: {
    marginTop: '14.5px',
    marginRight: '-5px',
  },

  spanTooltip: {
    color: '#A3A3A3',
    fontSize: '12px',
    marginLeft: '-10px',
    marginRight: '130px',
  },
}));

export default useStyles;
