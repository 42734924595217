import React, { useEffect, useState, useCallback } from 'react';

// Libs
import { Scope } from '@unform/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress, DialogTitle, Button, Slider } from '@material-ui/core';
import Cropper from 'react-easy-crop';

// Icons
import CloseIcon from '@material-ui/icons/Close';

// Components
import { toast } from 'react-toastify';
import getCroppedImg from './cropImage';

// Assets
import addImg from '../assets/images/addImg.png';

// Styles
import {
  ModalContainer,
  ProfilePictureContainer,
  DialogActionsContainer,
  RoundImageContainer,
  SliderContainer,
} from '../styles';
import useModalStyles from '../../modal/styles';
import { toBase64 } from '../../../utils/getBase64';
import { useColaborators } from '../../../contexts/contributors';
import { useAuth } from '../../../contexts/auth';

export default function AddModal({
  editGallery,
  image,
  isVisible,
  cancel,
  remove,
  close,
  formRef,
  // refetch,
  setImage,
  handleImage,
  setRemoveImg,
}) {
  const styles = useModalStyles();

  const marks = [
    {
      value: 1,
      label: '0%',
    },
    {
      value: 3,
      label: '100%',
    },
  ];

  /** STATES */
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCustom, setCustom] = useState(false);
  const [loading, setLoading] = useState(false);

  const { fetchColaborators } = useColaborators();
  const { updateAgentPicture } = useAuth();

  /** EFFECTS */
  useEffect(() => setCustom(editGallery?.is_customized || false), [setCustom, editGallery]);

  useEffect(() => {
    if (croppedImage) {
      setImage(croppedImage);
      handleImage(croppedImage);
      formRef.current.setFieldValue('img.image_base', croppedImage);

      setCroppedImage(null);
    }
  }, [croppedImage, formRef, handleImage, setImage]);

  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  const getCroppedImage = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();

      const imageUpdate = await toBase64(data?.img?.image_base);

      await updateAgentPicture({ image: imageUpdate, type: data?.img?.image_base?.type });
      await fetchColaborators({});

      const croppedImg = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      );
      setCroppedImage(croppedImg);
      setRemoveImg(false);
      setLoading(false);
      toast.success('Foto do  colaborador atualizada!');
    } catch (e) {
      setLoading(false);
      toast.error('Não  foi possível atualizar a foto do colaborador');
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image, setRemoveImg, fetchColaborators, formRef, updateAgentPicture]);

  /** FUNCTIONS */
  function valuetext(value) {
    let newValue = value;

    if (value === 1) newValue = 0;

    return `${Math.trunc((newValue * 100) / 3)}%`;
  }

  const modalWidth = () => {
    if (isCustom) {
      return '1098px';
    }

    return 740;
  };

  function onRemove() {
    setZoom(1);
    setImage(null);
    setCroppedImage(null);
    remove();
  }

  function onCancel() {
    setZoom(1);
    setImage(null);
    setCroppedImage(null);
    cancel();
    setRemoveImg(false);
  }

  return (
    <Dialog
      PaperProps={{ style: { width: modalWidth(), height: 550 } }}
      open={isVisible}
      onClose={close}
      maxWidth="false"
    >
      <DialogTitle className={styles.titleContainer}>
        <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            Foto do colaborador
          </Grid>
          <Grid item>
            <IconButton onClick={close} size="small">
              <CloseIcon color="disabled" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <ModalContainer className="modalContainer">
          <Scope path="medias">
            <ProfilePictureContainer className="profilePictureContainer">
              <p>Formatos aceitos: <b>.jpg</b> ou <b>.png</b>, com tamanho máximo de 2 MB.</p>
              <RoundImageContainer className="RoundImageContainer">
                <Cropper
                  image={image || addImg}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="round"
                  showGrid={false}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </RoundImageContainer>
              <SliderContainer>
                <p>Zoom para ajustar a foto ao circulo</p>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, selectedZoom) => setZoom(selectedZoom)}
                  valueLabelDisplay="on"
                  marks={marks}
                  getAriaValueText={valuetext}
                  valueLabelFormat={valuetext}
                />
              </SliderContainer>
            </ProfilePictureContainer>
          </Scope>
        </ModalContainer>
      </DialogContent>

      <DialogActionsContainer>
        <DialogActions className="dialogActions">
          <Button
            onClick={onRemove}
            style={{ color: '#D16262', textDecoration: 'underline', paddingInline: '8px' }}
          >
            remover imagem
          </Button>

          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            style={{ borderWidth: '2px' }}
          >
            cancelar
          </Button>

          <Button
            onClick={getCroppedImage}
            variant="contained"
            color="primary"
          >
            {loading ? <CircularProgress size={15} style={{ color: '#fff' }} /> : 'salvar foto'}
          </Button>
        </DialogActions>
      </DialogActionsContainer>
    </Dialog>
  );
}
