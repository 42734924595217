import React, { useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';

import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Placeholder from '../../assets/placeholder.svg';

import { useStyles } from '../styles';
import { StyledTooltip } from '../tooltip';

import DeleteProduct from './components/delete';

import actions from '../../actions';
import { useProduct } from '../../contexts/product';
import { useSettings } from '../../contexts/settings';
import { useAuth } from '../../contexts/auth';
import { getProduct } from '../../actions/product';

export default function ProductData({ product, refetch }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();
  const { settings } = useSettings();
  const { deleteProduct } = useProduct();

  const [modal, setModal] = useState(false);
  const [copy, setCopy] = useState('Copiar link para página do produto');

  const copyURL = (slug) => {
    const text = `https://${settings.store.domain}.loja.pedidopago.com.br/produto/${slug}`;
    const copiedMessage = 'Link copiado para a área de transferência';

    navigator.clipboard.writeText(text);

    setCopy(copiedMessage);
  };

  async function handleDeleteProduct() {
    try {
      await deleteProduct(product.id);
      await refetch({ p: 1 });

      setModal(!modal);

      toast.success('Produto removido com sucesso.');
    } catch (err) {
      toast.error('Não foi possível delete este produto. Tente novamente mais tarde');
    }
  }

  async function duplicateProduct(data) {
    const { image } = data;

    // We remove variants because back-end is not supporting it yet:
    // const variants = data.variants?.items.map(variant => ({ ...variant, options: variant.options.map(option => option.value) }));
    // eslint-disable-next-line no-param-reassign
    delete data?.['variants'];

    const detailedProduct = await getProduct(data.id);

    const newProduct = {
      ...data,
      title: `${data.title} (cópia)`,
      id: undefined,
      created_at: undefined,
      available_ecommerce: undefined,
      available_callcenter: undefined,
      recommendations: detailedProduct.recommendations,
      benefits: detailedProduct.benefits,
      description: detailedProduct.description,
      media: [{
        base: {
          image: image?.image_base,
        },
        description: data.subtitle,
        title: data.title,
      }],
      ecommerce: {
        from: Date.now(),
        availability: data.available_ecommerce,
      },
      call_center: {
        from: Date.now(),
        availability: data.available_callcenter,
      },
      form: 1,
      categories: null,
      slug: `${Date.now()}`,
    };

    try {
      const resp = await actions.products.createProduct(newProduct);

      if (resp.id) refetch({ p: 1 });
    } catch (error) {
      toast.error('Não foi possível duplicar este produto.');
    }
  }

  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          {product.image?.image_base
            ? (
              <img src={product?.image?.thumbnail} alt={product.image.title || 'image'} className={styles.tableImg} />
            )
            : (
              <img src={Placeholder} className={styles.tableImg} alt="placeholder" />
            )}

          {product.title}
        </Grid>
      </TableCell>

      {/* TODO: waiting resolution */}
      {/* <TableCell>{product.compounded ? 'Industrializado' : 'Manipulado'}</TableCell> */}

      <TableCell>
        {product.available_ecommerce
          ? `E-Commerce${product.available_callcenter ? ', Prescrição' : ''}`
          : `${product.available_callcenter ? 'Prescrição' : ''}`}
      </TableCell>
      <TableCell>{product.categories?.length}</TableCell>
      <TableCell>{format(product.created_at, 'dd/MM/yyyy')}</TableCell>

      <TableCell style={{ width: '160px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-8px' }}>
            <StyledTooltip title={copy} placement="top" className={styles.tableButton}>
              <IconButton
                className={styles.actionButton}
                onClick={() => copyURL(product.slug)}
                onMouseOut={() => setCopy('Copiar link para página do produto')}
                onBlur={() => setCopy('Copiar link para página do produto')}
              >
                <LinkIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item>
            <StyledTooltip title="Duplicar produto" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Catálogo', access: 'write' })}
                onClick={() => duplicateProduct(product)}
                className={styles.actionButton}
              >
                <FileCopyIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar Produto" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Catálogo', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/products/${product.id}`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-4px', marginRight: '-8px' }}>
            <StyledTooltip title="Remover Produto" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Catálogo', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => setModal(true)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      <DeleteProduct openModal={modal} setModal={setModal} product={product} deleteProduct={handleDeleteProduct} />
    </>
  );
}
