import React from 'react';

import { Scope } from '@unform/core';
import TitleCard from '../../title/title-card';
import EditorPolicy from './editor';

export default function ExchangeReturn({ content }) {
  return (
    <Scope path="exchange_return">
      <TitleCard color="#A3A3A3" bold> Política de Troca e Devolução</TitleCard>
      <EditorPolicy content={content} policy_name="exchange" policy_title="Política de Troca e Devolução" />
    </Scope>

  );
}
