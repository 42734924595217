/* eslint-disable no-unused-expressions */
import React, { useCallback, useRef, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';

import { Scope } from '@unform/core';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import TextField from '../../../form/text-field';
import DatePicker from '../../../form/date-picker';

import { useStyles } from './styles';
import Select from '../../../form/select';
import MaskField from '../../../form/mask-field';
import { useCustomer } from '../../../../contexts/customer';
import { ButtonPrimary } from '../../../settings/styles';
import { formatDate } from '../../../../utils/time';

export default function PersonalInformationForm({ customer }) {
  const formRef = useRef(null);
  const styles = useStyles();
  const { updateCustomer } = useCustomer();
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const foundCpf = customer?.document?.length ? customer?.document?.find((item) => item.type === 'cpf' || item.type === 'cnpj') : null;
  const foundRg = customer?.document?.length ? customer?.document?.find((item) => item.type === 'rg') : null;

  const [docType, setDocType] = useState(foundCpf?.type || 'cpf');

  const renderGender = ({ item, handleValue }) => <MenuItem value={item.value} key={item.label} onClick={handleValue}>{item.label}</MenuItem>;
  const renderType = ({ item }) => <MenuItem value={item.value} key={item.type} onClick={() => setDocType(item.value)}>{item.type}</MenuItem>;

  const handleUpdateCustomer = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();
      formRef?.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().email('Digite um e-mail válido'),
        phone: Yup.string().min(10, 'Digite um número de telefone válido'),
        ddd: Yup.string().min(2, 'Digite um DDD válido').max(2, 'Digite um DDD válido'),
      });

      await schema.validate({ ...data }, { abortEarly: false });

      await updateCustomer({ customer: { ...data, birthday: formatDate(data?.birthday), ddi: '+55' } }, id);
      setLoading(false);

      toast.success('Os dados do cliente atualizados com sucesso!');
      history.push('/customers');
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        toast.error(err.errors[0]);
        return formRef?.current?.setErrors(validationErrors);
      }

      return toast.error('Não foi possível alterar os dados deste cliente. Tente novamente.');
    }
  }, [id, updateCustomer, history]);

  const handleUpdateDocument = useCallback(async (data) => {
    try {
      const document = {
        document: data,
      };

      if (data.length === 11) {
        document.type = 'cpf';
      } else {
        document.type = 'cnpj';
      }

      await updateCustomer({ document: { ...document } }, id);
    } catch (err) {
      toast.error('Não foi possível alterar os dados deste cliente. Tente novamente.');
    }
  }, [id, updateCustomer]);

  const handleUpdateRg = useCallback(async () => {
    try {
      const data = formRef.current.getData();

      const document = {
        ...data.rg,
        type: 'rg',
        document: data.rg.document.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
      };
      await updateCustomer({ document: { ...document } }, id);
    } catch (err) {
      toast.error('Não foi possível alterar os dados deste cliente. Tente novamente.');
    }
  }, [id, updateCustomer]);

  return (
    <Form ref={formRef} initialData={{ ...customer.customer, document: foundCpf, rg: foundRg }}>
      <Grid container direction="row" spacing={2} className={styles.gridRow}>
        <Grid item xs={4}>
          <TextField name="name" label="Nome Completo" placeholder="Nome Completo" />
        </Grid>

        <Grid item xs={4}>
          <Select
            name="gender"
            label="Sexo"
            placeholder="Sexo"
            options={[{ label: 'Masculino', value: 'm' }, { label: 'Feminino', value: 'f' }, { label: 'Não binário', value: 'n' }]}
          >
            {renderGender}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <DatePicker name="birthday" label="Aniversário" placeholder="Aniversário" />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2} className={styles.gridRow}>
        <Scope path="document">
          <Grid item xs={4}>
            <Select
              name="type"
              placeholder="CPF/"
              label="Tipo do Documento"
              value={docType}
              options={[{ type: 'CPF', value: 'cpf' }, { type: 'CNPJ', value: 'cnpj' }]}
            >
              {renderType}
            </Select>
          </Grid>

          <Grid item xs={4}>
            <MaskField
              mask={docType === 'cpf' ? '999.999.999-99' : '99.999.999/9999-99'}
              maskChar="_"
              onBlur={(e) => handleUpdateDocument(e.target.value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''))}
              name="document"
              label="Nº do Documento"
              placeholder="Nº do Documento"
              required
            />
          </Grid>
        </Scope>
      </Grid>

      <Grid container direction="row" spacing={2} className={styles.gridRow}>
        <Scope path="rg">
          <Grid item xs={4}>
            <MaskField
              onBlur={handleUpdateRg}
              mask="99.999.999-9"
              name="document"
              label="RG"
              placeholder="RG"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="issuing"
              label="Emissor"
              onBlur={handleUpdateRg}
              placeholder="Emissor"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField name="country" label="UF" placeholder="UF" onBlur={handleUpdateRg} />
          </Grid>
        </Scope>

      </Grid>

      <Grid container direction="row" spacing={2} className={styles.gridRow}>
        <Grid item xs={4}>
          <TextField name="email" label="E-mail" placeholder="E-mail" />
        </Grid>

        {/* <Grid item xs={1}>
          <TextField name="ddi" label="País" placeholder="País" />
        </Grid> */}

        <Grid item xs={1}>
          {/* <TextField name="ddd" label="DDD" placeholder="DDD" /> */}
          <MaskField
            name="ddd"
            mask="99"
            maskChar=""
            label="DDD"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={2}>
          {/* <TextField name="phone" label="Celular" placeholder="Celular" /> */}
          <MaskField
            name="phone"
            mask="9 9999-9999"
            maskChar=""
            label="Celular"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* <Grid item xs={1}>
          <TextField disabled name="ddisabled" label="País" placeholder="País" />
        </Grid>

        <Grid item xs={1}>
          <TextField disabled name="dddisabled" label="DDD" placeholder="DDD" />
        </Grid>

        <Grid item xs={2}>
          <TextField disabled name="whatsapp" label="Whatsapp" placeholder="Whatsapp" />
        </Grid> */}
      </Grid>

      <Grid container justify="flex-end">
        <Grid item>
          <ButtonPrimary onClick={handleUpdateCustomer} variant="contained" color="primary">
            {isLoading ? (
              <CircularProgress size={15} style={{ color: '#fff' }} />
            ) : (
              'salvar alterações'
            )}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </Form>
  );
}
