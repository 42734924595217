import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import { useParams } from 'react-router';
import ContentWrapper from '../content-wrapper';
import PersonalInformation from './panels/personal-information';
import TabNavigator from '../tabs-navigation';

import { PageTitle, useStyles } from '../styles';

import CustomerSchema from './schema';
// import PatientsList from './panels/patients-list';

import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import Spinner from '../spinner';
import { useCustomer } from '../../contexts/customer';
import OrdersPanel from './panels/orders';

export default function CustomerDetails() {
  const styles = useStyles();
  const { getOneCustomer } = useCustomer();
  const { id } = useParams();

  const [customer, setCustomer] = useState();
  const [activeTab, setTab] = useState(0);

  useEffect(() => {
    async function getCustomer() {
      const response = await getOneCustomer(id);
      setCustomer(response);
    }

    getCustomer();
  }, [getOneCustomer, id]);

  return customer ? (
    <>
      <Breadcrumb title="Detalhes do Cliente" text="Clientes" current={customer?.customer?.name} />

      <ContentWrapper align="top">
        <Grid container direction="row" className={styles.gridRow} style={{ paddingRight: '0' }}>
          <div className={styles.bottomLine} />

          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>{customer.customer.name}</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <TabNavigator tabs={CustomerSchema} activeTab={activeTab} onChange={(event, newValue) => setTab(newValue)} />
          </Grid>
        </Grid>

        <PersonalInformation value={activeTab} customer={customer} />
        <OrdersPanel value={activeTab} customer={customer} />
        {/* <PatientsList value={activeTab} /> */}
      </ContentWrapper>
    </>
  ) : (
    <Spinner />
  );
}
