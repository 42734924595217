import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import Text from '../../text/text';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export default function SubtitleCard({ tip, children }) {
  return (
    <Container>
      <Text color="#A3A3A3" mr="10px">{children}</Text>

      {tip && (
        <Tooltip title={tip}>
          <HelpOutline fontSize="small" style={{ color: '#A3A3A3' }} />
        </Tooltip>
      )}
    </Container>
  );
}
