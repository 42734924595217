import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { useStyles } from '../styles';
import { useProduct } from '../../contexts/product';

export default function SaveBar({
  buttonPrimary,
  buttonSecondary,
  history,
  isLoading,
  type,
  disabled,
}) {
  const styles = useStyles();
  const params = useParams();
  const { search } = useLocation();
  const { deleteProduct } = useProduct();

  return (
    <Paper className={styles.save}>
      <Grid container direction="row" justify="flex-end" spacing={2} alignItems="center">
        <Grid item>
          <Link className={styles.buttonSecondary} to={history} onClick={() => search && deleteProduct(params.id)}>{buttonSecondary}</Link>
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" disabled={disabled} className={styles.buttonPrimary} type={type}>
            {isLoading ? <CircularProgress color="secondary" size={14} /> : buttonPrimary}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
