import React from 'react';

import { Scope } from '@unform/core';
import TitleCard from '../../title/title-card';
import EditorPolicy from './editor';

export default function TermsConditions({ content }) {
  return (
    <Scope path="terms_condition">
      <TitleCard color="#A3A3A3" bold> Termos e Condições</TitleCard>

      <EditorPolicy content={content} policy_name="termsCondition" policy_title="Termos e Condições" />

    </Scope>

  );
}
