import React from 'react';

import styled, { css } from 'styled-components';

const Styled = styled.span`
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 22px;

  ${props => props.nowrap && css`
    white-space: ${props.nowrap} !important;
  `}

  ${props => props.size && css`
    font-size: ${props.size} !important;
  `}

  ${props => props.jc && css`
    display:flex !important;
    justify-content: ${props.jc} !important;
  `}

  ${props => props.color === 'theme' && css`
    color: ${props.theme.main_color};
  `}

  ${props => props.color && css`
    color: ${props.color} !important;
  `}

  ${props => props.regular && css`
    font-weight: normal;
  `}

  ${props => props.fontWeight && css`
    font-weight: ${props.fontWeight} !important;
  `}

  ${props => props.pdl && css`
    padding-left: ${props.pdl};
  `}

  ${props => props.mt && css`
    margin-top: ${props.mt} !important;
  `}

  ${props => props.ml && css`
    margin-left: ${props.ml} !important;
  `}

  ${props => props.align && css`
    text-align: ${props.align} !important;
  `}

  ${props => props.displayMob && css`
    @media only screen and (min-device-width: 320px) and (max-device-width: 736px) {
      display: ${props.displayMob};
    }
  `}

  @media only screen and (min-device-width: 320px) and (max-device-width: 736px) {
    white-space: normal;
    ${props => props.displayMob && css`
      display: ${props.displayMob};
    `}

    ${props => props.spdl && css`
      padding-left: ${props.spdl};
    `}
    ${props => props.snowrap && css`
      white-space: ${props.snowrap} !important;
    `}
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    ${props => props.tbnowrap && css`
      white-space: ${props.tbnowrap} !important;
    `}
  }
`;

export default function Subtitle({ children, ...props }) {
  return (
    <Styled {...props}>{children}</Styled>
  );
}
