import React from 'react';

import { Grid, InputAdornment } from '@material-ui/core';

import CheckBox from '../../form/checkbox';
// import RadioButton from '../../form/form-radio-button';
import SubtitleCard from '../../title/subtitle-card';
import TitleCard from '../../title/title-card';
import PriceField from '../../form/price-field';

import useStyles from './styles';
import { StyledPaper } from '../../styles';
import TextField from '../../form/text-field';

export default function Rules({ isIlimited, setIlimited, isEditForbid }) {
  const styles = useStyles();
  return (
    <>
      <TitleCard>Regras De Uso</TitleCard>
      <StyledPaper>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <SubtitleCard>Requisitos mínimos</SubtitleCard>

            <PriceField
              name="minimum_cart"
              type="number"
              label="Carrinhos A Partir De"
              placeholder="Carrinhos A Partir De"
              separator="."
              InputProps={{
                startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
              }}
              style={{ width: 238 }}
              disabled={isEditForbid}
            />
          </Grid>

          <Grid container direction="column" item style={{ margin: '12px 0 -13px' }}>
            <SubtitleCard>Limite de Uso</SubtitleCard>
            <CheckBox disabled={isEditForbid} name="first_order" label="Cupom disponível apenas na primeira compra" />
            <CheckBox disabled={isEditForbid} name="once_customer" label="Cada cliente só poderá utilizar o cupom uma única vez" />
            <CheckBox disabled={isEditForbid} name="ecommerce_products_only" label="Valido apenas para produtos de e-commerce" />
            <CheckBox disabled={isEditForbid} name="undefined_availability" label="Cupons ilimitados" checked={isIlimited} value={isIlimited} onClick={() => setIlimited(!isIlimited)} />
          </Grid>
          {!isIlimited && (
            <Grid item container direction="row" alignItems="center" spacing={3}>
              <Grid item sm={6}>
                <TextField
                  name="maximum_available"
                  type="number"
                  label="Cupons Criados"
                  placeholder="Cupons Criados"
                  disabled={isEditForbid}
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  disabled
                  name="amount_used"
                  type="number"
                  label="Cupons Utilizados"
                  placeholder="Cupons Utilizados"
                />
              </Grid>
            </Grid>
          )}

        </Grid>
      </StyledPaper>
    </>
  );
}
