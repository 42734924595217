import React from 'react';

import Grid from '@material-ui/core/Grid';

import Modal from '../../modal';

import { Container } from '../../styles';

export default function DeleteProduct({
  openModal,
  product,
  setModal,
  deleteProduct,
}) {
  return (
    <Modal
      isVisible={openModal}
      title="Remover Produto"
      toggle={() => setModal(!openModal)}
      size="xs"
      buttonPrimary="manter produto"
      buttonSecondary="remover permanentemente"
      modalActions={2}
      onSubmit={deleteProduct}
      isDelete
    >
      <Container style={{ margin: '30px 0 0 0' }}>
        <Grid container justify="center" direction="column" alignItems="center">
          <Grid item>
            <span>Tem certeza que deseja remover o produto<span style={{ fontWeight: 'bold' }}>{` ${product.title}`}</span>?</span>
          </Grid>

          <Grid item>
            <span>Esta ação não poderá ser desfeita.</span>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}
