import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import { IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
// import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import Placeholder from '../../assets/placeholder.svg';

import DeleteCategory from './components/delete';
// import VisibilityCategory from './components/visibilities';

import Modal from '../modal';
import { StyledTooltip } from '../tooltip';
import { useStyles } from '../styles';

import { useCategory } from '../../contexts/category';
import { useSettings } from '../../contexts/settings';
import { useAuth } from '../../contexts/auth';

export default function CategoryData({ category, refetch }) {
  const styles = useStyles();

  const { verifyPerms } = useAuth();
  const { deleteCategory } = useCategory();
  const { settings } = useSettings();

  // const [visibModal, setVisibModal] = useState(false);
  const [delModal, setDelModal] = useState(false);

  const [copy, setCopy] = useState('Copiar link para página da categoria');

  const handleDelete = async () => {
    try {
      await deleteCategory(category.id);
      toast.success('Categoria removida com sucesso.');

      refetch({ p: 1, ascending: 1 });
    } catch (err) {
      setDelModal(!delModal);
    }
  };

  const copyURL = (slug) => {
    // const text = `${settings.store.domain}.loja.pedidopago.com.br/products?category_id=${id}`;
    const text = `${settings.store.domain}.loja.pedidopago.com.br/categoria/${slug}`;
    const copiedMessage = 'Link copiado para a área de transferência';

    navigator.clipboard.writeText(text);

    setCopy(copiedMessage);
  };

  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          {category?.logo
            ? (
              <img src={category.logo} alt={category.name} className={styles.tableImg} />
            )
            : (
              <img src={Placeholder} className={styles.tableImg} alt="placeholder" />
            )}

          {category.name}
        </Grid>
      </TableCell>

      <TableCell>
        {category.ecommerce.status
          ? `E-Commerce${category.callcenter.status ? ', Prescrição' : ''}`
          : `${category.callcenter.status ? 'Prescrição' : ''}`}
      </TableCell>

      <TableCell>{category.subcategories?.length}</TableCell>
      <TableCell>{category.product_ids?.length}</TableCell>
      <TableCell>{format(category.created_at, 'dd/MM/yyyy')}</TableCell>

      <TableCell style={{ width: '160px' }}>
        <Grid container direction="row">
          {/* <Grid item style={{ marginLeft: '-8px' }}>
            <StyledTooltip title="Alternar visibilidade" placement="top" className={styles.tableButton}>
              <IconButton className={styles.actionButton} onClick={() => setVisibModal(!visibModal)}>
                <VisibilityOffOutlinedIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid> */}

          <Grid item>
            <StyledTooltip title={copy} placement="top" className={styles.tableButton}>
              <IconButton
                className={styles.actionButton}
                onClick={() => copyURL(category.slug)}
                onMouseOut={() => setCopy('Copiar link para página da categoria')}
                onBlur={() => setCopy('Copiar link para página da categoria')}
              >
                <LinkIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar Categoria" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Catálogo', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={`/categories/${category.id}`}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-4px', marginRight: '-8px' }}>
            <StyledTooltip
              disabled={verifyPerms({ role: 'Catálogo', access: 'delete' })}
              title="Remover Categoria"
              placement="top"
              className={styles.tableButton}
            >
              <IconButton className={styles.actionButton} onClick={() => setDelModal(!delModal)}>
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>

      {/* <Modal
        isVisible={visibModal}
        title="Alternar visibilidade"
        toggle={() => setVisibModal(!visibModal)}
        size="xs"
        buttonPrimary="salvar"
        buttonSecondary="cancelar"
        modalStyle={2}
      >
        <VisibilityCategory category={category} />
      </Modal> */}

      <Modal
        isVisible={delModal}
        title="Remover Categoria"
        toggle={() => setDelModal(!delModal)}
        size="xs"
        buttonPrimary="manter categoria"
        buttonSecondary="remover permanentemente"
        onSubmit={handleDelete}
        modalActions={2}
        isDelete
      >
        <DeleteCategory category={category} visible={setDelModal} />
      </Modal>
    </>
  );
}
