import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Scope } from '@unform/core';

import Grid from '@material-ui/core/Grid';

import { CircularProgress } from '@material-ui/core';
import AddModal from './add-modal';
import ImageField from '../../form/image-upload';

import { useStyles } from '../../styles';

import { reorderProductMedia } from '../../../actions/product';
import { reorderList } from '../../../utils/reorder';

import useCustomImage from '../../../hooks/useCustomImage';
import { toBase64 } from '../../../utils/getBase64';

export default function AddImage({
  title,
  imageList,
  formRef,
  refetch,
  productId,
  setProductImages,
}) {
  const styles = useStyles();
  const [editGallery, setEditGallery] = useState();
  const [listIndex, setListIndex] = useState();
  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [isVisible, setVisible] = useState(false);
  const { imageValues, update } = useCustomImage({ initialValues: {}, implicitValues: {} });

  const [loading, setLoading] = useState(false);

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const updatePreview = useCallback(async () => {
    setLoading(true);
    await timeout(10000);
    setLoading(false);
  }, []);

  const listId = (data) => {
    if (data) {
      return data;
    }

    return imageList?.length + 1;
  };

  const handleUpdate = async (index) => {
    const newImageList = [...imageList];
    const customImage = newImageList[index];
    const isMaskSVG = customImage?.image_mask?.indexOf('.svg');
    const isTextSVG = customImage?.image_text?.indexOf('.svg');

    update({
      base: customImage?.image_base,
      mask: customImage?.image_mask,
      mask_type: isMaskSVG > 0 ? 'link_MaskSvg' : 'image/png',
      text: customImage?.image_text,
      text_type: isTextSVG > 0 ? 'link_TextSvg' : 'image/png',
      margin_left: customImage?.logo_area_left,
      margin_top: customImage?.logo_area_top,
      height: customImage?.logo_max_height,
      width: customImage?.logo_max_width,
      inverse_logo: customImage?.inverse_logo,
      text_blending: customImage?.text_blending === 'alpha' ? 'normal' : customImage.text_blending,
    });

    setImage();
    setEditGallery(customImage);
    setListIndex(index);
    setId(listId(newImageList[index].id));
    setVisible(true);
  };

  const openModal = () => {
    setImage();
    setEditGallery();
    setListIndex();
    setId(listId());
    setVisible(true);
  };

  const toggle = () => {
    setVisible(!isVisible);

    formRef.current.setFieldValue('img.image_base', undefined);

    update({});
  };

  const handleImage = async (item, value) => {
    const newValues = {};
    const { medias } = formRef.current.getData();

    if (item === 'mask') {
      newValues[item] = await toBase64(medias?.image_mask);
      newValues[`${item}_type`] = medias?.image_mask.type;

      return update({ ...imageValues, ...newValues });
    }

    if (item === 'text') {
      newValues[item] = await toBase64(medias?.image_text);
      newValues[`${item}_type`] = medias?.image_text.type;

      return update({ ...imageValues, ...newValues });
    }

    if (item === 'base') {
      setImage(value);

      newValues[item] = value;

      return update({ ...imageValues, ...newValues });
    }

    newValues[item] = value;

    return update({ ...imageValues, ...newValues });
  };

  useEffect(() => {
    async function changeImage() {
      if (image) {
        setVisible(true);
      }
    }

    changeImage();
  }, [image, formRef]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newOrder = {
      list: imageList,
      start: result.source.index,
      end: result.destination.index,
      id,
    };

    // FIXME: reorderList is coupled with a request to reorder categories, but this
    //        is a list of product media

    const items = await reorderList(newOrder);
    const ids = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].id);
    }

    setProductImages(items);
    await reorderProductMedia(productId, { items: ids });
    // refetch();
  };

  function getIdType(data) {
    if (data.id) {
      return data.id.toString();
    }

    return '';
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppableProvided) => (
            <Grid
              container
              alignItems="center"
              direction="row"
              spacing={1}
              className={styles.gridImage}
              style={{ marginLeft: '-4px' }}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {(imageList && !loading) ? imageList.map((item, index) => (
                <Draggable
                  key={getIdType(item)}
                  draggableId={getIdType(item)}
                  index={index}
                >
                  {(draggableProvided) => (
                    <Grid
                      item
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <Scope path="image">
                        <ImageField
                          name="image_base"
                          edit
                          previewImg={item.thumbnail || item.base.image}
                          setImage={previewURL => handleImage('base', previewURL)}
                          onClick={() => handleUpdate(index)}
                        />
                      </Scope>
                    </Grid>
                  )}
                </Draggable>
              )) : imageList && (
                <CircularProgress style={{ margin: '0 30px 0 30px' }} size={50} />
              )}

              {droppableProvided.placeholder}

              <Grid item>
                <Scope path="img">
                  <ImageField
                    name="image_base"
                    acceptImg=".svg, .jpg, .jpeg, .png"
                    setImage={previewURL => handleImage('base', previewURL)}
                    onClick={() => openModal()}
                  />
                </Scope>
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <AddModal
        updatePreview={updatePreview}
        setProductImages={setProductImages}
        image={image}
        isVisible={isVisible}
        listIndex={listIndex}
        editGallery={editGallery}
        setEditGallery={setEditGallery}
        imageList={imageList}
        title={title}
        toggle={toggle}
        id={id}
        formRef={formRef}
        refetch={refetch}
        productId={productId}
        setImage={setImage}
        handleImage={handleImage}
        item={imageValues}
      />
    </>
  );
}
