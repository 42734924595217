/* eslint-disable no-shadow */
import { FormControl, Grid, Radio, RadioGroup } from '@material-ui/core';
import { Scope } from '@unform/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import actions from '../../../actions';
// import { mediaSchema } from '../schemas';
import { toBase64 } from '../../../utils/getBase64';
import RadioButton from '../../form/form-radio-button';
import TextField from '../../form/text-field';
import ImageField from '../../form/image-upload';
import Modal from '../../modal';
import { Container, useStyles } from '../../styles';
import CustomImageForm from './custom-image-form';
import { useAuth } from '../../../contexts/auth';

export default function AddModal({
  updatePreview,
  setProductImages,
  editGallery,
  setEditGallery,
  image,
  isVisible,
  title,
  toggle,
  formRef,
  productId,
  setImage,
  handleImage,
  item,
}) {
  const styles = useStyles();

  const { verifyPerms } = useAuth();
  const [isCustom, setCustom] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [imgPreview, setPreview] = useState({});
  const [blendMode, setBlendMode] = useState('multiply');

  useEffect(() => setCustom(editGallery?.is_customized || false), [setCustom, editGallery]);

  const modalWidth = () => {
    if (isCustom) {
      return '1098px';
    }

    return 680;
  };

  const handleRemove = async () => {
    toggle();
    try {
      const removeMedia = await actions.media.removeMedia({ ...editGallery, productId });
      setProductImages(oldImages => oldImages.filter((image) => image.id !== editGallery.id));
      toggle();

      toast.success('Imagem deletada com sucesso!');
      return removeMedia;
    } catch (err) {
      return toast.error('Ocorreu um erro ao tentar deletar esta imagem');
    }
  };

  async function onSubmit() {
    try {
      const { medias, img, image } = formRef.current.getData();
      const { inverse_logo } = medias;

      setLoading(true);

      let pmask = null;
      let ptext = null;
      let image_base = null;

      if (img?.image_base?.size > 976563) {
        setLoading(false);
        return toast.error('A imagem deve ter no máximo 1 MB.');
      }

      if (medias?.image_mask?.size > 0) {
        pmask = {
          image: await toBase64(medias?.image_mask),
          content_type: medias?.image_mask.type,
        };
      }

      if (medias?.image_text?.size > 0) {
        ptext = {
          image: await toBase64(medias?.image_text),
          content_type: medias?.image_text.type,
        };
      }

      if (medias?.image_base?.size > 0 || img?.image_base?.size > 0 || image?.image_base?.size > 0) {
        const mediasImg = medias?.image_base?.size && medias?.image_base;
        const newImg = img?.image_base?.size && img?.image_base;
        const newPreviewImg = image?.image_base?.size && image?.image_base;

        image_base = {
          image: await toBase64(mediasImg || newImg || newPreviewImg),
          content_type: img?.image_base?.type || medias?.image_base?.type || image?.image_base?.type,
        };
      }

      const newMedia = {
        base: image_base,
        mask: pmask,
        text: ptext,
        title: medias?.title,
        description: medias?.description,
        logo: {
          area_left: medias?.margin_left ? parseInt(medias.margin_left, 10) : 0,
          area_top: medias?.margin_top ? parseInt(medias.margin_top, 10) : 0,
          max_height: medias?.height ? parseInt(medias.height, 10) : 0,
          max_width: medias?.width ? parseInt(medias.width, 10) : 0,
        },
        is_customized_image: isCustom || editGallery?.is_customized,
        productId,
        inverse_logo,
        text_blending: blendMode,
      };

      if (editGallery) {
        // TODO: check version for customized image (complex svgs)
        const update = await actions.media.updateMedia({ ...newMedia, productId, id: editGallery?.id });
        setProductImages((oldImages) => {
          const filteredNoUpdated = oldImages.filter((image) => image.id !== editGallery.id);

          filteredNoUpdated.push(editGallery);
          return filteredNoUpdated;
        });

        setImage();

        formRef.current.setFieldValue('img.image_base', undefined);

        setLoading(false);

        toggle();

        await updatePreview();

        toast.success('Imagem atualizada com sucesso!');
        return update;
      }

      if (!image_base) {
        toast.error('Não há uma imagem base selecionada.');
        return setLoading(false);
      }
      const response = await actions.media.addMediaToProduct(productId, newMedia);

      setProductImages((oldImages) => {
        const images = oldImages || [];
        images.push({ ...newMedia, id: response.id, type: 1 });
        return images;
      });
      setImage();

      formRef.current.setFieldValue('img.image_base', undefined);

      setLoading(false);

      toggle();

      // await refetch();

      toast.success('Imagem adicionada com sucesso!');
      return response;
    } catch (err) {
      setLoading(false);
      return toast.error('Ocorreu um erro au tentar salvar esta imagem. Tente novamente mais tarde');
    }
  }

  const handleChange = (field) => e => editGallery
    && setEditGallery({
      ...editGallery,
      [field]: e.target.value,
    });

  return (
    <Modal
      isDisabled={verifyPerms({ role: 'Catálogo', access: 'write' })}
      isDisabledDelete={verifyPerms({ role: 'Catálogo', access: 'delete' })}
      isVisible={isVisible}
      toggle={toggle}
      title={`Imagem do produto ${title}`}
      buttonPrimary="salvar"
      buttonRemove="remover imagem"
      onSubmit={onSubmit}
      secondaryFunction={handleRemove}
      width={{ width: modalWidth() }}
      isLoading={isLoading}
    >
      <Container className={styles.modalContainer}>
        <Grid container direction="row">
          <FormControl required>
            <RadioGroup name="is_customized" value={isCustom}>
              <Grid container direction="row">
                <RadioButton
                  value={false}
                  control={<Radio color="primary" />}
                  disabled={!!item?.base}
                  label="Foto Simples"
                  style={{ marginRight: '36px' }}
                  onClick={() => (!item?.base ? setCustom(false) : {})}
                />

                <RadioButton
                  value
                  control={<Radio color="primary" />}
                  label="Foto Composta"
                  onClick={() => setCustom(true)}
                />
              </Grid>
            </RadioGroup>
          </FormControl>

          {!isCustom && (
            <span className={styles.span}>
              Formatos aceitos: .jpg ou .png, com tamanho máximo de 1 MB.
            </span>
          )}

          {!isCustom ? (
            <Scope path="medias">
              <Grid container direction="row" style={{ marginTop: '16px' }}>
                <Grid item style={{ height: '136px' }}>
                  <ImageField
                    name="image_base"
                    edit
                    previewImg={image || editGallery?.image_base || editGallery?.base?.image}
                    acceptImg=".svg, .jpg, .jpeg, .png"
                  />
                </Grid>

                <Grid item className={styles.modalTextFieldRight}>
                  <Grid item xs={12} style={{ marginTop: '14px' }}>
                    <TextField
                      name="title"
                      placeholder="Título da Imagem"
                      value={editGallery?.title}
                      onChange={handleChange('title')}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '16px' }}>
                    <TextField
                      name="description"
                      placeholder="Subtítulo da Imagem"
                      value={editGallery?.description}
                      onChange={handleChange('description')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Scope>
          )
            : (
              <CustomImageForm
                preview={imgPreview}
                setPreview={setPreview}
                formRef={formRef}
                editGallery={editGallery}
                image={image}
                handleImage={handleImage}
                item={item}
                setBlendModeApi={setBlendMode}
              />
            )}
        </Grid>
      </Container>
    </Modal>
  );
}
