import React, { useCallback, useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';

import { toast } from 'react-toastify';
import TextField from '../../form/text-field';
import TitleCard from '../../title/title-card';

import Text from '../../text/text';
import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import { useAuth } from '../../../contexts/auth';

export default function SocialMedias({ formRef }) {
  const { verifyPerms } = useAuth();

  const [isLoading, setLoading] = useState(false);

  const { updateSocialMedia } = useSettings();

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const { social_medias } = formRef.current.getData();

      const parsedLinks = {};
      const parseLink = {
        facebook_page: 'https://www.facebook.com/',
        instagram_account: 'https://www.instagram.com/',
        twitter_profile: 'https://www.twitter.com/',
        youtube_channel: 'https://www.youtube.com/',
        linkedin_profile: 'https://www.linkedin.com/company/',
        tiktok_account: 'https://www.tiktok.com/',
      };

      Object.keys(social_medias).forEach((item) => {
        if (!social_medias[item]) return parsedLinks[item] = '';
        parsedLinks[item] = `${parseLink[item]}${social_medias[item]}`;
      });

      await updateSocialMedia(parsedLinks);

      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [formRef, updateSocialMedia]);

  return (
    <>
      <TitleCard color="#A3A3A3" bold> Redes Sociais </TitleCard>

      <StyledPaper>

        <GridContainer width="100%" mr="24px" container>

          <GridContainer container item width="auto" margin="12px" spacing={4}>
            <GridContainer container item lg={12} spacing={3}>
              <Grid item lg={4}>
                <TextField
                  name="facebook_page"
                  label="Página do facebook"
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">Facebook.com/</Text></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  name="instagram_account"
                  label="Conta do Instagram"
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">Instagram.com/</Text></InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item lg={4}>
                <TextField
                  name="twitter_profile"
                  label="Perfil do Twitter"
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">Twitter.com/</Text></InputAdornment>,
                  }}
                />
              </Grid>

            </GridContainer>

            <GridContainer container item lg={12} spacing={3}>
              <Grid item lg={4}>
                <TextField
                  name="youtube_channel"
                  label="Canal do youtube"
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">Youtube.com/</Text></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  name="linkedin_profile"
                  label="Perfil do Linkedin"
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">Linkedin.com/company/</Text></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  name="tiktok_account"
                  label="Conta do Tiktok"
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">Tiktok.com/</Text></InputAdornment>,
                  }}
                />
              </Grid>
            </GridContainer>
            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary disabled={verifyPerms({ role: 'Conf. Website', access: 'write' })} onClick={onSubmit} variant="contained" color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>

        </GridContainer>
      </StyledPaper>
    </>
  );
}
