import React from 'react';

import styled, { css } from 'styled-components';

const Styled = styled.span`
  color: #424242;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;

  ${props => props.bold && css`
    font-weight: 600;
  `}
  
  ${props => props.justfy && css`
    display:flex !important;
    justify-content: ${props.justfy} !important;
  `}
  
  ${props => props.lineHeight && css`
    line-height: ${props.lineHeight};
  `}
  
  ${props => props.font && css`
    font-size:  ${props.font};
  `}

  ${props => props.color === 'theme' && css`
    color: ${props.theme.main_color};
  `}

  ${props => props.color && css`
    color: ${props.color};
  `}

  ${props => props.block && css`
    display: block;
  `}

  ${props => props.mt && css`
    margin-top: ${props.mt} !important;
  `}

  ${props => props.underline && css`
    text-decoration-line: underline;
  `}

  ${props => props.align && css`
    text-align: ${props.align};
  `}

  ${props => props.width && css`
    width: ${props.width} !important;
  `}

  ${props => props.mb && css`
    margin-bottom: ${props.mb} !important;
  `}

  ${props => props.ml && css`
    margin-left: ${props.ml} !important;
  `}

  ${props => props.mr && css`
    margin-right: ${props.mt} !important;
  `}

  ${props => props.width && css`
    width: ${props.width} !important;
  `}

  ${props => props.height && css`
    height: ${props.height} !important;
  `}

  ${props => props.mb && css`
    margin-bottom: ${props.mb} !important;
  `}

  ${props => props.ml && css`
    margin-left: ${props.mt} !important;
  `}

  ${props => props.mr && css`
    margin-right: ${props.mt} !important;
  `}

  ${props => props.width && css`
    width: ${props.width} !important;
  `}

  ${props => props.height && css`
    height: ${props.height} !important;
  `}

  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px) {
    ${props => props.smText && css`
    font-size: 10px;
    `}
  }

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 736px) {
    ${props => props.mtMobile && css`
        margin-top: ${props.mtMobile} !important;
    `}

    ${props => props.wdMobile && css`
        width: ${props.wdMobile} !important;
    `}

    ${props => props.mlMobile && css`
    margin-left: ${props.mlMobile} !important;
    `}

    ${props => props.mrMobile && css`
      margin-right: ${props.mrMobile} !important;
    `}
    ${props => props.smb && css`
      margin-bottom: ${props.smb} !important;
    `}
    ${props => props.smt && css`
      margin-top: ${props.smt} !important;
    `}
  }
`;

export default function Text({ children, ...props }) {
  return (
    <Styled {...props}>{children}</Styled>
  );
}
