/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

import { Settings } from '../@types/settings';

interface PharmacyData {
  ae_registry: string;
  ae_registry_date: string;
  afe_registry: string;
  afe_registry_date: string;
  certificate: string;
  cmvs_registry: string;
  cmvs_registry_date: string;
  pharmaceutical_name: string;
  pharmaceutical_number: string;
  pharmaceutical_registry_estate: string;
}

interface AttendanceData {
  discount_billet: number;
  discount_credit_card: string;
  domain: string;
  email: string;
  free_shipping_city: number;
  free_shipping_country: number;
  installment_received: number;
  name: string;
  phone: string;
  work_time: {
    saturday_from: string;
    saturday_to: string;
    sunday_from: string;
    sunday_to: string;
    week_from: string;
    week_to: string;
  },
  workforce: string;
}

interface PaymentCredentials {
  billet_instructions: string;
  levpay_access: string;
  levpay_secret: string;
  pagarme_crypto: string;
  pagarme_default_recipient_pp: string;
  pagarme_default_recipient_store: string;
  pagarme_key: string;
  pagarme_split_pp: string;
  soft_descriptor: string;
}

interface LogoCredentials {
  favicon: string;
  logo: string;
  logo_inverse: string;
  logo_header: {
    image: string;
  };

}

interface Policy {
  name: string;
  content: string;
}

interface SocialMedia {
  facebook_analytics: string;
  google_analytics: string;
}

interface Credentials {
  facebook: {
    app_id: string;
    secret: string;
  };
  google: {
    client_id: string;
    project_id: string;
    secret: string;
  };
  instagram: {
    app_id: string;
    secret: string;
  }
}

interface Contact {
  leadership_email: string;
  prescription_email: string;
  recruitment_email: string;
  prescriber_email: string;
  pharmacist_email: string;
  purchase_email: string;
  financial_email: string;
}

interface RdForm {
  access_token?: string;
  client_id?: string;
  client_secret?: string;
  callback?: string;
  code?: string;
  connect_url?: string;
  connected?: boolean;
  refresh_token?: string;
  store_id?: string;
  token_expires_at?: number;
}

interface TransportData {
  id: number;
  store_id: string;
  branch_id: string;
  service_name: string;
  service_cost: string;
  country_code: string;
  zip_from: string;
  zip_to: string;
  shipping_time: number;
  week_availibility: string[];
  weekday_hours: DateRange;
  weekend_hours: DateRange;
  ships_on_holidays: boolean;
  ships_regulated_cargo: boolean;
  ships_low_temperature: boolean;
  service_type: string;
  created_at: number;
  city?: string;
  state?: string;
}
interface DateRange {
  from: number;
  to: number;
}

interface Locations {
  items: TransportData[]
}

interface RemoveLocation {
  id: number;
  branch_id: string;
}
interface UpdateLocation {
  id: string;
  body: object;
  branch_id: string;
}

interface SettingsContextData {
  isLoading: boolean;
  settings: Settings;
  locations: Locations;
  fetchSettings(params: Record<string, unknown>): Promise<Record<string, unknown>>;
  updateSettings(id: number, data: Settings): Promise<void>;
  updateColors(data: { main: string, text: string }): Promise<void>;
  fetchPaymentConfig(): Promise<PaymentCredentials>;
  updatePaymentConfig(data: PaymentCredentials): Promise<void>;
  updateLogos(data: LogoCredentials): Promise<void>;
  updatePolicy(data: Policy, section: string): Promise<void>;
  getSections(): Promise<void>;
  updateSocialMedia(data: SocialMedia): Promise<void>;
  getCredentials(): Promise<Credentials>;
  updateCredentials(data: Credentials): Promise<void>;
  updatePharmacy(data: PharmacyData): Promise<void>;
  updateAttendance(data: AttendanceData): Promise<void>;
  fetchLocations(branch_id:string): Promise<Locations>;
  addLocation(data: Record<string, unknown>): Promise<void>;
  removeLocation({ id, branch_id }: RemoveLocation): Promise<void>;
  updateLocation({ id, body, branch_id }: UpdateLocation): Promise<void>;
  getContact(): Promise<Contact>
  updateContact(credentials: Contact): Promise<void>;
  getRedirects(): Promise<Array<{ to: string, from: string }>>;
  updateRedirects(redirects: Array<{ path: string, value: string }>): Promise<void>;
  buildEcommerce(): Promise<void>;
  getRdStation(): Promise<RdForm>;
  updateRdStation(credentials: RdForm): Promise<void>;
  activateRDStationIntegration(): Promise<void>;
}

const SettingsContext = createContext<SettingsContextData>({} as SettingsContextData);

export const SettingsProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [settings, setSettings] = useState<Settings>(() => (null as unknown as Settings));
  const [locations, setLocations] = useState({} as Locations);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchSettings = useCallback(async (params: Record<string, unknown>) => {
    setLoading(true);

    const shipping_city = await api.get('/v2/freeshipping/city');
    const shipping_country = await api.get('/v2/freeshipping/country');

    const response = await api.get('/v2/store/domain', {
      params: {
        ...params,
      },
    });

    setSettings({ ...response.data, shipping_city: shipping_city.data.items, shipping_country: shipping_country.data.items });
    setLoading(false);

    return params;
  }, []);

  const updateSettings = useCallback(async (id: number, data: Settings) => {
    await api.put(`/v2/store/domain/${id}`, data);
    await fetchSettings({});
  }, [fetchSettings]);

  const updateColors = useCallback(async (data: any) => {
    await api.put('/v2/store/colors', data);
    await fetchSettings({});
  }, [fetchSettings]);

  const fetchPaymentConfig = useCallback(async () => {
    const response = await api.get('/v2/store/commercial-conditions');

    return response.data;
  }, []);

  const updatePaymentConfig = useCallback(async (data: any) => {
    await api.put('/v2/store', data);
    await fetchSettings({});
  }, [fetchSettings]);

  const updateLogos = useCallback(async (data: any) => {
    await api.put('/v2/store/logos', data);
    await fetchSettings({});
  }, [fetchSettings]);

  const updatePolicy = useCallback(async (data: any, section: any) => {
    await api.put(`/v2/section/${section}`, data);
    await fetchSettings({});
  }, [fetchSettings]);

  const getSections = useCallback(async () => {
    const response = await api.get('/v2/sections');
    return response.data;
  }, []);

  const updateSocialMedia = useCallback(async (data: any) => {
    await api.put('/v2/store/social', data);
    await fetchSettings({});
  }, [fetchSettings]);

  const getCredentials = useCallback(async () => {
    const response = await api.get('/v2/store/credentials');
    return response.data;
  }, []);

  const updateCredentials = useCallback(async (data: any) => {
    await api.put('/v2/store/credentials', data);
    await fetchSettings({});
  }, [fetchSettings]);

  const updatePharmacy = useCallback(async (data: any) => {
    setLoading(true);
    await api.put('/v2/branch/main/pharmacy', data);

    await fetchSettings({});

    setLoading(false);
  }, [fetchSettings]);

  const updateAttendance = useCallback(async (data: any) => {
    setLoading(true);
    await api.put('/v2/store', data);

    await fetchSettings({});

    setLoading(false);
  }, [fetchSettings]);

  const fetchLocations = useCallback(async (branch_id: string) => {
    const { data } = await api.get<{items: TransportData[]}>(`/v2/branch/${branch_id}/logistics/transporter/list`);

    setLocations(data);
    return data;
  }, []);

  const getContact = useCallback(async () => {
    const response = await api.get('/v2/store/contact');

    return response.data;
  }, []);

  const updateContact = useCallback(async (credentials: any) => {
    await api.put('/v2/store/contact', { ...credentials });
    await fetchSettings({});
  }, [fetchSettings]);

  useEffect(() => {
    (async () => {
      await fetchSettings({});
    })();
  }, [fetchSettings]);

  const addLocation = useCallback(async (data: any) => {
    await api.post('/v2/logistics/transporter', data);

    await fetchLocations(data.branch_id);
    await fetchSettings({});
  }, [fetchSettings, fetchLocations]);

  const removeLocation = useCallback(async ({ id, branch_id }: RemoveLocation) => {
    await api.delete(`/v2/logistics/transporter/${id}`);

    await fetchLocations(branch_id);
    await fetchSettings({});
  }, [fetchSettings, fetchLocations]);

  const updateLocation = useCallback(async ({ id, body, branch_id }: any) => {
    await api.put(`/v2/logistics/transporter/${id}`, body);

    await fetchLocations(branch_id);
    await fetchSettings({});
  }, [fetchSettings, fetchLocations]);

  const getRedirects = useCallback(async () => {
    const response = await api.get('/v2/store/redirects');
    return response?.data?.items || [];
  }, []);

  const updateRedirects = useCallback(async (redirects: any) => {
    await api.put('/v2/store/redirect/replace', redirects);

    await fetchSettings({});
  }, [fetchSettings]);

  const updateRdStation = useCallback(async (credentials: any) => {
    await api.put('/v2/rdstation', credentials);

    await fetchSettings({});
  }, [fetchSettings]);

  const getRdStation = useCallback(async () => {
    const response = await api.get('/v2/rdstation');

    return response.data;
  }, []);

  const activateRDStationIntegration = useCallback(async () => {
    const response = await api.get('/v2/rdstation/new-code');

    return response.data;
  }, []);

  const buildEcommerce = useCallback(async () => {
    const response = await api.get('/v2/admin/deployment/nextjs/new');

    return response.data;
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        fetchSettings,
        updateSettings,
        updateColors,
        fetchPaymentConfig,
        updatePaymentConfig,
        updateLogos,
        updatePolicy,
        getSections,
        updateSocialMedia,
        getCredentials,
        updateCredentials,
        updatePharmacy,
        updateAttendance,
        fetchLocations,
        addLocation,
        removeLocation,
        updateLocation,
        isLoading,
        settings,
        locations,
        getContact,
        updateContact,
        getRedirects,
        updateRedirects,
        updateRdStation,
        getRdStation,
        activateRDStationIntegration,
        buildEcommerce,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export function useSettings(): SettingsContextData {
  const context = useContext(SettingsContext);

  if (!context) { throw new Error('useSettings must be used within an BranchProvider'); }

  return context;
}
