import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Grid, IconButton, TableCell } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
/* import CloseIcon from '@material-ui/icons/Close'; */

import { toast } from 'react-toastify';
import { StyledTooltip } from '../tooltip';
import { useStyles } from '../styles';
import DeleteModal from '../delete-modal';
import { useBranch } from '../../contexts/branch';
import { useAuth } from '../../contexts/auth';

export default function Data({ branch }) {
  const styles = useStyles();

  const { verifyPerms } = useAuth();
  const { deleteBranch } = useBranch();
  const [openModal, setModalOpen] = useState(false);
  const { has_order_expedition, has_order_pickup } = branch?.address || {};
  const operation = (has_order_pickup && has_order_expedition && 'Balcão/Entrega') || (has_order_pickup && 'Balcão') || (has_order_expedition && 'Entrega');

  const handleDeleteBranch = useCallback(async () => {
    try {
      await deleteBranch(branch.id);

      toast.success(`${branch?.address?.title} removida.`);
    } catch (err) {
      toast.error(`Não foi possível deletar a loja ${branch?.address?.title}.`);
    }
  }, [deleteBranch, branch]);

  return (
    <>
      <TableCell>{branch?.address?.title || (branch?.addresses?.length && branch?.addresses[0]?.title)}</TableCell>
      <TableCell>{branch?.addresses?.length && branch?.addresses[0]?.city}</TableCell>
      <TableCell>{branch?.is_central ? 'Matriz' : 'Filial'}</TableCell>
      <TableCell>{operation}</TableCell>
      <TableCell>{branch?.bank_account}</TableCell>

      <TableCell style={{ width: '160px' }}>
        <Grid container direction="row" justify="flex-start">
          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar Loja" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'read' })}
                className={styles.actionButton}
                component={Link}
                to={{
                  pathname: `/branch/${branch.id}`,
                  state: branch,
                }}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>

            {/* {!branch?.is_central && (
            <StyledTooltip title="Editar Loja" placement="top" className={styles.tableButton}>
              <IconButton
                className={styles.actionButton}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" onClick={() => setModalOpen(!openModal)} />
              </IconButton>
            </StyledTooltip>
            )} */}

          </Grid>
        </Grid>
      </TableCell>

      {!branch?.is_central && (
      <DeleteModal openModal={openModal} setModal={setModalOpen} deleteFunction={handleDeleteBranch} type="loja" title={branch?.address?.title} prefix="a" />
      )}
    </>
  );
}
