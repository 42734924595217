import React from 'react';

import styled, { css } from 'styled-components';

const Styled = styled.span`
  color: #424242;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 33px;

  ${props => props.color === 'theme' && css`
    color: ${props.theme.main_color};
  `}

  ${props => props.color && css`
    color: ${props.color};
  `}

  ${props => props.mt && css`
    margin-top: ${props.mt} !important;
  `}

  @media only screen and (min-device-width: 320px) and (max-device-width: 736px){
    ${props => props.smt && css`
      margin-top: ${props.smt};
    `}

    ${props => props.sSize && css`
      font-size: ${props.sSize};
    `}
  }
`;

export default function Title({ children, ...props }) {
  return (
    <Styled {...props}>{children}</Styled>
  );
}
