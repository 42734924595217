import React, { useState, useEffect } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './styles';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function AutocompleteField({
  dataOption,
  textValue,
  renderLabel,
  options,
  setOptions,
  selected,
  setSelected,
  renderOptions,
  ...rest
}) {
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (textValue && textValue.length >= 3) {
        if (active) {
          const resp = await dataOption;
          setOptions(resp);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, textValue, dataOption, setOptions]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open, setOptions]);

  return (
    <div className={styles.searchDiv2}>
      <Autocomplete
        open={open}
        onOpen={() => setOpen(!open)}
        onClose={() => setOpen(!open)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={renderLabel}
        value={selected}
        options={options}
        onChange={(e, newValue) => setSelected(newValue)}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...rest}
            {...params}
            variant="outlined"
            className={styles.searchField3}
            value={textValue}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                loading ? <CircularProgress size={12.5} className={styles.circularProgressIcon} /> : <SearchIcon className={styles.circularProgressIcon} />
              ),
            }}
          />
        )}
        renderOption={(option) => renderOptions(option)}
      />
    </div>
  );
}
