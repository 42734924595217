import { pick } from 'lodash';

import store from '../store';
import api from '../api';
import errHandler from './_errhandler';

const includeKeysByEndpoint = {
  categories: [],
};

function getQueryId(params) {
  return JSON.stringify(params, Object.keys(params).sort());
}

export function updateCachedData(endpoint, items) {
  if (!items) return;

  const itemsArray = Array.isArray(items) ? items : [items];

  const key = `${endpoint}_byId`;

  const time = Date.now();
  const itemsById = itemsArray.reduce((memo, item) => {
    const oldItem = store.get()[key][item.id];
    const includeData = pick(oldItem, includeKeysByEndpoint[endpoint]);

    const newItem = {
      ...includeData,
      ...item,
      _fetched: time,
    };

    return {
      ...memo,
      [item.id]: newItem,
    };
  }, {});

  store.set({
    [key]: {
      ...store.get(key),
      ...itemsById,
    },
  });
}

export async function fetchOne(endpoint, id, queryParams = {}) {
  if (!id) return null;

  if (endpoint === 'product') {
    const response = await api.get(`/v2/store/${endpoint}/${id}/edit`, { params: queryParams })
      .then(res => res.data)
      .catch(errHandler);

    updateCachedData(endpoint, { ...response, id });

    return response;
  }

  const response = await api.get(`/v2/store/${endpoint}/${id}`, { params: queryParams })
    .then(res => res.data)
    .catch(errHandler);

  updateCachedData(endpoint, response);

  return response;
}

export function fetchOneCached(endpoint, id, initialParams) {
  if (!id) return null;

  const itemsById = store.get(`${endpoint}_byId`);

  let item = itemsById[id];

  if (!item && initialParams.altIdKey) {
    const altId = id.toLowerCase();

    item = Object.values(itemsById).find((i) => i[initialParams.altIdKey] === altId);
  }

  if (!item) return null;

  return {
    data: item,
    fetched: item._fetched,
  };
}

export async function fetch(endpoint, params = {}, { cancelToken } = {}) {
  try {
    const res = await api.get(`/v2/store/${endpoint}`, { cancelToken, params });

    const { items, total_count } = res.data;

    store.set({ [`${endpoint}_results`]: total_count });
    updateCachedData(endpoint, items);

    const queries = store.get(`${endpoint}_queries`);
    const queryId = getQueryId(params);
    const itemIds = items.map((item) => item.id);

    store.set({
      [`${endpoint}_queries`]: {
        ...queries,
        [queryId]: {
          _fetched: Date.now(),
          ids: itemIds,
        },
      },
    });

    return items;
  } catch (err) {
    errHandler(err);
    return null;
  }
}

export function fetchCached(endpoint, params = {}) {
  const queries = store.get(`${endpoint}_queries`);
  const itemsById = store.get(`${endpoint}_byId`);

  const queryId = getQueryId(params);
  const storedQuery = queries[queryId];

  if (!storedQuery || !storedQuery.ids) return null;

  const items = storedQuery.ids.reduce((memo, id) => {
    const item = itemsById[id];
    if (!item) return memo;

    return [...memo, item];
  }, []);

  return {
    data: items,
    fetched: storedQuery._fetched,
  };
}
