import styled from 'styled-components';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export const TextArea = styled(TextareaAutosize)`
  border: 1px solid #E1E1E1;
  border-radius: 0 0 5px 5px;
  color: #424242;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 18px;
  margin-bottom: -6px;
  padding: 20px 24px 0;
  width: 100%;
`;
