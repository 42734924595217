import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import PrivateRoute from './private-route';
import PublicRoute from './public-route';

import Branch from '../components/branch';
import BranchList from '../components/branch-list';
import Category from '../components/category';
import PromoCode from '../components/promo-code';
import PromoCodeList from '../components/promo-code-list';
import CustomerDetails from '../components/customer-details';
import CustomerList from '../components/customer-list';
import Home from '../components/home';
import Layout from '../components/layout';
import Login from '../components/login';
import NewCategory from '../components/category-new';
import OrdersList from '../components/orders';
import Product from '../components/product';
import ProductList from '../components/product-list';
import Spinner from '../components/spinner';
import Settings from '../components/settings';
import Contributors from '../components/organization/contributors';
import RolesAndPermissions from '../components/organization/roles-and-permissions';
import EditRolesAndPermissions from '../components/organization/roles-and-permissions/edit-roles-persmissions';
import AddContributor from '../components/organization/contributors/add-contributor';
import Team from '../components/organization/team';
import Pagarme from '../components/finance/pagarme';
import BlogPost from '../components/makerting/blog/index';
import AddBlogPost from '../components/makerting/blog/add-post';
import PrintOrder from '../components/orders/print';

const DynamicLoader = (Component) => (props) => (
  <Suspense fallback={<Spinner />}>
    <Component {...props} />
  </Suspense>
);

const DevUiPreview = DynamicLoader(lazy(() => import('../components/_dev/ui')));

const Router = () => (
  <BrowserRouter component={Layout}>
    <Switch>
      <PrivateRoute exact path="/" component={OrdersList} layout="default" />
      <PrivateRoute exact path="/_dev/ui" component={DevUiPreview} layout="default" />
      <PrivateRoute exact path="/branch" component={BranchList} layout="default" />
      <PrivateRoute exact path="/branch/:id?" component={Branch} layout="default" />
      <PrivateRoute exact path="/categories" component={Category} layout="default" />
      <PrivateRoute exact path="/categories/:id?" component={NewCategory} layout="default" />
      <PrivateRoute exact path="/promocode" component={PromoCodeList} layout="default" />
      <PrivateRoute exact path="/promocode/:id" component={PromoCode} layout="default" />
      <PrivateRoute exact path="/customers" component={CustomerList} layout="default" />
      <PrivateRoute exact path="/customers/:id" component={CustomerDetails} layout="default" />
      <PrivateRoute exact path="/dashboard" component={Home} layout="default" />
      <PrivateRoute exact path="/financial" component={Home} layout="default" />
      <PrivateRoute exact path="/product" component={ProductList} layout="default" />
      <PrivateRoute exact path="/products" component={ProductList} layout="default" />
      <PrivateRoute exact path="/products/new" component={Product} layout="default" />
      <PrivateRoute exact path="/products/:id?" component={Product} layout="default" />
      <PrivateRoute exact path="/orders/:id?" component={OrdersList} layout="default" />
      <PrivateRoute exact path="/orders/:id/print" component={PrintOrder} />
      <PrivateRoute exact path="/organization" component={Home} layout="default" />
      <PrivateRoute exact path="/team" component={Team} layout="default" />
      <PrivateRoute exact path="/contributors" component={Contributors} layout="default" />
      <PrivateRoute exact path="/add-contributor/:id?" component={AddContributor} layout="default" />
      <PrivateRoute exact path="/roles-and-permissions" component={RolesAndPermissions} layout="default" />
      <PrivateRoute exact path="/edit-roles-and-permissions/:id?" component={EditRolesAndPermissions} layout="default" />
      <PrivateRoute exact path="/pagarme" component={Pagarme} layout="default" />
      <PrivateRoute exact path="/blog" component={BlogPost} layout="default" />
      <PrivateRoute exact path="/blog-post" component={AddBlogPost} layout="default" />
      <PrivateRoute exact path="/blog-post/:id?" component={AddBlogPost} layout="default" />
      <PrivateRoute exact path="/settings" component={Settings} layout="default" />

      <PublicRoute exact path="/login/:step?/:cpf?" component={Login} layout="login" />
      <PublicRoute exact path="/logout" component={Home} layout="default" />
    </Switch>
  </BrowserRouter>
);

export default Router;
