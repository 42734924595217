import React, { useState } from 'react';

// Libs
import { Grid } from '@material-ui/core';
// import { Form } from '@unform/web';
// import { useHistory, useLocation } from 'react-router-dom';

// Icons
// import SortIcon from '@material-ui/icons/Sort';
// import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

// Components
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
// import SearchField from '../../form/search-field';
// import Spinner from '../../spinner';
// import TableMaterial from '../../table';
// import Select from '../../form/select';

import TabNavigator from '../../tabs-navigation';
import Overview from './overview';
import Transactions from './transactions';
import Recivables from './receivables';
import Configurations from './configurations';

// Schemas
import { tabs } from './schema';

// Styles
import { PageTitle, useStyles } from '../../styles';

function Pagarme() {
  /** CONSTS */
  const styles = useStyles();
  // const formRef = useRef();
  // const advancedFilterFormRef = useRef();
  // const history = useHistory();
  // const { search } = useLocation();

  const switchTab = {
    0: <Overview />,
    1: <Transactions />,
    2: <Recivables />,
    3: <Configurations />,
  };

  /** STATES */
  // const [parseSearch] = useState('');
  // const [showFilter, setShowFilter] = useState(false);
  // const [isLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  /** CALLBACKS */
  // const handleParams = useCallback((param, data) => {
  //   const getAllParams = new URLSearchParams(search);
  //   const allParams = Object.fromEntries(getAllParams);

  //   const params = {};
  //   params[param] = data;

  //   let newParams = {};

  //   if (allParams.ascending === '1' && allParams[param] === data) {
  //     newParams = { ...allParams, ...params, ascending: 1 };
  //   } else {
  //     newParams = { ...allParams, ...params, ascending: 0 };
  //   }

  //   if (param === 'q') {
  //     newParams.p = 1;
  //     const isEqual = allParams?.q === data;
  //     if (!isEqual) {
  //       formRef.current.reset();
  //     }
  //   }

  //   // return history.push({
  //   //   pathname: '/products',
  //   //   search: `?${new URLSearchParams(newParams)}`,
  //   // });
  // }, [search]);

  return (
    <>
      <Breadcrumb
        title="Vendas e Recebíveis"
        text="Financeiro"
        current="Pagar.me"
      />
      <ContentWrapper align="top">
        <Grid container direction="row" alignItems="center" className={styles.gridRow}>
          <Grid item lg={3}>
            <PageTitle>Pagar.me</PageTitle>
          </Grid>
          <Grid item lg={9}>
            <TabNavigator
              tabs={tabs}
              activeTab={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
            />
          </Grid>
        </Grid>

        {switchTab[activeTab]}

      </ContentWrapper>
    </>
  );
}

export default Pagarme;
