import React, { useState } from 'react';
import { remove, map } from 'lodash';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';

import ImageField from '../../form/image-upload';
import Modal from '../../modal';
// import SearchField from '../../form/search-field';
import SubtitleCard from '../../title/subtitle-card';
import TableMaterial from '../../table';
import TextField from '../../form/text-field';
import Select from '../../form/select';

import ProductsListData from './products-list-data';
import NewProducts from './new-products';

import { productsSchema } from '../schemas';

import useStyles from './styles';
import { AddButton, Container } from '../../styles';

import actions from '../../../actions';

export default function NewSubcategory({
  subProducts,
  setSubProducts,
  subcategories,
  editData,
  setEdit,
  options,
}) {
  const styles = useStyles();

  const [modal, setModal] = useState(false);
  const [productValues, setProductValues] = useState();
  const [productsData, setProductsData] = useState([]);
  const [newSub, setNewSub] = useState();

  const openModal = () => {
    setModal(true);
    setProductsData([]);
    setProductValues();
  };

  const closeModal = () => {
    setModal(false);
  };

  function handleSubmit() {
    const productList = () => (subProducts
      ? [...subProducts, productsData]
      : [productsData]);

    setSubProducts(productList());
    // setProducts([...products, productsData]);

    closeModal();
  }

  function lengthLabel(data) {
    if (data) {
      return data.name ? data.name.length : 0;
    }
    return newSub ? newSub.length : 0;
  }

  function renderLogo(data) {
    if (data) {
      return data && data.logo;
    }
    return subcategories && subcategories.logo;
  }

  function changeName(data, e) {
    if (data) {
      return setEdit({ ...editData, name: e.target.value });
    }
    return setNewSub(e.target.value);
  }

  function getValue(data) {
    if (data) {
      return data ? data.name : '';
    }
    return newSub || '';
  }

  async function deleteProduct(id) {
    if (editData) {
      remove(subProducts, (item) => item.id === id);

      const arrProd = map(subProducts, 'id');

      const resp = await actions.category.putCategory(editData.id, { replace_products: arrProd });

      if (resp) {
        const getArray = await actions.category.getCategoryByID(editData.id);

        setSubProducts(getArray.items[0].products);
      }

      return arrProd;
    }
    remove(subProducts, (item) => item.id === id);

    setSubProducts([...subProducts]);

    return subProducts;
  }

  const renderProducts = (item) => <ProductsListData product={item} deleteProduct={deleteProduct} />;

  const renderItem = ({ item, handleValue }) => <MenuItem key={item} value={item} onClick={handleValue}>{item}</MenuItem>;

  return (
    <>
      <Container>
        <Grid container direction="row">
          <Grid item>
            <ImageField name="logo" acceptImg=".svg" previewImg={renderLogo(editData)} />
          </Grid>

          <Grid item className={styles.gridSubcategoryFields}>
            <Grid item xs={12} className={styles.gridCategoryTextField}>
              <TextField
                name="name"
                label="Nome da subcategoria"
                placeholder="Nome da subcategoria"
                inputProps={{ maxlength: 20 }}
                helperText={`
                  ${lengthLabel(editData)}/20 Caracteres
                `}
                value={getValue(editData)}
                onChange={(e) => changeName(editData, e)}
              />
            </Grid>

            <Grid item xs={12} className={styles.gridCategoryTextField}>
              <Select
                name="position"
                placeholder="Ordernar na Posição"
                label="Ordernar na Posição"
                options={options}
                InputProps={{
                  startAdornment: <InputAdornment className={styles.orderAdornment}>#</InputAdornment>,
                }}
                initialValue={editData?.index || subcategories?.length + 1 || 1}
              >
                {renderItem}
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={12} className={styles.gridSubcategoryList}>
            <SubtitleCard>Lista de produtos inclusos</SubtitleCard>
          </Grid>

          <Grid container direction="row" alignItems="center" xs={12} className={styles.gridProductsModal}>
            {/* <Grid item>
              <SearchField name="search" label="Filtrar por nome do produto" placeholder="Filtrar por nome do produto" />
            </Grid> */}

            <Grid item direction="row" alignItems="center" className={styles.gridProductsModalAdd}>
              <Grid item onClick={openModal} style={{ cursor: 'pointer' }}>
                <IconButton type="button" className={styles.iconButton}>
                  <AddCircleOutlineIcon fontSize="small" color="primary" />
                </IconButton>

                <AddButton type="button">Adicionar Produto</AddButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs className={styles.gridProducts}>
            <TableMaterial
              headers={productsSchema}
              renderItem={renderProducts}
              listQuery={subProducts || []}
              noPagination
            />
          </Grid>
        </Grid>
      </Container>

      <Modal
        isVisible={modal}
        title="Adicionar produto a Subcategoria"
        size="xs"
        toggle={closeModal}
        buttonPrimary="adicionar"
        buttonSecondary="cancelar"
        onSubmit={handleSubmit}
      >
        <NewProducts
          category
          products={productsData}
          setProducts={setProductsData}
          values={productValues}
          setValues={setProductValues}
        />
      </Modal>
    </>
  );
}
