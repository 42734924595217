const CustomerSchema = [
  {
    name: 'personalInformation',
    label: 'Dados Pessoais',
  },
  {
    name: 'orderHistory',
    label: 'Pedidos',
  },
  // {
  //   name: 'patients',
  //   label: 'Pacientes',
  // },
];

export default CustomerSchema;
