import { InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import { remove } from 'lodash';
import React from 'react';
import TextField from '../../form/text-field';
import { ActionButton, useStyles } from '../../styles';
import Text from '../../text/text';
import { StyledTooltip } from '../../tooltip';

export default function RedirectList({ redirects, setRedirect }) {
  const styles = useStyles();
  const removeInput = item => {
    const list = [...redirects];
    remove(list, (data) => data === item);
    setRedirect(list);
  };

  return (
    <>
      {redirects.map((x, i) => (
        <Grid item xs={12} sm={12} key={x.value}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={4} sm={4}>
              <TextField
                name={`replace_redirects[${i}].path`}
                InputProps={{
                  startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">seu domínio/</Text></InputAdornment>,
                }}
                label="De"
                defaultValue={x?.path}
                InputLabelProps={{
                  shrink: true,
                }}

              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <TextField
                name={`replace_redirects[${i}].value`}
                InputProps={{
                  startAdornment: <InputAdornment><Text color="#A3A3A3" mt="18px">seu domínio/</Text></InputAdornment>,
                }}
                label="Para"
                defaultValue={x?.value}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={1} className={styles.gridButtonContainer}>
              <Grid container direction="row" justify="center" alignContent="center" spacing={1}>
                {i > 0
                  && (
                    <Grid item xs={4} className={styles.gridButton}>
                      <StyledTooltip title="Remover" placement="top">
                        <ActionButton className={styles.actionButton} onClick={() => removeInput(x)}>
                          <CloseIcon className={styles.icon} color="disabled" fontSize="default" />
                        </ActionButton>
                      </StyledTooltip>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
