import api from '../api';
import errHandler from './_errhandler';
import store from '../store';

export const getSettings = async () => {
  const fetchSettings = await api.get('/v2/store/domain')
    .then(res => {
      const settings = res.data;

      store.set({ settings });

      return settings;
    })
    .catch(errHandler);

  return fetchSettings;
};

export const getCredentials = async (domain) => {
  const fetchCredentials = await api.get(`/v2/store/info?domain=${domain}`)
    .then(res => {
      const { data } = res;

      localStorage.setItem('public_jwt', `Bearer ${data.session_jwt}`);

      return res.data;
    })
    .catch(errHandler);

  return fetchCredentials;
};
