import axios from 'axios';
import config from './config';

axios.interceptors.request.use((req) => {
  req.url = config.apiUrl + req.url;
  return req;
});

// Add Authorization header
axios.interceptors.request.use((req) => {
  if (!req.headers.Authorization) {
    const token = localStorage.getItem('accessToken'); // || localStorage.getItem('public_jwt');

    if (token) {
      req.headers.Authorization = token;
    }
  }

  return req;
});

// Handle errors; redirect to login on Unauthorized.
axios.interceptors.response.use(
  (res) => res,

  (res) => {
    const err = (res.response && res.response.data && res.response.data.error) || res;
    err.status = (res.response && res.response.status) || res.status;
    return Promise.reject(err);
  },
);

export default axios;
