import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, TableCell } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Modal from '../../../modal';
import City from './city';

import { StyledTooltip } from '../../../tooltip';
import useStyles from '../styles';

import { useSettings } from '../../../../contexts/settings';
import DeleteLocation from './delete';
import { useAuth } from '../../../../contexts/auth';

export default function DataCity({ data }) {
  const styles = useStyles();
  const formRef = useRef();

  const { verifyPerms } = useAuth();
  const { updateLocation } = useSettings();
  const { id: branch_id } = useParams();

  const dataCity = data;

  const [modalCity, setModalCity] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [cityWeekDays, setCityWeekDays] = useState(data?.week_availibility);

  const updateCity = useCallback(async () => {
    setLoading(true);
    const dataCityForm = formRef.current.getData();
    try {
      const shipping_time = Number(dataCityForm.city.shipping_time);
      await updateLocation({ id: data?.id, branch_id, body: { ...dataCityForm.city, ships_low_temperature: dataCityForm.city.ships_low_temperature, shipping_time, week_availibility: cityWeekDays } });

      setModalCity(!modalCity);

      toast.success(`A cidade ${dataCityForm.city.city} foi atualizada!`);
      setLoading(false);
    } catch (err) {
      toast.error('Ocorreu um erro');
      setLoading(false);
    }
  }, [data, branch_id, updateLocation, cityWeekDays, modalCity]);

  const days = useMemo(() => {
    const formatDays = {
      monday: 'SEG ',
      tuesday: 'TER ',
      wednesday: 'QUA ',
      thursday: 'QUI ',
      friday: 'SEX ',
      saturday: 'SÁB ',
      sunday: 'DOM ',
    };

    const mapped = data.week_availibility.map((item) => formatDays[item]);
    return mapped;
  }, [data]);

  const modality = useMemo(() => {
    const formatModality = {
      ground: 'Terrestre',
      air: 'Aéreo',
      sea: 'Marítimo',
    };

    const formtedModality = formatModality[data.service_type];

    return formtedModality;
  }, [data]);

  return (
    <>
      <TableCell>{data?.state}</TableCell>
      <TableCell>{data?.city}</TableCell>
      <TableCell>{days}</TableCell>
      <TableCell>{Number(data?.service_cost).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell>{data?.service_name}</TableCell>
      <TableCell>{data?.shipping_time} Dias</TableCell>
      <TableCell>{modality}</TableCell>
      <TableCell>{data?.ships_low_temperature ? 'Sim' : 'Não'}</TableCell>

      <TableCell style={{ width: '120px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar Cidade" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'write' })}
                className={styles.actionButton}
                onClick={() => setModalCity(!modalCity)}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Remover Cidade" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => setDeleteModal(true)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>
      <Form ref={formRef} initialData={{ city: dataCity }}>

        <Modal
          isVisible={modalCity}
          title="Atualizar Cidade"
          isLoading={isLoading}
          toggle={() => setModalCity(!modalCity)}
          buttonPrimary="atualizar"
          buttonSecondary="cancelar"
          onClick={updateCity}
        >

          <City weekDays={cityWeekDays} setWeekDays={setCityWeekDays} />
        </Modal>
      </Form>

      <DeleteLocation openModal={deleteModal} setModal={setDeleteModal} id={data.id} location={data.city} />

    </>
  );
}
