import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  gridRow: {
    marginBottom: '12px',
  },

  personalFormPadding: {
    padding: '14px 24px 12px 24px',
  },
}));

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: 385px;
  height: 100px;

  padding: 12px;
  border: 2px solid #E1E1E1;
  border-radius: 16px;

  /* ${props => props.isAddress && css`
    grid-template-columns: 15px 4fr 1fr 1fr;
  `} */

  @media (max-width: 450px){
    width: 100%;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;

  p {
    font-size: 12px;
    line-height: 20px;
    text-align: left;

    ${props => props.expired && css`
      color: #D16262;
    `}
  }
`;

export const StarContainer = styled.div`
  display: flex;

  padding: 8px 5px;
`;
