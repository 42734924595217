import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { FormControl, MenuItem, RadioGroup, Radio } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import Select from '../form/select';
import TitleCard from '../title/title-card';
import TextField from '../form/text-field';
import RadioButton from '../form/form-radio-button';
import SubtitleCard from '../title/subtitle-card';

import { StyledPaper, useStyles } from '../styles';

export default function Logistic({ setRefrigerated, isRefrigerated }) {
  const styles = useStyles();
  const [weightMeasure, setWeightMeasure] = useState('Gramas');

  return (
    <>
      <TitleCard>Logística</TitleCard>

      <StyledPaper>
        <Grid container direction="row" spacing={2} className={styles.gridRowTextField}>
          <Grid item xs={3}>
            <TextField name="weight" label="Peso" placeholder="Peso" />
          </Grid>

          <Grid item xs={3}>
            <Select
              name="weight_measure"
              label="Un. de peso"
              options={['Gramas', 'Kilos']}
              value={weightMeasure}
              onChange={(e) => setWeightMeasure(e.target.value)}
            >
              {({ item }) => <MenuItem key={item} value={item}>{item}</MenuItem>}
            </Select>
          </Grid>

          <Grid item xs={3}>
            <TextField
              name="max_qty"
              label="Max./Pedido (un)"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment className={styles.adornmentEnd}><span>unidades</span></InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              name="assembly_days"
              label="Prazo Expedição (dias)"
              InputProps={{
                endAdornment: <InputAdornment className={styles.adornmentEnd}><span>dias</span></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction="column" className={styles.gridColumn}>
          <SubtitleCard>Armazenagem e Transporte</SubtitleCard>

          <Grid className={styles.formControlGridColumn}>
            <FormControl required>
              <RadioGroup value={isRefrigerated}>
                <Grid container direction="row">
                  <RadioButton
                    value={false}
                    control={<Radio color="primary" />}
                    label="Temperatura Ambiente"
                    style={{ marginRight: '40px' }}
                    onClick={() => setRefrigerated(false)}
                  />

                  <RadioButton
                    value
                    control={<Radio color="primary" />}
                    label="Manter Refrigerado"
                    onClick={() => setRefrigerated(true)}
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
