import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Grid, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';

import ContentWrapper from '../content-wrapper';
import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import Select from '../form/select';
import SearchField from '../form/search-field';
import TableMaterial from '../table';
import { Container, PageTitle, useStyles } from '../styles';

import { schema } from './schema';
import DataOrdersRow from './data';
import OrderDetails from './details';

import { useOrder } from '../../contexts/order';
import Spinner from '../spinner';
import FilterStatus from './filter-status';

export default function OrdersList() {
  const styles = useStyles();
  const formRef = useRef();
  const params = useParams();
  const history = useHistory();

  const { search } = useLocation();
  const [parseSearch, setParseSearch] = useState('');

  const [isDetails, setDetails] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [orderAlias, setOrderAlias] = useState(null);

  const [shortId, setShortId] = useState(0);

  const [isVisible, setVisible] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const { orders, fetchOrders, isLoading } = useOrder();

  const renderItem = (order) => <DataOrdersRow order={order} handleDetails={() => history.push(`/orders/${order.id}`)} />;

  useEffect(() => {
    function getOrder() {
      if (params?.id) {
        setOrderId(params.id);
        setDetails(true);
      } else {
        setDetails(false);
      }
    }

    getOrder();
  }, [params]);

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const getOrders = async () => {
      await fetchOrders(allParams);
    };

    getOrders();
  }, [fetchOrders, search]);

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    setInitialData({ status: allParams?.status || 0, short_id: allParams?.short_id ? '1' : '0', search: allParams?.customer_name || allParams?.short_id });
    setParseSearch(allParams?.customer_name || allParams?.short_id || allParams?.['inquiry-display-id'] || '');
  }, [search]);

  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);

    const allParams = Object.fromEntries(getAllParams);

    const paramsObj = {};
    paramsObj[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...paramsObj, ascending: 1 };
    } else {
      newParams = { ...allParams, ...paramsObj, ascending: 0 };
    }

    if (param === 'customer_name' || param === 'status' || param === 'short_id' || param === 'inquiry-display-id') {
      newParams.p = 1;

      switch (param) {
        case 'short_id':
          newParams.customer_name = '';
          newParams.status = 0;
          break;
        case 'inquiry-display-id':
          newParams.customer_name = '';
          newParams.short_id = '';
          break;
        default:
          newParams.short_id = '';
          newParams['inquiry-display-id'] = '';
      }
    }

    return history.push({
      pathname: '/orders',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  const options = [
    { id: 0, type: 'Todos Pedidos' },
    { id: 10, type: 'Pedido Criado' },
    { id: 20, type: 'Pedido Aprovado' },
    { id: 30, type: 'Pedido Em Preparação' },
    { id: 50, type: 'Pedido Expedido' },
    { id: 55, type: 'Pedido Cancelado' },
  ];

  const renderStatus = ({ item }) => (
    <MenuItem key={item.id} value={item.id} onClick={() => handleParams('status', item.id)}>
      {item.type}
    </MenuItem>
  );

  const renderSortBy = ({ item }) => (
    <MenuItem key={item.id} value={item.id} onClick={() => setShortId(item.id)}>
      {item.type}
    </MenuItem>
  );

  const handleClear = useCallback(() => {
    const paramsList = ['short_id', 'customer_name', 'inquiry-display-id'];

    paramsList.forEach((param) => {
      handleParams(param, '');
    });

    formRef.current.setFieldValue('search', '');
  }, [handleParams]);

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    const newAdminUrl = process.env.REACT_APP_ADMIN_V2_URL;

    if (token) {
      window.location.href = `${newAdminUrl}/auth?token=${token}&redirect=/orders`;
    }
  }, []);

  return (
    <>
      <Breadcrumb title="Lista de Pedidos" text={isDetails && orderAlias ? `Pedidos > ${orderAlias}` : 'Pedidos'} />

      <ContentWrapper align="top">
        {!isDetails && (
          <Grid container direction="row" alignItems="center" className={styles.gridRow}>
            <Grid item xs={3} className={styles.gridTitle}>
              <PageTitle>Lista de Pedidos</PageTitle>
            </Grid>

            <Grid item xs={9}>
              {initialData ? (
                <Form ref={formRef} initialData={initialData} onSubmit={(formData) => handleParams(shortId > 1 ? 'inquiry-display-id' : shortId === 1 ? 'short_id' : 'customer_name', formData.search)}>
                  <div className={styles.divFlexEnd}>
                    <div className={styles.divFilterOrder}>
                      <Select
                        name="status"
                        label="Filtrar por Status"
                        options={options}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >{renderStatus}
                      </Select>
                    </div>

                    <div className={styles.divFilterOrder}>
                      <Select
                        name="short_id"
                        label="Buscar por"
                        options={[{
                          id: 0,
                          type: 'Nome do Cliente',
                        }, {
                          id: 1,
                          type: 'Pedido',
                        }, {
                          id: 2,
                          type: 'Solicitação',
                        }]}
                      >
                        {renderSortBy}
                      </Select>
                    </div>

                    <div className={styles.divSearchField}>
                      <SearchField
                        name="search"
                        placeholder="buscar pedidos"
                        label="buscar pedidos"
                        handleParams={handleParams}
                        search={search}
                        type="submit"
                        parseSearch={parseSearch}
                        clear={handleClear}
                      />
                    </div>
                  </div>
                </Form>
              )
                : (<h1>Loading</h1>)}
            </Grid>
          </Grid>
        )}

        <FilterStatus isVisible={isVisible} setVisible={setVisible} handleParams={handleParams} />

        <Container className={styles.containerTableBottom} style={{ marginTop: isDetails ? '-10px' : '16px' }}>
          {isDetails ? (<OrderDetails orderId={orderId} setDetails={setDetails} setOrderAlias={setOrderAlias} />)
            : (
              isLoading ? <Spinner /> : (
                <TableMaterial
                  headers={schema}
                  listQuery={orders.items}
                  renderItem={renderItem}
                  handleParams={handleParams}
                  pagination={orders.pagination}
                />
              )
            )}
        </Container>
      </ContentWrapper>
    </>
  );
}
