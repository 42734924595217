import React from 'react';

// Libs
import { Button } from '@material-ui/core';

// Icons
import { DolarCoinIcon } from '../assets/icons';

// Styles
import { Container, Circle, CircleContainer } from '../styles';

function Card({ type, status, value, actionType }) {
  const transactionStatusLabel = {
    approved: 'Aprovados',
    waiting: 'Aguardando',
    refused: 'Recusados',
    reversal: 'Estorno',
    chargeBack: 'Chargeback',
    pendent: 'Pendente',
  };

  const SimpleCard = () => (
    <Container type={type}>
      <b>{value}</b>
      <CircleContainer>
        <Circle status={status} />
        <p>{transactionStatusLabel[status]}</p>
      </CircleContainer>
    </Container>
  );

  const ActionCard = () => {
    const buttonContent = {
      balance: 'realizar saque',
      waitingBalance: 'criar antecipação',
      history: 'histórico de antecipações',
    };

    return (
      <Container type={type} actionType={actionType}>
        {actionType === 'history' ? (
          // <div className="actionContent">
          <DolarCoinIcon />
          // </div>
        ) : (
          <div className="actionContent">
            <p>Saldo disponível</p>
            <b>{value}</b>
          </div>
        )}
        <Button
          variant="contained"
        >
          {buttonContent[actionType]}
        </Button>
      </Container>
    );
  };

  const cardTypes = {
    informative: <SimpleCard />,
    action: <ActionCard />,
  };

  return (
    <>
      {cardTypes[type]}
    </>
  );
}

export default Card;
