import React from 'react';

import { List, Hidden, ListItem } from '@material-ui/core';

import {
  ExitToAppOutlined,
  AssessmentOutlined,
  AssignmentOutlined,
  LocalOfferOutlined,
  SentimentSatisfiedOutlined,
  SettingsOutlined,
  PersonOutlineOutlined,
  AttachMoneyOutlined,
  ChevronRightOutlined,
  MessageOutlined,
} from '@material-ui/icons';

import Item from './item';
import { TitleSection, DrawerItem } from './styles';
import { useAuth } from '../../../../contexts/auth';

export default function ListItems() {
  const { verifyPerms } = useAuth();

  const token = window.localStorage.getItem('token');

  const newAdminUrl = process.env.REACT_APP_ADMIN_V2_URL;

  const menu = {
    // dashboard: [{ text: 'Dashboard', link: '/dashboard' }],
    pedidos: [{ text: 'Pedidos', link: '/orders', disabled: false }],
    clientes: [{ text: 'Clientes', link: '/customers', disabled: verifyPerms({ role: 'Clientes', access: 'read' }) }],
    mensageria: [{ text: 'Mensageria', link: '/messenger', disabled: false }],
    financeiro: [{ text: 'Financeiro', link: '', disabled: false }],
    organizacao: [
      // { text: 'Equipe', link: '/team', disabled: true },
      {
        text: 'Colaboradores',
        link: `${newAdminUrl}/auth?token=${token}&redirect=/collaborators`,
        disabled: verifyPerms({
          role: 'Orga. > Colab.',
          access: 'read',
        }),
      },
      { text: 'Cargos e Permissões', link: '/roles-and-permissions', disabled: verifyPerms({ role: 'Orga. > Funç.', access: 'read' }) },
    ],
    catalogo: [
      { text: 'Produtos', link: '/products', disabled: verifyPerms({ role: 'Catálogo', access: 'read' }) },
      { text: 'Categorias', link: '/categories', disabled: verifyPerms({ role: 'Catálogo', access: 'read' }) },
    ],
    // financeiro: [
    //   { text: 'Pagar.me', link: '/pagarme' },
    //   { text: 'Braspag', link: '/braspag' },
    //   { text: 'Caixa', link: '/caixa' },
    // ],
    marketing: [
      { text: 'Blog', link: '/blog', disabled: verifyPerms({ role: 'Marketing', access: 'read' }) },
      { text: 'Cupons', link: '/promocode' },
    ],
    configuracoes: [
      { text: 'Lojas', link: `${newAdminUrl}/auth?token=${token}&redirect=/stores` },
      { text: 'Website', link: '/settings', disabled: verifyPerms({ role: 'Conf. Website', access: 'read' }) },

    ],
    sair: [{ text: 'Sair', link: '/logout' }],
  };

  const handleLogout = async () => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <>
      <List style={{ padding: '0' }}>
        {/* <Item disabled icon={<DashboardOutlined />} items={menu.dashboard} /> */}
        <a
          href={`${newAdminUrl}/auth?token=${token}&redirect=${menu.pedidos[0].link}`} target="_blank" rel="noreferrer noopener" style={{
            textDecoration: 'none',
          }}
        >
          <DrawerItem>
            <ListItem className="noPadding">
              <AssignmentOutlined />

              <Hidden xsDown>
                <p style={{ marginLeft: 16, color: '#424242' }}>{menu.pedidos[0].text}</p>
              </Hidden>

              <ChevronRightOutlined />
            </ListItem>
          </DrawerItem>
        </a>
        <Item icon={<SentimentSatisfiedOutlined />} items={menu.clientes} />

        <TitleSection>Gerenciamento</TitleSection>

        <Item icon={<PersonOutlineOutlined />} items={menu.organizacao} title="Organização" />
        <Item icon={<LocalOfferOutlined />} items={menu.catalogo} title="Catálogo" />
        <a
          href={`${newAdminUrl}/auth?token=${token}&redirect=${menu.financeiro[0].link}`} target="_blank" rel="noreferrer noopener" style={{
            textDecoration: 'none',
          }}
        >
          <DrawerItem>
            <ListItem className="noPadding">
              <AttachMoneyOutlined />

              <Hidden xsDown>
                <p style={{ marginLeft: 16, color: '#424242' }}>{menu.financeiro[0].text}</p>
              </Hidden>

              <ChevronRightOutlined />
            </ListItem>
          </DrawerItem>
        </a>
        <a
          href={`${newAdminUrl}/auth?token=${token}&redirect=${menu.mensageria[0].link}`} target="_blank" rel="noreferrer noopener" style={{
            textDecoration: 'none',
          }}
        >
          <DrawerItem>
            <ListItem className="noPadding">
              <MessageOutlined />

              <Hidden xsDown>
                <p style={{ marginLeft: 16, color: '#424242' }}>{menu.mensageria[0].text}</p>
              </Hidden>

              <ChevronRightOutlined />
            </ListItem>
          </DrawerItem>
        </a>
        {/* <Item disabled icon={<WorkOutlineOutlined />} items={menu.financeiro} title="Financeiro" /> */}
        <Item icon={<AssessmentOutlined />} items={menu.marketing} title="Marketing" />
        <Item icon={<SettingsOutlined />} items={menu.configuracoes} title="Configurações" />

        <Item color="#A3A3A3" icon={<ExitToAppOutlined className="rotateIcon" />} items={menu.sair} onClick={handleLogout} />
      </List>
    </>
  );
}
