import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

interface Customers {
  birthday: string | number;
  company_id: string;
  country: string;
  created_at: string;
  ddd: string;
  ddi: string;
  email: string;
  gender: string;
  has_password: boolean;
  id: string;
  name: string;
  phone: string;
  store_id: string;
  username: string;
  whatsapp: string;
  orders_info: {
    last_created_at: number;
    last_id: string;
    last_seq: string;
    total: number;
  }
}

interface Document {
  country: string;
  customer_id: string;
  date_of_expiry: string;
  date_of_issue: string;
  document: string;
  issuing: string;
  type: string
}

interface Card {
  brand: string;
  brand_logo: string;
  country: string;
  created_at: string;
  customer_id: string;
  expire_date: string;
  fingerprint: string;
  first_digits: string;
  holder_name: string;
  id: string;
  last_digits: string;
  pagarme_id: string;
  store_id: string;
}

interface Address {
  city: string;
  complement: string;
  customer_id: string;
  district: string;
  id: string;
  number: string;
  obs?: string;
  recipient?: string;
  reference?: string;
  street: string;
  title?: string;
  type: string;
  uf: string;
  zip: string;
}

interface CustomerState {
  customer: Customers;
  document: Document[];
  card: Card[];
  address: Address[];
}

interface CustomerContextData {
  isLoading: boolean;
  customers: CustomerState;
  fetchCustomers(params: Record<string, unknown>): Promise<void>;
  getOneCustomer(id: string): Promise<Customers>;
  updateCustomer(data: Record<string, unknown>, id: string): Promise<void>;
  updateCustomerAddress(data: Record<string, unknown>, customer_id: string, address_id: string): Promise<any>;
}

const CustomerContext = createContext<CustomerContextData>({} as CustomerContextData);

export const CustomerProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [customers, setCustomers] = useState<CustomerState>(() => ({} as CustomerState));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchCustomers = useCallback(async (params: any) => {
    setLoading(true);
    const response = await api.get('/v2/customers', {
      params: {
        ...params,
      },
    });

    setCustomers(response.data);
    setLoading(false);
    return response.data;
  }, []);

  const getOneCustomer = useCallback(async (id: string) => {
    const response = await api.get(`/v2/customer/${id}`);

    return response.data;
  }, []);

  const updateCustomer = useCallback(async (data: any, id: string) => {
    await api.put(`/v2/customer/${id}`, data);

    await fetchCustomers({});
  }, [fetchCustomers]);

  const updateCustomerAddress = useCallback(async (data: Record<string, unknown>, customer_id: string, address_id: string) => {
    setLoading(true);
    const response = await api.put(`/v2/customer/${customer_id}/address/${address_id}`, data);
    await fetchCustomers({});
    setLoading(false);

    return response;
  }, [fetchCustomers]);

  useEffect(() => {
    async function getCustomersData() {
      await fetchCustomers({});
    }
    getCustomersData();
  }, [fetchCustomers]);

  return (
    <CustomerContext.Provider
      value={{
        customers, fetchCustomers, getOneCustomer, updateCustomer, isLoading, updateCustomerAddress,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export function useCustomer(): CustomerContextData {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error('useCustomer must be used within an CustomerProvider');
  }
  return context;
}
