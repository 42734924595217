export const ColaboratorsSchema = [
  {
    name: 'name',
    label: 'Nome completo',
    isFilter: false,
  },
  {
    name: 'department',
    label: 'Departamento',
    isFilter: false,
  },
  {
    name: 'role',
    label: 'Cargo',
    isFilter: false,
  },
  {
    name: 'workPlace',
    label: 'Unidade',
    isFilter: false,
  },
  {
    name: 'status',
    label: 'Status',
    isFilter: false,
  },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];

export const PreColaboratorsSchema = [
  {
    name: 'name',
    label: 'Nome completo',
    isFilter: false,
  },
  {
    name: 'document',
    label: 'Documento',
    isFilter: false,
  },
  {
    name: 'expires_at',
    label: 'Expiração',
    isFilter: false,
  },
  {
    name: 'status',
    label: 'Status',
    isFilter: false,
  },
];
