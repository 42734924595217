import React from 'react';
import { Grid } from '@material-ui/core';

import { StyledPaper, useStyles } from '../styles';
import CheckBox from '../form/checkbox';
import TitleCard from '../title/title-card';

export default function Highlights() {
  const styles = useStyles();
  return (
    <>
      <TitleCard>Destaques na Página Inicial</TitleCard>

      <StyledPaper>
        <Grid item xs={12} className={styles.gridPricingCheckBox}>
          <div className={styles.checkBox}>
            <CheckBox
              name="is_highlight"
              label="Lançamentos"
            />
          </div>
        </Grid>

        <Grid item xs={12} className={styles.gridPricingCheckBox}>
          <div className={styles.checkBox}>
            <CheckBox
              name="is_offer"
              label="Últimas ofertas"
            />
          </div>
        </Grid>

        <Grid item xs={12} className={styles.gridPricingCheckBox}>
          <div className={styles.checkBox}>
            <CheckBox
              name="is_banner"
              label="Banner Página Inicial"
            />
          </div>
        </Grid>

        <Grid item xs={12} className={styles.gridPricingCheckBox}>
          <div className={styles.checkBox}>
            <CheckBox
              name="is_banner_category"
              label="Banner Categoria"
            />
          </div>
        </Grid>
      </StyledPaper>
    </>
  );
}
