import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import StepConnector from '@material-ui/core/StepConnector';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  button: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const QontoConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 43,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% - 5px)',
  },

  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },

  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },

  line: {
    borderColor: '#EAEAF0',
    borderRadius: 1,
    borderTopWidth: 4,
    marginLeft: 1,
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    color: '#EAEAF0',
    display: 'flex',
    height: 22,
  },

  active: {
    color: theme.palette.primary.main,
  },

  circle: {
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    height: 12,
    marginTop: 66,
    width: 12,
  },

  completed: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    height: 12,
    marginTop: 66,
    width: 12,
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={`${classes.root} ${active && classes.active}`}
    >
      {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

export default function CustomizedSteppers({ step }) {
  const classes = useStyles();
  const [activeStep, setStep] = useState(step);

  const actualSteps = {
    10: 0,
    20: 1,
    30: 2,
    50: 3,
  };

  useEffect(() => {
    async function getStep() {
      setStep(actualSteps[step]);
    }

    getStep();
  }, [step, actualSteps]);

  const steps = ['Pedido Realizado', 'Pedido Aprovado', 'Pedido em Preparação', 'Pedido Expedido'];

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        style={{ backgroundColor: 'transparent' }}
        connector={<QontoConnector />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Grid item container justify="center" sm={12} xs={12} style={{ marginTop: '-59px', marginBottom: '70px' }}>
                <Grid item sm={5} xs={12}>
                  {label}
                </Grid>
              </Grid>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
