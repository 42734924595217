export const Schema = [
  {
    name: 'alias',
    label: 'Apelido da loja',
    isFilter: true,
  },
  {
    name: 'city',
    label: 'Cidade',
    isFilter: false,
  },
  {
    name: 'type',
    label: 'Tipo',
    isFilter: false,
  },
  {
    name: 'operation',
    label: 'Operação',
    isFilter: false,
  },
  {
    name: 'bank_account',
    label: 'Conta Bancária',
    isFilter: true,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];
