import React from 'react';

import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { useStyles } from './styles';

import Description from '../../text/description';
import Paper from '../../paper';
import formatDocument from '../../../utils/formatDocument';
import formatCep from '../../../utils/formatCep';
import formatPhone from '../../../utils/formatPhone';
import formatRg from '../../../utils/formatRg';
import { ReactComponent as Buyer } from './assets/ic-customer.svg';

export default function Customer({ order }) {
  const styles = useStyles();

  return (
    <Paper height="auto">
      <div className={styles.flexGrow}>

        <Grid container direction="column">
          <Grid container item justify="center" alignContent="center">
            <Buyer style={{ marginBottom: -20, marginTop: -42, height: 140 }} />
          </Grid>

          <Grid item style={{ marginBottom: 2 }}>
            <Description bold>Dados básicos:  </Description>
          </Grid>

          <Grid item style={{ marginBottom: 2 }}>
            <Description>{order?.customer?.name}</Description>
          </Grid>

          {order?.customer.email && !order.customer.email.includes('id.pedidopago.com.br') && (
            <Grid item style={{ marginBottom: 2 }}>
              <Description>{order.customer.email}</Description>
            </Grid>
          )}

          {order?.customer?.phone && (
            <Grid item style={{ marginBottom: 2 }}>
              <Description>Tel: {formatPhone(order?.customer?.phone)}</Description>
            </Grid>
          )}

          {order?.customer?.rg?.number && (
            <Grid item style={{ marginBottom: 4 }}>
              <Description>RG:&nbsp;</Description>
              <Description>{formatRg(order?.customer?.rg?.number)} </Description>
              <Description>{order?.customer?.rg?.issuer}/</Description>
              <Description>{order?.customer?.rg?.uf}</Description>
            </Grid>
          )}

          {(order?.customer?.document?.type && order?.customer?.document?.number) && (
            <Grid item style={{ marginBottom: 4 }}>
              <Description>{order?.customer?.document?.type?.toUpperCase()}:&nbsp;</Description>
              <Description>{formatDocument(order?.customer?.document?.number)}</Description>
            </Grid>
          )}

          <Grid item style={{ marginBottom: 24 }}>
            <Description>Nascimento:&nbsp;</Description>
            <Description>{order?.customer?.birthday ? format(order.customer.birthday, 'dd/MM/yyyy') : 'Não informado'}</Description>
          </Grid>

        </Grid>

        <Grid container direction="column" style={{ flexGrow: 1 }}>
          <Grid item style={{ marginBottom: 4 }}>
            <Description bold>Endereço de Cobrança:</Description>
          </Grid>

          <Grid item style={{ marginBottom: 0 }}>
            <Description>{order?.billing_address?.street}, {order?.billing_address?.number}</Description>
          </Grid>

          {(order?.billing_address?.obs || order?.billing_address?.district) && (
            <Grid item style={{ marginBottom: 2 }}>
              <Description>{order?.billing_address?.obs && `${order?.billing_address?.obs}, `}
                {order?.billing_address?.district && order?.billing_address?.district}
              </Description>
            </Grid>
          )}

          <Grid item style={{ marginBottom: 2 }}>
            <Description>
              {order?.billing_address?.city}/{order?.billing_address?.uf}
            </Description>
          </Grid>

          <Grid item>
            <Description>
              CEP: {formatCep(order?.billing_address?.zip)}
            </Description>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
