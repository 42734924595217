import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import TitleCard from '../../title/title-card';

import CategoriesInput from './categories-input';
import SubtitleCard from '../../title/subtitle-card';
import { AddButton, StyledPaper, useStyles } from '../../styles';

export default function Catalog({ categories, categoriesList, setCategoriesList }) {
  const styles = useStyles();

  const [category, setCategory] = useState([]);

  function handleCategory() {
    setCategoriesList([...categoriesList, {}]);
  }

  return (
    <>
      <TitleCard>Catálogo</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard>Categorias e Subcategorias</SubtitleCard>
        </Grid>

        {categoriesList?.map((item, index) => {
          if (item === null) return null;
          return (
            <CategoriesInput
              key={item.id}
              categories={categories}
              section={item}
              subs={item.subs}
              index={index}
              category={category}
              setCategory={setCategory}
              categoriesList={categoriesList}
              setCategoriesList={setCategoriesList}
            />
          );
        })}

        <Grid container direction="row" alignItems="center" className={styles.gridPaperBottom} style={{ marginTop: '-8px' }}>
          <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={handleCategory}
          >
            <IconButton type="button" className={styles.iconButton}>
              <AddCircleOutlineIcon fontSize="small" color="primary" />
            </IconButton>

            <AddButton type="button">Adicionar Categoria</AddButton>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
