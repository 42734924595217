export default function formatDocument(cpf) {
  if (cpf?.length === 11) {
    const cpfAtualizado = cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
      (regex, argumento1, argumento2, argumento3, argumento4) => `${argumento1}.${argumento2}.${argumento3}-${argumento4}`);
    return cpfAtualizado;
  }
  const cnpjAtualizado = cpf?.replace(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/,
    (regex, argumento1, argumento2, argumento3, argumento4) => `${argumento1}.${argumento2}.${argumento3}-${argumento4}`);
  return cnpjAtualizado;
}
