import React from 'react';

import Grid from '@material-ui/core/Grid';

import SubtitleCard from '../title/subtitle-card';
import TextFieldMulti from '../form/text-fieldmulti';
import TitleCard from '../title/title-card';

import { StyledPaper, useStyles } from '../styles';

export default function Tags() {
  const styles = useStyles();

  return (
    <>
      <TitleCard>Palavras-chave</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard tip="Separe as palavras-chaves por vírgulas.">
            Termos para ajudar a encontrar este produto:
          </SubtitleCard>
        </Grid>

        <TextFieldMulti name="keywords_concat" rows={4} placeholder="Exemplo: energia, foco, disposição" textStyle={2} />
      </StyledPaper>
    </>
  );
}
