import React, { useCallback, useState, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';

import { toast } from 'react-toastify';

import { Editor } from '@tinymce/tinymce-react';
import GridContainer from '../components/grid-container';

import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import { useAuth } from '../../../contexts/auth';

export default function EditorPolicy({ content, policy_name, policy_title }) {
  const { verifyPerms } = useAuth();
  const { updatePolicy } = useSettings();

  const [isLoading, setLoading] = useState(false);

  const editorRef = useRef(null);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const sectionData = {
        name: policy_title,
        content: editorRef.current.getContent(),
      };

      await updatePolicy(sectionData, policy_name);

      setLoading(false);

      return toast.success('Dados atualizados com sucesso!');
    } catch (err) {
      setLoading(false);

      return toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updatePolicy, setLoading, policy_name, policy_title]);

  return (
    <StyledPaper>

      <GridContainer container mr="24px">

        <GridContainer container item margin="12px">

          <GridContainer item sm={12} spacing={3} mt="-30px">

            <Editor
              apiKey="dxot24ri4a0i7k3sinutegl9z9x52yz2xkm7kzwkl9zarrl6"
              initialValue={content}
              content_style="div { margin: 10px; border: 5px solid red; padding: 3px, background: red }"
              // eslint-disable-next-line no-return-assign
              onInit={(evt, editor) => editorRef.current = editor}
              init={{
                height: 350,
                directionality: 'pt_BR',
                content_style: "@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700&display=swap'); body { font-family: 'Poppins', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Poppins', sans-serif; }",
                selector: 'textarea#full-featured-non-premium',
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image  | ltr rtl',
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
              }}
            />
          </GridContainer>
          <GridContainer container item lg={12} mt="24px" justify="flex-end">
            <ButtonPrimary disabled={verifyPerms({ role: 'Conf. Website', access: 'write' })} onClick={onSubmit} variant="contained" color="primary">
              {isLoading ? (
                <CircularProgress size={15} style={{ color: '#fff' }} />
              ) : (
                'salvar alterações'
              )}
            </ButtonPrimary>
          </GridContainer>
        </GridContainer>
      </GridContainer>
    </StyledPaper>
  );
}
