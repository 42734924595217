import React, { useState, useCallback } from 'react';

import { Grid, Button, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Container } from '../../../styles';
import Modal from '../../../modal';
import { useStyles } from './styles';
import { useSettings } from '../../../../contexts/settings';

export default function BuildModal({ openModal, setOpenModal }) {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { buildEcommerce } = useSettings();

  const handleBuild = useCallback(async () => {
    try {
      const oldBuildDate = localStorage.getItem('buildDate');
      setIsLoading(true);
      const now = new Date();

      const hours = Math.abs(new Date(now) - new Date(oldBuildDate)) / 36e5;

      if (hours < 0.5) {
        setIsLoading(false);
        return toast.warning('Você precisa esperar no mínimos 30 minutos para atualizar o e-commerce novamente.');
      }

      const response = await buildEcommerce();
      localStorage.setItem('buildDate', now);

      setIsLoading(false);
      setOpenModal(false);

      toast.success('O E-commerce será atualizado!');
      return response;
    } catch (err) {
      setIsLoading(false);
      return toast.error('Não foi possivel atualizar o E-commerce', err);
    }
  }, [setOpenModal, buildEcommerce]);

  return (
    <Modal
      isVisible={openModal}
      title="Atualizar Páginas do E-commerce"
      toggle={() => setOpenModal(!openModal)}
      size="xs"
      modalActions={2}
      isDelete
    >
      <Container style={{ margin: '30px 0px 30px' }}>
        <Grid container justify="center" direction="column" alignItems="center">
          <Grid item>
            <span style={{ display: 'flex', textAlign: 'center', fontSize: '12px', lineHeight: '18px', marginBottom: '15px' }}>
              Com o objetivo de oferecer uma experiência para seu cliente de carregamento instantâneo das páginas do e-commerce,
              mantemos em nossos servidores uma versão estática de todo conteúdo,
              que é mais rapidamente processada pelos navegadores utilizados em smartphones, tablets e desktops.
              <br />
              <br />
              Dessa forma, quando alguma informação da loja, categoria ou produto for atualizada
              nesta interface administrativa, se faz necessário solicitar uma atualização completa
              do e-commerce que poderá levar até 10 minutos para ser concluída.
              <br />
              <br />
              Nesses 10 minutos que as atualizações estão sendo processadas,
              seu cliente continuará acessando normalmente o e-commerce porém verá informações ainda não atualizadas.
              <br />
              <br />
              Você não poderá realizar essa solicatação em intervalos de tempo menores que 30 minutos afim de não sobrecarregar o sistema,
              portanto consolide suas alterações para solicitar a atualização em bloco.
            </span>
          </Grid>

          <Grid container item justify="center">
            <Button variant="contained" color="primary" className={styles.buttonPrimary} onClick={handleBuild} type="button" style={{ width: 530, fontWeight: 600, marginBottom: '30px' }}>
              {isLoading ? (
                <CircularProgress size={15} style={{ color: '#fff' }} />
              ) : (
                'atualizar todo e-commerce (esta ação poderá levar até 10 min.)'
              )}
            </Button>
            <Button variant="outlined" color="primary" className={styles.buttonPrimary} onClick={() => setOpenModal(false)} type="button" style={{ width: 210, fontWeight: 600 }}>
              cancelar
            </Button>
          </Grid>

        </Grid>
      </Container>
    </Modal>
  );
}
