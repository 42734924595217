import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import { Container } from './styles';

import store from '../../store';

export default function SvgRender({ item }) {
  const [size, setSize] = useState({});

  const refMask = useRef(null);
  const refText = useRef(null);
  const { settings } = store.get();
  const { brand } = settings;
  const { logo, logo_inverse, product_logo_dark, product_logo_light } = brand;

  useEffect(() => {
    function resize() {
      const width = item?.maxWidthLogo || '80';
      const newSize = {
        logoLeft: (`${Math.round((350 * item?.margin_left) / 512)}px`).toString(),
        logoTop: (`${Math.round((350 * item?.margin_top) / 512)}px`).toString(),
        maxWidthLogo: (`${Math.round((350 * item?.width) / 512)}px`).toString(),
        maxHeightLogo: (`${Math.round((350 * item?.height) / 512)}px`).toString(),
        heightContainer: `${item?.height}px`,
        widthContainer: `${width}px`,
      };

      return setSize(newSize);
    }

    resize();
  }, [item]);

  const loadSVG = (container, data) => {
    if (!data) return null;

    const dataURL = data;
    const xhr = new XMLHttpRequest();

    xhr.open('GET', dataURL);
    xhr.addEventListener('load', (ev) => {
      const xml = ev.target.response;
      const dom = new DOMParser();
      const svg = dom.parseFromString(xml, 'image/svg+xml');

      while (container.current.firstChild) {
        container.current.removeChild(container.current.firstChild);
      }

      container.current.appendChild(svg.rootElement);
    });

    xhr.send(null);

    return false;
  };

  useEffect(() => {
    if (item.mask_type === 'image/svg+xml') loadSVG(refMask, item.mask);

    if (item.text_type === 'image/svg+xml') loadSVG(refText, item.text);
  }, [item]);

  const isFallbackLogo = !product_logo_dark && !product_logo_light;

  const inverseLogo = isFallbackLogo ? logo_inverse : product_logo_dark;

  const defaultLogo = isFallbackLogo ? logo : product_logo_light;

  const productLogo = item?.inverse_logo ? inverseLogo : defaultLogo;

  return (
    <Container
      heightContainer="350px"
      widthContainer="350px"
      size={size}
      isInverse={item?.inverse_logo}
      textBlend={item.text_blending || 'multiply'}
    >
      <img src={item?.base} className="base" alt="frasco" />

      <div ref={refMask} className="tampa" />
      {item.mask && item.mask_type === 'link_MaskSvg' && <ReactSVG src={item?.mask} className="tampa" />}
      {item.mask && item.mask_type === 'image/png' && <img src={item.mask} className="tampa" alt="tampa" />}

      <div ref={refText} className="text" />

      {item.text && item.text_type === 'link_TextSvg' && <ReactSVG src={item?.text} className="text" />}
      {item.text && item.text_type === 'image/png' && <img src={item.text} className="text" alt="text" />}

      <div className="logo-container">
        <img src={productLogo} className="logo" alt="logo" />
      </div>
    </Container>
  );
}

SvgRender.propTypes = {
  item: PropTypes.object,
};
