import React from 'react';

// import Button from '@material-ui/core/Button';

import { Grid } from '@material-ui/core';
import PersonalInformationForm from './personal-information-form';
import TabPanel from '../tab-panel';
import TitleCard from '../../../title/title-card';

// import CardsList from './cards';
import AddressList from './address';
// import NewAddressForm from './new-address-form';

import { Container, StyledPaper } from '../../../styles';

import { useStyles } from './styles';

export default function PersonalInformation({ value, customer }) {
  const styles = useStyles();

  // const [isVisible, setVisible] = useState(false);
  return (
    <TabPanel value={value} index={0}>
      <Container>
        <TitleCard>Dados Pessoais</TitleCard>

        <StyledPaper className={styles.personalFormPadding}>
          <PersonalInformationForm customer={customer} />
        </StyledPaper>
      </Container>
      {/*
      <Container>
        <TitleCard>Cartões Cadastrados</TitleCard>

        <StyledPaper>
          <CardsList />
        </StyledPaper>
      </Container> */}

      <Container>
        <TitleCard>Endereços Cadastrados</TitleCard>

        <StyledPaper>
          <Grid container direction="row" spacing={3}>
            {customer?.address?.length && customer.address.map((address) => (
              <Grid item key={address.id}>
                <AddressList address={address} />
              </Grid>
            ))}
          </Grid>

          {/* <Container>
            {!isVisible && (
              <Button variant="outlined" color="primary" onClick={() => setVisible(!isVisible)}>Adicionar novo endereço</Button>
            )}

            {isVisible && (
            <Form ref={formRef}>
              <NewAddressForm toggle={() => setVisible(!isVisible)} />

            </Form>
            )}
          </Container> */}
        </StyledPaper>
      </Container>
    </TabPanel>
  );
}
