import api from '../api';
import errHandler from './_errhandler';

export const getForms = async (usage) => {
  const forms = await api.get(`/v2/store/product-form?usage=${usage}`)
    .then(res => res.data)
    .catch(errHandler);

  return forms;
};

export const getProductList = async (search) => {
  const resp = await api.get(`/v2/store/products?q=${search}`)
    .then(res => res.data)
    .catch(errHandler);

  return resp;
};

export const updateBuyWith = async (products, productId) => {
  const response = await api.put(`/v2/store/product/${productId}/buy-with`, { products })
    .then(res => res.data)
    .catch(errHandler);

  return response;
};

export const updateSimilar = async (products, productId) => {
  const response = await api.put(`/v2/store/product/${productId}/related`, { products })
    .then(res => res.data)
    .catch(errHandler);

  return response;
};

export const getProduct = (product_id) => api.get(`/v2/store/product/${product_id}/edit`)
  .then(res => res.data)
  .catch(errHandler);

export const getListForm = () => api.get('/v2/product/forms')
  .then(res => res.data)
  .catch(errHandler);

export const createProduct = (product) => api.post('/v2/store/product', product)
  .then(res => res.data)
  .catch(errHandler);

export const insertMedia = (media, product_id) => api.post(`/v2/store/product/${product_id}/media`, media)
  .then(res => res.data)
  .catch(errHandler);

export const insertIngredient = (ingredient, product_id) => api.post(`/v2/product/${product_id}/ingredient`, ingredient)
  .then(res => res.data)
  .catch(errHandler);

export const getSearchProducts = (title) => api.get(`/v2/store/products?q=${title}`)
  .then(res => res.data)
  .catch(errHandler);

export const deleteProduct = (product_id) => api.delete(`/v2/store/product/${product_id}`)
  .then(res => res)
  .catch(errHandler);

export const getMeasureList = async () => {
  const measure = await api.get('/v2/store/measure-units')
    .then(res => res.data)
    .catch(errHandler);

  return measure;
};

export const updateProduct = async (product) => {
  const update = await api.put(`/v2/store/product/${product.id}`, product)
    .then(res => res.data)
    .catch(errHandler);

  return update;
};

export const reorderProductMedia = async (product_id, idlist) => {
  const d = await api.put(`/v2/store/product/${product_id}/reorder-media`, idlist)
    .then(res => res.data)
    .catch(errHandler);
  return d;
};
