import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

import Card from '@material-ui/core/Card';

export const useStyles = makeStyles(() => ({
  boldSubtitle: {
    fontWeight: '600',
    lineHeight: '24px',
    marginTop: '16px',
  },

  button: {
    fontSize: '12px',
    fontWeight: '600',
    height: '36px',
    width: '100%',
  },

  buttonIcon: {
    background: '#1472ED',
    border: '1px solid #1472ED',
    fontSize: '12px',
    fontWeight: '600',
    height: '44px',
    justifyContent: 'left',
    marginTop: '-8px',
    paddingLeft: '80px',
    width: '100%',

    '&:hover': {
      background: '#1472ED',
      opacity: '.9',
    },
  },

  buttonOutline: {
    border: '1px solid #A3A3A3',
    color: '#424242',
    fontSize: '12px',
    fontWeight: '600',
    height: '36px',
    marginTop: '-8px',
    width: '100%',
  },

  buttonOutlineIcon: {
    border: '1px solid #A3A3A3',
    color: '#424242',
    fontSize: '12px',
    fontWeight: '600',
    height: '44px',
    justifyContent: 'left',
    paddingLeft: '80px',
    width: '100%',
  },

  buttonText: {
    color: '#424242',
    fontSize: '12px',
    height: '36px',
    paddingLeft: '0',
    width: '100%',
  },

  decoration: {
    color: '#22E0A1',
    textDecoration: 'underline',
  },

  gridButton: {
    marginTop: '38px',

    '& .item': {
      height: '36px',
    },

    '& .spinner': {
      marginLeft: 'calc(50% - 22px)',
      marginTop: '-5px',
    },
  },

  icon: {
    height: '22px',
    marginRight: '12px',
  },

  iconKeyboard: {
    color: '#22E0A1',
    marginLeft: '-4px',
    marginRight: '16px',
    marginTop: '2px',
  },

  spanMargin: {
    marginLeft: '4px',
    marginRight: '4px',
  },
}));

export const CardLogin = styled(Card)`
  border: 1px solid #E1E1E1;
  border-radius: 10px !important;
  box-sizing: border-box;
  height: 448px;
  margin-left: calc(50% - 224px);
  max-width: 448px;
  min-width: 448px;
  padding: 56px;

  ${props => props.cardCPF && css`
    height: 466px;
    margin-top: calc(50vh - 356px);
  `}

  ${props => props.cardEmail && css`
    margin-top: calc(50vh - 346px);
  `}

  ${props => props.cardOrganization && css`
    height: 464px;
    margin-top: calc(50vh - 356px);
  `}

  ${props => props.cardPassword && css`
    height: 376px;
    margin-top: calc(50vh - 310px);
  `}

  ${props => props.cardRequest && css`
    height: 646px;
    margin-top: calc(50vh - 444px);
  `}
`;

export const Row = styled.div`
  margin: 24px 0;
`;

export const RowBottom = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
  margin-left: calc(50% - 224px);
  margin-top: 20px;
  padding-left: 56px;
  padding-right: 56px;
  width: 448px;
`;

export const Span = styled.div`
  align-items: center;
  color: #A3A3A3;
  display: flex;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  justify-content: center;
  margin-bottom: -4px;
  margin-top: 8px;
`;

export const Subtitle = styled.div`
  color: #424242;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.03em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 8px;
  padding: 0 48px;
  text-align: center;

  span {
    color: #22E0A1;
    cursor: pointer;
  }

  ${props => props.mt && css`
    margin-top: ${props.mt};
  `}

  ${props => props.padding && css`
    padding: ${props.padding};
  `}
`;

export const Title = styled.div`
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.03em;
  line-height: 18px;
  padding: 0 48px;
  text-align: center;
`;
