/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

import { BankAccount, Branch, Pharmacy } from '../@types/branch';

interface BranchState {
  items: Branch;
  total_count: number;
}

interface FreightData {
  store_code: string;
  password: string;
  enabled: boolean, // enviar false para "não quero operar com correios"
  extra_fee: string;
  branch_id: string;
}

interface BranchContextData {
  isLoading: boolean;
  branchs: BranchState;
  fetchBranchs(params: Record<string, unknown>): Promise<Record<string, unknown>>;
  getOneBranch(id: number): Promise<{ item: Branch }>;
  updateMainBranch(body: Branch): Promise<void>;
  updateOneBranch(id: number, body: Branch): Promise<void>;
  updateMainBranchAddress(body: Branch): Promise<void>;
  updateOneBranchAddress(id: number, body: Branch): Promise<void>;
  updateBranchPharmacy(id: string, body: Pharmacy): Promise<void>;
  addBankAccount(id: number, data: BankAccount): Promise<void>;
  addBranch(data: Branch): Promise<void>;
  addCorreios(data: FreightData): Promise<void>;
  getCorreios(id: string): Promise<FreightData>;
  addPharmacy(id: number, data: Pharmacy): Promise<any>;
  deleteBranch(id: number): Promise<void>;
}

const BranchContext = createContext<BranchContextData>({} as BranchContextData);

export const BranchProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [branchs, setBranchs] = useState<BranchState>(() => ({} as BranchState));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchBranchs = useCallback(async (params: any) => {
    setLoading(true);

    const response = await api.get('/v2/branches', {
      params: {
        ...params,
      },
    });

    setBranchs(response.data);
    setLoading(false);

    return params;
  }, []);

  const getOneBranch = useCallback(async (id: number) => {
    const queryParams = {
      recursive: true,
      fetch_products: true,
      fetch_keywords: true,
    };

    const response = await api.get(`/v2/store/branch/${id}`, { params: queryParams });

    return { item: response.data };
  }, []);

  useEffect(() => {
    async function getBranchsData() { await fetchBranchs({}); }

    getBranchsData();
  }, [fetchBranchs]);

  const updateMainBranch = useCallback(async (body: any) => {
    await api.put('/v2/branch/main', body);
    await fetchBranchs({});
  }, [fetchBranchs]);

  const updateOneBranch = useCallback(async (id: any, body: any) => {
    await api.put(`/v2/branch/${id}`, body);
    await fetchBranchs({});
  }, [fetchBranchs]);

  const updateMainBranchAddress = useCallback(async (body: any) => {
    await api.put('/v2/branch/main/primary-address', body);

    await fetchBranchs({});
  }, [fetchBranchs]);

  const updateOneBranchAddress = useCallback(async (id: any, body: any) => {
    await api.put(`/v2/branch/${id}/primary-address`, body);

    await fetchBranchs({});
  }, [fetchBranchs]);

  const updateBranchPharmacy = useCallback(async (id: any, body: any) => {
    await api.put(`/v2/branch/${id}/pharmacy`, body);

    await fetchBranchs({});
  }, [fetchBranchs]);

  const addBankAccount = useCallback(async (id: number, data: any) => {
    const response = await api.post(`/v2/store/branch/${id}/bank`, data);
    return response.data;
  }, []);

  const addBranch = useCallback(async (data: any) => {
    const response = await api.post('/v2/branch', data);
    await fetchBranchs({});
    return response.data;
  }, [fetchBranchs]);

  const addCorreios = useCallback(async (data: any) => {
    await api.put('/v2/branch/logistics/correios', data);

    await fetchBranchs({});
  }, [fetchBranchs]);

  const getCorreios = useCallback(async (id: string) => {
    const response = await api.get(`/v2/branch/logistics/correios/${id}`);

    return response.data;
  }, []);

  const addPharmacy = useCallback(async (id: number, data: Pharmacy) => {
    const response = await api.post(`/v2/store/branch/${id}/pharmacy`, data);
    return response.data;
  }, []);

  const deleteBranch = useCallback(async (id: number) => {
    await api.delete(`/v2/store/branch/${id}`);
    await fetchBranchs({});
  }, [fetchBranchs]);

  return (
    <BranchContext.Provider
      value={{
        fetchBranchs,
        getOneBranch,
        updateMainBranch,
        updateOneBranch,
        updateMainBranchAddress,
        updateOneBranchAddress,
        updateBranchPharmacy,
        addBankAccount,
        addBranch,
        addPharmacy,
        addCorreios,
        getCorreios,
        deleteBranch,
        isLoading,
        branchs,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

export function useBranch(): BranchContextData {
  const context = useContext(BranchContext);

  if (!context) { throw new Error('useBranch must be used within an BranchProvider'); }

  return context;
}
