import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Divider, Grid, MenuItem } from '@material-ui/core';

import {
  MonetizationOnOutlined,
} from '@material-ui/icons';
import Paper from '../../paper';
import Select from '../../form/select';
import Subtitle from '../../text/subtitle';

import { ExpeditionModal } from './expedition-modal';

import { ReactComponent as Billet } from './assets/ic-aguardando-boleto.svg';
import { ReactComponent as Card } from './assets/ic-aguardando-cartao.svg';
import { ReactComponent as Included } from './assets/ic-inclusao-pedido.svg';
import { ReactComponent as Delivery } from './assets/ic-pedido-enviado.svg';
import { ReactComponent as WithdrawalIcon } from './assets/ic-pedido-retirada.svg';
import { ReactComponent as Canceled } from './assets/ic-pedido-cancelado.svg';
import { ReactComponent as Preparation } from './assets/ic-pedido-preparacao.svg';
import { ReactComponent as PixIcon } from './assets/pix.svg';

import { useOrder } from '../../../contexts/order';
import { useAuth } from '../../../contexts/auth';
import { CancelModal } from './cancel-modal';

export default function OrderStatus({ pickup, order, updateOrder }) {
  const { fetchOrders, updateOrderStatus } = useOrder();
  const { verifyPerms, user } = useAuth();
  const [modals, setModals] = useState({
    isCancelModalOpen: false,
    isPreparationModalOpen: false,
  });

  const paymentMethod = order.order?.payment_method || order.payment?.[0]?.payment_method;

  const [cancelJustification, setCancelJustification] = useState('');
  const options = useMemo(() => [
    { id: 10, type: 'Pedido Realizado', disabled: true },
    { id: 20, type: 'Pedido Aprovado', disabled: (order?.alternative_payment ? order?.order?.status !== 10 && !['Supervisor', 'Diretor', 'Gerente'].includes(user?.group?.name) : true) },
    { id: 30, type: 'Pedido Em Preparação', disabled: order?.order?.status !== 20 },
    { id: 50, type: 'Pedido Expedido', disabled: order?.order?.status !== 30 },
    { id: 55, type: 'Pedido Cancelado' },
  ], [order, user.group.name]);

  const updateStatusCode = useCallback(async (statusCode) => {
    await updateOrderStatus({
      status: statusCode,
      id: order.order.id,
    });

    toast.success(`O pedido ${order.order.label} foi atualizado`);

    await updateOrder();
    await fetchOrders({});
  }, [order, fetchOrders, updateOrderStatus, updateOrder]);

  const onSubmit = useCallback(async (statusCode) => {
    try {
      switch (statusCode) {
        case 30:
          setModals({
            ...modals,
            isPreparationModalOpen: true,
          });
          break;
        case 55:
          setModals({
            ...modals,
            isCancelModalOpen: true,
          });
          break;
        default:
          await updateStatusCode(statusCode);
          break;
      }
    } catch (err) {
      toast.error('Não possível atualizar esse pedido');
    }
  }, [modals, updateStatusCode]);

  const onSubmitShipment = useCallback(async (data) => {
    try {
      setModals({
        isCancelModalOpen: false,
        isPreparationModalOpen: false,
      });
      await updateOrderStatus({
        status: 30,
        id: order.order.id,
        expedition_date: data.expedition_date.getTime(),
        delivery_date_time_specified: data.delivery_date_time_specified,
        delivery_obs: data.delivery_observation,
        tracking_code: data.tracking_code,
      });

      toast.success(`A data de entrega/retirada e status do pedido ${order.order.label} foram atualizados.`);

      await updateOrder();
      await fetchOrders({});
    } catch (err) {
      console.err(err);
      toast.error('Não possível atualizar esse pedido');
    }
  }, [order, fetchOrders, updateOrderStatus, updateOrder]);

  const onSubmitCancel = useCallback(async () => {
    try {
      setModals({
        isCancelModalOpen: false,
        isPreparationModalOpen: false,
      });

      await updateOrderStatus({
        status: 55,
        id: order.order.id,
        status_justification: cancelJustification,
      });

      toast.success(`O status do pedido ${order.order.label} foi atualizado para cancelado.`);

      await updateOrder();
      await fetchOrders({});
    } catch (err) {
      toast.error('Não possível atualizar esse pedido');
    }
  }, [order, fetchOrders, updateOrderStatus, updateOrder, cancelJustification]);

  const renderItem = ({ item }) => (
    <MenuItem key={item.id} disabled={item?.disabled} value={item.id} onClick={() => onSubmit(item.id)}>
      {item.type}
    </MenuItem>
  );

  function Cancel() {
    return (
      <>
        <Grid container item justify="center">
          <Canceled style={{ height: 110 }} />
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <Subtitle>Pedido Cancelado</Subtitle>
        </Grid>
      </>
    );
  }

  function Manipulation() {
    return (
      <>
        <Grid container item justify="center">
          <Preparation style={{ height: 110 }} />
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <Subtitle>Pedido em Preparação</Subtitle>
        </Grid>
      </>
    );
  }

  function Aprove() {
    return (
      <>
        <Grid container item justify="center">
          <Included color="#A3A3A3" style={{ height: 110 }} />
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <Subtitle>Aguardando inclusão do pedido</Subtitle>
        </Grid>
      </>
    );
  }

  function Created() {
    return (
      <>
        <Grid container item justify="center">
          {paymentMethod === 'pix' ? (
            <div style={{
              width: 100,
              height: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <PixIcon fill="#22dfa1" />
            </div>
          ) : paymentMethod === 'boleto' ? (
            <Billet style={{ height: 110, marginBottom: 20 }} />
          ) : paymentMethod === 'credit_card' ? (
            <Card style={{ height: 110, marginBottom: 20 }} />
          ) : (
            <MonetizationOnOutlined style={{
              fontSize: '4rem',
              color: '#22dfa1',
              marginBottom: 20,
            }}
            />
          )}

        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          {paymentMethod === 'credit_card' && (
            <Subtitle>Aguardando confirmação do pagamento</Subtitle>
          )}

          {paymentMethod === 'boleto' && (
            <Subtitle>Aguardando pagamento de boleto</Subtitle>
          )}

          {!paymentMethod && (
            <Subtitle>Operador selecionou um método de pagamento alternativo</Subtitle>
          )}

          {paymentMethod === 'pix' && (
            <>
              <Divider />
              <span style={{ display: 'block', marginTop: '12px', fontSize: 14 }}>O pagamento do PIX pode demorar até 5 minutos para ser confirmado</span>
            </>
          )}
        </Grid>
      </>
    );
  }

  function Withdrawal() {
    return (
      <>
        <Grid container item justify="center">
          {pickup ? (
            <WithdrawalIcon style={{ height: 110, marginBottom: 20 }} />
          ) : (
            <Delivery style={{ height: 110, marginBottom: 20 }} />
          )}
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <Subtitle>{pickup ? 'Pedido pronto para a retirada' : 'Pedido Enviado'}</Subtitle>
        </Grid>
      </>
    );
  }

  const AcceptedStatus = {
    waiting_payment: Billet,
    10: Created,
    20: Aprove,
    30: Manipulation,
    50: Withdrawal,
    55: Cancel,
  };

  const Status = AcceptedStatus[order?.order?.status || 10];

  return (
    <Paper height="300px">
      <Grid container direction="column">
        <Status order={order.order} />
      </Grid>

      <Grid container justify="center" style={{ alignItems: 'flex-end', flexGrow: 1 }}>
        <Select
          disabled={verifyPerms({ role: 'Pedidos', access: 'write' })
        || order.order.status === 55} name="status_id" label="Status" options={options} value={order?.order?.status}
        >{renderItem}
        </Select>
      </Grid>
      {order?.order.last_update && (
        <Grid container style={{ marginTop: '1rem' }}>
          <Subtitle size="12px">Última edição: {order?.order.last_update.agent_name || order?.order.last_update.agent_id}</Subtitle>
        </Grid>
      )}
      <ExpeditionModal
        onSubmitShipment={onSubmitShipment}
        onClose={() => setModals({
          ...modals,
          isPreparationModalOpen: false,
        })}
        order={order}
        isOpen={modals.isPreparationModalOpen}
      />
      <CancelModal
        value={cancelJustification}
        onChange={(value) => setCancelJustification(value)}
        onClose={() => setModals({
          ...modals,
          isCancelModalOpen: false,
        })}
        isOpen={modals.isCancelModalOpen}
        onSubmit={onSubmitCancel}
      />
    </Paper>
  );
}
