import React from 'react';

import Bar from './app-bar';
import DrawerMenu from './drawer';

export default function AppHeader() {
  return (
    <div style={{ display: 'flex' }}>
      <Bar drawerWidth={240} />
      <DrawerMenu />
    </div>
  );
}
