import styled, { css } from "styled-components";

import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

export const TabsContainer = styled(Tabs)`
  border: 1px solid #e1e1e1;
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  min-height: 40px !important;
`;

export const TextTab = styled(Tab)`
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: bold !important;
  letter-spacing: 0.03em !important;
  line-height: 22px !important;
  min-height: 40px !important;
  min-width: 126px !important;
  text-transform: none !important;
  width: 126px !important;
`;

export const TooltipContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const HelperText = styled(Typography)`
  color: #a3a3a3;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: -10px;
`;

export const IconGrid = styled(Grid)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;

export const AddImageContainer = styled.div`
  /* width: 90%; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  height: 100%;
  margin: 10px 0px 0px 0px;
  display: flex;
  place-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0px 180px 0px !important;
  position: relative;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
  width: 100%;

  .title {
    padding: 8px 0px 0px 0px;
    font-size: 12px;
    font-weight: 600;
  }

  .select {
    padding: 20px 24px 0px 0px;
    width: 173px;
  }

  .form {
    width: 70%;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e1e1e1;
  width: 100%;

  position: absolute;
`;

export const CleanForm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 30%;

  background-color: transparent;
  border: 0px;

  margin: 63px 20px 0px 0px;

  p {
    text-decoration: underline;
    margin: 0px 0px 0px 4px;
  }
`;

export const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    color: #a3a3a3;
    font-size: 12px;
    justify-content: flex-start;
    margin: 16px 0px 24px 0px;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 22px 0px;
  width: 689px;
  position: relative;
  justify-content: space-between;
  align-content: center;

  p {
    color: #a3a3a3;
    font-size: 12px;
  }

  .MuiSlider-root {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 20px;
  }

  .MuiSlider-rail {
    background-color: #c4c4c4;
    height: 6px;
    border-radius: 5px;
  }

  .MuiSlider-track {
    height: 6px;
    border-radius: 5px;
  }

  .MuiSlider-mark {
    height: 14px;
    width: 14px;
    border-radius: 50%;
  }

  .MuiSlider-thumb {
    height: 14px;
    width: 14px;
  }

  .MuiSlider-valueLabel {
    background-color: transparent;
    color: #fff;
    transform: scale(1) translateY(50px) !important;
  }
`;

export const RoundImage = styled.div`
  display: flex;
  place-content: center;

  .imgButton {
    border-radius: 50%;
    margin: 0px;
    left: 0px;
    right: 0px;
    position: relative;
    padding: 6px 7px;
  }

  .imgContainer {
    position: relative;
    width: 256px;
    height: 256px;
    border-radius: 50%;
  }

  .overlayImg {
    position: absolute;
    left: 6px;
    right: 0;
    top: 3px;
    height: 260px;
    width: 260px;
    border-radius: 50%;
    opacity: 0;
    transition: background-color 0.15s ease;
    background-color: #424242;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 32%;
  }

  .iconButton {
    color: #fff;
  }

  .imgContainer:hover .overlayImg {
    opacity: 1;
    position: relative;
    top: -265px;
  }

  .dragAndDrop {
    transition: background-color 0.15s ease;
    background-color: #424242;
    color: #fff;
    height: 256px;
    width: 256px;
    margin: 0px 0px 50px 0px;
    text-align: center;
    border-radius: 50%;
    position: fixed;
    display: flex;
    flex-direction: column;
    place-content: center;
  }
`;

export const RoundImageContainer = styled.div`
  position: relative;
  width: 689px;
  height: 286px;

  .reactEasyCrop_Container {
    border-radius: 10px;
  }

  .iconButton {
    border-radius: 0;
    color: #ffffff;
    margin-top: calc(50% - 55px);
  }

  .iconButton:hover {
    background: none;
  }

  .mt {
    margin-top: -8px;
  }

  .noDash {
    border: none !important;
    outline: none !important;

    div {
      border: none !important;
      outline: none !important;
    }
  }

  .noEvent {
    pointer-events: none;
  }

  .onFileDrop {
    background-color: rgb(66, 66, 66, 0.8);
    height: 256px;
    width: 256px;
    margin: 0px 0px 50px 0px;
    opacity: 1;
    text-align: center;
    border-radius: 50%;
    position: fixed;
  }

  .onImageNew {
    background-color: rgb(66, 66, 66);
    border-radius: 50%;
    height: 256px;
    width: 256px;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .onImagePreview {
    background-color: rgb(66, 66, 66, 0.8);
    border-radius: 50%;
    height: 256px;
    width: 256px;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 47.5%;
    transform: translate(-50%, -50%);

    ${(props) =>
      props.size &&
      css`
        height: ${props.size.height} !important;
        width: ${props.size.width} !important;
      `}
  }

  span {
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }
`;

export const DialogActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  height: 75px;

  .MuiDialogActions-root {
    height: 100%;
    width: 100%;
    padding: 0px 24px 37px 0px;
  }

  .MuiButton-root {
    width: 150px;
  }

  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 20px;
  }
`;

export const UploadedImage = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  border-radius: 50%;
  border: 1.5px solid #22e0a1;

  img {
    width: 256px;
    height: 256px;
    border-radius: 50%;
  }
`;
