import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

import TextField from './text-field';

export default function MaskField({
  isDisabled, name, label, include, theme, width, mask, maskChar, onBlur, onChange, ...rest
}) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      onBlur={onBlur}
      onChange={onChange}
      defaultValue={defaultValue}
      ref={inputRef}
      disabled={isDisabled}
    >
      {() => <TextField width="176px" name={name} label={label} disabled={isDisabled} {...rest} />}
    </InputMask>
  );
}
