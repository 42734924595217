import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

import { OrderItem } from '../@types/order';
import { Pagination } from '../@types/pagination';

interface OrderState {
  items: OrderItem[];
  pagination: Pagination;
}

interface FetchOder {
  status?: string;
  customer_name?: string
}

interface UpdateOrder {
  id: number;
  delivery_date: number;
  status: number
  tracking_code: string;
  status_justification?: string;
}

interface OrderContextData {
  isLoading: boolean;
  orders: OrderState;
  cachedOrder?: OrderItem;
  fetchOrders(params: FetchOder): Promise<any>;
  searchOrders(params: FetchOder): Promise<OrderState>;
  fetchOneOrder(id: string): Promise<void>;
  updateOrderInvoice(params: {
    id: string,
    number: string
  }): Promise<void>;
  updateOrderStatus(credentials: UpdateOrder): Promise<void>;
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData);

export const OrderProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [orders, setOrders] = useState<OrderState>(() => ({} as OrderState));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [cachedOrder, setCachedOrder] = useState<OrderItem>();

  const fetchOrders = useCallback(async (params: FetchOder) => {
    setLoading(true);
    const response = await api.get('/v2/orders', {
      params: {
        'fetch-products': '1',
        ...params,
      },
    });

    setOrders(response.data);
    setLoading(false);
    return params;
  }, []);

  const searchOrders = useCallback(async (params: FetchOder) => {
    const response = await api.get('/v2/orders', {
      params: {
        'fetch-products': '1',
        ...params,
      },
    });

    return response.data;
  }, []);

  const fetchOneOrder = useCallback(async (id: string) => {
    setLoading(true);
    const response = await api.get(`/v2/order/${id}`);

    setCachedOrder(response.data);

    setLoading(false);
    return response.data;
  }, []);

  useEffect(() => {
    async function getOrdersData() {
      await fetchOrders({});
    }

    getOrdersData();
  }, [fetchOrders]);

  const updateOrderStatus = useCallback(async (data: any) => {
    const { id } = data;

    await api.put(`/v2/order/${id}/status`, {
      ...data,
    });
  }, []);

  const updateOrderInvoice = useCallback(async (params: {
    id: string,
    number: string
  }) => {
    await api.put(`/v2/order/${params.id}/invoice`, {
      number: params.number,
    });
  }, []);

  return (
    <OrderContext.Provider
      value={{
        cachedOrder, fetchOrders, searchOrders, fetchOneOrder, updateOrderStatus, isLoading, orders, updateOrderInvoice,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export function useOrder(): OrderContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider');
  }
  return context;
}
