import React, { useMemo } from 'react';

import { TableCell, IconButton, Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { format } from 'date-fns';
import { StyledTooltip } from '../tooltip';
import { useStyles } from '../styles';

import placeHolder from '../../assets/placeholder.svg';
import { useAuth } from '../../contexts/auth';

export default function DataOrdersRow({ order, handleDetails }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();

  const img = useMemo(() => {
    if (order.products) return order.products[0]?.thumbnail_url;

    return null;
  }, [order]);

  return (
    <>
      <TableCell>
        <Grid container alignItems="center">
          <img src={img || placeHolder} alt="Img" className={styles.tableImg} />
          {order.alias}
        </Grid>
      </TableCell>

      <TableCell>{order?.customer_name}</TableCell>
      <TableCell>{order?.inquiry_display_ids ? order.inquiry_display_ids.map((id) => (<>{id}<br /></>)) : ''}</TableCell>
      <TableCell>{order?.prescription_retained ? 'Sim' : ''}</TableCell>
      <TableCell>{format(order?.created_at, 'dd/MM/yyy')}</TableCell>
      <TableCell>{order?.price_net}</TableCell>
      <TableCell>{order?.payment_method_name}</TableCell>
      <TableCell>{order?.status_name}</TableCell>
      <TableCell>{order?.tracking_code}</TableCell>

      <TableCell style={{ width: 72 }}>
        <StyledTooltip title="Editar Pedido" placement="top" className={styles.tableButton}>
          <IconButton disabled={verifyPerms({ role: 'Pedidos', access: 'read' })} className={styles.actionButton} onClick={handleDetails}>
            <Edit className={styles.icon} color="disabled" fontSize="small" />
          </IconButton>
        </StyledTooltip>
      </TableCell>
    </>
  );
}
