import styled from 'styled-components';

export const ChevronRotate = styled.div`
  svg[rotation="1"] {
    animation: rotation .3s 1;
  }

  svg[rotated="1"] {
    transform: rotate(90deg);
  }

  @keyframes rotation {
    25% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
  }
`;

export const Container = styled.div`
  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    height: 56px;
    min-height: 56px;
  }

  .MuiAccordionDetails-root {
    border-bottom: 1px solid #EDF8F6;
    padding: 0 !important;
  }

  .MuiList-root {
    width: 100%;
  }
`;

export const DrawerItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  height: 56px;
  line-height: 18px;
  margin: 0;
  padding: 0 16px 0 26px;
  text-decoration: none;
  width: 100%;

  p {
    color: #424242;
    flex: 1;
    font-size: 12px;
    line-height: 18px;
  }

  svg {
    color: #A3A3A3;
    height: 18px;
    width: 18px;
  }

  &:hover {
    background-color: #EDF8F6;

    p, svg {
      color: #22E0A1 !important;
    }
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }
`;

export const TitleSection = styled.h3`
  color: #424242;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  margin: 24px 0 8px 26px; 
`;

export const Toolbar = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: center;

  img {
    border-radius: 50%;
    height: 56px;
    margin-right:15px;
    width: 56px;
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
  }

  p {
    font-size: 12px;
  }
`;
