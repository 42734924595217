import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from '@material-ui/core/Checkbox';

import { LabelCheck } from '../styles';

export default function FormCheckBox({
  name, include, theme, label, defaultChecked, labelStyles, ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = false, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      getValue: (refs) => refs.checked,
      clearValue: (refs) => { refs.checked = false; },
      setValue: (refs) => { refs.checked = true; },
    });
  }, [fieldName, registerField]);

  return (
    <FormControlLabel
      style={labelStyles}
      control={(
        <CheckBox
          color="primary"
          inputRef={inputRef}
          defaultChecked={Boolean(defaultValue) || Boolean(defaultChecked)}
          {...rest}
        />
      )}
      label={(
        <LabelCheck>{label}</LabelCheck>
      )}
    />
  );
}
