import React from 'react';

import { BranchProvider } from './branch';
import { SettingsProvider } from './settings';
import { CategoryProvider } from './category';
import { ProductProvider } from './product';
import { OrderProvider } from './order';
import { ColaboratorsProvider } from './contributors';
import { CouponsProvider } from './coupons';
import { BlogPostProvider } from './blog';
import { CustomerProvider } from './customer';
import { AuthProvider } from './auth';
import { GroupsProvider } from './group';

const AppProvider = ({ children }: {
  children: React.ReactNode
}) => (
  <AuthProvider>
    <SettingsProvider>
      <BranchProvider>
        <CategoryProvider>
          <GroupsProvider>
            <ProductProvider>
              <OrderProvider>
                <ColaboratorsProvider>
                  <CouponsProvider>
                    <BlogPostProvider>
                      <CustomerProvider>
                        {children}
                      </CustomerProvider>
                    </BlogPostProvider>
                  </CouponsProvider>
                </ColaboratorsProvider>
              </OrderProvider>
            </ProductProvider>
          </GroupsProvider>
        </CategoryProvider>
      </BranchProvider>
    </SettingsProvider>
  </AuthProvider>

);
export default AppProvider;
