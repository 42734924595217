import styled from 'styled-components';

export const WhatsappArea = styled.div`
  position: fixed;
  bottom: ${props => props.bottomAnchor};
  right: 35px;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`;
