import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { Scope } from '@unform/core';
import { toast } from 'react-toastify';
import CloseIcon from '@material-ui/icons/Close';

import { CircularProgress } from '@material-ui/core';
import ImageField from '../../form/image-upload';

import { useStyles } from '../../styles';
import actions from '../../../actions';
import { toBase64 } from '../../../utils/getBase64';
import { useAuth } from '../../../contexts/auth';

export default function AddBanners({ formRef, productId, initialBanner, setBannerImage }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();
  const [isLoading, setLoading] = useState(false);

  const handleBanner = async () => {
    const { banner } = formRef.current.getData();

    setLoading(true);

    if (banner?.base?.size > 976563) {
      setLoading(false);
      formRef.current.setFieldValue('banner.base', initialBanner?.image_base);
      return toast.error('O banner deve ter no máximo 1 MB.');
    }

    const newBanner = {
      base: {
        image: await toBase64(banner?.base),
        content_type: banner?.base?.type,
      },
      title: 'banner',
      type: 'banner_image',
    };

    if (initialBanner) {
      const update = await actions.media.updateMedia({ ...newBanner, productId, id: initialBanner?.id });

      // await refetch();

      setBannerImage({ ...newBanner, id: update.id });
      toast.success('Banner atualizado com sucesso!');

      setLoading(false);

      return update;
    }

    const add = await actions.media.addMediaToProduct(productId, newBanner);
    setBannerImage({ ...newBanner, id: add.id });
    // await refetch();

    toast.success('Banner adicionado com sucesso!');

    setLoading(false);

    return add;
  };

  const handleRemoveBanner = async () => {
    setLoading(true);

    const removeMedia = await actions.media.removeMedia({ ...initialBanner, productId });

    setBannerImage(null);
    setLoading(false);

    // await refetch();

    if (removeMedia) toast.success('Banner removido com sucesso');

    return removeMedia;
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="row"
        spacing={1}
        className={styles.gridImage}
        style={{ marginLeft: '-4px' }}
      >
        {/* <Grid item>
        <div className={stylesImg.imgContainer}>
          <ImagePreview src="" />

          <div className={stylesImg.middle} style={{ outline: 'none' }}>
            <div className={stylesImg.noBorder}>
              <IconButton className={stylesImg.iconButton} style={{ margin: '48px 0 0' }}>
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid> */}

        <Grid item sm={11} xs={11} className={verifyPerms({ role: 'Catálogo', access: 'write' }) && 'disabled-events'}>
          <Scope path="banner">
            {isLoading ? <CircularProgress style={{ margin: '0 30px 0 30px' }} size={30} /> : (
              <ImageField
                name="base"
                edit
                acceptImg=".svg, .jpg, .jpeg, .png"
                size={{ height: '136px', width: '100%' }}
                isBanner
                setImage={handleBanner}
                previewImg={initialBanner?.image_base || initialBanner?.base?.image}
              />
            )}

          </Scope>
        </Grid>
        {(initialBanner?.image_base || initialBanner?.base?.image) && (
        <Grid
          item
          sm={1}
          xs={1}
          alignContent="center"
          alignItems="center"
          justify="center"
          container
          className={verifyPerms({ role: 'Catálogo', access: 'write' }) ? 'disabled-events' : styles.gridCategoriesInputCloseIcon2}
        >
          <CloseIcon color="disabled" onClick={handleRemoveBanner} />
        </Grid>
        )}

      </Grid>
    </>
  );
}
