import React, { useState, useRef, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';

import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import { orderBy } from 'lodash';
import ContentWrapper from '../content-wrapper';
import { PageTitle, useStyles } from '../styles';

import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import CategoryBasicInfo from './components/category-info';
import FeaturedProducts from './components/featured-products';
import ProductsList from './components/products-list';
import SaveBar from '../save-bar';
import Subcategories from './components/subcategories';
import Keywords from './components/keywords';
// import DetailedDescription from './components/detailed-description';
import Visibility from './components/visibility';

import { subcategoriesSchema, productsSchema } from './schemas';

import { toBase64 } from '../../utils/getBase64';
import { formatDate } from '../../utils/time';

import { useCategory } from '../../contexts/category';
import { useAuth } from '../../contexts/auth';

export default function NewCategory() {
  const { search } = useLocation();
  const { id } = useParams();
  const history = useHistory();

  const { verifyPerms } = useAuth();
  const { updateCategory, getOneCategory } = useCategory();

  const [category, setCategory] = useState(null);
  const styles = useStyles();
  const refs = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [products, setProducts] = useState([]);
  const [productsWithSubs, setProductsWithSubs] = useState([]);

  const refetch = useCallback(async () => {
    try {
      const { item } = await getOneCategory(id);

      setCategory(item);
      setName(item.name);
    } catch (err) {
      toast.error('Ocorreu um erro ao atualizar a categoria. Tente novamente mais tarde');
    }
  }, [getOneCategory, id]);
  useEffect(() => {
    async function getCategory() {
      try {
        await refetch();
      } catch (err) {
        toast.error('Ocorreu um erro ao atualizar a categoria. Tente novamente mais tarde');
      }
    }
    getCategory();
  }, [refetch]);

  useEffect(() => {
    async function getProducts() {
      const prodcutsArr = [];
      const productsApi = category?.products;
      const subcategories = category?.subcategories;

      if (!productsApi && !category?.subcategories) return null;

      const subProducts = subcategories && subcategories.map(item => {
        const subProductsArr = item.products;

        if (!subProductsArr) return null;

        return subProductsArr.map(procuct => prodcutsArr.push(procuct));
      });

      if (!productsApi) return setProducts(prodcutsArr);

      setProducts([...prodcutsArr, ...productsApi]);

      return subProducts;
    }

    async function getProductsWithSubs() {
      // const subsProducts = category?.subcategories?.map(item => item?.products?.map(product => {
      //   setProductsWithSubs(items => [...items, product.id]);
      // }));

      const subsProducts = category?.subcategories?.map(item => item?.products?.map(product => setProductsWithSubs(items => [...items, product.id])));

      return subsProducts;
    }

    getProductsWithSubs();
    getProducts();
  }, [category]);

  function orderSubcategories(data, newIndex) {
    if (newIndex === true) {
      const newIndexs = data?.map((item, index) => {
        const subs = {
          ...item, index: index + 1,
        };

        return subs;
      });

      const newOrder = orderBy(newIndexs, ['index'], ['asc']);

      return newOrder;
    }

    const newOrder = orderBy(data, ['index'], ['asc']);

    return newOrder;
  }

  const handleSubmit = async (data) => {
    setLoading(true);

    if (!data.name) {
      setLoading(false);
      return toast.error('Nome da categoria é obrigatório');
    }

    const { ecommerce, callcenter } = data;
    const logo = data.logo.type ? await toBase64(data.logo) : null;

    const addCategory = {
      ...data,
      new_logo_image: logo,
      new_logo_content_type: data.logo?.type,
      visible: true,
      ecommerce: { ...ecommerce, from: formatDate(ecommerce.from) },
      callcenter: { ...callcenter, from: formatDate(callcenter.from) },
    };

    try {
      const response = await updateCategory(id, addCategory);

      history.push('/categories');

      toast.success('Categoria atualizada com sucesso.');

      return response;
    } catch (error) {
      const validatorError = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          validatorError[err.path] = err.message;
        });

        refs.current.setErrors(validatorError);
      }

      setLoading(false);
    }
  };

  return category && (
    <>
      <Breadcrumb
        title="Nova Categoria"
        text="Catálogo"
        breadcrumb="Categorias"
        link="/categories"
        current={search ? 'Criar Nova Categoria' : 'Editar Categoria'}
      />

      <Form onSubmit={handleSubmit} initialData={category} ref={refs}>
        <ContentWrapper align="top">
          <Grid container direction="row" className={styles.gridRowForm} alignItems="baseline">
            <Grid item xs={12} className={styles.gridTitle}>
              <PageTitle>{name || category?.name || 'Nova categoria'}</PageTitle>
            </Grid>

            <Grid item xs={8} sm={8} className={styles.gridRowFormPadding}>
              <CategoryBasicInfo
                refs={refs}
                setName={setName}
                name={name}
                category={category}
              />

              <Subcategories
                formRef={refs}
                tableHeaders={subcategoriesSchema}
                subcategories={category?.subcategories}
                id={id}
                orderSubcategories={orderSubcategories}
                refetch={refetch}
              />

              <ProductsList
                subProducts={productsWithSubs}
                tableHeaders={productsSchema}
                products={category?.products}
                refetch={refetch}
                categoryId={id}
              />

              <FeaturedProducts featured={products} />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Visibility category={category} formRef={refs} />
              <Keywords editCategory={category || {}} />
              {/* <DetailedDescription editCategory={category || {}} /> */}
            </Grid>

          </Grid>
        </ContentWrapper>

        <SaveBar
          disabled={verifyPerms({ role: 'Catálogo', access: 'write' })}
          buttonPrimary="salvar alterações"
          buttonSecondary="sair sem salvar"
          type="submit"
          history="/categories"
          isLoading={isLoading}
        />
      </Form>
    </>
  );
}
