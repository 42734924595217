export const RolesAndPermissionsSchema = [
  {
    name: 'role',
    label: 'Cargo',
    isFilter: false,
  },
  {
    name: 'department',
    label: 'Departamento',
    isFilter: false,
  },
  {
    name: 'contributors',
    label: 'Colaboradores',
    isFilter: false,
  },
  {
    name: 'action',
    label: 'Ações',
    isFilter: false,
  },
];

export const EditRolesAndPermissionsSchema = [
  {
    name: 'permissions',
    label: 'Permissões relacionadas à tela',
    isFilter: false,
  },
  {
    name: 'read',
    label: 'Ler',
    isFilter: false,
  },
  {
    name: 'edit',
    label: 'Editar',
    isFilter: false,
  },
  {
    name: 'delete',
    label: 'Deletar',
    isFilter: false,
  },
];
