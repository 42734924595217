import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  productImg: {
    marginLeft: '16px',
    marginTop: '5px',
    heigth: '60px',
    width: '60px',
  },

  adornmentStart: {
    marginRight: '-8px',
    marginTop: '16px',

    '& span': {
      color: '#A3A3A3',
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: '0.03em',
    },
  },

  adornmentEnd: {
    marginRight: '0',
    marginTop: '16px',

    '& span': {
      color: '#A3A3A3',
      fontFamily: 'Poppins',
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: '0.03em',
    },
  },

  gridCategoryFields: {
    height: '146px',
    maxHeight: '146px',
    marginLeft: '24px',
    marginTop: '-10px',
    width: 'calc(100% - 160px)',
  },

  griCategoryLogo: {
    height: '136px',
  },

  gridCategoryMulti: {
    marginTop: '-2px',
  },

  gridCategoryTextField: {
    marginBottom: '24px',
  },

  gridFeaturedPaper: {
    marginBottom: '24px',
  },

  gridFeaturedSelect: {
    marginBottom: '6px',
    marginTop: '-10px',
  },

  gridFeaturedSubtitle: {
    marginTop: '28px',
  },

  gridModal: {
    marginTop: '26px',
  },

  gridProducts: {
    '& .MuiTableContainer-root': {
      height: '186px',
    },
  },

  gridProductsModalFields: {
    marginTop: '-8px',
    width: '100%',
  },

  gridProductsModal: {
    marginBottom: '22px',
  },

  gridProductsModalAdd: {
    display: 'flex',
    flexGrow: '1',
    marginTop: '16px',
    justifyContent: 'flex-end',
  },

  gridSubcategoryFields: {
    height: '128px',
    maxHeight: '146px',
    marginLeft: '24px',
    marginTop: '8px',
    width: 'calc(100% - 160px)',
  },

  gridSubcategoryList: {
    marginTop: '18px',
  },

  gridTextField: {
    marginBottom: '26px',
  },

  imagePreview: props => ({
    backgroundImage: props.backgroundImage,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '5px',
    height: '104px',
    marginTop: '8px',
    width: '100%',
  }),

  orderAdornment: {
    marginRight: '-10px',
  },

  productName: {
    color: '#ffffff',
    fontSize: '36px',
    fontWeight: 'bold',
  },
}));

export default useStyles;
