const schema = [
  {
    name: 'id',
    label: 'Código',
    // isFilter: true,
  },
  {
    name: 'discount',
    label: 'Desconto',
    // isFilter: true,
  },
  {
    name: 'step',
    label: 'Aplicação',
    // isFilter: true,
  },
  // {
  //   name: 'eligibility',
  //   label: 'Eligibilidade',
  //   // isFilter: true,
  // },
  {
    name: 'disponibility',
    label: 'Disponibilidade',
    // isFilter: true,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];

export default schema;
