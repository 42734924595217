import React, { useCallback } from 'react';
import { Route } from 'react-router-dom';

import Layout from '../components/layout';

import store from '../store';
import actions from '../actions';
import Spinner from '../components/spinner';

function PrivateRoute({ component: Component, layout, ...rest }) {
  const [isLoading, setLoading] = React.useState(false);
  const { user } = store.get();
  const accessToken = localStorage.getItem('accessToken');
  // const history = useHistory();

  React.useEffect(() => {
    async function checkUser() {
      if (!user && accessToken) {
        setLoading(true);

        actions.auth.fetchUser().then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }

    checkUser();
  }, [user, accessToken]);

  const handleWizardNavigate = useCallback(() => {
    // window.location.replace('https://pp-website-prod.vercel.app/');
    window.location.replace(process.env.REACT_APP_WEBSITE_URL);
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoading) return <Layout Component={Spinner} user={user} {...props} LayoutFrame={layout} />;

        return accessToken ? <Layout Component={Component} user={user} LayoutFrame={layout} login {...props} /> : handleWizardNavigate();
        // <Redirect to={{
        //   pathname: '/login',
        //   state: {
        //     referrer: history.location.pathname,
        //   },
        // }}
        // />
      }}
    />
  );
}

export default PrivateRoute;
