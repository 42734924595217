import React, { useRef, useCallback, useState, useEffect } from 'react';

// Libs
import { Grid, Button, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';

// Icons
import SortIcon from '@material-ui/icons/Sort';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

// Components
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
import SearchField from '../../form/search-field';
import Spinner from '../../spinner';
import TableMaterial from '../../table';
import Select from '../../form/select';
import BlogPostItem from './blog-post-item';

// Schemas
import { BlogSchema } from './schema';

// Context & Store
import { useBlogPosts } from '../../../contexts/blog';
import store from '../../../store';

// Styles
import { PageTitle, useStyles } from '../../styles';
import { Container, CleanForm, Divider, FormContainer, AdvancedFiltersContainer } from '../styles';
import { useAuth } from '../../../contexts/auth';

function Blog() {
  /** CONSTS */
  const { agent } = store.get();

  const { verifyPerms } = useAuth();
  const styles = useStyles();
  const history = useHistory();
  const formRef = useRef();
  const advancedFormRef = useRef();
  const {
    searchPosts,
    listAuthorsAndStatus,
    isLoading,
    posts,
    authorsAndStatus,
    addPost,
  } = useBlogPosts();
  const { search } = useLocation();

  const postStatus = [
    {
      name: 'Rascunho',
      value: 'Draft',
    },
    {
      name: 'Publicado',
      value: 'Published',
    },
    {
      name: 'Ocultado',
      value: 'Hidden',
    },
    {
      name: 'Todos',
      value: 'all',
    },
  ];

  /** STATES */
  const [showFilter, setShowFilter] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState();

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedAuthor, setSelectedAuthor] = useState('');

  const [parseSearch, setParseSearch] = useState('');

  /** EFFECTS */
  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const fetchPosts = async () => {
      await searchPosts(allParams);
    };

    fetchPosts();
  }, [search, searchPosts]);

  useEffect(() => {
    listAuthorsAndStatus();
  }, [listAuthorsAndStatus]);

  useEffect(() => {
    function getValue() {
      const splitSearch = search.split('&');
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      const keywords = splitSearch.map(item => {
        if (item.indexOf('q=') !== -1 && formRef.current) {
          setParseSearch(item.replace('q=', '').replace('?', ''));

          return formRef.current.setFieldValue('search', allParams?.q);
        }

        return null;
      });

      return keywords;
    }

    getValue();
  }, [search]);

  /** CALLBACKS */
  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    const newParams = { ...allParams, ...params };

    if (param === 'q') {
      // newParams.p = 1;
      const isEqual = allParams?.q === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    return history.push({
      pathname: '/blog',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  const handleStatusParams = useCallback(async (data) => {
    if (data?.status) {
      setSelectedStatus(data.status);
      const apiData = {
        author: (selectedAuthor !== '' && selectedAuthor !== 'all') ? selectedAuthor : '',
        status: data.status === 'all' ? '' : data.status,
      };
      searchPosts(apiData).then(res => {
        setFilteredPosts(res);
      });
    }
  }, [searchPosts, selectedAuthor]);

  const handleAuthorParams = useCallback(async (data) => {
    if (data?.author !== undefined) {
      setSelectedAuthor(data.author);
      const apiData = {
        author: data.author === 'all' ? '' : data.author,
        status: (selectedStatus !== '' && selectedStatus !== 'all') ? selectedStatus : '',
      };

      searchPosts(apiData).then(res => {
        setFilteredPosts(res);
      });
    }
  }, [searchPosts, selectedStatus]);

  const hanndleAddNewPost = useCallback(async () => {
    const apiFakeData = {
      title: 'post',
      author: agent.name,
      status: 'Draft',
    };
    const resp = await addPost({ apiData: apiFakeData, displayMsg: false });
    history.push(`/blog-post/${resp.id}`);
  }, [agent, addPost, history]);

  // FUNCTIONS
  const renderItem = (post) => <BlogPostItem post={post} />;

  const handleCleanAdvancedForm = useCallback(() => {
    setFilteredPosts();

    setSelectedAuthor('');
    setSelectedStatus('');

    const advancedData = advancedFormRef?.current?.getData();
    if (advancedData !== undefined) {
      advancedFormRef.current.setFieldValue('author', '');
      advancedFormRef.current.setFieldValue('status', '');
    }

    searchPosts();
  }, [advancedFormRef, searchPosts]);

  return (
    <>
      <Breadcrumb
        title="Lista de Blog Posts"
        text="Marketing"
        current="Blog"
      />

      <ContentWrapper align="top">
        <Form ref={formRef} onSubmit={(formData) => handleParams('q', formData.title)}>
          <Grid container direction="row" alignItems="center" className={styles.gridRow}>
            <Grid item xs={3} className={styles.gridTitle}>
              <PageTitle>Lista de Blog Posts</PageTitle>
            </Grid>

            <Grid item xs={9}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchField}>
                  <SearchField
                    name="title"
                    handleParams={handleParams}
                    placeholder="buscar por título do post"
                    label=""
                    search={search}
                    parseSearch={parseSearch}
                    clear={() => handleParams('q', '')}
                  />
                </div>

                <Button
                  variant={showFilter ? 'contained' : 'outlined'}
                  color="primary"
                  className={styles.buttonFilter}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <SortIcon />
                </Button>

                <Button
                  disabled={verifyPerms({ role: 'Marketing', access: 'write' })}
                  className={styles.filterButton}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => hanndleAddNewPost()}
                >
                  adicionar novo post
                </Button>
              </div>
            </Grid>
          </Grid>
        </Form>
        {showFilter ? (
          <Container>
            <Divider />
            <FormContainer>
              <Grid container>
                <Grid item xs={12} className="title">
                  Filtros avançados
                </Grid>
              </Grid>
              <AdvancedFiltersContainer>
                <Grid container direction="row">
                  <Form
                    ref={advancedFormRef}
                  >
                    <Grid container direction="row">
                      <Grid item className="select">
                        <Select
                          name="status"
                          label="Status"
                          options={postStatus}
                          InputLabelProps={{ shrink: true }}
                          value={selectedStatus}
                          onChange={(e) => handleStatusParams({ status: e.target.value })}
                        >
                          {({ item, handleValue }) => <MenuItem value={item.value} key={item.value} onClick={handleValue}>{item.name}</MenuItem>}
                        </Select>
                      </Grid>
                      <Grid item className="select">
                        <Select
                          name="author"
                          label="Autor"
                          options={authorsAndStatus.authors}
                          InputLabelProps={{ shrink: true }}
                          value={selectedAuthor}
                          onChange={(e) => handleAuthorParams({ author: e.target.value })}
                        >
                          {({ item, handleValue }) => <MenuItem value={item} key={item} onClick={handleValue}>{item}</MenuItem>}
                        </Select>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>

                <CleanForm
                  onClick={handleCleanAdvancedForm}
                >
                  <HighlightOffOutlinedIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }} />
                  <p>Limpar todos os filtros</p>
                </CleanForm>
              </AdvancedFiltersContainer>
            </FormContainer>
          </Container>
        ) : ('')}
      </ContentWrapper>
      {
        isLoading ? <Spinner /> : (
          <Container
            className={styles.containerTableBottom} style={{
              margin: '24px 0 110px',
            }}
          >
            {filteredPosts ? (
              <TableMaterial
                headers={BlogSchema}
                renderItem={renderItem}
                pagination={filteredPosts.pagination}
                listQuery={filteredPosts.items}
                results={filteredPosts?.pagination?.total_items}
                handleParams={handleParams}
              />
            ) : (
              <TableMaterial
                headers={BlogSchema}
                renderItem={renderItem}
                pagination={posts.pagination}
                listQuery={posts.items || []}
                results={posts?.pagination?.total_items}
                handleParams={handleParams}
              />
            )}
          </Container>
        )
      }
    </>
  );
}

export default Blog;
