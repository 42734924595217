import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import { remove } from 'lodash';
import React from 'react';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import TextField from '../form/text-field';
import { ActionButton, useStyles } from '../styles';
import { StyledTooltip } from '../tooltip';

import CheckBox from '../form/checkbox';
import MultipleValuesFreeSolo from '../form/multiple-values-freesolo';

export default function SecondaryVariantsList({ variants, setVariants, formRef }) {
  const styles = useStyles();
  const removeInput = item => {
    const list = [...variants];
    remove(list, (data) => data === item);
    setVariants(list);
  };

  return (
    <>
      {variants.map((x, i) => (
        <Grid item xs={12} sm={12} key={x.name}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={3} sm={3}>
              <TextField
                name={`replace_secondary_variants[${i}].name`}
                placeholder="Tipo de Variante"
                label="Tipo de Variante"
                defaultValue={x?.name}
              />
            </Grid>

            <Grid item xs={7} sm={7}>
              <MultipleValuesFreeSolo
                name={`replace_secondary_variants[${i}].options`}
                placeholder="Variantes (separar com vírgulas)"
                label="Variantes (separar com vírgulas)"
                formRef={formRef}
                defaultValue={x?.options}
              />
            </Grid>

            <Grid item xs={i >= 1 ? 2 : 1} className={styles.gridButtonContainer}>
              <Grid container direction="row" justify="center" alignContent="center" spacing={i >= 1 ? 1 : 4}>

                <Grid item xs={i >= 1 ? 4 : 6} className={styles.gridButton}>
                  <StyledTooltip
                    placement="top"
                    title={x.visible
                      ? 'Marcar como invisível para o cliente'
                      : 'Marcar como visível para o cliente'}
                  >
                    <CheckBox
                      name={`replace_secondary_variants[${i}].visible`}
                      icon={<VisibilityOffIcon className={styles.icon} color="primary" fontSize="default" />}
                      checkedIcon={<VisibilityOffIcon className={styles.icon} color="disabled" fontSize="default" />}
                      defaultChecked={x?.visible}
                    />

                  </StyledTooltip>
                </Grid>

                {i > 0
                  && (
                    <Grid item xs={4} className={styles.gridButton}>
                      <StyledTooltip title="Remover" placement="top">
                        <ActionButton className={styles.actionButton} onClick={() => removeInput(x)}>
                          <CloseIcon className={styles.icon} color="disabled" fontSize="default" />
                        </ActionButton>
                      </StyledTooltip>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
