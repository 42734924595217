import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import { useStyles } from './styles';

function FormTimePicker({ ...rest }) {
  const styles = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <TimePicker
        ampm={false}
        variant="inline"
        inputVariant="outlined"
        className={styles.textField}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormTimePicker;
