import React from 'react';
import styled from 'styled-components';

import { Grid } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import Description from '../../text/description';
import { StyledTooltip } from '../../tooltip';

export const Container = styled(Grid)`
  align-items: center;
  background: #FFF;
  border: 1px solid #E1E1E1;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  padding: 8px;
`;

export default function TitleCard({ children, isHelper, tooltip, direction }) {
  return (
    <Container>
      {isHelper
        ? (
          <>
            <Description color="#A3A3A3" ml="16px" weight="600">{children}</Description>

            <StyledTooltip title={tooltip} placement={direction}>
              <HelpOutline color="disabled" fontSize="small" style={{ margin: '0 0 0 8px' }} />
            </StyledTooltip>
          </>
        )
        : (
          <Description color="#A3A3A3" ml="16px" weight="600">{children}</Description>
        )}
    </Container>
  );
}
