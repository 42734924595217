import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Form } from '@unform/web';

// import SortIcon from '@material-ui/icons/Sort';

import { toast } from 'react-toastify';
import Spinner from '../spinner';
import ContentWrapper from '../content-wrapper';
// import Filters from './components/filters';
import { CategorySchema } from './schema';
import SearchField from '../form/search-field';
import TableMaterial from '../table';
import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';

import { Container, PageTitle, useStyles } from '../styles';

import { useCategory } from '../../contexts/category';
import CategoryData from './data';
import { useAuth } from '../../contexts/auth';

export default function CategoryList() {
  const styles = useStyles();
  const formRef = useRef();

  const { verifyPerms } = useAuth();
  const history = useHistory();

  const [parseSearch, setParseSearch] = useState('');

  const [newCategoryLoading, setNewCategoryLoading] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  const { fetchCategories, fetchCategoriesWithParams, addCategory } = useCategory();

  const { search } = useLocation();

  const renderItem = (cat) => {
    if (!cat.name) return null;
    return (
      <CategoryData category={cat} refetch={fetchCategories} />
    );
  };
  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);
    const getCategory = async () => {
      setLoading(true);
      const response = await fetchCategoriesWithParams({
        recursive: true,
        fetch_product_ids: true,
        fetch_keywords: true,
        ...allParams,
      });

      setCategories(response);
      setLoading(false);
    };

    getCategory();
  }, [fetchCategoriesWithParams, search]);

  useEffect(() => {
    function getValue() {
      const splitSearch = search.split('&');
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      const keywords = splitSearch.map(item => {
        if (item.indexOf('name=') !== -1 && formRef.current) {
          setParseSearch(item.replace('name=', '').replace('?', ''));

          return formRef.current.setFieldValue('search', allParams?.name);
        }

        return null;
      });

      return keywords;
    }

    getValue();
  }, [search]);

  const handleNewCategory = useCallback(async () => {
    setNewCategoryLoading(true);
    try {
      const newCategory = await addCategory({
        name: 'Nova categoria',
      });

      if (newCategory.id) return history.push(`categories/${newCategory.id}?new`);
      setNewCategoryLoading(false);
      return newCategory;
    } catch (err) {
      setNewCategoryLoading(false);
      return toast.error('Ocorreu um erro ao criar uma nova categoria. Tente novamente mais tarde');
    }
  }, [addCategory, history]);

  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...params, ascending: 0 };
    } else {
      newParams = { ...allParams, ...params, ascending: 1 };
    }

    if (param === 'name') {
      newParams.p = 1;
      const isEqual = allParams?.q === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    return history.push({
      pathname: '/categories',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  return (
    <>
      <Breadcrumb title="Lista de Categorias" text="Catálogo" current="Categorias" />

      <ContentWrapper align="top">
        <Grid container direction="row" alignItems="center" className={styles.gridRow}>
          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>Lista de Categorias</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <Form ref={formRef} onSubmit={(formData) => handleParams('name', formData.search)}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchField}>
                  <SearchField
                    name="search"
                    handleParams={handleParams}
                    search={search}
                    label="buscar categoria"
                    placeholder="buscar categoria"
                    parseSearch={parseSearch}
                    clear={() => handleParams('name', '')}
                  />
                </div>

                {/*
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.buttonFilter}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <SortIcon />
                </Button>
                */}

                <Button
                  className={styles.filterButton}
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={verifyPerms({ role: 'Catálogo', access: 'write' }) || newCategoryLoading}
                  onClick={handleNewCategory}
                >
                  criar nova categoria
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>

        {/* showFilter && (
          <Filters />
        ) */}

        {isLoading && !categories ? <Spinner /> : (
          <Container className={styles.containerTableBottom}>

            <TableMaterial
              headers={CategorySchema}
              renderItem={renderItem}
              listQuery={categories?.items}
              results={categories?.total_count}
              pagination={categories?.pagination}
              handleParams={handleParams}
            />
          </Container>
        )}

      </ContentWrapper>
    </>
  );
}
