import React from 'react';

import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import LoginCPF from './components/cpf';
import LoginEmail from './components/email';
import LoginOrganization from './components/organization';
import LoginPassword from './components/password';
import LoginRequest from './components/request-access';

import ContentWrapper from '../content-wrapper';
import StepManager from '../step-manager';

const steps = {
  cpf: LoginCPF,
  email: LoginEmail,
  organization: LoginOrganization,
  password: LoginPassword,
  request: LoginRequest,
};

export default function Login() {
  const history = useHistory();

  return (
    <ContentWrapper align="top">
      <Grid container spacing={0}>
        <Grid item xs />

        <Grid item xs style={{ height: '100vh' }}>
          <StepManager steps={steps} defaultSection="email" redirect={history.location?.state?.referrer} />
        </Grid>

        <Grid item xs />
      </Grid>
    </ContentWrapper>
  );
}
