import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { Form } from '@unform/web';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import TextField from '../../form/text-field';

import {
  CardLogin, Row, RowBottom, Subtitle, Title, useStyles,
} from '../styles';

import actions from '../../../actions';

export default function LoginPassword({ redirect }) {
  const styles = useStyles();
  const history = useHistory();
  const buttonContinuar = useRef();

  const handleSubmit = async (formData) => {
    const response = await actions.auth.login(formData);

    if (!redirect) return history.push('/login');

    history.push(redirect);

    return response;
  };

  return (
    <div>
      <CardLogin cardPassword>
        <Title>
          Alterar senha
        </Title>

        <Subtitle mt="16px">
          Cadastre sua nova senha
        </Subtitle>

        <Form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                name="new_password"
                label="Nova Senha"
                placeholder="********"
                InputLabelProps={{ shrink: true }}
                type="password"
                style={{ marginTop: '30px' }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                name="confirm_password"
                label="Confirmar Nova Senha"
                placeholder="********"
                InputLabelProps={{ shrink: true }}
                type="password"
              />
            </Grid>
          </Grid>

          <Row style={{ marginTop: '38px' }}>
            <Button color="primary" variant="contained" type="submit" ref={buttonContinuar} className={styles.button}>
              Cadastrar Nova Senha
            </Button>
          </Row>
        </Form>
      </CardLogin>

      <RowBottom>
        <Button
          onClick={() => { history.push('/'); }}
          color="primary"
          type="button"
          className={styles.buttonText}
        >
          <KeyboardBackspaceIcon className={styles.iconKeyboard} />
          voltar para página inicial
        </Button>
      </RowBottom>
    </div>
  );
}
