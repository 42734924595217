import React, { useRef, useState, useEffect, useCallback } from 'react';

// Libs
import {
  Grid, IconButton, InputAdornment,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { unionBy } from 'lodash';
import { toast } from 'react-toastify';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Components
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
import TextField from '../../form/text-field';
import TextFieldMulti from '../../form/text-fieldmulti';
import TitleCard from '../../title/title-card';
import ImageField from '../../form/image-upload';
import Editor from './blog-editor';
import MultipleValuesAutocomplete from '../../form/multiple-values-autocomplete';
import Modal from '../../modal';

import BasicData from './add-post-components/basic-data';

// Context
import { useBlogPosts } from '../../../contexts/blog';
import { useProduct } from '../../../contexts/product';

// Styles
import { PageTitle, StyledPaper } from '../../styles';

// Utils
import { getBase64FromUrl } from '../utils';
import { NewPostSchema } from './schema';
import SaveBar from '../../save-bar';
import MultipleValuesFreeSolo from '../../form/multiple-values-freesolo';
import Spinner from '../../spinner';
import { useAuth } from '../../../contexts/auth';
import Text from '../../text/text';
import { slugify } from '../../../utils/slugify';
import FormSwitch from '../../form/switch';

function AddBlogPost() {
  const formRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const { verifyPerms } = useAuth();
  const {
    listPosts,
    getPost,
    addPostImage,
    updatePost,
    posts,
  } = useBlogPosts();
  const { products, fetchProducts, searchProducts } = useProduct();

  const [initialData, setInitialData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState();
  const [relatedPosts, setRelatedPosts] = useState(initialData?.related_posts?.posts || []);
  const [relatedProducts, setRelatedProducts] = useState(initialData?.products_buy_together?.products || []);
  const [singleProducts, setSingleProducts] = useState(initialData?.products_buy_separately?.products || []);

  const [allProducts, setAllProducts] = useState([]);
  const [scrollPage, setSrollPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [isGettingData, setGettingData] = useState(false);

  const [title, setTitle] = useState();
  const [resume, setResume] = useState();
  const [keywords, setKeywords] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  // const keywords = initialData?.keywords?.words?.join('; ') || '';
  const fetchedRelatedPosts = initialData?.related_posts?.posts;
  const fetchedBuyTogetherProducts = initialData?.products_buy_together?.products;
  const fetchedBuySeparatelyProducts = initialData?.products_buy_separately?.products;

  /** CALLBACKS */
  const returnToBlogList = useCallback(() => {
    history.push('/blog');
  }, [history]);

  const [slug, setSlug] = useState();
  const [fixLink, setFixLink] = useState();

  useEffect(() => {
    const fetchPost = async () => {
      const fetchedPost = await getPost(id);

      setSlug(fetchedPost?.slug);
      setFixLink(!!fetchedPost?.is_fixed_slug);

      setInitialData({
        title: fetchedPost.title,
        slug: fetchedPost.slug,
        abstract: fetchedPost.abstract,
        image: fetchedPost.image,
        author: fetchedPost.author,
        status: fetchedPost.status,
        body: fetchedPost.body,
        updated_at: fetchedPost.updated_at,
        is_fixed_slug: !!fetchedPost?.is_fixed_slug,
      });

      setKeywords(fetchedPost?.keywords?.words?.length ? fetchedPost?.keywords?.words : []);
      setTitle(fetchedPost.title);
      setResume(fetchedPost.abstract);

      setRelatedPosts(fetchedPost.related_posts?.posts || []);
      setRelatedProducts(fetchedPost.products_buy_together?.products || []);
      setSingleProducts(fetchedPost.products_buy_separately?.products || []);
    };

    fetchPost();
  }, [id, getPost]);

  useEffect(() => {
    if (fixLink) return;
    setSlug(slugify(title));
  }, [title, fixLink]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const data = formRef.current.getData();

      const replace_keywords = Array.isArray(data?.keywords) ? data.keywords : data?.keywords ? data.keywords.split(',') : [];

      formRef.current.setErrors({});

      // Data validation
      await NewPostSchema.validate(data, {
        abortEarly: false,
      });

      // Data manipulation
      // const keyWordsArr = data.keywords.split(',');

      // Data grouping
      const apiData = {
        id,
        abstract: data.abstract,
        author: data.author,
        body: content,
        title: data.title,
        keywords: replace_keywords,
        related_posts: relatedPosts?.map(product => parseInt(product.id, 10)),
        products_buy_separately: singleProducts?.map(product => product.id),
        products_buy_together: relatedProducts?.map(product => product.id),
        status: data.status,
        slug: data.slug,
        is_fixed_slug: !!data?.is_fixed_slug,
      };

      const update = await updatePost(apiData);

      if (update.status !== 200) return null;

      toast.success('Post criado/atualizado com sucesso!');

      setLoading(false);
      history.push('/blog');

      return update;
    } catch (err) {
      const validationErrors = {};
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        toast.error(err.errors[0]);
        return formRef.current.setErrors(validationErrors);
      }

      return toast.error('Não foi possível atualizar o post');
    }
  }, [
    id,
    formRef,
    content,
    singleProducts,
    relatedProducts,
    relatedPosts,
    updatePost,
    history,
  ]);

  const handleSaveSketch = useCallback(() => {
    onSubmit(singleProducts, relatedProducts, relatedPosts);
  }, [singleProducts, relatedProducts, relatedPosts, onSubmit]);

  const handleBanner = useCallback(async (url) => {
    const { postFrontCover } = formRef.current.getData();

    const base64Img = await getBase64FromUrl(url);

    const imgAPIData = {
      id,
      content_type: postFrontCover?.type,
      image: base64Img,
    };

    addPostImage(imgAPIData);
  }, [formRef, id, addPostImage]);

  /** EFFECTS */
  useEffect(() => {
    listPosts();
    if (scrollPage <= (lastPage - 1)) {
      fetchProducts({
        'sort-by': 'title',
        ascending: 1,
        p: scrollPage,
      });
    }
  }, [listPosts, fetchProducts, scrollPage, lastPage]);

  useEffect(() => {
    if (scrollPage !== 1) return;

    const newProducts = products.items;

    setAllProducts(newProducts);
  }, [products, scrollPage]);

  const getByName = useCallback(async (name) => {
    setGettingData(true);
    const response = await searchProducts({ q: name });

    const fetchedProducts = allProducts;

    if (response?.items?.length) {
      response.items.map((item) => fetchedProducts.push(item));
    }

    const mergeProducts = unionBy(fetchedProducts, 'id');
    setAllProducts(mergeProducts);

    setGettingData(false);
  }, [searchProducts, allProducts]);

  const getMoreProducts = useCallback(async (page) => {
    try {
      setGettingData(true);
      const newProducts = await searchProducts({
        p: page,
        'sort-by': 'title',
        ascending: 1,
      });

      setLastPage(newProducts.pagination?.last_page);
      setSrollPage(page);

      setAllProducts(prevProducts => unionBy(prevProducts, newProducts.items, 'id'));
      setGettingData(false);
    } catch (err) {
      toast.error('Não foi possível obter mais produtos.');
      setGettingData(false);
    }
  }, [searchProducts]);

  if (!initialData) return <Spinner />;

  return (
    <>
      <Modal
        isVisible={openModal}
        title=""
        toggle={() => setOpenModal(!openModal)}
        buttonPrimary="sim"
        buttonRemove="não"
        onClick={handleSaveSketch}
        secondaryFunction={returnToBlogList}
      >
        <div style={{ padding: 16, textAlign: 'center', marginTop: 20 }}>
          <h5>
            Você deseja salvar as alterações feitas antes de sair?
          </h5>
        </div>
      </Modal>

      <Breadcrumb
        title="Adição do Post"
        text="Marketing"
        breadcrumb="Blog"
        link="/blog"
        current={`${initialData ? initialData.title : 'Novo post'}`}
      />
      <Form ref={formRef} onSubmit={onSubmit} initialData={{ ...initialData }} style={{ marginBottom: 80 }}>
        <ContentWrapper align="top">
          <Grid container justify="flex-start" direction="row" style={{ marginBottom: '32px' }}>
            <IconButton
              size="small"
              style={{ color: '#A3A3A3' }}
              onClick={returnToBlogList}
            >
              <ArrowBackIcon />
            </IconButton>
            <PageTitle>{id ? 'Editar postagem' : 'Criar nova postagem'}</PageTitle>
          </Grid>
        </ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <TitleCard>Miniatura</TitleCard>
            <StyledPaper>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <span style={{ fontSize: 12, color: '#424242' }}><b>Capa</b></span>
                    </Grid>
                    <Grid item style={{ marginBottom: 16 }}>
                      <span style={{ fontSize: 12, color: '#A3A3A3' }}>Formatos aceitos: <b>.jpg</b> ou <b>.png</b>, com tamanho máximo de 200 KB.</span>
                    </Grid>
                  </Grid>
                  <Grid className={verifyPerms({ role: 'Marketing', access: 'write' }) && 'disabled-events'}>
                    <Scope path="image">
                      <ImageField
                        name="image"
                        edit
                        acceptImg=".svg, .jpg, .jpeg, .png"
                        size={{ height: '136px', width: '260px' }}
                        // isBanner
                        setImage={(prevURL) => handleBanner(prevURL)}
                      />
                    </Scope>
                  </Grid>
                </Grid>
                <Grid item>
                  <span style={{ fontSize: 12, color: '#424242' }}><b>Título</b></span>
                  <TextField
                    name="title"
                    label=""
                    placeholder="Título"
                    value={title}
                    onChange={(e) => e.target.value.length <= 50 && setTitle(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      `${formRef.current ? formRef.current.getFieldValue('title').length : 0}/50 Caracteres`
                    }
                    InputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item>
                  <span style={{ fontSize: 12, color: '#424242' }}><b>Resumo</b></span>
                  <TextFieldMulti
                    name="abstract"
                    label=""
                    value={resume}
                    onChange={(e) => e.target.value.length <= 300 && setResume(e.target.value)}
                    placeholder="Digite o resumo do post aqui"
                    rows={7}
                    helperText={
                      `${formRef.current ? formRef.current.getFieldValue('abstract').length : 0}/300 Caracteres`
                    }
                    InputProps={{ maxLength: 300 }}
                  />
                </Grid>

                <Grid
                  item
                  container
                  alignItems="center"
                  justify="center"
                  direction="row"
                  spacing={3}
                  xs={12}
                >

                  <Grid item xs={9}>
                    <TextField
                      name="slug"
                      label="Link do post"
                      placeholder="Link do post"
                      disabled={!!fixLink}
                      inputProps={{ maxLength: 50 }}
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      helperText={`${slug.length}/50 Caracteres`}
                      InputProps={{
                        startAdornment: <InputAdornment><Text color="#A3A3A3" mt="16px">meudominio/blog/post/</Text></InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: 15 }}>
                    <FormSwitch name="is_fixed_slug" label="Fixar link" onClick={() => setFixLink(!fixLink)} />
                  </Grid>

                </Grid>
              </Grid>
            </StyledPaper>

            <TitleCard>Conteúdo do Post</TitleCard>
            <StyledPaper>
              <Editor
                initialValue={initialData?.body}
                getContent={setContent}
                limit={65535}
              />
              <Grid style={{ marginTop: '6px' }}>
                <TitleCard>Posts Relacionados</TitleCard>
                <StyledPaper>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <MultipleValuesAutocomplete
                        options={posts.items?.length > 0 ? posts.items : []}
                        placeholder="Escolha uma postagem"
                        name="relatedPosts"
                        label="Posts relacionados"
                        id="relatedPosts"
                        getValues={setRelatedPosts}
                        limit={3}
                        values={relatedPosts.length > 0 ? relatedPosts : fetchedRelatedPosts}
                      />
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>

              {/* <GridContainer container item lg={12} mt="24px" justify="flex-end">
                <Button onClick={onSubmit} variant="contained" color="primary">
                  {isLoading ? (
                    <CircularProgress size={15} style={{ color: '#fff' }} />
                  ) : (
                    'salvar alterações'
                  )}
                </Button>
              </GridContainer> */}
            </StyledPaper>
          </Grid>

          <Grid item xs={12} sm={4}>

            <BasicData formRef={formRef} />

            <TitleCard>Palavras-Chaves</TitleCard>
            <StyledPaper>
              <span style={{ fontSize: 12, color: '#A3A3A3' }}>Separe as Palavras-Chaves utilizando vírgulas</span>
              <MultipleValuesFreeSolo
                name="keywords"
                label="Palavras-Chaves"
                defaultValue={keywords}
              />
            </StyledPaper>

            <TitleCard>Sugestões de Compra abaixo do Post</TitleCard>
            <StyledPaper>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <span style={{ fontSize: 12, color: '#424242' }}><b>Produtos Avulsos</b></span>
                  <MultipleValuesAutocomplete
                    options={allProducts?.length > 0 ? allProducts : []}
                    placeholder="Escolha um produto"
                    name="singleProduct"
                    isGettingData={isGettingData}
                    getMoreProducts={getMoreProducts}
                    label="Nome do produto"
                    id="singleProduct"
                    getValues={setSingleProducts}
                    limit={6}
                    getByName={getByName}
                    getSrollPage={setSrollPage}
                    actualPage={scrollPage}
                    values={singleProducts.length > 0 ? singleProducts : fetchedBuySeparatelyProducts}
                  />
                </Grid>

                <Grid item>
                  <span style={{ fontSize: 12, color: '#424242', marginBottom: 14 }}><b>Compre Junto</b></span>
                  <MultipleValuesAutocomplete
                    options={allProducts?.length > 0 ? allProducts : []}
                    placeholder="Escolha um produto"
                    name="buyTogether"
                    label="Nome do produto"
                    id="buyTogether"
                    getMoreProducts={getMoreProducts}
                    getValues={setRelatedProducts}
                    limit={3}
                    getByName={getByName}
                    isGettingData={isGettingData}
                    getSrollPage={setSrollPage}
                    actualPage={scrollPage}
                    values={relatedProducts.length > 0 ? relatedProducts : fetchedBuyTogetherProducts}
                  />
                </Grid>
              </Grid>
            </StyledPaper>

          </Grid>
        </Grid>

        <SaveBar
          disabled={verifyPerms({ role: 'Marketing', access: 'write' })}
          buttonPrimary="salvar alterações"
          buttonSecondary="sair sem salvar"
          type="submit"
          history="/blog"
          isLoading={isLoading}
        />
      </Form>
    </>
  );
}

export default AddBlogPost;
