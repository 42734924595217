/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef, useState } from 'react';

// Libs
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import SearchIcon from '@material-ui/icons/Search';

// Styles
import { CircularProgress, InputAdornment } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { MVAutocompleteContainer } from '../styles';

function MultipleValuesAutocomplete({
  id,
  name,
  label,
  options,
  getValues,
  limit,
  actualPage,
  getByName,
  getMoreProducts,
  isGettingData,
  values,
  ...rest
}) {
  const ref = useRef(null);
  const [value, setValue] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [onTyping, setOnTyping] = useState();

  function handleValue(e) {
    setValue(e);
    getValues(e);
    setOnTyping('');
    setLoading(false);
  }

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function loadMoreItems(event) {
    if (values?.length >= limit) return;
    if (getMoreProducts !== undefined) {
      if (Math.round(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight) {
        setLoading(true);
        await getMoreProducts(actualPage + 1);
        setLoading(false);
      }
    }
  }

  const getOptionLabel = useCallback((option) => {
    if (option?.title) {
      return option.title;
    }
    return '';
  }, []);

  const getMoreItems = useCallback(async (e) => {
    // const findOption = options.filter((item) => item?.title?.toLowerCase() === e.toLowerCase());
    setOnTyping(e);

    if (getByName && !isGettingData) {
      setLoading(true);
      await timeout(4500);
      await getByName(e);
      setLoading(false);
    }
  }, [getByName, isGettingData]);

  return (
    <MVAutocompleteContainer className="MVAutocompleteContainer">
      <Autocomplete
        multiple
        id={id}
        ref={ref}
        options={options.length > 0 ? options : []}
        getOptionLabel={(option) => getOptionLabel(option)}
        defaultValue={values?.length > 0 ? values : []}
        filterSelectedOptions
        size="small"
        value={values?.length > 0 ? values : value}
        onChange={(e, newValue) => handleValue(newValue)}
        clearText="Limpar"
        closeText="Fechar"
        getOptionDisabled={() => (values?.length >= limit)}
        freeSolo={!(value?.length >= limit)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
        renderInput={(params) => (
          <TextField
            {...rest}
            {...params}
            variant="outlined"
            label={label}
            name={name}
            inputRef={ref}
            onChange={(e) => getMoreItems(e.target.value.toLowerCase())}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress size={18} />}
                  {(onTyping && !isLoading) && <SearchIcon style={{ cursor: 'pointer' }} fontSize="small" onClick={() => getMoreItems(onTyping)} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={{
          onScroll: loadMoreItems,
        }}
      />
    </MVAutocompleteContainer>
  );
}

export default MultipleValuesAutocomplete;
