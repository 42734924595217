import React from 'react';

import styled, { css } from 'styled-components';

const Styled = styled.span`
  color: #424242;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  width: 100%;

  ${props => props.bold && css`
    font-weight: 600;
  `}

  ${props => props.size && css`
    font-size: ${props.size};
  `}

  ${props => props.color === 'theme' && css`
    color: ${props.theme.main_color};
  `}

  ${props => props.color && css`
    color: ${props.color};
  `}

  ${props => props.mt && css`
    margin-top: ${props.mt} !important;
  `}

  ${props => props.mb && css`
    margin-bottom: ${props.mb} !important;
  `}

  ${props => props.ml && css`
    margin-left: ${props.ml} !important;
  `}

  ${props => props.mr && css`
    margin-right: ${props.mr} !important;
  `}

  ${props => props.align && css`
    text-align: ${props.align} !important;
  `}

  ${props => props.mheight && css`
    min-height: ${props.mheight} !important;
  `}

  ${props => props.height && css`
    height: ${props.height} !important;
  `}

  ${props => props.Hiden && css`
    display:${props.Hiden} !important;
  `}

  ${props => props.weight && css`
    font-weight: ${props.weight};
  `}

  @media only screen and (max-width: 768px) {

    ${props => props.decorationMobile && css`
      text-decoration: ${props.decorationMobile}
    `}

    ${props => props.smt && css`
      margin-top: ${props.smt};
    `}

    ${props => props.smb && css`
      margin-bottom: ${props.smb} !important;
    `}

    ${props => props.sHiden && css`
      display:${props.sHiden} !important;
    `}
}
`;

export default function Description({ children, ...props }) {
  return (
    <Styled {...props}>{children}</Styled>
  );
}
