import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';

import TableHeader from './table-head';
import Pagination from '../pagination';

import { RowContainer, useStyles } from './styles';

import { reorderList } from '../../utils/reorder';

export default function TableMaterial({
  draggableList, editData, handleParams, headers, id, isDraggable, listQuery,
  maxHeight, noPagination, pagination, renderItem, setDraggable, refetch,
}) {
  const postsPerPage = useState(30);
  const styles = useStyles();

  useEffect(() => setDraggable && setDraggable(listQuery), [listQuery, setDraggable]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newOrder = {
      list: draggableList,
      start: result.source.index,
      end: result.destination.index,
      editData,
      id,
    };

    const items = await reorderList(newOrder);

    await refetch();

    setDraggable(items);
  };

  function getIdType(data) {
    if (data.id) {
      return data.id.toString();
    }

    return data.position.toString();
  }

  return (
    <>
      {isDraggable
        ? (
          <>
            <TableContainer component={Paper} className={styles.container} style={{ maxHeight }}>
              <Table className={styles.table} stickyHeader>
                <TableHeader headers={headers} />

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {draggableList && draggableList.map((item, index) => (
                          <Draggable draggableId={getIdType(item)} index={index} key={getIdType(item)}>
                            {(draggableProvided) => (
                              <RowContainer
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                {renderItem(item, index)}
                              </RowContainer>
                            )}
                          </Draggable>
                        ))}

                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </>
        )
        : (
          <TableContainer component={Paper} className={styles.container} style={{ maxHeight }}>
            <Table className={styles.table} stickyHeader>
              <TableHeader handleParams={handleParams} headers={headers} />

              <TableBody>
                {listQuery?.map(item => (
                  <RowContainer key={item.id}>
                    {renderItem(item)}
                  </RowContainer>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      {!noPagination && !isDraggable && (
        <Pagination handleParams={handleParams} pagination={pagination} postsPerPage={postsPerPage || []} />
      )}
    </>
  );
}
