import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import errHandler from '../actions/_errhandler';
import api from '../api';

interface Stores {
  domain: string;
  is_admin: boolean;
  logo_url: string;
  name: string;
  store_id: string;
}

interface GroupRules {
  role: string;
  permissions: string[];
}
interface Group {
  name: string;
  store_id: string;
  grouprules: GroupRules[];
}

interface AuthState {
  created_at: number;
  document: string;
  email: string;
  id: string;
  is_enabled: boolean;
  name: string;
  phone_ddd: string;
  phone_ddi: string;
  phone_number: string;
  stores: Stores[];
  group: Group;
  is_admin?: boolean;
  username: string;
}

interface PictureProps {
  data: string;
  type: string;
}

interface PermissionProps {
  role: 'Pedidos' | 'Clientes' | 'Orga. > Colab.' | 'Orga. > Funç.' | 'Catálogo' | 'Financeiro' | 'Marketing' | 'Conf. > Lojas' | 'Conf. Website'
  access: 'delete' | 'write' | 'read';
}

interface AuthContextData {
  isLoading: boolean;
  user: AuthState;
  fetchLogin(): Promise<void>;
  agentLogin(data: { username: string, password: string }): Promise<void>;
  verifyPerms(data: PermissionProps): boolean;
  updateAgentPicture(data: PictureProps): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<AuthState>(() => ({} as AuthState));
  const [group, setGroup] = useState<Group>({} as Group);
  const [isLoading, setLoading] = useState<boolean>(false);

  const token = localStorage.getItem('accessToken');

  const getOneGroup = useCallback(async (id: string) => {
    try {
      const response = await api.get(`/v2/agent/group/${id}`);

      return response.data.group;
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  const fetchLogin = useCallback(async () => {
    if (token) {
      try {
        setLoading(true);
        const response = await api.get('/v2/agent/check-login');

        const groupData = await getOneGroup(response.data?.agent?.group?.id);
        setGroup(groupData);
        setUser({ ...response.data.agent, group: groupData });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errHandler(err);
        localStorage.removeItem('accessToken');
      }
    }
  }, [token, getOneGroup]);

  useEffect(() => {
    async function getUser() {
      await fetchLogin();
    }

    getUser();
  }, [fetchLogin]);

  const agentLogin = useCallback(async (data: any) => {
    try {
      const response = await api.post('/v2/agent/login', { ...data, type: 'password' });
      localStorage.setItem('accessToken', `Bearer ${response.data.jwt}`);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  const updateAgentPicture = useCallback(async (data: PictureProps) => {
    await api.post('/v2/agent/media', { ...data });
    fetchLogin();
  }, [fetchLogin]);

  const verifyPerms = useCallback(({ role, access }: PermissionProps) => {
    if (user?.is_admin) return false;
    const findRole = group?.grouprules?.length && group.grouprules.find((item: any) => item.role === role);

    if (findRole && findRole.permissions?.length) {
      const findPermission = findRole.permissions.includes(access);

      if (findPermission) return false;
    } else {
      return true;
    }
    return true;
  }, [group, user]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        user,
        updateAgentPicture,
        fetchLogin,
        agentLogin,
        verifyPerms,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
