import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PaymentPrint } from './payment-printer';
import { useOrder } from '../../contexts/order';
import { useSettings } from '../../contexts/settings';

export default function PrintOrder() {
  const params = useParams();

  const { cachedOrder, fetchOneOrder } = useOrder();

  const {
    settings,
  } = useSettings();

  function handleAfterPrint() {
    window.close();
  }

  useEffect(() => {
    async function fetch() {
      try {
        if (!cachedOrder) {
          await fetchOneOrder(params.id);
        }
      } catch (error) {
        handleAfterPrint();
      }
    }

    fetch();
  }, [fetchOneOrder, params.id, cachedOrder]);

  useEffect(() => {
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  });

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    }}
    >
      {cachedOrder && settings ? (
        <PaymentPrint order={cachedOrder} settings={settings} />
      ) : (
        <div>Carregando...</div>
      )}
    </div>
  );
}
