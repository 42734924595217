import styled from 'styled-components';

const Paper = styled.div`
  background: #FFF;
  border: 1px solid #E1E1E1;
  border-radius: 0px 0px 5px 5px;
  border-top: 0;
  display: flex;
  flex-direction: column;
  height: ${props => props.height};
  padding: ${props => !props.noPadding && '24px'};
`;

export default Paper;
