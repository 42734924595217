import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import api from '../api';

import { ProductsItem } from '../@types/category';
import { Pagination } from '../@types/pagination';

interface ProductState {
  items: ProductsItem;
  pagination: Pagination;
}

interface ProductContextData {
  isLoading: boolean;
  products: ProductState;
  fetchProducts(params: Record<string, unknown>): Promise<Record<string, unknown>>;
  searchProducts(credentials: Record<string, unknown>): Promise<ProductsItem>;
  getOneProduct(id: number): Promise<{item: ProductsItem}>
  addProduct(product: ProductsItem): Promise<void>;
  updateProduct(product: ProductsItem): Promise<void>;
  deleteProduct(id: number): Promise<void>;
}

const ProductContext = createContext<ProductContextData>({} as ProductContextData);

export const ProductProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [products, setProducts] = useState<ProductState>(() => ({} as ProductState));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchProducts = useCallback(async (params: Record<string, unknown>) => {
    const defaultParams = { 'sort-by': 'title', ascending: 1 };
    const replacedParams = Object.keys(params).length > 0 ? params : defaultParams;
    setLoading(true);
    const response = await api.get('/v2/products', {
      params: {
        ...replacedParams,
      },
    });

    setProducts(response.data);
    setLoading(false);
    return params;
  }, []);

  const searchProducts = useCallback(async (params: Record<string, unknown>) => {
    const response = await api.get('/v2/products', {
      params: {
        ...params,
      },
    });

    return response.data;
  }, []);

  const getOneProduct = useCallback(async (id: number) => {
    setLoading(true);
    const response = await api.get(`/v2/store/product/${id}/edit`);
    setLoading(false);
    return { item: response.data };
  }, []);

  const addProduct = useCallback(async (product: ProductsItem) => {
    const response = await api.post('/v2/store/product', product);
    return response.data;
  }, []);

  const updateProduct = useCallback(async (product: ProductsItem) => {
    await api.put(`/v2/store/product/${product.id}`, product);
    await fetchProducts({});
  }, [fetchProducts]);

  const deleteProduct = useCallback(async (id: any) => {
    await api.delete(`/v2/store/product/${id}`);
    await fetchProducts({});
  }, [fetchProducts]);

  useEffect(() => {
    async function getCategoriesData() {
      await fetchProducts({
        'sort-by': 'title',
        ascending: 1,
      });
    }

    getCategoriesData();
  }, [fetchProducts]);

  return (
    <ProductContext.Provider
      value={{
        fetchProducts, searchProducts, getOneProduct, addProduct, updateProduct, deleteProduct, isLoading, products,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct(): ProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within an ProductProvider');
  }
  return context;
}
