/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import api from '../api';

// import { Pagination } from '../@types/pagination';
import errHandler from '../actions/_errhandler';

interface GroupRules {
  role: string;
  permissions: string[];
}

interface Departments {
  created_at: number;
  id: number;
  is_visible: boolean;
  name: string;
  store_id: string;
}

interface Group {
  name: string;
  store_id: string;
  grouprules: GroupRules[];
}

interface GroupItem {
  colaboradores: string;
  department: {
    id: number;
    name: string;
  }
  id: number;
  name: string;
  store_id: string;
}

interface GroupContextData {
  isLoading: boolean;
  groups: GroupItem[];
  departments: Departments[];
  fetchGroups(params: object): Promise<void>;
  getOneGroup(id: string): Promise<Group>;
  getDepartments(): Promise<void>;
  searchGroup(group_name: string): Promise<Group>;
  addGroup(group: Group): Promise<void>;
  updateGroup(group_id: string, group: Group): Promise<void>;
  deleteGroup(group_id: string): Promise<void>;
}

const GroupContext = createContext<GroupContextData>({} as GroupContextData);

export const GroupsProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [groups, setGroups] = useState<GroupItem[]>(() => ({} as GroupItem[]));
  const [departments, setDepartments] = useState<Departments[]>(() => ({} as Departments[]));
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchGroups = useCallback(async (params: any) => {
    try {
      setLoading(true);
      const response = await api.get('/v2/agent/group/search/name', { params: { ...params } });
      setGroups(response.data.Data.groups);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  const getOneGroup = useCallback(async (id: string) => {
    try {
      const response = await api.get(`/v2/agent/group/${id}`);

      return response.data.group;
    } catch (err) {
      setLoading(false);
      errHandler(err);
    }
  }, []);

  const getDepartments = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/v2/department/list');

      setDepartments(response.data.departments);
      setLoading(false);
    } catch (err) {
      errHandler(err);
    }
  }, []);

  const searchGroup = useCallback(async (group_name: string) => {
    try {
      const response = await api.get(`/v2/agent/group/search/name?${group_name}`);
      setGroups(response.data.Data.groups);
      return response.data;
    } catch (err) {
      errHandler();
    }
  }, []);

  useEffect(() => {
    async function getGroups() {
      await fetchGroups({});
      await getDepartments();
    }
    getGroups();
  }, [fetchGroups, getDepartments]);

  const addGroup = useCallback(async (group: any) => {
    try {
      await api.post('/v2/agent/group', group);
      await fetchGroups({});
    } catch (err) {
      errHandler(err);
    }
  }, [fetchGroups]);

  const updateGroup = useCallback(async (group_id: string, group: any) => {
    try {
      await api.put(`/v2/agent/group/${group_id}`, {
        replaceall: true,
        replaceperms: true,
        ...group,
      });
      fetchGroups({});
    } catch (err) {
      errHandler(err);
    }
  }, [fetchGroups]);

  const deleteGroup = useCallback(async (group_id: string) => {
    try {
      await api.delete(`/v2/agent/group/${group_id}`);
      fetchGroups({});
    } catch (err) {
      errHandler(err);
    }
  }, [fetchGroups]);

  return (
    <GroupContext.Provider
      value={{
        groups, departments, getOneGroup, getDepartments, fetchGroups, searchGroup, addGroup, isLoading, updateGroup, deleteGroup,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export function useGroups(): GroupContextData {
  const context = useContext(GroupContext);

  if (!context) {
    throw new Error('useGroups must be used within an GroupProvider');
  }
  return context;
}
