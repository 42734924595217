import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';

import { useStyles } from './styles';

const TextFieldCustom = forwardRef(({
  name, include, theme, value, onLoadFieldValue, offerDiscount, discountPrice, isNumber, ...rest
}, ref) => {
  const styles = useStyles();
  const inputRef = useRef(null);
  const [state, setState] = useState();
  const firstLoad = useRef(true);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useImperativeHandle(ref, () => inputRef.current);

  function getShrink(data) {
    if (data) {
      return {
        shrink: true,
      };
    }
    return null;
  }

  function handleChange(e) {
    setState(e.target.value);
  }

  const getValue = useCallback(() => value ?? state, [state, value]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (firstLoad.current && onLoadFieldValue) {
      firstLoad.current = false;
      onLoadFieldValue(defaultValue);
    } else if (onLoadFieldValue) {
      onLoadFieldValue(getValue());
    }
  }, [defaultValue, onLoadFieldValue, getValue]);

  return (
    <TextField
      variant="outlined"
      className={styles.textField}
      value={discountPrice ? (!offerDiscount ? 0 : getValue()) : getValue()}
      onChange={(e) => handleChange(e)}
      InputLabelProps={getShrink(defaultValue)}
      inputRef={inputRef}
      defaultValue={defaultValue}
      error={!!error}
      type={isNumber ? 'number' : ''}
      {...rest}
    />
  );
}
)

TextFieldCustom.displayName = 'TextFieldCustom';

export default TextFieldCustom;