import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import TitleCard from '../title/title-card';
import SecondaryVariantsList from './secondary-variants-list';

import { AddButton, StyledPaper, useStyles } from '../styles';

export default function SecondayVariants({
  variants, setVariants, formRef,
}) {
  const styles = useStyles();

  const addVariants = () => {
    setVariants([...variants, {
      name: '',
      options: '',
      visible: true,
    }]);
  };

  return (
    <>
      <TitleCard>Variantes Secundárias</TitleCard>

      <StyledPaper style={{ marginTop: '-20px' }}>
        <Grid item xs={12} sm={12}>
          <SecondaryVariantsList formRef={formRef} variants={variants} setVariants={setVariants} addInput={addVariants} />
        </Grid>

        <Grid container direction="row" alignItems="center" className={styles.gridPaperBottom}>
          <Grid
            item
            style={{ cursor: 'pointer' }}
            onClick={addVariants}
          >
            <IconButton type="button" className={styles.iconButton}>
              <AddCircleOutlineIcon fontSize="small" color="primary" />
            </IconButton>

            <AddButton type="button">Adicionar Variante</AddButton>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
