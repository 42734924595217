import React from 'react';

import Grid from '@material-ui/core/Grid';

import SubtitleCard from '../../title/subtitle-card';
import TitleCard from '../../title/title-card';

import AddImage from './add-image';
import AddBanners from './add-banners';
// import AddVideo from './add-video';

import { StyledPaper, Container, useStyles } from '../../styles';

export default function Gallery({
  title,
  formRef,
  refetch,
  id,
  productImages,
  setProductImages,
  banner,
  setBannerImage,
}) {
  const styles = useStyles();

  return (
    <>
      <TitleCard>Galeria</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard>Fotos do Produto</SubtitleCard>
        </Grid>

        <span className={styles.span} style={{ color: '#424242' }}>
          Arraste para reordenar. A primeira imagem será a foto principal do produto.
        </span>

        <AddImage
          title={title}
          imageList={productImages}
          setProductImages={setProductImages}
          formRef={formRef}
          refetch={refetch}
          productId={id}
        />

        <Container>
          <SubtitleCard>Banners</SubtitleCard>
          <AddBanners formRef={formRef} productId={id} refetch={refetch} initialBanner={banner} setBannerImage={setBannerImage} />
        </Container>

        {/*
        <Container>
          <SubtitleCard>Vídeo</SubtitleCard>
          <AddVideo />
        </Container> */}
      </StyledPaper>
    </>
  );
}
