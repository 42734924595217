import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import {
  // RadioGroup, FormControl, Radio, InputAdornment,
  InputAdornment,
} from '@material-ui/core';

// import RadioButton from '../form/form-radio-button';
import Checkbox from '../form/checkbox';
import SubtitleCard from '../title/subtitle-card';
import TextField from '../form/text-field';
import TextFieldMulti from '../form/text-fieldmulti';
import TitleCard from '../title/title-card';

import { StyledPaper, useStyles } from '../styles';
import { slugify } from '../../utils/slugify';
import Text from '../text/text';
import FormSwitch from '../form/switch';

export default function BasicInfo({
  title,
  setTitle,
  subtitle,
  setSubtitle,
  resume,
  setResume,
  product,
}) {
  const styles = useStyles();
  const [slug, setSlug] = useState(product.slug);
  const [fixLink, setFixLink] = useState(!!product?.is_fixed_slug);

  useEffect(() => {
    if (fixLink) return;
    setSlug(slugify(title, subtitle));
  }, [title, subtitle, fixLink]);

  return (
    <>
      <TitleCard>Dados do Produto</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <SubtitleCard>Dados Básicos</SubtitleCard>

          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Título"
                placeholder="Título"
                inputProps={{ maxLength: 25 }}
                onChange={(e) => setTitle(e.target.value)}
                helperText={`${title ? title.length : 0}/25 Caracteres`}
                value={title}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: '42px' }}>
              <TextField
                name="subtitle"
                label="Subtítulo"
                placeholder="Subtítulo"
                inputProps={{ maxLength: 25 }}
                onChange={(e) => setSubtitle(e.target.value)}
                helperText={`${subtitle ? subtitle.length : 0}/25 Caracteres`}
                value={subtitle}
              />
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justify="center"
              direction="row"
              spacing={3}
              xs={12}
              style={{ marginTop: '42px' }}
            >

              <Grid item xs={9}>
                <TextField
                  name="slug"
                  label="Link do produto"
                  placeholder="Link do produto"
                  disabled={!!fixLink}
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  helperText={`${slug.length}/50 Caracteres`}
                  InputProps={{
                    startAdornment: <InputAdornment><Text color="#A3A3A3" mt="16px">meudominio/produto/</Text></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ marginTop: 15 }}>
                <FormSwitch name="is_fixed_slug" label="Fixar link" onClick={() => setFixLink(!fixLink)} />
              </Grid>

            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '56px' }}>
            <SubtitleCard>Resumo</SubtitleCard>

            <TextFieldMulti
              name="summary"
              rows={2}
              textStyle={2}
              inputProps={{ maxLength: 200 }}
              onChange={(e) => setResume(e.target.value)}
              helperText={`${resume ? resume.length : 0}/200 Caracteres`}
            />
          </Grid>

          <Grid container direction="row" style={{ marginTop: '36px' }}>

            {/* TODO waiting resolution */}
            {/* <Grid item xs={3}>
              <SubtitleCard>Forma de Fabricação:</SubtitleCard>

              <Grid item xs={12}>
                <FormControl required className={styles.formControl}>
                  <RadioGroup value={isCompounded}>
                    <RadioButton
                      value={false}
                      control={<Radio color="primary" />}
                      label="Manipulado"
                      disabled
                    />

                    <RadioButton
                      value
                      control={<Radio color="primary" />}
                      label="Industrializado"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid> */}

            {/* <Grid item xs={3}>
              <SubtitleCard tip="Se o produto possui variações de cor, tamanho, sabor, entre outras.">
                Tipo de Produto:
              </SubtitleCard>

              <Grid item xs={12}>
                <FormControl required className={styles.formControl}>
                  <RadioGroup value={hasVariant}>
                    <RadioButton
                      value={false}
                      control={<Radio color="primary" />}
                      label="Único (sem variações)"
                    />

                    <RadioButton
                      value
                      control={<Radio color="primary" />}
                      label="Com Variações"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid> */}

            <Grid item xs={3}>
              <SubtitleCard>
                Tipo de Prescrição:
              </SubtitleCard>

              <Grid item xs={12} direction="column">
                <div>
                  <Checkbox
                    defaultChecked={product.medical_prescription_only}
                    name="medical_prescription_only"
                    label="Prescrição médica"
                  />
                </div>

                <div>
                  <Checkbox
                    defaultChecked={product.retains_prescription}
                    name="retains_prescription"
                    label="Retenção da original"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <SubtitleCard>
                Ferramenta de prescrição
              </SubtitleCard>

              <Grid item xs={12} direction="column">
                <div>
                  <Checkbox
                    defaultChecked={product.request_cid}
                    name="request_cid"
                    label="Solicitar CID ao prescritor"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}
