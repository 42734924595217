import React, { useMemo } from 'react';

import { Grid, MenuItem, Tooltip } from '@material-ui/core';
import {
  List,
} from '@material-ui/icons';

import Paper from '../../paper';
import Subtitle from '../../text/subtitle';
import Text from '../../text/text';

import placeHolder from '../../../assets/placeholder.svg';
import Select from '../../form/select';

export default function ProductOrder({ product }) {
  const price = useMemo(() => Number(product?.price_net / product?.qty).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), [product]);
  const total = useMemo(() => Number(product?.price_net).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), [product]);

  const renderOption = ({ item }) => <MenuItem value={item}>{item}</MenuItem>;
  const renderVolume = ({ item }) => <MenuItem value={item}>{`${item} ${product.volume_unit}`}</MenuItem>;
  return (
    <Paper style={{ minHeight: '170px' }}>
      <Grid container direction="column">
        <Grid item container>
          <Grid
            item md={2} style={{
              position: 'relative',
            }}
          >
            <img src={product.thumbnail_url || placeHolder} width="120px" height="120px" alt="ProductImg" />

            {product?.inquiry_id && product.ingredients?.items && (
              <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
              }}
              >
                <Tooltip title={(
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                  >
                    {product.ingredients.items.map((ingredient) => (
                      <Text color="white" key={ingredient}>{ingredient}</Text>
                    ))}
                  </div>
                  )}
                >
                  <List />
                </Tooltip>
              </div>
            )}
          </Grid>

          <Grid item md={10}>
            <Grid container spacing={4} justify="flex-start">
              <Grid container item md={6}>
                <Grid item md={12} style={{ marginBottom: 12 }}>
                  <Text bold color="#A3A3A3">Opções Selecionadas:</Text>
                </Grid>

                <Grid item container direction="column" spacing={1} md={12}>
                  {product?.variants?.length && product.variants.map((variant) => (
                    <Grid item>
                      <Select
                        disabled
                        initialValue={variant.option}
                        options={[variant.option]}
                        label={variant.name}
                        name={variant.name}
                      >
                        {renderOption}
                      </Select>
                    </Grid>
                  ))}

                  <Grid item>
                    <Select
                      disabled
                      initialValue={product.volume}
                      options={[product.volume]}
                      label="Volume"
                      name="volume"
                    >
                      {renderVolume}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={2}>
                <Grid item style={{ marginBottom: 12 }}>
                  <Text bold color="#A3A3A3">Unitário:</Text>
                </Grid>

                <Grid item>
                  <div style={{ height: 40, padding: '7px 0 0 0', width: 65 }}>
                    {product?.price_net && <Text>{price}</Text>}
                  </div>
                </Grid>
              </Grid>

              <Grid item md={2}>
                <Grid item style={{ marginBottom: 12 }}>
                  <Text bold color="#A3A3A3">Qtd:</Text>
                </Grid>

                <Grid item>
                  <div style={{ border: '2px solid #E1E1E1', borderRadius: 10, height: 40, padding: '5px 0 0 16px', width: 65 }}>
                    <Text color="#A3A3A3">{product.qty}</Text>
                  </div>
                </Grid>
              </Grid>

              <Grid item md={2}>
                <Grid item style={{ marginBottom: 12 }}>
                  <Text bold color="#A3A3A3">Total:</Text>
                </Grid>

                <Grid item>
                  <div style={{ height: 40, padding: '7px 0 0 0', width: 100 }}>
                    {product?.price_net && <Subtitle>{total}</Subtitle>}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
