import React, { useState } from 'react';

// import AssignmentIcon from '@material-ui/icons/Assignment';
// import ChatIcon from '@material-ui/icons/Chat';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import PhoneIcon from '@material-ui/icons/Phone';
// import SearchIcon from '@material-ui/icons/Search';
// import LoopIcon from '@material-ui/icons/Loop';

// import { StyledTooltip } from '../../../tooltip';
import logo from '../../../../assets/logo/logo.svg';

import { AppBarContainer, Symbol, ToolbarContainer, useStyles } from './styles';
// import { useStyles as useGlobalStyles } from '../../styles';

import store from '../../../../store';
import BuildModal from './build-modal';

export default function Bar({ drawerWidth }) {
  const styles = useStyles();

  const [openModal, setOpenModal] = useState(false);
  // const global = useGlobalStyles();
  const { agent } = store.get();

  return (
    <>
      <AppBarContainer style={{ width: `calc(100% - ${drawerWidth}px)` }}>
        <ToolbarContainer>
          <a href="/" rel="noopener noreferrer" className={styles.logoContainer}>
            <img src={logo} alt="Pedido Pago" />
          </a>

          <Symbol header>・</Symbol>
          <p>{agent?.store_name}</p>

          <div className={styles.iconContainer}>
            {/* <StyledTooltip title="Atualizar todo e-commerce" placement="top" className={styles.tableButton}>
              <LoopIcon style={{ cursor: 'pointer' }} onClick={() => setOpenModal(!openModal)} className={global.icon} color="primary" fontSize="small" />
            </StyledTooltip> */}
            {/* <SearchIcon className={global.icon} color="primary" fontSize="small" />
          <PhoneIcon className={global.icon} color="primary" fontSize="small" />
          <NotificationsIcon className={global.icon} color="primary" fontSize="small" />
          <AssignmentIcon className={global.icon} color="primary" fontSize="small" />
          <ChatIcon className={global.icon} color="primary" fontSize="small" /> */}
          </div>
        </ToolbarContainer>
      </AppBarContainer>
      <BuildModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
