import React from 'react';

import { useStyles } from '../styles';

import Logo from '../../../assets/logo/logo_black.svg';

export default function HeaderLogin() {
  const styles = useStyles();

  return (
    <div className={styles.headerLogin}>
      <img src={Logo} style={{ height: '24px' }} alt="logo" />
    </div>
  );
}
