import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { StyledTooltip } from '../../tooltip';
import { useStyles } from '../../styles';
import { useAuth } from '../../../contexts/auth';

export default function SubCategoriesData({ subcategories, deleteSubcategory, editSubcategory, setVisibility, index }) {
  const styles = useStyles();
  const { verifyPerms } = useAuth();

  return (
    <>
      <TableCell>
        <Grid container alignItems="center" alignContent="center">
          <DragIndicatorIcon color="disabled" fontSize="small" style={{ marginRight: '12px' }} />
          {`# ${subcategories.index || index + 1}`}
        </Grid>
      </TableCell>

      <TableCell>
        <Grid container alignItems="center">
          {subcategories.name}
        </Grid>
      </TableCell>

      <TableCell>{subcategories.products ? subcategories.products.length : 0}</TableCell>

      <TableCell style={{ width: '120px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-9px' }}>
            <StyledTooltip title="Editar" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Catálogo', access: 'write' })}
                className={styles.actionButton}
                onClick={() => editSubcategory(subcategories.id, index + 1)}
              >
                <EditIcon color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip
              disabled={verifyPerms({ role: 'Catálogo', access: 'write' })}
              title={subcategories.visible
                ? 'Ocultar'
                : 'Alternar visibilidade'}
              placement="top"
              className={styles.tableButton}
            >
              <IconButton className={styles.actionButton} onClick={() => setVisibility(subcategories.id, !subcategories.visible)}>
                {subcategories.visible
                  ? <VisibilityOffOutlinedIcon color="disabled" fontSize="small" />
                  : <VisibilityOffOutlinedIcon color="primary" fontSize="small" />}
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-4px', marginRight: '-8px' }}>
            <StyledTooltip
              disabled={verifyPerms({ role: 'Catálogo', access: 'delete' })}
              title="Remover"
              placement="top"
              className={styles.tableButton}
            >
              <IconButton className={styles.actionButton} onClick={() => deleteSubcategory(subcategories.index, subcategories.id)}>
                <CloseIcon color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
}
