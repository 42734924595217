export const tabs = [
  {
    name: 'tab1',
    label: 'Visão Geral',
  },
  {
    name: 'tab2',
    label: 'Transações',
  },
  {
    name: 'tab3',
    label: 'Extrato de Recebíveis',
  },
  {
    name: 'tab4',
    label: 'Configurações',
  },
];

export const transactionTableHeader = [
  {
    name: 'status',
    label: 'Status',
    isFilter: false,
  },
  {
    name: 'client_name',
    label: 'Nome do Cliente',
    isFilter: false,
  },
  {
    name: 'tID',
    label: 'tID',
    isFilter: false,
  },
  {
    name: 'date',
    label: 'Data',
    isFilter: false,
  },
  {
    name: 'payment_method',
    label: 'Forma de pagamento',
    isFilter: false,
  },
  {
    name: 'value',
    label: 'Valor',
    isFilter: false,
  },
  {
    name: 'order',
    label: 'Pedido',
    isFilter: false,
  },
  {
    name: 'receiver',
    label: 'Recebedor',
    isFilter: false,
  },
  {
    name: 'actions',
    label: 'Ações',
    isFilter: false,
  },
];

export const tableExtractHeader = [
  {
    name: 'date',
    label: 'Data',
  },
  {
    name: 'tid_withdraw',
    label: 'TID do Saque',
  },
  {
    name: 'income',
    label: 'Entrada',
  },
  {
    name: 'outcome',
    label: 'Saída',
  },
  {
    name: 'receiver',
    label: 'Recebedor',
  },
];

export const tableCollapseExtractHeader = [
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'order',
    label: 'Pedido',
  },
  {
    name: 'tid',
    label: 'TID',
  },
  {
    name: 'installment',
    label: 'Parcelas',
  },
  {
    name: 'antecipation',
    label: 'Antecipação',
  },
  {
    name: 'receivable',
    label: 'Recebível',
  },
  {
    name: 'tx_acquisition',
    label: 'Tx Adquirência',
  },
  {
    name: 'tx_antecipation',
    label: 'Tx Antecipação',
  },
  {
    name: 'tx_antiFraud',
    label: 'Tx Antifraude',
  },
  {
    name: 'income',
    label: 'Entrada',
  },
];

export const tableCollapseExtractHeader2 = [
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'tid_withdraw_2',
    label: 'TID do saque',
  },
  {
    name: 'transfer_value',
    label: 'Valor A Ser Transferido',
  },
  {
    name: 'transfer_charge',
    label: 'Custo da transferência',
  },
  {
    name: 'outcome',
    label: 'Saída',
  },
];
