import React from 'react';

import styled, { css } from 'styled-components';

const Styled = styled.svg`
  width:100%;
  height:100%;
  
  ${props => props.type === 'default' && css`
    width: 80px;
    color: ${props.theme.main_color};

    path {
      fill: ${props.theme.main_color};
    }
  `}

  ${props => props.color && css`
    color: ${props.color} !important;
    path {
      fill: ${props.color};
    }
  `}
    
  ${props => props.border && css`
    border: ${props.border.size} solid ${props.border.color} !important;
  `}

  ${props => props.width && css`
    width: ${props.width} !important;
  `}

  ${props => props.height && css`
    height:: ${props.height} !important;
  `}

  ${props => props.mb && css`
    margin-bottom: ${props.mb} !important;
  `}

  ${props => props.ml && css`
    margin-left: ${props.ml};
  `}

  ${props => props.mlMobile && css`
    @media only screen and (max-width: 768px) {
      margin-left: ${props.mlMobile};
    }
  `}

  ${props => props.mr && css`
    margin-right: ${props.mr};
  `}

  ${props => props.mt && css`
    margin-top: ${props.mt};
  `}

  ${props => props.mb && css`
    margin-bottom: ${props.mb} !important;
  `}

  ${props => props.width && css`
    width: ${props.width};
  `}

  ${props => props.hiden && css`
    display: ${props.hiden};
  `}

  ${props => props.height && css`
    height: ${props.height};
  `}

  ${props => props.justfy && css`
    display:flex;
    justify-content: ${props.justfy};
  `}

  @media only screen and (max-width: 768px) {
    ${props => props.smb && css`
      margin-bottom: ${props.smb} !important;
    `}


    ${props => props.swidth && css`
      width: ${props.swidth};
    `}

    ${props => props.hidenMobile && css`
      display: ${props.hidenMobile};
    `}

    ${props => props.sml && css`
      margin-left: ${props.sml} !important;
    `}

    ${props => props.spdl && css`
      padding-left: ${props.spdl} !important;
    `}

    ${props => props.smr && css`
      margin-right: ${props.smr} !important;
    `}

    ${props => props.smt && css`
      margin-top: ${props.smt} !important;
    `}

    ${props => props.sHiden && css`
      display:${props.sHiden} !important;
    `}
  }
`;

export default function Icon({ children, ...props }) {
  return (
    <Styled {...props}>{children}</Styled>
  );
}
