import React from 'react';

import { Grid, InputAdornment } from '@material-ui/core';
import { Scope } from '@unform/core';

import TitleCard from '../title/title-card';
import PriceField from '../form/price-field';

import TimeDatePicker from '../form/date-time-picker';

import { StyledPaper, useStyles } from '../styles';

export default function LightningPromotion({ product }) {
  const styles = useStyles();
  return (
    <>
      <TitleCard>Promoção Relâmpago</TitleCard>

      <Scope path="promotion">
        <StyledPaper style={{ paddingTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PriceField
                name="price"
                label="Preço Promocional"
                separator="."
                InputProps={{
                  startAdornment: <InputAdornment className={styles.adornmentStart}><span>R$</span></InputAdornment>,
                }}
              />
            </Grid>

          </Grid>

          <Grid container item direction="row" spacing={3} style={{ marginBottom: 16 }}>
            <Grid item xs={12}>
              <TimeDatePicker name="from" date={product.promotion?.from} label="Data de Início" />
            </Grid>

            <Grid item xs={12}>
              <TimeDatePicker name="to" date={product.promotion?.to} label="Data de Término" />
            </Grid>
          </Grid>

        </StyledPaper>
      </Scope>

    </>
  );
}
