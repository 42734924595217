import React, { useRef, useCallback } from 'react';

// Libs
import { Button, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';

// Icons
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

// Components
import Table from '../components/CollapsibleTable';
import Select from '../../form/select';
import DatePicker from '../../form/date-picker';
import Card from '../components/Card';

import { tableExtractHeader, tableCollapseExtractHeader, tableCollapseExtractHeader2 } from './schema';

// Styles
import {
  SecondHeaderContainer, Divider, FilterFormContainer, CardContainer, TableHeaderContainer, TitleContainer, PageTitleBold,
} from '../styles';

function Recivables() {
  /** CONSTS */
  // const history = useHistory();
  const { search } = useLocation();
  const formRef = useRef();

  const tableCollapseContent = [
    {
      status: 'Crédito',
      order: '024541645',
      tid: '89350382',
      installment: '1 de 3',
      antecipation: '-',
      receivable: 'R$500,00',
      txAcquisition: '-R$5,00',
      txAntecipation: 'R$5,00',
      txAntiFraud: '-R$5,00',
      income: 'R$485,00',
    },
    {
      status: 'Crédito',
      order: '024541645',
      tid: '89350382',
      installment: '1 de 3',
      antecipation: '-',
      receivable: 'R$500,00',
      txAcquisition: '-R$5,00',
      txAntecipation: 'R$5,00',
      txAntiFraud: '-R$5,00',
      income: 'R$485,00',
    },
  ];

  const tableContent = [
    {
      date: new Date(),
      tid_withdraw: '89350361',
      income: 'R$ 3969.20',
      outcome: '- R$ 3969,20',
      receiver: 'Farmácia Buenos Ayres LTDA',
      collapseData: tableCollapseContent,
    },
    {
      date: new Date(),
      tid_withdraw: '89350361',
      income: 'R$ 3969.20',
      outcome: '- R$ 3969,20',
      receiver: 'Farmácia Buenos Ayres LTDA',
      collapseData: tableCollapseContent,
    },
  ];

  /** STATES */
  // const [parseSearch] = useState('');

  /** CALLBACKS */
  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...params, ascending: 1 };
    } else {
      newParams = { ...allParams, ...params, ascending: 0 };
    }

    if (param === 'q') {
      newParams.p = 1;
      const isEqual = allParams?.q === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    // return history.push({
    //   pathname: '/products',
    //   search: `?${new URLSearchParams(newParams)}`,
    // });
  }, [search]);

  return (
    <>
      <SecondHeaderContainer>
        <Form ref={formRef} onSubmit={(formData) => handleParams('q', formData.search)}>
          <FilterFormContainer>
            <Select
              name="receiver"
              label="Recebedor"
              options={['Farmacia Buenos Ayres', 'Farmacia A', 'Farmacia B']}
              value="Farmacia Buenos Ayres"
              InputLabelProps={{ shrink: true }}
              style={{ width: '100%', marginRight: 20, maxWidth: 295 }}
            >
              {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
            </Select>
            <div>
              <Select
                name="createdAt"
                label="Data de criação"
                options={['Entre:', 'Hoje', 'Ontem']}
                value="Entre:"
                InputLabelProps={{ shrink: true }}
                style={{ width: 167, marginRight: 20 }}
              >
                {({ item }) => <MenuItem value={item} key={item}>{item}</MenuItem>}
              </Select>
              <DatePicker
                name="startDate"
                label="Data Início"
                style={{ width: 173, marginRight: 20 }}
              />
              <DatePicker
                name="endDate"
                label="Data Término"
                style={{ width: 173 }}
              />
            </div>
          </FilterFormContainer>
        </Form>
      </SecondHeaderContainer>

      <Divider />

      <CardContainer>
        <Card
          type="action"
          actionType="balance"
          value="R$20.202.303,00"
        />
        <Card
          type="action"
          actionType="waitingBalance"
          value="R$20.202.303,00"
        />
        <Card
          type="action"
          actionType="history"
          value="R$20.202.303,00"
        />
      </CardContainer>

      <TableHeaderContainer>
        <TitleContainer>
          <PageTitleBold>Lista de Recebíveis (5.663 resultados)</PageTitleBold>
        </TitleContainer>
        <Button
          variant="contained"
          endIcon={<VerticalAlignBottomIcon style={{ color: '#A3A3A3' }} />}
          style={{ height: 36, paddingInline: 27 }}
        >
          download da planilha
        </Button>
      </TableHeaderContainer>
      <Table
        tableHeader={tableExtractHeader}
        collapseHeader={tableCollapseExtractHeader}
        collapseHeader2={tableCollapseExtractHeader2}
        tableContent={tableContent}
      />
    </>
  );
}

export default Recivables;
