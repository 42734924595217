import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { Form } from '@unform/web';
import ContentWrapper from '../content-wrapper';
import { PageTitle, useStyles } from '../styles';

import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';
import Logistic from './components/logistic';
import Region from './components/logistic/region';
import Spinner from '../spinner';

import { useSettings } from '../../contexts/settings';
import { useBranch } from '../../contexts/branch';
// import store from '../../store';

export default function Branch() {
  const { state } = useLocation();
  const { settings } = useSettings();
  const { getOneBranch, getCorreios, branchs } = useBranch();
  const { id } = useParams();

  const formRef = useRef();

  const [initialData, setInitialData] = useState(null);
  const [filialBranch, setFilialBranch] = useState(null);
  const [correiosState, setCorreios] = useState(null);

  const styles = useStyles();

  useEffect(() => {
    async function getBranch() {
      const correios = await getCorreios(id);
      const response = await getOneBranch(id);
      setFilialBranch({ ...response.item, mainBranch: branchs?.id });

      const overlay_price = correios.overlay_price / 100;
      setCorreios({
        ...correios,
        enabled: !!correios.enabled,
        password: !correios.password ? '' : correios.password,
        contract_code: !correios.contract_code ? '' : correios.contract_code,
      });

      const data = {
        pharmacy: response.item.pharmacy,
        correios: { ...correios, overlay_price: overlay_price.toFixed(2) },
        attendance: {
          work_time: response?.item?.work_time,
          email: response?.item?.email,
          ddi: response?.item?.ddi,
          ddd: response?.item?.ddd,
          phone: response?.item?.phone,
          whats_ddi: response?.item?.whats_ddi,
          whats_ddd: response?.item?.whats_ddd,
          whatsapp: response?.item?.whatsapp,
        },
      };

      setInitialData(data);
    }

    if (!!settings && Object.keys(settings).length > 0) {
      getBranch();
    }
  }, [settings, state, getOneBranch, id, getCorreios, branchs]);

  const handleBack = useCallback(() => {
    const newAdminUrl = process.env.REACT_APP_ADMIN_V2_URL;
    const token = window.localStorage.getItem('token');

    window.location.href = `${newAdminUrl}/auth?token=${token}&redirect=/stores/settings/${id}`;
  }, [id]);

  return (
    <>
      <Breadcrumb
        title="Gerenciar Loja"
        text="Configurações"
        breadcrumb="Lojas"
        current={filialBranch?.fantasy_name ? filialBranch.fantasy_name : 'Criar Nova Loja'}
      />

      {initialData && filialBranch ? (
        <ContentWrapper align="top">
          <Form ref={formRef} initialData={initialData}>

            <Grid container className={styles.gridRowForm} alignItems="baseline" style={{ paddingRight: '0', paddingBottom: '90px' }}>
              <Grid container className={styles.gridRow} style={{ paddingRight: '0' }}>
                <div className={styles.bottomLine} />

                <Grid item xs={3} className={styles.gridTitle} style={{ paddingLeft: 24 }}>
                  <IconButton onClick={handleBack} type="button" style={{ color: '#A3A3A3', marginLeft: 12, marginRight: -16 }}>
                    <ArrowBack fontSize="small" />
                  </IconButton>

                  <PageTitle>{filialBranch.fantasy_name || 'Nova Loja'}</PageTitle>
                </Grid>

              </Grid>

              <Grid item xs={12} sm={12} className={styles.gridRowFormPadding}>
                <Logistic address={filialBranch.address} correios={correiosState} formRef={formRef} />
                <Region />
              </Grid>
            </Grid>
          </Form>
        </ContentWrapper>
      ) : (
        <Spinner />
      )}

    </>
  );
}
