import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import TextFieldMulti from '../../form/text-fieldmulti';
import TitleCard from '../../title/title-card';

import { StyledPaper, useStyles } from '../../styles';

export default function Keywords({ editCategory }) {
  const styles = useStyles();
  const [keywords, setKeywords] = useState();

  useEffect(() => editCategory
    && setKeywords(editCategory.keywords && editCategory.keywords.join(', ')), [editCategory]);

  return (
    <>
      <TitleCard>Palavras-chave</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <span className={styles.span}>
            Termos utilizados para buscar esta categoria:
          </span>
        </Grid>

        <TextFieldMulti
          name="keywords_concat"
          rows={9}
          placeholder="Exemplo: energia, foco, disposição"
          textStyle={2}
          value={editCategory && keywords}
          onChange={(e) => setKeywords(e.target.value)}
        />
      </StyledPaper>
    </>
  );
}
