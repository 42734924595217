import React, { useEffect, useRef, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { useField } from '@unform/core';

import { useStyles } from './styles';

function Select({
  name,
  label,
  options,
  children,
  initialValue,
  ...rest
}) {
  const styles = useStyles();
  const ref = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [value, setValue] = useState(defaultValue || initialValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [value, fieldName, registerField]);

  function handleValue(e) {
    setValue(e.target.value);
  }

  return (
    <TextField
      variant="outlined"
      className={styles.textField}
      select
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      name={name}
      inputRef={ref}
      error={error}
      {...rest}
      InputLabelProps={{
        shrink: true,
      }}
      SelectProps={{
        displayEmpty: true,
      }}
    >
      <MenuItem disabled>
        Selecionar
      </MenuItem>
      {options && options.map((item, index) => {
        if (typeof children === 'function') {
          return children({ item, handleValue, index, ...rest });
        }

        return null;
      })}
    </TextField>
  );
}

export default Select;
