import { makeStyles } from '@material-ui/styles';

const useModalStyles = makeStyles((theme) => ({
  modal: {
    borderRadius: '5px',
    border: 'none',
    overflow: 'hidden',
    width: '680px',

    '& .MuiButton-root': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      letterSpacing: '0.03em',
      lineHeight: '20px',
      paddingLeft: '36px',
      paddingRight: '36px',
      minWidth: '150px',
    },

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: '24px',
    },

    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      marginBottom: '36px',
      marginTop: '32px',
      padding: '0',
    },

    '& .MuiDialogContent-root': {
      padding: '0 24px',
    },
  },

  modal2: {
    borderRadius: '5px',
    border: 'none',
    overflow: 'hidden',
    width: '448px',

    '& .MuiButton-root': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      letterSpacing: '0.03em',
      lineHeight: '20px',
      paddingLeft: '36px',
      paddingRight: '36px',
      minWidth: '150px',
    },

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: '24px',
    },

    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      marginBottom: '36px',
      marginTop: '32px',
      padding: '0',
    },

    '& .MuiDialogContent-root': {
      padding: '0 24px',
    },
  },

  modal3: {
    borderRadius: '5px',
    border: 'none',
    width: '526px',

    '& .MuiButton-root': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      letterSpacing: '0.03em',
      lineHeight: '20px',
      paddingLeft: '36px',
      paddingRight: '36px',
      minWidth: '150px',
    },

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: '24px',
    },

    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      marginBottom: '24px',
      marginTop: '32px',
      padding: '0',
    },

    '& .MuiDialogContent-root': {
      padding: '0 24px',
    },
  },

  titleContainer: {
    alignItems: 'center',
    borderBottom: '1px solid #E1E1E1',
    height: '41px',
    paddingTop: theme.spacing(1),
    width: '100%',

    '& h2': {
      color: '#A3A3A3',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '22px',
      letterSpacing: '0.03em',
    },

    '& button': {
      marginRight: -8,
    },
  },

  modalActions: {
    '&.MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '24px',
    },
  },

  modalActions2: {
    '&.MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default useModalStyles;
