/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from '@unform/core';

import { IconButton } from '@material-ui/core';

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { toast } from 'react-toastify';
import { ImageContainer, ImageNew, ImageNewContainer, ImagePreview } from './styles';

export default function ImgInput({
  acceptImg,
  edit,
  editNew,
  editRemove,
  isBanner,
  name,
  setImage,
  previewImg,
  size,
  ...rest
}) {
  const bodyNew = () => (
    <>
      <IconButton className="iconButton noEvent">
        <AddAPhotoIcon fontSize="large" />
      </IconButton>

      <br />

      <div>
        <span>enviar nova</span>
      </div>

      <div className="mt">
        <span>imagem</span>
      </div>
    </>
  );

  const { fieldName, registerField, defaultValue } = useField(name);
  const inputRef = useRef();
  const [preview, setPreview] = useState(defaultValue);

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    if (!file) {
      return setPreview(null);
    }

    if (Array.isArray(acceptImg)) {
      const imgRejected = acceptImg.find((item) => file.type === item);
      if (!imgRejected) return toast.error(`Tipo de imagem inválido, tente: ${acceptImg.map((item) => `${item}`)}`);
    }

    if (inputRef.current && file) {
      const previewURL = URL.createObjectURL(file);
      inputRef.current.acceptedFiles = file;

      if (setImage) {
        setImage(previewURL);
        setPreview(previewURL);
      } else {
        setPreview(previewURL);
      }
    }
  }, [setImage, acceptImg]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: acceptImg, onDrop });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => ref.acceptedFiles || [],
      clearValue: (ref) => {
        ref.acceptedFiles = [];
        setPreview([]);
      },
      setValue: (ref, value) => {
        ref.acceptedFiles = value;
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <div {...getRootProps()} onClick={() => inputRef.current?.click()}>
      <label htmlFor="button-file">
        {preview || previewImg
          ? (
            <ImageContainer size={size}>
              <ImagePreview src={preview || previewImg} alt="Preview" size={size} />

              {edit && (
                <div className="onImagePreview noDash">
                  <IconButton className="iconButton edit">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </div>
              )}

              {editRemove && (
                <div className="onImagePreview">
                  <IconButton className="iconButton editRemove">
                    <AddAPhotoIcon fontSize="medium" />
                    <span>Substituir</span>
                  </IconButton>

                  <IconButton className="iconButton editRemoveSpan">
                    <CloseIcon fontSize="medium" />
                    <span>Remover</span>
                  </IconButton>
                </div>
              )}
            </ImageContainer>
          )
          : (
            <ImageNew size={size}>
              {isDragActive ? (
                <>
                  <ImageContainer size={size}>
                    <div className="onFileDrop noDash">
                      {bodyNew()}
                    </div>
                  </ImageContainer>
                </>
              )
                : (
                  <ImageContainer>
                    <ImageNewContainer size={size} banner={isBanner}>
                      <AddAPhotoIcon fontSize="large" color="primary" />
                    </ImageNewContainer>

                    <div className="onImageNew noDash">
                      {bodyNew()}
                    </div>
                  </ImageContainer>
                )}
            </ImageNew>
          )}
      </label>

      <input
        accept={acceptImg}
        ref={inputRef}
        {...getInputProps({ refKey: inputRef })}
        {...rest}
      />
    </div>
  );
}
