import { InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import { remove } from 'lodash';
import React from 'react';
import Select from '../../form/select';
import TextField from '../../form/text-field';
import { ActionButton, useStyles } from '../../styles';
import { StyledTooltip } from '../../tooltip';
import CheckBox from '../../form/checkbox';
import PriceField from '../../form/price-field';

export default function VariantsList({ variants, setVariants, volumeUnit }) {
  const styles = useStyles();

  const removeInput = item => {
    const list = [...variants];

    remove(list, (data) => data === item);

    setVariants(list);
  };

  const unity = [
    'g',
    'Kg',
    'mL',
    'L',
    'un',
    'filmes',
    'sachês',
    'envelopes',
    ' pastilhas',
    'doses',
    'cápsulas',
    ' comprimidos',
    'biscoitos',
    'chocolates',
    'gomas',
    'glóbulos',
  ];

  return (
    <>
      {variants?.length && variants.map((x, i) => (
        <Grid item xs={12} sm={12} key={x.value}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={4} sm={4}>
              <TextField
                name={`replace_volume_variants[${i}].value`}
                placeholder="Volume ou Quantidade da Variante"
                label="Volume ou Quantidade da Variante"
                defaultValue={x?.value}
              />
            </Grid>

            <Grid item xs={3} sm={3}>
              <Select
                name={`replace_volume_variants[${i}].unit`}
                label="Un. de Volume"
                options={unity}
                value={volumeUnit}
                disabled
                // initialValue={x.unit}
              >
                {({ item }) => <MenuItem key={item} value={item}>{item}</MenuItem>}
              </Select>
            </Grid>

            <Grid item xs={3} sm={3}>
              <PriceField
                name={`replace_volume_variants[${i}].price_relative_perc`}
                label="Preço Vs Padrão"
                initialValue={x.price_relative_perc}
                separator="."
                InputProps={{
                  endAdornment: <InputAdornment className={styles.adornmentEnd}><span>%</span></InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={2} className={styles.gridButtonContainer}>
              <Grid container direction="row" justify="center" alignContent="center" spacing={4}>

                <Grid item xs={4} className={styles.gridButton}>
                  <StyledTooltip
                    placement="top"
                    title={x.visible
                      ? 'Marcar como invisível para o cliente'
                      : 'Marcar como visível para o cliente'}
                  >
                    <CheckBox
                      name={`replace_volume_variants[${i}].visible`}
                      defaultChecked={x?.visible}
                      icon={<VisibilityOffIcon className={styles.icon} color="primary" fontSize="default" />}
                      checkedIcon={<VisibilityOffIcon className={styles.icon} color="disabled" fontSize="default" />}
                    />
                  </StyledTooltip>
                </Grid>

                <Grid item xs={4} className={styles.gridButton}>
                  <StyledTooltip title="Remover" placement="top">
                    <ActionButton className={styles.actionButton} onClick={() => removeInput(x)}>
                      <CloseIcon className={styles.icon} color="disabled" fontSize="default" />
                    </ActionButton>
                  </StyledTooltip>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
