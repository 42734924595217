import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import TitleCard from '../../title/title-card';
// import SubtitleCard from '../../title/subtitle-card';
import Select from '../../form/select';

import { StyledPaper, useStyles } from '../../styles';

import featuredImg from '../../../assets/Produtos/featured-product.png';

import categoryStyles from './styles';

export default function FeaturedProducts({ featured }) {
  const styles = useStyles();
  const img = { backgroundImage: `url(${featuredImg})` };
  const stylesCategory = categoryStyles(img);
  const [featuredProduct, setFeaturedProduct] = useState('');

  const renderFeatured = ({ item, handleValue }) => <MenuItem key={item.id} value={item.id} onClick={handleValue}>{item.title}</MenuItem>;

  return (
    <>
      <TitleCard>Produto em destaque</TitleCard>

      <StyledPaper>
        <Grid container direction="row" className={styles.gridPaperTop}>
          <span className={styles.span}>
            Selecione um destaque entre os produtos que contém banner:
          </span>
        </Grid>

        <Grid container direcion="column" style={{ marginTop: '13px' }}>
          <Grid item xs={12} className={stylesCategory.gridFeaturedSelect}>
            <Select
              name="featured_product"
              label="Selecione o banner a partir do produto"
              variant="outlined"
              options={featured}
              value={featuredProduct}
              onChange={(e) => setFeaturedProduct(e.target.value)}
            >
              {renderFeatured}
            </Select>
          </Grid>

          {/*
          <Grid item xs className={stylesCategory.gridFeaturedSubtitle}>
            <SubtitleCard>Prévia:</SubtitleCard>

            <Grid container className={stylesCategory.imagePreview} justify="center" alignItems="center">
              <span className={stylesCategory.productName}>
                {featured && featured.title}
              </span>
            </Grid>
          </Grid>
          */}
        </Grid>
      </StyledPaper>
    </>
  );
}
