export const toBase64 = file => new Promise((resolve) => {
  if (!file) return null;

  if (file?.length === undefined && typeof file === 'object') {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    return true;
  }

  return resolve(file);
});
