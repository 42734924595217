import React, { useState } from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  Text,
}
  from '../styles';

function CheckBox({ label, onClick, id, defaultValue }) {
  const [checked, setChecked] = useState(() => (defaultValue ? defaultValue?.find((value) => value === id) : false));

  function handleCheckboxChange() {
    setChecked(!checked);
    onClick();
  }

  return (
    <CheckboxContainer
      style={{ minWidth: '68px' }}
      checked={checked}
      onClick={handleCheckboxChange}
    >
      <HiddenCheckbox
        onChange={handleCheckboxChange}
        checked={checked}
      />
      <Text checked={checked}>{label}</Text>
    </CheckboxContainer>
  );
}
export default CheckBox;
