import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
  },
  label: {
    fontSize: 12,
    color: '#424242',
    lineHeight: '12px',
  },
});

function FormRadioButton({ classes, label, ...rest }) {
  return (
    <FormControlLabel label={label} classes={{ label: classes.label }} {...rest} />
  );
}

export default withStyles(styles)(FormRadioButton);
