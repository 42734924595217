/* eslint-disable consistent-return */
import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../api';

import { Pagination } from '../@types/pagination';
import { ProductsItem } from '../@types/category';

interface IArray {
  [index: number]: string | number;
}
interface ISearchPost {
  p?: number;
  n?: number | 30;
  sortby?: 'id' | 'title' | 'created_at' | 'updated_at';
  sortorder?: 'a' | 'asc' | 'ascending' | 'd' | 'desc' | 'descending';
  q?: string;
  category_id?: number;
  status?: 'Draft' | 'Published' | 'Hidden';
  author?: string;
}
interface IAddData {
  title: string;
  abstract: string;
  body: string;
  author: string;
  keywords: IArray;
  related_posts: IArray;
  products_buy_separately: IArray;
  products_buy_together: IArray;
  status: 'Draft' | 'Published' | 'Hidden';
}
interface IAddPost {
  apiData: IAddData;
  displayMsg: boolean;
}
interface IUpdatePost {
  id: string;
  title: string;
  abstract: string;
  body: string;
  author: string;
  keywords: IArray;
  related_posts: IArray;
  products_buy_separately: IArray;
  products_buy_together: IArray;
  status: 'Draft' | 'Published' | 'Hidden';
}
interface IPostItem {
  abstract: string;
  author: string;
  created_at: number;
  id: string;
  image: IImageItem;
  keywords: IArray;
  title: string;
  updated_at: number;
}
interface IAddPostImage {
  id: string;
  image: string;
  content_type: string;
}
interface ISubscribeItem {
  email: string;
  name: string;
  gender: string;
  all_categories: boolean | Array<number>;
}
interface IKeywordsItem {
  words: IArray;
}
interface IImageItem {
  asset_id: number;
  image: string;
}
interface IProductItem {
  products: ProductsItem;
}
interface IPosts {
  posts: IPostItem;
}
interface BlogPostItem {
  abstract: string;
  author: string;
  body: string;
  created_at: number;
  id: number;
  image: IImageItem;
  keywords: IKeywordsItem;
  products_buy_separately: IProductItem;
  products_buy_together: IProductItem;
  related_posts: IPosts;
  title: string;
  updated_at: number;
}
interface BlogPostState {
  items: BlogPostItem;
  pagination: Pagination;
}
interface ICategoryAssign {
  ids: IArray;
}
interface IDeletePost {
  id: number;
  displayMsg: boolean;
}

interface IDisplayToast {
  toast: boolean;
}

interface IListAuthorsAndStatus {
  authors: IArray;
  statuses: IArray;
}

interface BlogPostContextData {
  isLoading: boolean;
  posts: BlogPostState;
  authorsAndStatus: IListAuthorsAndStatus;
  listPosts(): Promise<BlogPostState>;
  getPost(id: number): Promise<BlogPostItem | void>
  addPost(data: IAddPost): Promise<void>;
  updatePost(data: IUpdatePost): any;
  deletePost(data: IDeletePost): Promise<number | undefined>;
  addPostImage(data: IAddPostImage): Promise<void>;
  deletePostImage(id: number): Promise<void>;
  assignPostCategory(ids: ICategoryAssign): Promise<void>;
  searchPosts(data: ISearchPost): Promise<BlogPostState>;
  listAuthorsAndStatus(): Promise<IListAuthorsAndStatus>;
}

const BlogPostContext = createContext<BlogPostContextData>({} as BlogPostContextData);

export const BlogPostProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [posts, setPosts] = useState<BlogPostState>(() => ({} as BlogPostState));
  const [authorsAndStatus, setAuthorsAndStatus] = useState<IListAuthorsAndStatus>(() => ({} as IListAuthorsAndStatus));
  const [isLoading, setLoading] = useState<boolean>(false);

  /** LIST */
  const listPosts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/v2/blog/posts/list');
      setPosts(response.data);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível encontrar as postagens. Tente novamente.');
    }
  }, []);

  /** SEARCH POST */
  const searchPosts = useCallback(async (data: ISearchPost) => {
    setLoading(true);
    try {
      const response = await api.get('/v2/blog/posts/search', { params: data });
      setLoading(false);

      setPosts(response.data);
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível encontrar a postagem. Tente novamente.');
    }
  }, []);

  /** GET */
  const getPost = useCallback(async (id: number) => {
    setLoading(true);
    try {
      const response = await api.get(`/v2/blog/post/${id}`);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível buscar a postagem. Tente novamente.');
    }
  }, []);

  /** ADD */
  const addPost = useCallback(async (data: IAddPost) => {
    setLoading(true);
    const { apiData, displayMsg } = data;
    try {
      const response = await api.post('/v2/blog/post', apiData);
      setLoading(false);
      if (displayMsg) {
        toast.success('Post criado com sucesso!');
      }

      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível criar a postagem. Tente novamente.');
    }
  }, []);

  /** UPDATE */
  const updatePost = useCallback(async (data: any) => {
    setLoading(true);
    const response = await api.put(`/v2/blog/post/${data.id}`, data);

    setLoading(false);
    searchPosts({});

    return response;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** DELETE */
  const deletePost = useCallback(async (data: IDeletePost) => {
    setLoading(true);
    const { id, displayMsg } = data;
    try {
      const response = await api.delete(`/v2/blog/post/${id}`);
      setLoading(false);
      if (displayMsg) {
        toast.success('Post deletado com sucesso!');
      }

      searchPosts({});

      return response.status;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível deletar a postagem. Tente novamente.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** ADD POST IMAGE */
  const addPostImage = useCallback(async (data: IAddPostImage) => {
    setLoading(true);
    try {
      const { id } = data;
      const imgUpload = {
        content_type: data.content_type,
        image: data.image,
      };
      const response = await api.put(`/v2/blog/post/${id}/image`, imgUpload);
      setLoading(false);
      // toast.success('Imagem adicionada com sucesso!');
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível adicionar imagem a postagem. Tente novamente.');
    }
  }, []);

  /** DELETE POST IMAGE */
  const deletePostImage = useCallback(async (id: number) => {
    setLoading(true);
    try {
      const response = await api.delete(`/v2/blog/post/${id}/image`);
      setLoading(false);
      toast.success('Imagem deletada com sucesso!');
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível deletar a imagem da postagem. Tente novamente.');
    }
  }, []);

  /** ASSIGN CATEGORY TO POST */
  const assignPostCategory = useCallback(async (ids: ICategoryAssign) => {
    setLoading(true);
    try {
      const response = await api.put(`/v2/blog/post/category/${ids}`);
      setLoading(false);
      toast.success('Categorias atribuídas com sucesso!');
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível atribuir categorias a essa postagem. Tente novamente.');
    }
  }, []);

  /** LIST AUTHORS AND STATUS */
  const listAuthorsAndStatus = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/v2/blog/posts/search/filters?authors=true&statuses=true');
      setLoading(false);
      setAuthorsAndStatus(response.data);
      return response.data;
    } catch (err) {
      setLoading(false);
      toast.error('Não foi possível listar os autores. Tente novamente.');
    }
  }, []);

  return (
    <BlogPostContext.Provider
      value={{
        listPosts,
        getPost,
        addPost,
        updatePost,
        deletePost,
        addPostImage,
        deletePostImage,
        assignPostCategory,
        searchPosts,
        listAuthorsAndStatus,
        isLoading,
        posts,
        authorsAndStatus,
      }}
    >
      {children}
    </BlogPostContext.Provider>
  );
};

export function useBlogPosts(): BlogPostContextData {
  const context = useContext(BlogPostContext);

  if (!context) {
    throw new Error('useBlogPosts must be used within an ProductProvider');
  }
  return context;
}
