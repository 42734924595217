import React, { useCallback, useState } from 'react';

import { Grid, CircularProgress, MenuItem } from '@material-ui/core';

import { toast } from 'react-toastify';
import TitleCard from '../../title/title-card';

import GridContainer from '../components/grid-container';
import { ButtonPrimary, StyledPaper } from '../styles';
import { useSettings } from '../../../contexts/settings';
import Select from '../../form/select';

export default function FacebookTags({ formRef }) {
  const { settings, updateSocialMedia } = useSettings();
  const [isLoading, setLoading] = useState(false);

  const [is_enabled, setIsEnabled] = useState(!!settings.social.whatsapp_chat_enabled);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { chat } = formRef.current.getData();

      await updateSocialMedia({ whatsapp_chat_enabled: chat?.whatsapp_chat_enabled });
      setLoading(false);
      toast.success('Os dados do Website foram atualizados');
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro ao atualizar os dados da farmácia');
    }
  }, [updateSocialMedia, formRef]);

  const renderType = ({ item, handleValue }) => <MenuItem value={item.value} key={item.label} onClick={handleValue}>{item.label}</MenuItem>;

  return (
    <>
      <TitleCard color="#A3A3A3" bold>Chat</TitleCard>

      <StyledPaper>

        <GridContainer>

          <GridContainer container item width="auto" margin="12px" spacing={3}>
            <GridContainer container item xs={12} spacing={3}>

              <Grid item xs={6}>
                <Grid item container spacing={3} xs={12}>
                  <Grid item xs={12}>
                    <Select
                      options={[{ label: 'Habilitado', value: true }, { label: 'Desabilitado', value: false }]}
                      name="whatsapp_chat_enabled"
                      value={is_enabled}
                      placeholder="Botão de chat via WhatsApp"
                      onChange={(e) => setIsEnabled(e.target.value)}
                      label="Botão de chat via WhatsApp"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {renderType}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>

            </GridContainer>

            <GridContainer container item lg={12} mt="24px" justify="flex-end">
              <ButtonPrimary variant="contained" onClick={onSubmit} color="primary">
                {isLoading ? (
                  <CircularProgress size={15} style={{ color: '#fff' }} />
                ) : (
                  'salvar alterações'
                )}
              </ButtonPrimary>
            </GridContainer>
          </GridContainer>

        </GridContainer>
      </StyledPaper>
    </>
  );
}
