import api from '../api';
import errHandler from './_errhandler';
import store from '../store';
import { getSettings } from './token';

export const fetchUser = async () => {
  const userInfo = await api.get('/v2/agent/check-login')
    .then(async res => {
      const { agent, store_name } = res.data;

      await getSettings();

      store.set({ agent: { ...agent, store_name } });
    })
    .catch(err => {
      if (err.status === 400 || 500) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('token');

        window.location.reload();
      }
    });

  return userInfo;
};

export const facebookLogin = async (userData) => {
  const { settings } = store.get();

  await api.post('/v2/customer/login', {
    company_id: '01E6HBG4MK1W5HGK2AB33S8DFR',
    store_id: settings.store.id,
    type: 'facebook',
    facebook_token: userData.accessToken,
    version: '1',
  })
    .then(async (res) => {
      const { data } = res;

      if (data.step !== 'login_succeeded') {
        return null;
      }

      localStorage.setItem('accessToken', `Bearer ${data.jwt}`); // set token on localStorage

      const user = fetchUser(); // get customer informations using accessToken from localStorage

      return user;
    })
    .catch(err => errHandler(err));
};

export const login = async (data) => {
  const userLogin = await api.post('/v2/agent/login', {
    ...data,
    type: 'password',
  })
    .then(res => {
      const { jwt } = res.data;

      window.localStorage.setItem('accessToken', `Bearer ${jwt}`);
    })
    .catch(errHandler);

  return userLogin;
};
