/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useEffect, useRef } from 'react';

// Libs
import { useDropzone } from 'react-dropzone';
import { useField } from '@unform/core';
import { IconButton, Button } from '@material-ui/core';

// Icons
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

// Assets
import addImg from '../assets/images/addImg.png';

// Styles
import { RoundImage, UploadedImage } from '../styles';

export default function ImgInput({
  acceptImg,
  name,
  setImage,
  previewImg,
  remove,
  ...rest
}) {
  /** CONSTS */
  const { fieldName, registerField, defaultValue } = useField(name);
  const inputRef = useRef();

  const bodyNew = () => (
    <>
      <IconButton disabled className="iconButton noEvent" size="small" disableRipple>
        <AddAPhotoIcon fontSize="large" />
      </IconButton>

      <br />

      <div>
        <span>enviar nova</span>
      </div>

      <div className="mt">
        <span>imagem</span>
      </div>
    </>
  );

  /** STATES */
  const [preview, setPreview] = useState(defaultValue);

  /** EFFECTS */
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => ref.acceptedFiles || [],
      clearValue: (ref) => {
        ref.acceptedFiles = [];
        setPreview([]);
      },
      setValue: (ref, value) => {
        ref.acceptedFiles = value;
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  /** CALLBACKS */
  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    if (!file) {
      setPreview(null);
    }

    if (inputRef.current && file) {
      const previewURL = URL.createObjectURL(file);
      inputRef.current.acceptedFiles = file;

      if (setImage) {
        setImage(previewURL);
      } else {
        setPreview(previewURL);
      }
    }
  }, [setImage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: acceptImg, onDrop });

  function uploadAgain() {
    return inputRef.current?.click();
  }

  useEffect(() => {
    if (remove) {
      uploadAgain();
    }
  }, [remove]);

  return (
    // <div {...getRootProps()} onClick={() => inputRef.current?.click()}>
    <>
      <label htmlFor="button-file">
        {preview || previewImg
          ? (
            <div {...getRootProps()} onClick={() => uploadAgain()}>
              <UploadedImage>
                <img src={preview} alt="preview" />
              </UploadedImage>
            </div>
          )
          : (
            <div {...getRootProps()} onClick={() => uploadAgain()}>
              <RoundImage size={256}>
                {isDragActive ? (
                  <div className="imgContainer">
                    <div className="dragAndDrop">
                      {bodyNew()}
                    </div>
                  </div>
                ) : (
                  <div className="imgContainer">
                    <Button className="imgButton" disableRipple>
                      <img src={addImg} alt="addImg" />
                    </Button>
                    <div className="overlayImg">
                      {bodyNew()}
                    </div>
                  </div>
                )}
              </RoundImage>
            </div>
          )}
      </label>

      <input
        accept={acceptImg}
        ref={inputRef}
        {...getInputProps({ refKey: inputRef })}
        {...rest}
      />

    </>
  );
}
