import React from 'react';

import { Scope } from '@unform/core';
import TitleCard from '../../title/title-card';
import EditorPolicy from './editor';

export default function WhoWeAre({ content }) {
  return (
    <Scope path="who_we_are">
      <TitleCard color="#A3A3A3" bold> Quem Somos</TitleCard>

      <EditorPolicy content={content} policy_name="whoWeAre" policy_title="Quem Somos" />

    </Scope>
  );
}
