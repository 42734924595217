import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Accordion, AccordionDetails, AccordionSummary, Hidden, List, ListItem, makeStyles,
} from '@material-ui/core';

import { ChevronRightOutlined } from '@material-ui/icons';

import { ChevronRotate, Container, DrawerItem } from './styles';

export const useStyles = makeStyles(() => ({
  activeLink: {
    background: '#EDF8F6',

    '& p, svg': {
      color: '#22E0A1 !important',
      fontWeight: 'bold',
    },
  },

  activeSub: {
    '& p': {
      color: '#22E0A1 !important',
    },
  },

  chevron: {
    color: '#22E0A1 !important',
    margin: '0 10px 0 30px !important',
  },
  disabledLink: {
    pointerEvents: 'none',
    cursor: 'default',
    color: '#A3A3A3',
  },

}));

export default function Item({ icon, items, title, disabled, ...rest }) {
  const history = useHistory();
  const styles = useStyles();

  const { pathname } = history.location;

  const [active, setActive] = useState();
  const [rotation, setRotation] = useState(0);
  const [rotated, setRotated] = useState(0);

  useEffect(() => {
    function changeActive() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        if (pathname === items[i].link || pathname.includes(items[i].link.substring(1), 5)) {
          setActive(true);
          return;
        }

        setActive(false);
      }
    }

    changeActive();
  }, [items, pathname]);

  const handleRotation = () => {
    if (rotation === 0) {
      setRotation(1);
    } else {
      setRotation(0);
      setRotated(0);
    }
  };

  return (
    <>
      {items.length === 1 ? (
        <Link to={items[0].link} style={{ textDecoration: 'none' }} className={disabled && styles.disabledLink} onClick={(e) => disabled && e.preventDefault()} {...rest}>
          <DrawerItem className={pathname === items[0].link ? styles.activeLink : {}}>
            <ListItem className="noPadding">
              {icon}

              <Hidden xsDown>
                <p style={{ marginLeft: 16, color: disabled ? '#A3A3A3' : '#424242' }}>{items[0].text}</p>
              </Hidden>

              <ChevronRightOutlined />
            </ListItem>
          </DrawerItem>
        </Link>
      )
        : (
          <Container>
            <Accordion>
              <AccordionSummary className={disabled && styles.disabledLink} onClick={(e) => disabled && e.preventDefault()}>
                <DrawerItem className={`${active ? styles.activeLink : {}} ${disabled && styles.disabledLink}`} onClick={(e) => (disabled ? e.preventDefault() : (handleRotation()))}>
                  <ListItem className="noPadding">
                    {icon}

                    <Hidden xsDown>
                      <p style={{ marginLeft: 16, color: disabled ? '#A3A3A3' : '#424242' }}>{title}</p>
                    </Hidden>

                    <ChevronRotate>
                      <ChevronRightOutlined onAnimationEnd={() => setRotated(1)} rotated={rotated} rotation={rotation} />
                    </ChevronRotate>
                  </ListItem>
                </DrawerItem>
              </AccordionSummary>

              <AccordionDetails>
                <List className="noPadding">
                  {items.map((i) => (
                    i.link.includes('redirect') ? (
                      <a
                        href={i.link} target="_blank" rel="noreferrer noopener" style={{
                          textDecoration: 'none',
                        }}
                      >
                        <DrawerItem>
                          <ChevronRightOutlined />
                          <ListItem className="noPadding">
                            <Hidden xsDown>
                              <p style={{ marginLeft: 16, color: '#424242' }}>{i.text}</p>
                            </Hidden>
                          </ListItem>
                        </DrawerItem>
                      </a>
                    ) : (
                      <Link key={i.text} to={i.link} style={{ textDecoration: 'none' }} className={i?.disabled && styles.disabledLink} onClick={(e) => i?.disabled && e.preventDefault()}>
                        <DrawerItem className={pathname === i.link || pathname.includes(i.link.substring(1), 5) ? styles.activeSub : {}}>
                          <ChevronRightOutlined />

                          <Hidden xsDown>
                            <p style={{ marginLeft: 16, color: i?.disabled ? '#A3A3A3' : '#424242' }}>{i.text}</p>
                          </Hidden>
                        </DrawerItem>
                      </Link>
                    )
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Container>
        )}
    </>
  );
}
