import React from 'react';

import { Grid } from '@material-ui/core';

export default function TabPanel({ children, value, index, ...other }) {
  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sm={12}
      xs={12}
      lg={12}
      {...other}
    >
      {children}
    </Grid>
  );
}
