import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Grid } from '@material-ui/core';
import { Form } from '@unform/web';

// import SortIcon from '@material-ui/icons/Sort';

// import { toast } from 'react-toastify';
import Spinner from '../spinner';
import ContentWrapper from '../content-wrapper';
// import Filters from './components/filters';
import { Schema } from './schema';
import SearchField from '../form/search-field';
import TableMaterial from '../table';
import Breadcrumb from '../layout/app-header/app-bar/breadcrumb';

import { Container, PageTitle, useStyles } from '../styles';

import { useSettings } from '../../contexts/settings';

import Data from './data';
import { useBranch } from '../../contexts/branch';

export default function BranchList() {
  const styles = useStyles();
  const formRef = useRef();
  const history = useHistory();

  // const [newSettings, setNewSettings] = useState();

  const [parseSearch, setParseSearch] = useState('');

  const { settings, fetchSettings, isLoading } = useSettings();
  const { branchs } = useBranch();

  const { search } = useLocation();

  const renderItem = (set) => <Data branch={set} />;

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const getBranch = async () => {
      await fetchSettings(allParams);
    };

    getBranch();
  }, [fetchSettings, search]);

  useEffect(() => {
    function getValue() {
      const splitSearch = search.split('&');
      const getAllParams = new URLSearchParams(search);
      const allParams = Object.fromEntries(getAllParams);

      const keywords = splitSearch.map(item => {
        if (item.indexOf('name=') !== -1 && formRef.current) {
          setParseSearch(item.replace('name=', '').replace('?', ''));

          return formRef.current.setFieldValue('search', allParams?.name);
        }

        return null;
      });

      return keywords;
    }

    getValue();
  }, [search]);

  // const handleNewBranch = useCallback(async () => {
  //   setNewBranchLoading(true);

  //   try {
  //     const newBranch = await addBranch({});
  //     setNewBranchLoading(false);

  //     if (newBranch.id) return history.push(`branch/${newBranch.id}`);

  //     return history.push('/branch');
  //   } catch (err) {
  //     setNewBranchLoading(false);
  //     return toast.error('Ocorreu um erro ao criar uma nova loja. Tente novamente mais tarde');
  //   }
  // }, [addBranch, history]);

  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);
    const params = {};

    params[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...params, ascending: 0 };
    } else {
      newParams = { ...allParams, ...params, ascending: 1 };
    }

    if (param === 'name') {
      newParams.p = 1;
      const isEqual = allParams?.q === data;

      if (!isEqual) { formRef.current.reset(); }
    }

    return history.push({
      pathname: '/branch',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  return (
    <>
      <Breadcrumb title="Gerenciar Lojas" text="Configurações" current="Lojas" />

      <ContentWrapper align="top">
        <Grid container direction="row" alignItems="center" className={styles.gridRow}>
          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>Lista de Lojas</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <Form ref={formRef} onSubmit={(formData) => handleParams('name', formData.search)}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchField}>
                  <SearchField
                    name="search"
                    handleParams={handleParams}
                    search={search}
                    label="buscar por apelido da loja"
                    placeholder="buscar apelido da loja"
                    parseSearch={parseSearch}
                    clear={() => handleParams('name', '')}
                  />
                </div>

                {/*
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.buttonFilter}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <SortIcon />
                </Button>
                */}

                <Button
                  disabled
                  className={styles.filterButton}
                  variant="contained"
                  color="primary"
                  type="button"
                  // onClick={handleNewBranch}
                >
                  adicionar nova loja
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>

        {/* showFilter && (
          <Filters />
        ) */}

        {isLoading ? <Spinner /> : (
          <Container className={styles.containerTableBottom}>
            <TableMaterial
              headers={Schema}
              renderItem={renderItem}
              listQuery={branchs.items}
              results={settings?.total_count}
              noPagination
              handleParams={handleParams}
            />
          </Container>
        )}
      </ContentWrapper>
    </>
  );
}
