import React, { useCallback, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import EditIcon from '@material-ui/icons/Edit';

import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Modal from '../../../modal';
import Neighborhood from './neighborhood';

import { StyledTooltip } from '../../../tooltip';
import useStyles from '../styles';

import { useSettings } from '../../../../contexts/settings';
import DeleteLocation from './delete';
import { useAuth } from '../../../../contexts/auth';

export default function DataDsitrict({ data }) {
  const styles = useStyles();
  const formRef = useRef();

  const { verifyPerms } = useAuth();
  const { updateLocation } = useSettings();
  const { id: branch_id } = useParams();

  const dataDistrict = { ...data, service_cost: data?.service_cost };

  const [modalDistrict, setModalDistrict] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [districtWeekDays, setDistrictWeekDays] = useState(data?.week_availibility);

  const updateDistrict = useCallback(async () => {
    setLoading(true);
    const dataDistrictForm = formRef.current.getData();

    try {
      const shipping_time = Number(dataDistrictForm.district.shipping_time);
      await updateLocation({ id: data?.id, branch_id, body: { ...dataDistrictForm.district, shipping_time, week_availibility: districtWeekDays } });

      setModalDistrict(!modalDistrict);

      toast.success(`O bairro ${dataDistrictForm.district.district} foi atualizado!`);
      setLoading(false);
    } catch (err) {
      toast.error('Ocorreu um erro');
      setLoading(false);
    }
  }, [data, branch_id, updateLocation, districtWeekDays, modalDistrict]);

  const days = useMemo(() => {
    const formatDays = {
      monday: 'SEG ',
      tuesday: 'TER ',
      wednesday: 'QUA ',
      thursday: 'QUI ',
      friday: 'SEX ',
      saturday: 'SÁB ',
      sunday: 'DOM ',
    };

    const mapped = data.week_availibility.map((item) => formatDays[item]);

    return mapped;
  }, [data]);

  const modality = useMemo(() => {
    const formatModality = {
      ground: 'Terrestre',
      air: 'Aéreo',
      sea: 'Marítimo',
    };

    const formtedModality = formatModality[data.service_type];

    return formtedModality;
  }, [data]);

  return (
    <>
      <TableCell>{data?.state}</TableCell>
      <TableCell>{data?.city}</TableCell>
      <TableCell>{data?.district}</TableCell>
      <TableCell>{days}</TableCell>
      <TableCell>{Number(data?.service_cost).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
      <TableCell>{data?.service_name}</TableCell>
      <TableCell>{data?.shipping_time} Dias</TableCell>
      <TableCell>{modality}</TableCell>

      <TableCell style={{ width: '160px' }}>
        <Grid container direction="row">
          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Editar Cidade" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'write' })}
                className={styles.actionButton}
                onClick={() => setModalDistrict(!modalDistrict)}
              >
                <EditIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>

          <Grid item style={{ marginLeft: '-2px' }}>
            <StyledTooltip title="Remover Bairro" placement="top" className={styles.tableButton}>
              <IconButton
                disabled={verifyPerms({ role: 'Conf. > Lojas', access: 'delete' })}
                className={styles.actionButton}
                onClick={() => setDeleteModal(true)}
              >
                <CloseIcon className={styles.icon} color="disabled" fontSize="small" />
              </IconButton>
            </StyledTooltip>
          </Grid>
        </Grid>
      </TableCell>
      <Form ref={formRef} initialData={{ district: dataDistrict }}>

        <Modal
          isVisible={modalDistrict}
          title="Atualizar Bairro"
          toggle={() => setModalDistrict(!modalDistrict)}
          buttonPrimary="atualizar"
          isLoading={isLoading}
          buttonSecondary="cancelar"
          onClick={updateDistrict}
        >
          <Neighborhood weekDays={districtWeekDays} setWeekDays={setDistrictWeekDays} />
        </Modal>
      </Form>

      <DeleteLocation openModal={deleteModal} setModal={setDeleteModal} id={data.id} location={data.district} />

    </>
  );
}
