import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import * as Yup from 'yup';

// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import { Form } from '@unform/web';
// import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';
// import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

// import TextField from '../../form/text-field';

import { CardLogin } from '../styles';
import { useAuth } from '../../../contexts/auth';

// import { useAuth } from '../../../contexts/auth';

export default function LoginEmail() {
  // const styles = useStyles();
  const history = useHistory();
  const user = useAuth();
  // const { agentLogin } = useAuth();
  const params = useParams();
  // const refs = useRef(null);
  // const [isLoading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user?.id) {
      return history.push('/');
    }
    const getAllParams = new URLSearchParams(location.search);
    const allParams = Object.fromEntries(getAllParams);

    async function autoLogin() {
      localStorage.setItem('token', allParams.access);
      localStorage.setItem('accessToken', `Bearer ${allParams.access}`);
      if (allParams.redirect) {
        return history.push(`/${allParams.redirect}`);
      }
      return history.push('/');
    }

    if (allParams?.access) {
      autoLogin();
    } else {
      // window.location.replace('https://pp-website-prod.vercel.app/');
      window.location.replace(process.env.REACT_APP_WEBSITE_URL);
    }
  }, [params.cpf, location.search, history, user]);

  // const handleSubmit = async (formData) => {
  //   try {
  //     setLoading(true);

  //     await agentLogin(formData);

  //     if (!redirect) return history.push('/products');

  //     return history.push(redirect);
  //   } catch (error) {
  //     const validatorError = {};

  //     if (error instanceof Yup.ValidationError) {
  //       error.inner.forEach(err => {
  //         validatorError[err.path] = err.message;
  //       });

  //       refs.current.setErrors(validatorError);
  //     }

  //     setLoading(false);
  //     toast.error('Usuário ou senha incorreta.');
  //     return false;
  //   }
  // };

  return (
    <>
      <CardLogin style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none' }}>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      </CardLogin>
      {/* <CardLogin cardEmail>
        <Title>
          Fazer Login
        </Title>

        <Subtitle mt="16px">
          Ir para a interface administrativa da Pedido Pago
        </Subtitle>

        <Form onSubmit={handleSubmit} ref={refs}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                name="username"
                label="E-mail"
                placeholder="nome@exemplo.com.br"
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '30px' }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                name="password"
                label="Senha"
                placeholder="********"
                InputLabelProps={{ shrink: true }}
                type="password"
                required
              />
            </Grid>
          </Grid>

          <Grid container justify="center" className={styles.gridButton}>
            <Grid item sm={12} className="item">
              {isLoading ? (
                <CircularProgress className="spinner" />
              )
                : (
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={styles.button}
                  >
                    Continuar
                  </Button>
                )}
            </Grid>
          </Grid>
        </Form>

        <Subtitle mt="24px" padding="0 36px">
          <span>Esqueceu a senha?</span> Receba link de troca de senha no email cadastrado.
        </Subtitle>
      </CardLogin>

      <RowBottom>
        <Button
          onClick={() => { history.push('/'); }}
          color="primary"
          type="button"
          className={styles.buttonText}
        >
          <KeyboardBackspaceIcon className={styles.iconKeyboard} />
          voltar para página inicial
        </Button>
      </RowBottom> */}
    </>
  );
}
