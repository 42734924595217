import React, { useRef, useCallback, useState, useEffect } from 'react';

// Libs
import { Grid, Button } from '@material-ui/core';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import Breadcrumb from '../../layout/app-header/app-bar/breadcrumb';
import ContentWrapper from '../../content-wrapper';
import SearchField from '../../form/search-field';
import Spinner from '../../spinner';
import TableMaterial from '../../table';
// import Select from '../../form/select';

// Schemas
import { RolesAndPermissionsSchema } from './schema';

// Context
// import { useColaborators } from '../../../contexts/contributors';

// Data
import RolesAndPermissionsData from './data';

// Styles
import { PageTitle, useStyles } from '../../styles';
import { Container } from '../styles';
import { useGroups } from '../../../contexts/group';

function RolesAndPermissions() {
  /** CONSTS */
  const styles = useStyles();
  const formRef = useRef();

  const history = useHistory();
  const { search } = useLocation();
  const { groups, fetchGroups, isLoading } = useGroups();

  // const { colaborators, fetchColaborators, isLoading } = useColaborators();
  const renderItem = (group) => <RolesAndPermissionsData roleAndPermission={group} refetch={fetchGroups} />;

  /** STATES */
  // const [isLoading] = useState(false);
  const [parseSearch, setParseSearch] = useState('');

  useEffect(() => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    setParseSearch(allParams.name);
    formRef.current.setFieldValue('search', allParams?.name);
    const getGroup = async () => {
      await fetchGroups(allParams);
    };

    getGroup();
  }, [search, fetchGroups]);

  // useEffect(() => {
  //   function getValue() {
  //     const splitSearch = search.split('&');
  //     const getAllParams = new URLSearchParams(search);
  //     const allParams = Object.fromEntries(getAllParams);

  //     const keywords = splitSearch.map(item => {
  //       if (item.indexOf('q=') !== -1 && formRef.current) {
  //         setParseSearch(item.replace('name=', '').replace('?', ''));

  //         return formRef.current.setFieldValue('search', allParams?.name);
  //       }

  //       return null;
  //     });

  //     return keywords;
  //   }

  //   getValue();
  // }, [search]);

  /** CALLBACKS */
  const handleParams = useCallback((param, data) => {
    const getAllParams = new URLSearchParams(search);
    const allParams = Object.fromEntries(getAllParams);

    const params = {};
    params[param] = data;

    let newParams = {};

    if (allParams.ascending === '1' && allParams[param] === data) {
      newParams = { ...allParams, ...params, ascending: 1 };
    } else {
      newParams = { ...allParams, ...params, ascending: 0 };
    }

    if (param === 'name') {
      newParams.p = 1;
      const isEqual = allParams?.name === data;
      if (!isEqual) {
        formRef.current.reset();
      }
    }

    return history.push({
      pathname: '/roles-and-permissions',
      search: `?${new URLSearchParams(newParams)}`,
    });
  }, [search, history]);

  return (
    <>
      <Breadcrumb
        title="Gerenciar Cargos e Permissões"
        text="Organização"
        current="Cargos e Permissões"
      />

      <ContentWrapper align="top">
        <Grid container direction="row" alignItems="center" className={styles.gridRow}>
          <Grid item xs={3} className={styles.gridTitle}>
            <PageTitle>Lista de Cargos</PageTitle>
          </Grid>

          <Grid item xs={9}>
            <Form ref={formRef} onSubmit={(formData) => handleParams('name', formData.search)}>
              <div className={styles.divFlexEnd}>
                <div className={styles.divSearchField}>
                  <SearchField
                    name="search"
                    handleParams={handleParams}
                    search={search}
                    label="buscar cargo"
                    placeholder="buscar cargo"
                    parseSearch={parseSearch}
                    clear={() => handleParams('name', '')}
                  />
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => history.push('/edit-roles-and-permissions/create')}
                  style={{ marginLeft: '24px' }}
                >
                  cadastrar cargo e permissões
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>
      </ContentWrapper>
      {(isLoading || !groups.length) ? <Spinner /> : (
        <Container className={styles.containerTableBottom}>
          <TableMaterial
            headers={RolesAndPermissionsSchema}
            noPagination
          // pagination={colaborators.pagination}
            renderItem={renderItem}
            listQuery={groups}
          // handleParams={handleParams}
          />
        </Container>
      )}
    </>
  );
}

export default RolesAndPermissions;
