import dotenv from 'dotenv';

dotenv.config();

const env = process.env || {};

function getApiUrl() {
  return env.REACT_APP_API_URL;
}

function getStoreId() {
  return env.REACT_STORE_ID;
}

function getGTMID() {
  return env.REACT_APP_GTM_ID;
}

export default {
  apiUrl: getApiUrl(),
  storeId: getStoreId(),
  gtmID: getGTMID(),
};
